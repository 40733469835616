import { BiPrinter } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx"; 
import { BiLockOpenAlt } from "react-icons/bi"; 
import { BiLockAlt } from "react-icons/bi"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import CxTable from '../../cx/CxTable';
import CxButtonMenu from '../../cx/CxButtonMenu';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';
import CxDlgRadioList from '../../cx/CxDlgRadioList';
import CxChooser from '../../cx/CxChooser';
import CxDate from '../../cx/CxDate';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import * as FxFetch from '../../fx/FxFetch';
import * as FxStr from '../../fx/FxStr';
import DocType from '@material-ui/icons/Receipt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useWindowSize } from '../../hx/useWindowSize';
import { getStoreValueBo, clearStore, StoreSlsLst, StoreSlsDoc, StoreCyStats, StoreLongTask } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import * as MxSlsDocFormH1 from './MxSlsDocFormH1';
import CxToolbar from '../../cx/CxToolbar';
import CxDialog from '../../cx/CxDialog';
import { makeStyles } from '@material-ui/core/styles';
import { BiDotsVerticalRounded, } from "react-icons/bi";
import MxSlsDocListQuickPaymDlg from './MxSlsDocListQuickPaymDlg';
import CxSnackbar from '../../cx/CxSnackbar';
import { isApp } from '../../gx/GxStore';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';
import GxPrint from '../../gx/GxPrint';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles({
  toggleButtonGroup: {
    background: 'var(--ibs_color_gray_light)',
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'var(--ibs_color_white)',
    },
    '& .MuiToggleButton-root': {
      padding: '0px 10px 0px 10px',
    },
  },
});

const MxSlsDocList = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const [{ navTo }] = useNav();
  const size = useWindowSize();
  const { state } = useLocation(); //captura los parámetros de navBack()

  const [useInvoices,] = React.useState(getStoreValueBo('StoreSession', 'boUseInvoices'));
  const [useReceipts,] = React.useState(getStoreValueBo('StoreSession', 'boUseReceipts'));

  const hasColumnBranch = () => { return Boolean(localStorage.getItem('MxSlsDocList.addcol_txBranchId') === '1') };
  const hasColumnSeries = () => { return Boolean(localStorage.getItem('MxSlsDocList.addcol_txTypeSeries') === '1') };
  const hasColumnReference = () => { return Boolean(localStorage.getItem('MxSlsDocList.addcol_txReference') === '1') };

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const rf_table = React.useRef(null);
  const [lockOps, setLockedOps] = React.useState(true);

  const [dlgAskSureToClone, setDlgAskSureToClone] = React.useState(false);
  const [inXXXToClone, setInXXXToClone] = React.useState(false);
  
  let r_print = React.useRef(null);
  
  let r_snack_error = React.useRef(null);
  let r_snack_required = React.useRef(null);
  let r_dlg_quick_paym = React.useRef(null);

  let r_dlg_special_options = React.useRef(null);
  let r_dlg_special_options_ask_from_n_series = React.useRef(null);
  let rf_special_options_from = React.useRef(null);
  let rf_special_options_series = React.useRef(null);
  const [dlgSpecialOptionsAskFromAndSeries, setDlgSpecialOptionsAskFromAndSeries] = React.useState(false);

  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);
  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);
  // eslint-disable-next-line
  const [storeCyStats, setStoreCyStats] = useSessionStorage('StoreCyStats', StoreCyStats);

  const [docTitle, setDocTitle] = React.useState(trans('field.invoices'));

  React.useEffect(() => {
    FxLog.infox('MxSlsDocList.useEffect', '...', storeSlsLst);
    changeDocType(null, storeSlsLst.listType || getDefaultListType());
    clearStore('StoreLongTask', storeLongTask);
    if (state) {
      FxLog.infox('MxSlsDocList.useEffect', '...state:', state);
      rf_table.current.setSearchForced(state);
    }

    // eslint-disable-next-line
  }, []);

  const getDefaultListType = () => {
    if (useInvoices) return 'i';
    if (!useInvoices && useReceipts) return 'r';
    if (!useInvoices && !useReceipts) return 'q';
  }

  const showSpecialOptions = async () => {
    if (getStoreValueBo('StoreSession', 'boInvoicingUpdateDelete')) {
      r_dlg_special_options.current.setOpenAndFill(true);
    }
  }

  const doSpecialOption = (option) => {
    storeLongTask._url = 'sls/doclist/special_options';
    let record = { 
      searchText: rf_table.current.getSearchText(), 
      tooltip: rf_table.current.getTooltipText(), 
      dateFrom: rf_table.current.getOptionalFrom(), 
      dateTo: rf_table.current.getOptionalTo(), 
    };
    storeLongTask._canCancel = true;
    switch (option) {
      case "delete":
        if (storeSlsLst.listType === 'r') {
          storeLongTask._record = { txTaskName: "delete_listed_receipts", ...record };
          storeLongTask._warning = trans('msg.delete_warning_x_0', 'field.receipts');
        }
        if (storeSlsLst.listType === 'i') {
          storeLongTask._record = { txTaskName: "delete_listed_invoices", ...record };
          storeLongTask._warning = trans('msg.delete_warning_x_1', 'field.invoices');
        }
        if (storeSlsLst.listType === 'q') {
          storeLongTask._record = { txTaskName: "delete_listed_quotations", ...record };
          storeLongTask._warning = trans('msg.delete_warning_x_1', 'field.quotations');
        }
        break;
      case "renum":
        if (storeSlsLst.listType === 'r') {
          storeLongTask._record = { txTaskName: "renum_listed_receipts", ...record };
          storeLongTask._warning = trans('msg.renum_warning_x_0', 'field.receipts');
        }
        if (storeSlsLst.listType === 'i') {
          storeLongTask._record = { txTaskName: "renum_listed_invoices", ...record };
          storeLongTask._warning = trans('msg.renum_warning_x_1', 'field.invoices');
        }
        if (storeSlsLst.listType === 'q') {
          storeLongTask._record = { txTaskName: "renum_listed_quotations", ...record };
          storeLongTask._warning = trans('msg.renum_warning_x_1', 'field.quotations');
        }
        break;
      default: break;
    }
    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
      if (option === 'delete') {
        navTo('sls-doc-list-spec-opt');
      }
      if (option === 'renum') {
        setDlgSpecialOptionsAskFromAndSeries(true);
      }
    });
  }

  const doSpecialOptionRenum = () => {
    if (rf_special_options_from.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_special_options_from.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    let dtFrom = rf_special_options_from.current.getVal();
    let itSeries = rf_special_options_series.current.getKey();
    storeLongTask._record = { ...storeLongTask._record, dtFrom: dtFrom, itSeries: itSeries };
    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
      navTo('sls-doc-list-spec-opt');
    });
  }

  const openForm = async (op, row) => {
    FxLog.infox('MxSlsDocList.openForm', '...op:', op);
    storeSlsDoc.boFirstRender = true;
    storeSlsDoc.boDataChanged = '0';
    storeSlsDoc.docType = storeSlsLst.listType;
    if (row === null) {
      storeSlsDoc.inXXX = null;
      setStoreSlsDoc(storeSlsDoc);
      navTo("sls-doc-form");
    } else {
      storeSlsDoc.inXXX = row.original[storeSlsLst.table_idField];
      storeSlsDoc.boUpdateDelete = row.ctrlKey && getStoreValueBo('StoreSession', 'boInvoicingUpdateDelete');
      Promise.all([MxSlsDocFormH1.load(storeSlsLst, storeSlsDoc)]).then((result) => {
        setStoreSlsDoc(storeSlsDoc);
        navTo("sls-doc-form");
      });
    }
  }

  const changeDocType = (event, newType) => {
    clearStore('StoreSlsDoc', storeSlsDoc);
    if (newType === null) {
      return;
    }
    let hasTypeChanged = false;
    if (newType !== storeSlsLst.listType) {
      hasTypeChanged = true;
    }
    switch (newType) {
      case 'q':
        storeSlsLst.listType = 'q';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_yellow_light)';
        storeSlsLst.table = 'n_quotation';
        storeSlsLst.table_idField = 'inQuotation';
        storeSlsLst.table_txField = 'txQuotation';
        storeSlsLst.table_dtField = 'dtQuotation';
        storeSlsLst.table_item = 'n_quotation_item';
        storeSlsLst.table_item_idField = 'inQuotationItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        Promise.all([setStoreSlsLst(storeSlsLst)]).then((result) => {
          setDocTitle(trans('field.quotations'));
          rf_table.current.setTableID(`MxSlsDocList-${storeSlsLst.listType}`);
          rf_table.current.setTooltipText(getTooltip());
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/q');
        });
        break;
      case 'i':
        storeSlsLst.listType = 'i';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storeSlsLst.table = 'n_invoice';
        storeSlsLst.table_idField = 'inInvoice';
        storeSlsLst.table_txField = 'txInvoice';
        storeSlsLst.table_dtField = 'dtInvoice';
        storeSlsLst.table_item = 'n_invoice_item';
        storeSlsLst.table_item_idField = 'inInvoiceItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        Promise.all([setStoreSlsLst(storeSlsLst)]).then((result) => {
          setDocTitle(trans('field.invoices'));
          rf_table.current.setTableID(`MxSlsDocList-${storeSlsLst.listType}`);
          rf_table.current.setTooltipText(getTooltip());
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/i');
        });
        break;
      case 'r':
        storeSlsLst.listType = 'r';
        storeSlsLst.listTypeBackground = 'var(--ibs_color_green_light)';
        storeSlsLst.table = 'n_receipt';
        storeSlsLst.table_idField = 'inReceipt';
        storeSlsLst.table_txField = 'txReceipt';
        storeSlsLst.table_dtField = 'dtReceipt';
        storeSlsLst.table_item = 'n_invoice_item';
        storeSlsLst.table_item_idField = 'inInvoiceItem';
        storeSlsLst.table_paym_idField = 'inPayment';
        Promise.all([setStoreSlsLst(storeSlsLst)]).then((result) => {
          setDocTitle(trans('field.receipts'));
          rf_table.current.setTableID(`MxSlsDocList-${storeSlsLst.listType}`);
          rf_table.current.setTooltipText(getTooltip());
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('sls/doclist/r');
        });
        break;

      default:
        FxLog.errorx("MxSlsDocList.openForm", "Cannot find doctype!!! -> " + newType);
        break;
    }
  };

  const doLineOptions = (option, row) => {
    if (!option) {
      return;
    }
    try {

      // Marca presupuesto como aceptado o pendiente
      if (option === 'QA') {
        let record = {};
        record['inQuotation'] = row.inQuotation;
        Promise.all([FxFetch.doJsonX('sls/doclist/q/change_status', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theValue === 'OK') {
              rf_table.current.refresh();
            }
          }
        });
      }

      // pago rápido albarán
      if (option === 'IP') {
        Promise.all([r_dlg_quick_paym.current.setRow(row), r_dlg_quick_paym.current.setListType(storeSlsLst.listType)]).then((result) => {
          let record = {};
          record['inClient'] = row.inClient;
          Promise.all([FxFetch.doJsonX('sls/doclist/get_client_debt', record)]).then((result) => {
            if (result[0]) {
              if (result[0].theValue) {
                r_dlg_quick_paym.current.setClientName(row.txClient);
                r_dlg_quick_paym.current.setAmountAll(result[0].theValue);
                r_dlg_quick_paym.current.setAmountOne(row.nuTotalFinal - row.nuTotalPayment || '0');
                r_dlg_quick_paym.current.setTableToRefresh(rf_table);
                r_dlg_quick_paym.current.setDlg(true);
              }
            }
          });
        });
      }

      // pago rápido factura
      if (option === 'RP') {
        Promise.all([r_dlg_quick_paym.current.setRow(row), r_dlg_quick_paym.current.setListType(storeSlsLst.listType)]).then((result) => {
          let record = {};
          record['inClient'] = row.inClient;
          Promise.all([FxFetch.doJsonX('sls/doclist/get_client_debt', record)]).then((result) => {
            if (result[0]) {
              if (result[0].theValue) {
                r_dlg_quick_paym.current.setClientName(row.txClient);
                r_dlg_quick_paym.current.setAmountAll(result[0].theValue);
                r_dlg_quick_paym.current.setAmountOne(row.nuTotalFinal - row.nuTotalPayment || '0');
                r_dlg_quick_paym.current.setTableToRefresh(rf_table);
                r_dlg_quick_paym.current.setDlg(true);
              }
            }
          });
        });
      }

      // clona albarán
      if (option === 'IC') {
        Promise.all([setInXXXToClone(row.inInvoice)]).then((result) => {
          setDlgAskSureToClone(true);
        });
      }

      // clona albaranes de factura
      if (option === 'RC') {
        Promise.all([setInXXXToClone(row.inReceipt)]).then((result) => {
          setDlgAskSureToClone(true);
        });
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocList.doLineOptions", error);
    }
  }

  const doClone = () => {
    try {
      if (storeSlsLst.listType === 'i') {
        // clona albarán
        let record = {};
        record['inInvoice'] = inXXXToClone;
        record['boNegative'] = '0';
        Promise.all([FxFetch.doJsonX('sls/doclist/i/clone', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theValue === 'OK') {
              rf_table.current.redisplay();
            }
          }
        });
      }

      if (storeSlsLst.listType === 'r') {
        // clona albaranes de factura
        let record = {};
        record['inReceipt'] = inXXXToClone;
        record['boNegative'] = '0';
        Promise.all([FxFetch.doJsonX('sls/doclist/r/clone_invoices', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theValue === 'OK') {
              changeDocType(null, 'i');
            }
          }
        });
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocList.doClone", error);
    }
  }

  const doPrintX = async (option, row) => {
    let boPreview = false;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = row.inClient;
    filter['inXXX'] = FxStr.choose(storeSlsLst.listType, 'q|i|r', row.inQuotation, row.inInvoice, row.inReceipt);
    filter['docType'] = storeSlsLst.listType;
    filter['docSize'] = option;
    filter['nuCopies'] = '0';
    filter['salesSlipWidth'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_width') || '60';
    filter['salesSlipMarginLeft'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_margin_left') || '0';
    let record = {};
    if (filter['docSize'] === 'A8') {
      r_print.current.setScale(2.0);
    }
    if (filter['docSize'] === 'A4') {
      r_print.current.setScale(1.5);
    }
    r_print.current.setUrl('slsDoc/report');
    r_print.current.setPreview(boPreview);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(row.inClient),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const getTableButtonMenu = (row) => {
    let rvButton;
    let theOptions;
    try {
      switch (storeSlsLst.listType) {
        case 'q':
          theOptions = [
            { id: 'QA', text: row.boAccepted === '1' ? trans('field.pending') : trans('field.accepted') },
          ];
          break;

        case 'i':
          theOptions = [
            { id: 'IP', text: trans('field.quick_x_1', 'field.payment') },
            { id: 'IC', text: trans('field.clone') },
          ];
          break;

        case 'r':
          theOptions = [
            { id: 'RP', text: trans('field.quick_x_1', 'field.payment') },
            { id: 'RC', text: trans('field.clone') + ' ' + trans('field.invoices') },
          ];
          break;

        default:
          break;
      }
      rvButton = <CxButtonMenu
        row={row}
        onSelection={doLineOptions}
        className='ibs_iconbutton_table'
        icon={<BiDotsVerticalRounded />}
        options={theOptions}
        disabled={lockOps}
      />

    } catch (error) {
      FxLog.errorx("MxSlsDocList.getTableButtonMenu", error);
    }
    return rvButton;
  };

  const getrefColors = () => {
    let rv;
    try {
      switch (storeSlsLst.listType) {
        case 'q':
          rv = ['table_cell_color_green|' + trans('field.accepted')];
          break;

        case 'i':
          if (useReceipts) {
            rv = ['table_cell_color_red|' + trans('field.debtor'), 'table_cell_color_blue|' + trans('field.voided'), 'table_cell_color_green|' + trans('field.receipted')];
          } else {
            rv = ['table_cell_color_red|' + trans('field.debtor')];
          }
          break;

        case 'r':
          if (useReceipts) {
            rv = ['table_cell_color_red|' + trans('field.debtor'), 'table_cell_color_blue|' + trans('field.voided')];
          } else {
            rv = ['table_cell_color_red|' + trans('field.debtor')];
          }
          break;

        default: break;
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocList.getrefColors", error);
    }
    return rv;
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: storeSlsLst.table_idField, show: false });
    cols.push({ id: 'xx1', Header: "_itSeries", accessor: "itSeries", show: false }); // 2023-05-13 - daq: ATENCIÓN: con id numérico no la oculta.
    cols.push({ id: 'xx2', Header: "_inClient", accessor: "inClient", show: false }); // 2023-05-13 - daq: ATENCIÓN: con id numérico no la oculta.
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: "xx3", Header: trans('field.branch_id'), accessor: row => rf_table.current.highlightText(row["txBranchId"]), width: '10%', show: hasColumnBranch() });
      cols.push({ id: "xx4", Header: trans('field.series'), accessor: row => rf_table.current.highlightText(row["txTypeSeries"]), width: '10%', show: hasColumnSeries() });
      cols.push({ id: id++, Header: trans('field.doc'), accessor: row => rf_table.current.highlightText(row[storeSlsLst.table_txField]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => rf_table.current.highlightDate(row[storeSlsLst.table_dtField]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '30%' });
      if ((isApp('vett') || isApp('spaw'))) {
        storeSlsLst.listType !== 'r' && cols.push({ id: id++, Header: trans('field.patient'), accessor: row => rf_table.current.highlightText(row["animal_txAnimal"]), width: '15%' });
      }
      cols.push({ id: id++, Header: trans('field.reference'), accessor: row => rf_table.current.highlightText(row["txReference"]), width: '15%', show: hasColumnReference() });
      cols.push({ id: id++, Header: trans('field.taxable'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTaxable"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.discount'), accessor: row => (FxTable.cellTo2r00(row["nuTotalDiscount"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.tax'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTax"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.wtax_perc'), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtaxPerc"])), show: false });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.accepted'), accessor: row => (FxTable.cellToYesNo(row["boAccepted"])), width: '10%', align: 'right', show: (storeSlsLst.listType === 'q') });
      cols.push({ id: id++, Header: trans('field.paid'), accessor: row => (FxTable.cellTo2r00(row["nuTotalPayment"])), width: '10%', align: 'right', show: (storeSlsLst.listType !== 'q') });
  
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => rf_table.current.highlightDate(row[storeSlsLst.table_dtField]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => rf_table.current.highlightText(row["txClient"]), width: '50%' });
      cols.push({ id: id++, Header: trans('field.wtax_perc'), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtaxPerc"])), show: false });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '20%', align: 'right' });
    }
    cols.push({
      id: "BTN_OPT",
      icon: <BiLockAlt style={{marginTop:'-1px'}} />, icon_alt: <BiLockOpenAlt style={{marginTop:'-1px'}} />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockedOps(!lockOps),
      accessor: row => { return getTableButtonMenu(row) },
      align: 'center', width: '33px'
    });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({
        id: "BTN_PRT", icon: <BiPrinter />,
        accessor: row => { 
          return <CxIconButtonMenu data={row} onSelection={doPrintX} className="ibs_iconbutton_toolbar" icon={<RxDotFilled />}
              options={[
                { id: 'A4', text: trans('field.paper_a4'), },
                { id: 'A8', text: trans('field.paper_a8'), },
              ]}
            />
        },
        align: 'center', width: '33px'
      });
    }

    if (storeSlsLst.listType === 'q') {
      cols.push({ id: 'G', Header: "boAccepted", accessor: row => Boolean(row["boAccepted"] === '1'), show: false });
    }
    if (storeSlsLst.listType === 'i' || storeSlsLst.listType === 'r') {
      cols.push({ id: 'B', Header: "isVoid", accessor: row => Boolean(row["_isVoid"]), show: false });
    }
    if (storeSlsLst.listType === 'i') {
      cols.push({ id: 'G', Header: "hasReceipt", accessor: row => Boolean(row["_inReceipt"] !== ''), show: false });
    }
    if (storeSlsLst.listType === 'i' || storeSlsLst.listType === 'r') {
      cols.push({ id: 'R', Header: "balance", accessor: row => Boolean((row["nuTotalPayment"] - row["nuTotalFinal"]) < 0 ? true : false), show: false });
    }
    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    if ((isApp('vett') || isApp('spaw'))) {
    } else {
    }
    cols.push({ id: 'txBranchId', name: trans('field.branch_id'), checked: hasColumnBranch() ? '1' : '0' });
    cols.push({ id: 'txTypeSeries', name: trans('field.series'), checked: hasColumnSeries() ? '1' : '0' });
    cols.push({ id: 'txReference', name: trans('field.reference'), checked: hasColumnReference() ? '1' : '0' });
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxSlsDocList.addcol_txBranchId', options.includes('txBranchId') ? '1' : '0');
    localStorage.setItem('MxSlsDocList.addcol_txTypeSeries', options.includes('txTypeSeries') ? '1' : '0');
    localStorage.setItem('MxSlsDocList.addcol_txReference', options.includes('txReference') ? '1' : '0');
    rf_table.current.setTooltipText(getTooltip());
    rf_table.current.clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = '';
    if ((isApp('vett') || isApp('spaw')) && storeSlsLst.listType !== 'r') {
      tip = `${trans('field.doc')} / ${trans('field.date')} / ${trans('field.client')} / ${trans('field.patient')}`;
    } else {
      tip = `${trans('field.doc')} / ${trans('field.date')} / ${trans('field.client')}`;
    }
    if (hasColumnBranch()) tip = tip + `${' / ' + trans('field.branch_id')}`;
    if (hasColumnSeries()) tip = tip + `${' / ' + trans('field.series')}`;
    if (hasColumnReference()) tip = tip + `${' / ' + trans('field.reference')}`;

    return tip;
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.sales')}: ${docTitle}`}
        //help="sales"
        background={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <>
            <GxIconButtonLastClient theTable={rf_table} />
            <ToggleButtonGroup value={storeSlsLst.listType} exclusive onChange={changeDocType} className={classes.toggleButtonGroup} >
              <ToggleButton value="q">
                <DocType style={{ color: 'var(--ibs_color_toggle_yellow)' }} />
              </ToggleButton>
              {useInvoices && <ToggleButton value="i">
                <DocType style={{ color: 'var(--ibs_color_toggle_blue)' }} />
              </ToggleButton>}
              {useReceipts && <ToggleButton value="r">
                <DocType style={{ color: 'var(--ibs_color_toggle_green)' }} />
              </ToggleButton>}
            </ToggleButtonGroup>
          </>
        }
      />

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxTable
          xref={rf_table}
          id={`MxSlsDocList-${storeSlsLst.listType || getDefaultListType()}`}
          tooltip={getTooltip()}
          url={`sls/doclist/${storeSlsLst.listType || getDefaultListType()}`}
          fullscreen
          showtip
          hasSearchBox
          hasButtonAdd={storeSlsLst.listType !== 'r'}
          refColors={getrefColors()}
          disabledUntilUpgrade={Boolean(!FxEdition.isInEdition('SLS_unl') && storeCyStats.nuSalesThisMonth > 100)}
          onSpecialOptions={showSpecialOptions}
          onOpenForm={openForm}
          columns={getTableColumns()}
          optionalColumns={getTableOptionalCols()}
          optionalSorting={[
            { id: 'txBranchId', type: trans('field.branch_id') },
            { id: 'txTypeSeries asc, txXXX desc', type: `${trans('field.series')} + ${trans('field.doc')}` },
            { id: 'dtXXX desc', type: `${trans('field.date')}` },
            { id: 'nuTotalFinal', type: trans('field.total') },
            { id: 'nuTotalPayment', type: trans('field.paid') },
          ]}
          optionalFromTo={true}
          optionalFromToButtonToday={true}
          optionalFromToButtonLast7Days={true}
          optionalFromToButtonLast30Days={true}
          onOptions={optionsSelected}
          speechExternal
        >
        </CxTable>
      </div>

      <MxSlsDocListQuickPaymDlg xref={r_dlg_quick_paym} onOk={() => rf_table.current.redisplay()} />

      <CxDialog title={trans('msg.warning')} open={dlgAskSureToClone}
        onCancel={() => setDlgAskSureToClone(false)}
        onOk={() => { setDlgAskSureToClone(false); doClone() }}
        height='200px'>
        {trans('msg.ask_sure_to_x', 'field.clone')}
      </CxDialog>

      <CxDlgRadioList
        xref={r_dlg_special_options}
        onOk={doSpecialOption}
        width="var(--ibs_control_width_md)"
        onOpen={() => {
          return [
            {
              id: 'delete', type: FxStr.choose(storeSlsLst.listType, 'q|i|r',
                trans('field.delete_listed_x_1', "field.quotations"),
                trans('field.delete_listed_x_1', "field.invoices"),
                trans('field.delete_listed_x_0', "field.receipts"),
              )
            },
            FxStr.choose(storeSlsLst.listType, 'q|i|r',
              {},
              { id: 'renum', type: trans('field.renumber') },
              { id: 'renum', type: trans('field.renumber') },
            )
          ]
        }}
        title={trans('field.special_options')}
      />

      <CxDialog
        xref={r_dlg_special_options_ask_from_n_series}
        title={trans('field.renumber')}
        open={dlgSpecialOptionsAskFromAndSeries}
        onOk={doSpecialOptionRenum}
        onCancel={() => setDlgSpecialOptionsAskFromAndSeries(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxDate xref={rf_special_options_from} label={trans('field.from')} today required />
          <CxChooser xref={rf_special_options_series} label={trans('field.series')} table="t_series" col_id="itSeries" col_tx="txTypeSeries" extra_filter_1={"boActive='1'"} />
        </div>

      </CxDialog>

      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_required} severity="error" />

      <GxPrint xref={r_print} />

    </div>
  );

}

export default MxSlsDocList;
