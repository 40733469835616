import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettPosCounter } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsCounterForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const [storeSettPosCounter, setStoreSettPosCounter] = useSessionStorage('StoreSettPosCounter', StoreSettPosCounter);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettPosCounter.iiCounter = lastSaveRv.theValue;
      Promise.all([setStoreSettPosCounter(storeSettPosCounter)]).then((result) => {
        navBack();
      });
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={trans('field.counter')}
        xref={r_form}
        url="sett/counter" 
        filterIdField='iiCounter'
        filterIdValue={storeSettPosCounter.iiCounter} 
        onFormLoad={formLoad} 
        saveAndExit
        onFormSave={formSave} 
        >
        <CxHidden xref={useRef(null)} dbfield="i_counter.iiCounter" value={storeSettPosCounter.iiCounter || ''} />
        <CxNumber xref={useRef(null)} label={trans('field.last_x_1','field.quotation')} dbfield="i_counter.nuQuotation"  required />
        <CxNumber xref={useRef(null)} label={trans('field.last_x_1','field.invoice')} dbfield="i_counter.nuInvoice"  required />
        <CxNumber xref={useRef(null)} label={trans('field.last_x_0','field.receipt')} dbfield="i_counter.nuReceipt"  required />
        
      </GxForm>

    </div>
  );

}

export default MxSettSlsCounterForm;
