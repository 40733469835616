import { MdOutlinePets } from "react-icons/md"; 
import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import GxForm from '../../gx/GxForm';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import CxSnackbar from '../../cx/CxSnackbar';
import CxStatic from '../../cx/CxStatic';
import { getStoreValue, getStoreValueBo, StoreClient, StoreGrooming } from "../../gx/GxStore";
import { useAccess } from '../../hx/useAccess';
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import * as FxStr from '../../fx/FxStr';


const MxAnimalGrooForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo, navStack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeGrooming, setStoreGrooming] = useSessionStorage('StoreGrooming', StoreGrooming);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_time_pickup = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txEmployee = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
      if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
        rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
        rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    storeGrooming.inGrooming = lastSaveRv.theValue;
    Promise.all([setStoreGrooming(storeGrooming)]).then((result) => {
      r_sls_aux_table.current.setTxLinkAuxId(storeGrooming.inGrooming);
      forceUIUpdate();
    });
    //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}: ${trans('field.grooming')}`}
        xref={r_form}
        url="groo"
        filterIdField='inGrooming'
        filterIdValue={storeGrooming.inGrooming}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {storeGrooming.inGrooming && navStack()[navStack().length-2]==='activity-vett-g-list' && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={() => navTo("animal-form")} tooltip={trans('field.patient')} />}
          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_grooming.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_grooming.inAnimal" value={storeClient.inAnimal || ''} />

        <CxStatic label={`${FxStr.join(' / ', storeClient.txClient, storeClient.txAnimal)}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_grooming.dtFrom`} required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield={`n_grooming.dtFrom`} required />
        <CxTime xref={rf_time_pickup} label={trans('field.groo_pickup')} dbfield={`n_grooming.dtTo`} required />
        <CxInput xref={useRef(null)} label={trans('field.type')} dbfield="n_grooming.txType" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_grooming.txRemarks" maxLength={150} />
        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_grooming.txReference" maxLength={50} />

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_grooming.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_grooming.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.condition_initial')} dbfield="n_grooming.txConditionInitial" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_skin_condition')} dbfield="n_grooming.txSkinCondition" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_head_cut')} dbfield="n_grooming.txHeadCut" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_body_cut')} dbfield="n_grooming.txBodyCut" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_legs_cut')} dbfield="n_grooming.txLegsCut" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_ears_treatment')} dbfield="n_grooming.txEarsTreatment" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_nails_treatment')} dbfield="n_grooming.txNailsTreatment" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_shampoo_type')} dbfield="n_grooming.txShampooType" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.groo_conditioner_type')} dbfield="n_grooming.txConditionerType" maxLength={50} />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_grooming.txNotes" minRows={4} />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} txLinkAuxTable={'n_grooming'} txLinkAuxId={storeGrooming.inGrooming} rf_txEmployee={rf_txEmployee} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxAnimalGrooForm;
