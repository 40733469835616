import React from 'react';
import { useTranslation } from '../hx/useTranslation';
import { useNav } from '../hx/useNav';
import CxInput from '../cx/CxInput';
import CxSearcher from '../cx/CxSearcher';
import CxStatic from '../cx/CxStatic';
import CxNumber from '../cx/CxNumber';
import * as FxFetch from "../fx/FxFetch";
import * as FxLog from '../fx/FxLog';
import * as FxNum from '../fx/FxNum';
import * as FxTable from '../fx/FxTable';
import GxForm from './GxForm';
import MxPsOTF from '../modules/common/otf/MxPsOTF';
import '../../styles/main.css';
import { useSessionStorage } from '../hx/useSessionStorage';
import CxIconButton from '../cx/CxIconButton';
import { useWindowSize } from '../hx/useWindowSize';
import { useAccess } from '../hx/useAccess';
import { StoreSlsAux, } from "./GxStore";

const GxSlsAuxTableItem = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ access }] = useAccess();
  const [{ navBack }] = useNav();
  const size = useWindowSize();

  let r_form = React.useRef(null);
  let r_dlg_new_ps = React.useRef(null);
  let r_boComposite = React.useRef(false);

  const [storeSlsAux,] = useSessionStorage('StoreSlsAux', StoreSlsAux);


  const rf_txPs = React.useRef(null);

  const rf_nuFrequency = React.useRef(null);
  
  const rf_nuQuantity = React.useRef(null);
  const rf_nuPrice = React.useRef(null);
  const rf_nuDiscountPerc = React.useRef(null);
  const rf_nuTaxPerc = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const rf_txPsAlias = React.useRef(null);
  const rf_txRemarks = React.useRef(null);

  const [psAlert, setPsAlert] = React.useState('');


  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const hasColumnPsCode = () => { return Boolean(localStorage.getItem('GxSlsAuxTableItem.addcol_txPsCode') === '1') };
  const hasColumnReference = () => { return Boolean(localStorage.getItem('GxSlsAuxTableItem.addcol_txReference') === '1') };

  const formLoadCustom = () => {
    if (storeSlsAux.inInvoiceItemX === null) { //nuevo registro
      rf_txPs.current.setDlg(true); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de producto-servicio */

    } else { // registro existente
      let record = {};
      record['idValue'] = storeSlsAux.inInvoiceItemX;
      Promise.all([FxFetch.doJsonX(`sls/aux/r`, record)]).then((result) => {
        if (result[0]) {
          FxLog.infox('GxSlsAuxTableItem.formLoadCustom', '...result[0]:', result[0]);
          // cargar controles
          rf_txPs.current.setKey(result[0][0].inPs);
          rf_txPs.current.setVal(result[0][0].txPs);
          rf_txPsAlias.current.setVal(result[0][0].txPsAlias);
          rf_txRemarks.current.setVal(result[0][0].txRemarks);

          rf_nuFrequency.current.setVal(result[0][0].nuFrequency);
          rf_nuFrequency.current.setVisible(!rf_nuFrequency.current.isZero())

          rf_nuQuantity.current.setVal(result[0][0].nuQuantity);
          rf_nuPrice.current.setVal(result[0][0].nuPrice);
          rf_nuDiscountPerc.current.setVal(result[0][0].nuDiscountPerc);
          rf_nuTaxPerc.current.setVal(result[0][0].nuTaxPerc);
          rf_nuAmount.current.setVal("0");

          setPsAlert(result[0][0].txAlert);

          // recálculo
          recalc();
        }
      });
    }
  }

  const formDeleteCustom = () => {
    if (storeSlsAux.inInvoiceItemX !== null) {
      let record = {};
      record['idValue'] = storeSlsAux.inInvoiceItemX;
      Promise.all([FxFetch.doJsonX(`sls/aux/d`, record)]).then((result) => {
        if (result[0].theValue === 'OK') {
          navBack({ scroll: 'bottom'});
        }
      });
    }
  }

  const recalc = async (from) => {
    try {
      //realiza cálculos a partir de los valores
      let nuQuantity = rf_nuQuantity.current.getFloatVal();
      let nuPrice = rf_nuPrice.current.getFloatVal();
      let nuDiscountPerc = rf_nuDiscountPerc.current.getFloatVal();
      let nuTaxPerc = rf_nuTaxPerc.current.getFloatVal();
      let nuAmount = rf_nuAmount.current.getFloatVal();

      if (from === 'amount') {
        nuPrice = (-1 * ((10000 * nuAmount) / (((nuDiscountPerc - 100) * nuTaxPerc + 100 * nuDiscountPerc - 10000) * nuQuantity)));
      }
      let nuDiscount = (nuQuantity * nuPrice * nuDiscountPerc / 100);
      let nuTaxable = ((nuQuantity * nuPrice) - nuDiscount);
      let nuTax = (nuTaxable * nuTaxPerc / 100);
      nuAmount = FxNum.to3r(nuTaxable + nuTax);

      rf_nuPrice.current.setVal(nuPrice);
      rf_nuAmount.current.setVal(nuAmount);

    } catch (error) {
      FxLog.errorx("GxSlsAuxTableItem.recalc", error);
    }
  }

  const searcherClearValues = () => {
    r_boComposite.current = false;
    rf_nuFrequency.current.setVal('');
    rf_nuQuantity.current.setVal('');
    rf_nuPrice.current.setVal('');
    rf_nuDiscountPerc.current.setVal('');
    rf_nuTaxPerc.current.setVal('');
    rf_nuAmount.current.setVal('');
    setPsAlert('');
  }

  const formLoadSelected = async (inPs) => {
    try {
      let record = {};
      record['idValue'] = inPs;
      Promise.all([FxFetch.doJsonX(`sls/docitem/load_selected`, record)]).then((result) => {
        if (result[0]) {
          // cargar controles
          rf_txPsAlias.current.setVal(result[0][0].txPsAlias);
          rf_nuFrequency.current.setVal(result[0][0].nuFrequency);
          rf_nuFrequency.current.setVisible(!rf_nuFrequency.current.isZero())
          rf_nuQuantity.current.setVal(result[0][0].nuQuantity);
          rf_nuPrice.current.setVal(result[0][0].nuPrice);
          rf_nuDiscountPerc.current.setVal(result[0][0].nuDiscountPerc);
          rf_nuTaxPerc.current.setVal(result[0][0].nuTaxPerc);
          rf_nuAmount.current.setVal("0");
          setPsAlert(result[0][0].txAlert);

          if (storeSlsAux.arRates) {

            const keys = Object.keys(storeSlsAux.arRates[0]);
            let discPerc = 0;
            keys.forEach((key, index) => {
              //console.log(`${key}: ${storeSlsAux.arRates[0][key]}`);
              if (key === inPs) {
                discPerc = storeSlsAux.arRates[0][key];
                rf_nuDiscountPerc.current.setVal(discPerc);
              }
            });
          }

          // recálculo
          recalc();

          // levanta flag si es un ps compuesto (tiene adjuntos).
          let boComposite = result[0][0].boComposite;
          if (boComposite === '1') {
            r_boComposite.current = true;
          }

        }
      });

    } catch (error) {
      FxLog.errorx("GxSlsAuxTableItem.formLoadSelected", error);
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let inPs = rf_txPs.current.getKey();

    let record = {};
    record['inInvoiceItemX'] = storeSlsAux.inInvoiceItemX;
    record['inClient'] = storeSlsAux.inClient;
    record['txLinkAuxTable'] = storeSlsAux.txLinkAuxTable;
    record['txLinkAuxId'] = storeSlsAux.txLinkAuxId;
    record['inPs'] = inPs;
    record['txPs'] = rf_txPs.current.getVal();
    record['txPsAlias'] = rf_txPsAlias.current.getVal();
    record['txAlert'] = psAlert;
    record['txRemarks'] = rf_txRemarks.current.getVal();
    record['nuFrequency'] = rf_nuFrequency.current.getIntVal();
    record['nuQuantity'] = rf_nuQuantity.current.getFloatVal();
    record['nuPrice'] = rf_nuPrice.current.getFloatVal();
    record['nuDiscountPerc'] = rf_nuDiscountPerc.current.getFloatVal();
    record['nuTaxPerc'] = rf_nuTaxPerc.current.getFloatVal();
    record['nuAmount'] = FxNum.to2r(rf_nuAmount.current.getFloatVal());

    Promise.all([FxFetch.doJsonX("sls/aux/cu", record)]).then((result) => {
      if (result[0]) {
        navBack({ scroll: 'bottom'});
      }
    });
  }

  const searcherPsChanged = async (row) => {
    if (row !== null) {
      searcherClearValues();
      let inPs = row.original.inPs;
      formLoadSelected(inPs);
    }
  }

  const selectedPsOTF = (inPs, txPs) => {
    try {
      rf_txPs.current.setKey(inPs);
      rf_txPs.current.setVal(txPs);
      formLoadSelected(inPs);

    } catch (error) {
      FxLog.errorx('GxSlsAuxTableItem.selectedPsOTF', error)
    }
  }

  const getSearcherTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txPsCode', name: trans('field.code'), checked: hasColumnPsCode() ? '1' : '0' });
    cols.push({ id: 'txReference', name: trans('field.reference'), checked: hasColumnReference() ? '1' : '0' });
    return cols;
  }
  const searcherOptionsSelected = (options) => {
    localStorage.setItem('GxSlsAuxTableItem.addcol_txReference', options.includes('txReference') ? '1' : '0');
    localStorage.setItem('GxSlsAuxTableItem.addcol_txPsCode', options.includes('txPsCode') ? '1' : '0');
    rf_txPs.current.getTable().setTooltipText(getSearcherTooltip());
    rf_txPs.current.getTable().clearSearch();
    forceUIUpdate();
  }
  const getSearcherTooltip = () => {
    let tip = `${trans('field.ps')}`;
    if (hasColumnPsCode()) tip = tip + `${' / ' + trans('field.code')}`;
    if (hasColumnReference()) tip = tip + `${' / ' + trans('field.reference')}`;

    return tip;
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.ps')}`}
        xref={r_form}
        //toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            {storeSlsAux.inInvoiceItemX !== null && isFullAccess()
              && <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar */}
            {isFullAccess()
              && <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.confirm')} />}
          </div>
        }
      >

        {psAlert && <CxStatic label={psAlert} border='red' />}

        <CxSearcher xref={rf_txPs} id='GxSlsAuxTableItem_txPs'
          label={trans('field.ps')}
          //readOnly={storeSlsLst.table_item_idValue}
          onSelection={searcherPsChanged}
          onBlur={recalc}
          onClear={searcherClearValues}
          onButtonAddOTF={access(trans('field.pss')) !== 'W' ? false : () => {
            rf_txPs.current.setDlg(false);
            r_dlg_new_ps.current.setDlg(true);
          }}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          tooltip={getSearcherTooltip()}
          refColors={['table_cell_color_red|' + trans('field.stock_below_min'), 'table_cell_color_brown|' + trans('field.composite'), 'table_cell_color_green|' + trans('field.frequency')]}
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '50%' },
            { id: "2", Header: trans('field.code'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPsCode"]), width: '25%', align: 'right', show: size.isLarge && hasColumnPsCode() },
            { id: "3", Header: trans('field.reference'), accessor: row => rf_txPs.current.getTable().highlightText(row["txReference"]), width: '25%', show: size.isLarge && hasColumnReference() },
            { id: "4", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["theTotalPrice"]), width: '25%', align: 'right' },
            { id: "W", Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false },
            { id: "G", Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false },
            { id: "R", Header: "_stock_below_min", accessor: row => Boolean(row["boStockControl"] === '1') && Boolean(row["nuStock"] < row["nuStockMin"]), show: false },
          ]}
          optionalColumns={getSearcherTableOptionalCols()}
          onOptions={searcherOptionsSelected}
          required
        />

        <CxNumber xref={rf_nuFrequency} label={trans('field.frequency')} visible="false" />

        <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} sumButton onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuPrice} label={trans('field.price')} onBlur={recalc} decimals={4} decimalsfixed="true" required />
        <CxNumber xref={rf_nuDiscountPerc} label={trans('field.discount_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuTaxPerc} label={trans('field.tax_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuAmount} label={trans('field.amount')} onBlur={() => recalc('amount')} decimals={2} decimalsfixed="true" required />
        <CxInput xref={rf_txPsAlias} label={trans('field.alias')} />
        <CxInput xref={rf_txRemarks} label={trans('field.remarks')} />

        <MxPsOTF xref={r_dlg_new_ps} onOk={selectedPsOTF} />

      </GxForm>

    </div>
  );

}

export default GxSlsAuxTableItem;
