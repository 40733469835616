import { BiReset } from "react-icons/bi";
import { RiFileHistoryLine } from "react-icons/ri";
import { CgFileDocument } from "react-icons/cg";
import { BiSend } from "react-icons/bi";
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
import CxChooser from '../../cx/CxChooser';
import CxCombo from '../../cx/CxCombo';
import CxButton from '../../cx/CxButton';
import CxButtonShowMore from '../../cx/CxButtonShowMore';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import CxTable from '../../cx/CxTable';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import * as FxDat from '../../fx/FxDat';
import * as FxFetch from '../../fx/FxFetch';
import * as FxNum from '../../fx/FxNum';
import * as FxPrint from '../../fx/FxPrint';
import CxSnackbar from './../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';
import CxDialog from '../../cx/CxDialog';
import CxDlgDoc from '../../cx/CxDlgDoc';
import CxSearcher from '../../cx/CxSearcher';
import CxStatic from '../../cx/CxStatic';
import { clearStore, StoreFile, getStoreValue, getStoreValueBo, StoreClient, isApp, StoreRxGlas, StoreRxClen, StoreRepl, StoreLabOrder, StoreRxHaid } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxAccordion from '../../cx/CxAccordion';
import CxState from '../../cx/CxState';
import { useWindowSize } from '../../hx/useWindowSize';
import { useNav } from '../../hx/useNav';
import { useAccess } from '../../hx/useAccess';
import GxPrint from '../../gx/GxPrint';
import GxDlgSend from '../../gx/GxDlgSend';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
import GxPrintLong from '../../gx/GxPrintLong';


const MxClientForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxHaid, setStoreRxHaid] = useSessionStorage('StoreRxHaid', StoreRxHaid);
  const [storeRxGlas, setStoreRxGlas] = useSessionStorage('StoreRxGlas', StoreRxGlas);
  const [storeRxClen, setStoreRxClen] = useSessionStorage('StoreRxClen', StoreRxClen);
  const [storeRepl, setStoreRepl] = useSessionStorage('StoreRepl', StoreRepl);
  const [storeLabOrder, setStoreLabOrder] = useSessionStorage('StoreLabOrder', StoreLabOrder);
  const [storeFile, setStoreFile] = useSessionStorage('StoreFile', StoreFile);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_missing_mobile = React.useRef(null);

  const r_dlg_doc = React.useRef(null);
  const r_dlg_upgrade = React.useRef(null);
  const r_dlg_send = React.useRef(null);

  let r_print = React.useRef(null);
  const r_form = React.useRef(null);
  const r_table_animals = React.useRef(null);
  const r_table_incidences = React.useRef(null);

  const r_gx_print_long = React.useRef(null);
  
  const rf_txUser = React.useRef(null);
  const rf_txCode = React.useRef(null);
  const rf_txMobile = React.useRef(null);
  const rf_txCity = React.useRef(null);
  const rf_txState = React.useRef(null);
  const rf_txCountry = React.useRef(null);
  const rf_txPostalCode = React.useRef(null);

  const r_table_rx_haid = React.useRef(null);
  const r_table_rx_glass = React.useRef(null);
  const r_table_rx_clen = React.useRef(null);
  const r_table_repl = React.useRef(null);
  const r_table_lab_ord = React.useRef(null);
  const r_table_images = React.useRef(null);
  const r_table_multimedia = React.useRef(null);
  const r_accordion_rx_haid = React.useRef(null);
  const r_accordion_rx_glass = React.useRef(null);
  const r_accordion_rx_clen = React.useRef(null);
  const r_accordion_repl = React.useRef(null);
  const r_accordion_lab_ord = React.useRef(null);
  const r_accordion_images = React.useRef(null);
  const r_accordion_multimedia = React.useRef(null);
  const r_accordion_incidences = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txTypeClient = React.useRef(null);
  const rf_dtFrom = React.useRef(null);
  const rf_txTypeRate = React.useRef(null);
  const rf_txTypePayment = React.useRef(null);
  const rf_txLang = React.useRef(null);

  const rf_nuTotalIssued = React.useRef(null);
  const rf_nuTotalPaid = React.useRef(null);
  const rf_nuTotalBalance = React.useRef(null);
  const rf_nuTotalBalanceAd = React.useRef(null);

  const rf_nuPurseBalance = React.useRef(null);
  const rf_purse_new_credit = React.useRef(null);
  const [dlgPurseReset, setDlgPurseReset] = React.useState(false);

  const [balanceBackgroundColor, setBalanceBackgroundColor] = React.useState('var(--ibs_color_gray_light)');
  const [dlgBalance, setDlgBalance] = React.useState(false);
  const [balanceVals, setBalanceVals] = React.useState('');
  const [alert, setAlert] = React.useState('');

  React.useEffect(() => {
    clearStore('StoreRxGlas', storeRxGlas);
    clearStore('StoreRxClen', storeRxClen);
    clearStore('StoreRepl', storeRepl);
    clearStore('StoreLabOrder', storeLabOrder);
    clearStore('StoreFile', storeFile);

    checkPendingDocs();

    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
      rf_txTypeClient.current.setKeyAndVal('t_client', 'txTypeClient', 'itClient', '000001.SRV');
      rf_txCity.current.setVal(getStoreValue('StoreSession', 'txDefaultCity'));
      rf_txState.current.setVal(getStoreValue('StoreSession', 'txDefaultState'));
      rf_txCountry.current.setVal(getStoreValue('StoreSession', 'txDefaultCountry'));
      rf_txPostalCode.current.setVal(getStoreValue('StoreSession', 'txDefaultPostalCode'));
    } else {
      let nuTotalBalance = rf_nuTotalPaid.current.getFloatVal() - rf_nuTotalIssued.current.getFloatVal();
      setBalanceBackgroundColor(nuTotalBalance >= 0 ? 'var(--ibs_color_green_dark)' : 'var(--ibs_color_red_dark)');
      rf_nuTotalBalance.current.setVal(nuTotalBalance);
      localStorage.setItem('MxClientForm.last_client', `${storeClient.inClient}`);
      rf_txClient.current.setLocked(true);
      updateAccordions();
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
      if (lastSaveRv.theValue.startsWith("rv_exists")) {
        r_snack_exists.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeClient.inClient = lastSaveRv.theValue;
      storeClient.txClient = rf_txClient.current.getVal();
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        localStorage.setItem('MxClientForm.last_client', `${storeClient.inClient}`);
        if (lastSaveRv.txCode) {
          rf_txCode.current.setVal(lastSaveRv.txCode);
        }
        forceUIUpdate();
      });
    }
  }
  const formDelete = async (rv) => {
    localStorage.removeItem('MxClientForm.last_client');
  }

  const openFormRxGlas = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeRxGlas.inRxGlas = null;
      } else {
        storeRxGlas.inRxGlas = row.original.inRxGlas;
      }
      setStoreRxGlas(storeRxGlas);
      navTo("client-rx-glas-form");
    }
  }

  const openFormRxHaid = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeRxHaid.inRxHaid = null;
      } else {
        storeRxHaid.inRxHaid = row.original.inRxHaid;
      }
      setStoreRxHaid(storeRxHaid);
      navTo("client-rx-haid-form");
    }
  }

  const openFormRxClen = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeRxClen.inRxClen = null;
      } else {
        storeRxClen.inRxClen = row.original.inRxClen;
      }
      setStoreRxClen(storeRxClen);
      navTo("client-rx-clen-form");
    }
  }

  const openFormRepl = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeRepl.inRepl = null;
      } else {
        storeRepl.inRepl = row.original.inRepl;
      }
      setStoreRepl(storeRepl);
      navTo("client-repl-form");
    }
  }

  const openFormLabOrder = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeLabOrder.inLabOrder = null;
        storeLabOrder.inRxGlas = null;
      } else {
        storeLabOrder.inLabOrder = row.original.inLabOrder;
        storeLabOrder.inRxGlas = row.original.inRxGlas;
      }
      setStoreLabOrder(storeLabOrder);
      navTo("client-lab-order-form");
    }
  }

  const openFormAnimal = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inAnimal = null;
        storeClient.txAnimal = null;
        setStoreClient(storeClient);
      } else {
        storeClient.inAnimal = row.original.inAnimal;
        storeClient.txAnimal = row.original.txAnimal;
        setStoreClient(storeClient);
      }
      navTo("animal-form");

    }
  }

  const openFormImage = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inImage = null;
      } else {
        storeClient.inImage = row.original.inImage;
      }
      setStoreClient(storeClient);
      navTo("client-image-form");
    }
  }

  const openFormMultimedia = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inMultimedia = null;
      } else {
        storeClient.inMultimedia = row.original.inMultimedia;
      }
      setStoreClient(storeClient);
      navTo("client-multimedia-form");
    }
  }

  const openFormClientIncidence = async (op, row) => {
    if (!storeClient.inClient) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inClientIncidence = null;
      } else {
        storeClient.inClientIncidence = row.original.inClientIncidence;
      }
      setStoreClient(storeClient);
      navTo("client-incidence-form");
    }
  }

  const checkPendingDocs = () => {
    if (storeClient.inClient) {
      let record = {};
      record['inClient'] = storeClient.inClient;
      Promise.all([FxFetch.doJsonX("clients/doc/get_pending_docs", record)]).then((result) => {
        if (result[0]) {
          let newValue = result[0].theValue;
          if (newValue) {
            setAlert(trans('msg.docs_pending_signature') + ": " + newValue);
          } else {
            setAlert('');
          }
        }
      });
    }
  }

  const updateBalance = () => {
    if (storeClient.inClient) {
      let record = {};
      record['inClient'] = storeClient.inClient;
      Promise.all([FxFetch.doJsonX("clients/update_balance", record)]).then((result) => {
        if (result[0]) {
          let vals = result[0].theValue;
          if (vals) {
            setBalanceVals(vals);
            let nuTotalIssued = FxNum.to2r00(vals.split('|')[0]);
            let nuTotalPaid = FxNum.to2r00(Number(vals.split('|')[1]) + Number(vals.split('|')[2]));
            let nuTotalBalance = FxNum.to2r00(vals.split('|')[3]);
            rf_nuTotalIssued.current.setVal(nuTotalIssued);
            rf_nuTotalPaid.current.setVal(nuTotalPaid);
            rf_nuTotalBalance.current.setVal(nuTotalBalance);
            setDlgBalance(true);
          }
        }
      });
    }
  }

  const doSend = () => {
    Promise.all([FxPrint.getClientInfo(storeClient.inClient)]).then((info) => {
      let clientInfo = info[0];
      storeFile.addressee = clientInfo;
      Promise.all([setStoreFile(storeFile)]).then((result) => {
        r_dlg_send.current.setStore(storeFile);
        r_dlg_send.current.setOpen(true);
      });
    });
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    if (option === 'emails') {
      filter['reportName'] = 'ibs_stats_mkt_email';
    }
    if (option === 'sms') {
      filter['reportName'] = 'ibs_stats_mkt_sms';
    }
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('clients/report/mkt');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const updateAccordions = () => {
    r_accordion_rx_glass.current && r_accordion_rx_glass.current.setFontWeight(r_table_rx_glass.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_rx_clen.current && r_accordion_rx_clen.current.setFontWeight(r_table_rx_clen.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_rx_haid.current && r_accordion_rx_haid.current.setFontWeight(r_table_rx_haid.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_repl.current && r_accordion_repl.current.setFontWeight(r_table_repl.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_lab_ord.current && r_accordion_lab_ord.current.setFontWeight(r_table_lab_ord.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_images.current && r_accordion_images.current.setFontWeight(r_table_images.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_multimedia.current && r_accordion_multimedia.current.setFontWeight(r_table_multimedia.current.isEmpty() ? 'normal' : 'bold');
    r_accordion_incidences.current && r_accordion_incidences.current.setFontWeight(r_table_incidences.current.isEmpty() ? 'normal' : 'bold');
    forceUIUpdate();
  }

  const resetPurseBalance = () => {
    try {
      let credits = rf_purse_new_credit.current.getVal();
      
      let record = {};
      record['inClient'] = storeClient.inClient;
      record['txClient'] = storeClient.txClient;
      record['credits'] = credits;
      Promise.all([FxFetch.doJsonX("clients/purse_balance_reset", record)]).then((result) => {
        if (result[0]) {
          if (result[0].theValue === 'OK') {
            rf_nuPurseBalance.current.setVal(credits);
          }
        }
      });

      setDlgPurseReset(false);

    } catch (error) {
      FxLog.errorx("MxClientForm.resetPurseBalance", error);
    }
  }

  const doPrintCard = () => {
    let inClient = storeClient.inClient;
    let filter = {};
    let record = {};
    r_gx_print_long.current.setUrlBuild('clients/report/card_build');
    r_gx_print_long.current.setUrlGet('clients/report/card_get');
    //----------------
    Promise.all([
      r_gx_print_long.current.setFilter(filter),
      r_gx_print_long.current.setRecord(record),
      r_gx_print_long.current.setInClient(inClient),
    ]).then((result) => {
      r_gx_print_long.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.client')}
        xref={r_form}
        url="clients"
        filterIdField='inClient'
        filterIdValue={storeClient.inClient}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        alert={alert}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón para documentos personalizados */}
            {storeClient.inClient
              && <CxIconButtonNew icon={<CgFileDocument fontSize={24} />} classType="ibs_iconbutton_toolbar" onClick={() => r_dlg_doc.current.setDlgDocs(true)} tooltip={trans('field.docs')} />}

            {/* botón para log de comunicaciones por email */}
            {storeClient.inClient
              && <CxIconButtonMenu onSelection={doPrintX} className="ibs_iconbutton_toolbar" icon={<RiFileHistoryLine fontSize={24} />}
                options={[
                  { id: 'emails', text: trans('rpt.mkt_email') },
                  { id: 'sms', text: trans('rpt.mkt_sms'), },
                ]}
                tooltip={`${trans('field.emails')} / ${trans('field.sms')}`}
              />
            }

            {/* botón de envío */}
            {storeClient.inClient
              && <CxIconButtonNew icon={<BiSend fontSize={24} style={{marginTop:'1px'}} />} classType="ibs_iconbutton_toolbar" onClick={doSend} tooltip={trans('field.send')} />}

            {/* enlaces */}
            {storeClient.inClient && 
              <CxIconButtonMenu className="ibs_iconbutton_toolbar" onSelection={(option) => {
                switch (option) {
                  case 'sales': navTo('sls-doc-list', `${storeClient.txClient}`); break;
                  case 'history': navTo('history-filter', { inClient: storeClient.inClient, txClient: storeClient.txClient }); break;
                  case 'card': doPrintCard(); break;
                  default: break;
                }
              }}
                options={[
                  { id: 'sales', text: trans('field.sales') },
                  { id: 'history', text: trans('field.history'), },
                  { id: 'card', text: trans('field.card'), },
                ]}
                /* tooltip={`${trans('field.show_more')}`} */
              />
            }

          </div>
        }
      >
        <CxAccordion title={trans('field.balance')} background={balanceBackgroundColor} color={"var(--ibs_color_white)"} >

          <CxNumber xref={rf_nuTotalIssued}
            label={trans('field.issued')}
            dbfield="n_client.nuTotalIssued"
            decimals={2}
            decimalsfixed="true"
            disabled={true}
            hidebuttons='true'
            width='var(--ibs_control_width_xs)'
          />

          <CxNumber xref={rf_nuTotalPaid}
            label={trans('field.paid')}
            dbfield="n_client.nuTotalPaid"
            decimals={2}
            decimalsfixed="true"
            disabled={true}
            hidebuttons='true'
            width='var(--ibs_control_width_xs)'
          />

          <CxNumber xref={rf_nuTotalBalance}
            label={trans('field.balance')}
            dbfield="n_client.nuTotalBalance"
            decimals={2}
            decimalsfixed="true"
            disabled={true}
            hidebuttons='true'
            width='var(--ibs_control_width_xs)'
          />

          <CxNumber xref={rf_nuTotalBalanceAd}
            label={trans('field.balance_ad')}
            dbfield="n_client.nuTotalBalanceAd"
            decimals={2}
            decimalsfixed="true"
            width='var(--ibs_control_width_xs)'
          />

          <CxButton label={trans('field.recalculate')} onClick={updateBalance} />

          <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_client.txRemarks" minRows={4} maxLength={500} />
        </CxAccordion>

        <CxInput xref={rf_txClient} label={trans('field.client')} dbfield="n_client.txClient" capitalize normalizeSpaces maxLength={100} required />
        <CxInput xref={useRef(null)} label={trans('field.contact_person')} dbfield="n_client.txContact" capitalize normalizeSpaces maxLength={100} />
        <CxInput xref={rf_txCode} label={trans('field.code')} dbfield="n_client.txCode" maxLength={30}
          placeholder={getStoreValueBo('StoreSession', 'boAutoNumFiles') ? 'AUTO' : ''}
          disabled={getStoreValueBo('StoreSession', 'boAutoNumFiles') && !storeClient.inClient}
        />
        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_client.txReference" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_client.txPhone1" maxLength={20} speech />
        <CxInput xref={useRef(null)} label={trans('field.phone2')} dbfield="n_client.txPhone2" maxLength={20} speech />
        <CxInput xref={rf_txMobile} label={trans('field.mobile')} dbfield="n_client.txMobile" maxLength={20} speech />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_client.txEmail" maxLength={255} />

        <CxSearcher xref={rf_txUser} label={trans('field.linked_to_x', 'field.user')}
          dbfield="n_user.txUser" dbfieldkey={`n_client.inUser`}
          url="sett/sec/users"
          filter={`n_user.txType='C'`}
          col_id="inUser"
          col_tx="txUser"
          columns={[
            { id: "0", Header: "ID", accessor: "inUser", show: false },
            { id: "1", Header: trans('field.user'), accessor: row => rf_txUser.current.getTable().highlightText(row["txUser"]), width: '100%' },
          ]}
        />

        <CxButtonShowMore label={`[${trans('field.show_more').toUpperCase()}]`} style={{ margin: '4px 0 4px 0px' }} >
          <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_client.dtFrom"} required />
          <CxDate xref={useRef(null)} label={trans('field.to')} dbfield={"n_client.dtTo"} />
          <CxState mode='checkbox' xref={useRef(null)} label={`${trans('field.female')}|${trans('field.male')}`} dbfield="n_client.boGender" />
          <CxDate xref={useRef(null)} label={trans('field.birth')} dbfield="n_client.dtBirth" showAge />

          <CxChooser xref={rf_txTypeClient}
            label={trans('field.type')}
            dbfield="t_client.txTypeClient" dbfieldkey="n_client.itClient"
            table="t_client"
            col_id="itClient"
            col_tx="txTypeClient"
          />

          <CxInput xref={useRef(null)} label={trans('field.ic')} dbfield="n_client.txIc" maxLength={15} />
          <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_client.txAddress" maxLength={100} />
          <CxInput xref={rf_txPostalCode} label={trans('field.postal_code')} dbfield="n_client.txPostalCode" maxLength={10} />
          <CxInput xref={rf_txCity} label={trans('field.city')} dbfield="n_client.txCity" maxLength={50} />
          <CxInput xref={rf_txState} label={trans('field.state')} dbfield="n_client.txState" maxLength={50} />
          <CxInput xref={rf_txCountry} label={trans('field.country')} dbfield="n_client.txCountry" maxLength={50} />

          <CxChooser xref={rf_txTypeRate}
            label={trans('field.rate')}
            dbfield="t_rate.txTypeRate" dbfieldkey="n_client.itRate"
            table="t_rate"
            col_id="itRate"
            col_tx="txTypeRate"
            filter={`t_rate.boActive='${'1'}'`}
          />
          <CxDate xref={useRef(null)} label={`${trans('field.rate')} ${trans('field.from')}`} dbfield={"n_client.dtRateFrom"} />
          <CxDate xref={useRef(null)} label={`${trans('field.rate')} ${trans('field.to')}`} dbfield={"n_client.dtRateTo"} />

          <CxChooser xref={rf_txTypePayment}
            label={trans('field.payment')}
            dbfield="t_payment.txTypePayment" dbfieldkey="n_client.itPayment"
            table="t_payment"
            col_id="itPayment"
            col_tx="txTypePayment"
            extra_filter_1={"not txTypePayment like 'OLD-%'"}
          />

          <CxInput xref={useRef(null)} label={trans('field.bank')} dbfield="n_client.txBank" maxLength={50} />
          <CxInput xref={useRef(null)} label={trans('field.bic')} dbfield="n_client.txBic" maxLength={11} />
          <CxInput xref={useRef(null)} label={trans('field.account')} dbfield="n_client.txAccount" maxLength={50} />
          <CxInput xref={useRef(null)} label={trans('field.holder')} dbfield="n_client.txHolder" maxLength={100} capitalize normalizeSpaces />

          <CxNumber xref={rf_nuPurseBalance}
            label={`${trans('field.purse')}: ${trans('field.balance')}`}
            dbfield="n_client.nuPurseBalance"
            decimals={2}
            decimalsfixed="true"
            disabled={true}
            action1={{
              icon: <BiReset fontSize="20px" style={{ margin: '0 0 0 0px', color: 'var(--ibs_color_gray)' }} />,
              onClick: () => setDlgPurseReset(true),
              tooltip: `${trans('field.reset')}`
            }}
          />
          <CxDate xref={useRef(null)} label={`${trans('field.purse')}: ${trans('field.expiration')}`} dbfield={"n_client.dtPurseExpr"} />

          <CxCombo xref={useRef(null)}
            label={`${trans('field.mailing')}`}
            dbfield='n_client.nuMailing'
            list={[
              { id: '0', type: trans('field.no') },
              { id: '1', type: trans('field.yes') },
              { id: '2', type: trans('field.email') }, 
              { id: '3', type: trans('field.postal') }, 
              { id: '4', type: trans('field.sms') }, 
            ]}
            clearDisabled
            defaultValue="1"
          />

          <CxChooser xref={rf_txLang}
            label={trans('field.language')}
            dbfield="t_client_lang.txTypeClientLang" dbfieldkey="n_client.txLang"
            table="t_client_lang"
            col_id="txLang"
            col_tx="txTypeClientLang"
            extra_filter_1={"boActive='1'"}
          />

        </CxButtonShowMore>

        {isApp('opti') &&
          <CxAccordion xref={r_accordion_rx_glass} title={trans('field.rx_glas')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_rx_glass}
              margin="-15px 0px 0px 0px"
              url="rx_glas"
              filter={`n_rx_glas.inClient='${storeClient.inClient}'`}
              onOpenForm={openFormRxGlas}
              hasButtonAdd={isFullAccess()}
              columns={[
                { id: "0", Header: "ID", accessor: "inRxGlas", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
                { id: "2", Header: trans('field.reason'), accessor: "txReason", width: '70%' },
              ]}
            />
          </CxAccordion>
        }

        {isApp('opti') &&
          <CxAccordion xref={r_accordion_rx_clen} title={trans('field.rx_clen')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_rx_clen}
              margin="-15px 0px 0px 0px"
              url="rx_clen"
              filter={`n_rx_clen.inClient='${storeClient.inClient}'`}
              onOpenForm={openFormRxClen}
              hasButtonAdd={isFullAccess()}
              columns={[
                { id: "0", Header: "ID", accessor: "inRxClen", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
                { id: "2", Header: trans('field.reason'), accessor: "txReason", width: '70%' },
              ]}
            />
          </CxAccordion>
        }

        {isApp('opti') &&
          <CxAccordion xref={r_accordion_rx_haid} title={trans('field.rx_haid')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_rx_haid}
              margin="-15px 0px 0px 0px"
              url="rx_haid"
              filter={`n_rx_haid.inClient='${storeClient.inClient}'`}
              onOpenForm={openFormRxHaid}
              hasButtonAdd={isFullAccess()}
              columns={[
                { id: "0", Header: "ID", accessor: "inRxHaid", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
                { id: "2", Header: trans('field.reason'), accessor: "txReason", width: '70%' },
              ]}
            />
          </CxAccordion>
        }

        {isApp('opti') &&
          <CxAccordion xref={r_accordion_repl} title={trans('field.repls')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_repl}
              margin="-15px 0px 0px 0px"
              url="repls"
              filter={`inClient='${storeClient.inClient}'`}
              onOpenForm={openFormRepl}
              hasButtonAdd={isFullAccess()}
              disabledUntilUpgrade={!FxEdition.isInEdition('CLI_rpl')}
              columns={[
                { id: "0", Header: "ID", accessor: "inRepl", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '30%' },
                { id: "2", Header: trans('field.ps'), accessor: "txPs", width: '70%' },
              ]}
            />
          </CxAccordion>
        }

        {isApp('opti') &&
          <CxAccordion xref={r_accordion_lab_ord} title={trans('field.lab_orders')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_lab_ord}
              margin="-15px 0px 0px 0px"
              url="lab_order"
              filter={`n_lab_order.inClient='${storeClient.inClient}'`}
              onOpenForm={openFormLabOrder}
              hasButtonAdd={isFullAccess()}
              disabledUntilUpgrade={!FxEdition.isInEdition('LAB_ord')}
              columns={[
                { id: "0", Header: "ID", accessor: "inLabOrder", show: false },
                { id: "1", Header: "_inRxGlas", accessor: "inRxGlas", show: false },
                { id: "2", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
                { id: "3", Header: trans('field.status'), accessor: "txTypeStatusLabOrder", width: '15%' },
                { id: "4", Header: trans('field.lab'), accessor: "txLab", width: '20%', show: size.isLarge },
                { id: "5", Header: trans('field.reason'), accessor: "txReason", width: '52%' /* 2% adicional para alineación */, show: size.isLarge },
              ]}
            />
          </CxAccordion>
        }

        {(isApp('invo') || isApp('opti')) &&
          <CxAccordion xref={r_accordion_images} title={trans('field.images')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_images}
              margin="-15px 0px 0px 0px"
              url="images"
              filter={`n_image.inClient='${storeClient.inClient}'`}
              onOpenForm={openFormImage}
              hasButtonAdd={isFullAccess()}
              disabledUntilUpgrade={!FxEdition.isInEdition('CLI_img')}
              columns={[
                { id: "0", Header: "ID", accessor: "inImage", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
                { id: "2", Header: trans('field.type'), accessor: "txTypeImage", width: '15%' },
                { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
              ]}
            />
          </CxAccordion>
        }

        {(isApp('invo') || isApp('opti')) &&
          <CxAccordion xref={r_accordion_multimedia} title={trans('field.files2')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
            <CxTable
              xref={r_table_multimedia}
              margin="-15px 0px 0px 0px"
              url="multimedia"
              filter={`n_multimedia.txLinkTable='n_client' and n_multimedia.txLinkId='${storeClient.inClient}'`}
              onOpenForm={openFormMultimedia}
              hasButtonAdd={isFullAccess()}
              disabledUntilUpgrade={!FxEdition.isInEdition('CLI_mme')}
              columns={[
                { id: "0", Header: "ID", accessor: "inMultimedia", show: false },
                { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtMultimedia"]), width: '15%' },
                { id: "2", Header: trans('field.type'), accessor: "txTypeMultimedia", width: '15%' },
                { id: "3", Header: trans('field.issue'), accessor: "txIssue", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
              ]}
            />
          </CxAccordion>
        }

        {(isApp('vett') || isApp('spaw')) &&
          <CxTable
            title={trans('field.patients')}
            xref={r_table_animals}
            tooltip={`${trans('field.patient')}`}
            url="animals"
            filter={`n_animal.inClient='${storeClient.inClient}'`}
            onOpenForm={openFormAnimal}
            hasSearchBox
            hasButtonAdd={isFullAccess()}
            refColors={['table_cell_color_gray|' + trans('field.inactive')]}
            columns={[
              { id: "0", Header: "ID", accessor: "inAnimal", show: false },
              { id: "1", Header: trans('field.patient'), accessor: row => r_table_animals.current.highlightText(row["txAnimal"]), width: '25%' },
              { id: "2", Header: trans('field.species'), accessor: "txTypeAnimalSpecies", width: '25%' },
              { id: "3", Header: trans('field.breed'), accessor: "txTypeAnimalBreed", width: '25%', show: size.isLarge },
              {
                id: "4", Header: trans('field.gender'), accessor: row =>
                  FxStr.choose(row["boGender"], '0|1|2',
                    trans('field.animal_female'),
                    trans('field.animal_male'),
                    trans('field.na'),
                  ),
                width: '25%', show: size.isLarge
              },
              { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
            ]}
          />
        }

        <CxAccordion xref={r_accordion_incidences} title={trans('field.incidences')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} >
          <CxTable
            xref={r_table_incidences}
            margin="-15px 0px 0px 0px"
            url="clients/incidence"
            filter={`inClient='${storeClient.inClient}'`}
            onOpenForm={openFormClientIncidence}
            hasButtonAdd={isFullAccess()}
            columns={[
              { id: "0", Header: "ID", accessor: "inClientIncidence", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtClientIncidence"]), width: size.isLarge ? '15%' : '30%' },
              { id: "2", Header: trans('field.status'), accessor: "txTypeStatusClientIncidence", width: '15%', show: size.isLarge },
              { id: "3", Header: trans('field.incidence'), accessor: "txClientIncidence", width: '70%' },
            ]}
          />
        </CxAccordion>

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_client.txNotes" minRows={4} speech />

      </GxForm>

      <CxDialog
        title={trans('field.balance')}
        open={dlgBalance}
        onClose={() => setDlgBalance(false)}
        height='200px'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <table>
            <tbody>
              <tr><td><CxStatic label={`${trans('field.issued')}:`} height='30px' width='100px' /></td><td><CxStatic label={`${FxNum.toString(FxNum.to2r00(balanceVals.split('|')[0]))}`} alignRight height='30px' width='100px' /></td></tr>
              <tr><td><CxStatic label={`${trans('field.paid')}:`} height='30px' width='100px' /></td><td><CxStatic label={`${FxNum.toString(FxNum.to2r00(balanceVals.split('|')[1]))}`} alignRight height='30px' width='100px' /></td></tr>
              <tr><td><CxStatic label={`${trans('field.cash_reg')}:`} height='30px' width='100px' /></td><td><CxStatic label={`${FxNum.toString(FxNum.to2r00(balanceVals.split('|')[2]))}`} alignRight height='30px' width='100px' /></td></tr>
            </tbody>
          </table>
        </div>

      </CxDialog>

      <CxDialog
        title={trans('field.reset')}
        open={dlgPurseReset}
        onOk={() => resetPurseBalance()}
        onCancel={() => setDlgPurseReset(false)}
        height='290px'
      >
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxNumber xref={rf_purse_new_credit} label={trans('field.credit')} decimals={2} width='var(--ibs_control_width_md)' />
        </div>
      </CxDialog>

      <CxDlgDoc xref={r_dlg_doc} inClient={storeClient.inClient} txModule={'C'} onClose={checkPendingDocs} />
      <CxDlgUpgrade xref={r_dlg_upgrade} />

      <GxDlgSend xref={r_dlg_send} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_exists} severity="error" value={trans('msg.existing_x', 'field.ic')} />
      <CxSnackbar xref={r_snack_missing_mobile} severity="error" value={trans('msg.missing_x', 'field.mobile')} />

      <GxPrint xref={r_print} />
      <GxPrintLong xref={r_gx_print_long} />

    </div>
  );

}

export default MxClientForm;
