import { FaFilePrescription } from "react-icons/fa"; 
import React from 'react';
import GxList from '../../gx/GxList';
import CxDlgTable from '../../cx/CxDlgTable';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDialog from '../../cx/CxDialog';
import CxDate from '../../cx/CxDate';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxStr from '../../fx/FxStr';
import * as FxRegion from '../../fx/FxRegion';
import * as FxFetch from '../../fx/FxFetch';
import * as FxFile from '../../fx/FxFile';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient, StoreRxAuto } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import * as MxAnimalRxAutoFormH1 from '../files/MxAnimalRxAutoFormH1';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';
import CxDlgAlert from '../../cx/CxDlgAlert';


const MxActivityVettR = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const size = useWindowSize();
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);
  
  const r_dlg_alert = React.useRef(null);
  
  const r_list = React.useRef(null);
  let refColors = [];
  const r_dlg_select_client = React.useRef(null);
  const r_dlgtable_rxs_invoice = React.useRef(null);

  const hasColumnSpecies = () => { return Boolean(localStorage.getItem('MxActivityVettR.addcol_txTypeAnimalSpecies') === '1') };
  const hasColumnBreed = () => { return Boolean(localStorage.getItem('MxActivityVettR.addcol_txTypeAnimalBreed') === '1') };
  const hasColumnSeries = () => { return Boolean(localStorage.getItem('MxActivityVettR.addcol_txTypeSeriesRx') === '1') };

  const rf_create_recevet_from = React.useRef(null);
  const rf_create_recevet_to = React.useRef(null);

  const [dlgRxsInvoice, setDlgRxsInvoice] = React.useState(false);
  const [dlgCreateRecevetFile, setDlgCreateRecevetFile] = React.useState(false);

  React.useEffect(() => {
    clearStore('StoreRxAuto', storeRxAuto);

    refColors.push('table_cell_black|' + trans('field.manual'));
    refColors.push('table_cell_color_green|' + trans('field.automatic_0'));
    if (FxRegion.isSpain() && !FxRegion.isAndalusia()) {
      refColors.push('table_cell_color_blue|Presvet - En proceso');
      refColors.push('table_cell_color_red|Presvet - Error');
    }

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      let iv = {};
      iv = { txType: op.split(':')[1], _id: null, txRxAuto: null, itSeriesRx: null };
      r_dlg_select_client.current.setIntermediateValues(iv);
      r_dlg_select_client.current.setOpen(true);
    } else {
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      storeClient.inAnimal = row.original.animal_inAnimal;
      storeClient.txAnimal = row.original.animal_txAnimal;

      storeRxAuto.inClient = row.original.inClient;
      storeRxAuto.inAnimal = row.original.animal_inAnimal;
      storeRxAuto.txClient = row.original.txClient;
      storeRxAuto.txAnimal = row.original.animal_txAnimal;

      storeRxAuto.boFirstRender = true;
      storeRxAuto.boDataChanged = '0';
      storeRxAuto.boAuto = Boolean(row.original.inInvoice);

      if (row.original.txType === 'A') {
        storeRxAuto.txRxAuto = null;
        storeRxAuto.itSeriesRx = null;
        storeRxAuto.inInvoice = null;
      } else {
        storeRxAuto.inRxAuto = row.original._id;
        storeRxAuto.txRxAuto = row.original.txRxAuto;
        storeRxAuto.itSeriesRx = row.original.itSeriesRx;
      }

      Promise.all([setStoreClient(storeClient)]).then((result) => {
        FxLog.infox('MxActivityVettR.openForm', '...storeClient:', storeClient);
        Promise.all([MxAnimalRxAutoFormH1.load(storeRxAuto)]).then((result) => {
          Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
            if (row.original.txType === 'A') {
              setDlgRxsInvoice(true);
            } else {
              navTo("animal-rx-auto-form");
            }
          });
        });
      });
    }
  }

  const openFormRxsNewAuto = async (row) => {
    setDlgRxsInvoice(false);

    if (row) {
      storeRxAuto.inInvoice = row.original.inInvoice;
      Promise.all([MxAnimalRxAutoFormH1.loadFromInvoice(storeRxAuto)]).then((result) => {
        Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
          navTo("animal-rx-auto-form");
        });
      });
    }
  }

  const doExportRecevet = () => {
    setDlgCreateRecevetFile(false);
    let filter = {};
    let record = {};
    record['dtRxAuto_from'] = rf_create_recevet_from.current.getVal();
    record['dtRxAuto_to'] = rf_create_recevet_to.current.getVal();
    Promise.all([FxFetch.doJsonF('rx_auto/recevet_excel_upload/precheck', filter, record)]).then((result) => {
      let rv = result[0].theValue;
      if (rv==='OK') {
        // Crea el excel
        Promise.all([FxFetch.doJsonF('rx_auto/recevet_excel_upload', filter, record)]).then((result) => {
          FxLog.infox('MxActivityVettR.doExportRecevet', '...result[0]:', result[0]);
          if (result[0].theFilePath) {
            let theUrl = 'https://www.iberical.com/' + result[0].theFilePath;
            FxFile.downloadFile(theUrl);
          } else {
            r_dlg_alert.current.setValAndOpen('No hay recetas para estos criterios', null, "var(--ibs_color_red)");
          }
        });
      } else {
        // Hay cosas mal configuradas
        let errorDesc = ';'
        if (rv==='rv_no_ps_is_antibiotic') errorDesc = 'Ningún producto es de naturaleza antibiótico.';
        if (rv==='rv_missing_incorrect_rx_national_code') errorDesc = 'Falta "Código Nacional" en la ficha del Producto o es incorrecto.';
        if (rv==='rv_missing_incorrect_species') errorDesc = 'Falta "Especie" en ficha de Paciente o no está entre las aceptadas.';
        if (rv==='rv_missing_incorrect_employee_state') errorDesc = 'Falta "Provincia" en la ficha de Empleado o es incorrecta.';
        if (rv==='rv_missing_incorrect_rx_duration') errorDesc = 'Falta "Duración de Tratamiento" en la ficha del Producto o en la receta. El valor debe comenzar con un número.';
        if (rv==='rv_incorrect_rx_date') errorDesc = 'La fecha prescripción no puede ser mayor a la de hoy, ni anterior a 15 días.';
        r_dlg_alert.current.setValAndOpen(errorDesc, null, "var(--ibs_color_red)");
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    try{
    cols.push({ id: id++, Header: "ID", accessor: "_id", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    cols.push({ id: "inAnimal", Header: "inAnimal", accessor: "inAnimal", show: false });
    cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%' });
    cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '10%' });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.species'), accessor: row => r_list.current.getTable().highlightText(row["animal_txTypeAnimalSpecies"]), width: '10%', show: hasColumnSpecies() });
      cols.push({ id: id++, Header: trans('field.breed'), accessor: row => r_list.current.getTable().highlightText(row["animal_txTypeAnimalBreed"]), width: '10%', show: hasColumnBreed() });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => r_list.current.getTable().highlightDate(row["dtRxAuto"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.series'), accessor: row => r_list.current.getTable().highlightText(row["txTypeSeriesRx"]), width: '10%', show: hasColumnSeries() });
      cols.push({ id: id++, Header: trans('field.rx'), accessor: row => r_list.current.getTable().highlightText(row["txRxAuto"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '25%' });
      cols.push({ id: id++, Header: trans('field.notes'), accessor: row => r_list.current.getTable().highlightText(row["txNotes"]), width: '25%' });
      if (FxRegion.isSpain()) {
        cols.push({ id: id++, Header: 'Atb', accessor: row => (row["isAntibiotic"]==='1' ? trans('field.yes') : trans('field.no')), width: '10%' });
      }
      if (FxRegion.isSpain() && !FxRegion.isAndalusia()) {
        cols.push({ id: id++, Header: 'PresVet', accessor: row => (row["isAntibiotic"]==='1' ? Boolean(FxStr.isNumeric(row["txCodePresvet"])) ? trans('field.yes') : trans('field.no'): ''), width: '10%' });
      }
      
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtRxAuto"])), width: '10%' });
    }

    cols.push({ id: "G", Header: "automatic", accessor: row => Boolean(row["inInvoice"]), show: false });
    if (FxRegion.isSpain() && !FxRegion.isAndalusia()) {
      cols.push({ id: "B", Header: "PresvetProcess", accessor: row => Boolean(row["txCodePresvet"].includes('PRESVET-IN-PROCESS') && !row["txCodePresvet"].includes('[')), show: false });
      cols.push({ id: "R", Header: "PresvetError", accessor: row => Boolean(row["txCodePresvet"].includes('[')), show: false });
    }

    return cols;

    } catch(error) {
      FxLog.errorx('MxActivityVettR.getTableColumns', error)
    }
    return cols;
  }

  const getTooltip = () => {
    let tip = `${trans('field.client')} / ${trans('field.patient')} / ${trans('field.date')} / ${trans('field.ps')} / ${trans('field.rx')}`;
    if (size.isLarge) {
      if (hasColumnSeries()) tip = tip + `${' / ' + trans('field.series')}`;
      if (hasColumnSpecies()) tip = tip + `${' / ' + trans('field.species')}`;
      if (hasColumnBreed()) tip = tip + `${' / ' + trans('field.breed')}`;
    }

    return tip;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txTypeAnimalSpecies', name: trans('field.patient') + '-' + trans('field.species'), checked: hasColumnSpecies() ? '1' : '0' });
    cols.push({ id: 'txTypeAnimalBreed', name: trans('field.patient') + '-' + trans('field.breed'), checked: hasColumnBreed() ? '1' : '0' });
    cols.push({ id: 'txTypeSeriesRx', name: trans('field.series'), checked: hasColumnSeries() ? '1' : '0' });
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxActivityVettR.addcol_txTypeAnimalSpecies', options.includes('txTypeAnimalSpecies') ? '1' : '0');
    localStorage.setItem('MxActivityVettR.addcol_txTypeAnimalBreed', options.includes('txTypeAnimalBreed') ? '1' : '0');
    localStorage.setItem('MxActivityVettR.addcol_txTypeSeriesRx', options.includes('txTypeSeriesRx') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.activity')}: ${trans('field.rxs')}`}
        xref={r_list}
        tooltip={getTooltip()}
        fullscreen
        url={`activity/vett/typelist/r`}
        onOpenForm={openForm}
        refColors={refColors}
        columns={getTableColumns()}
        buttonAddOptions={[
          { id: 'M', text: trans('field.manual') },
          { id: 'A', text: trans('field.automatic_0') },
        ]}
        onOptions={optionsSelected}
        optionalColumns={getTableOptionalCols()}
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonLast7Days={true}
        optionalFromToButtonLast30Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />

            {/* botón de Exportación Recevet (Andalucía) */}
            {FxRegion.isAndalusia() && 
              <CxIconButtonNew icon={<FaFilePrescription fontSize="19px" style={{marginTop:'1px'}} />} classType="ibs_iconbutton_toolbar" onClick={() => setDlgCreateRecevetFile(true)} tooltip={'Crear Excel para Recevet'} 
            />}
          </div>
        }
        speechExternal
      />

      <GxDlgSelectClient xref={r_dlg_select_client} 
        includeAnimal={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('MxActivityVettR.return.GxDlgSelectClient', '...intermediateValues:', intermediateValues);
          row.original.txType = intermediateValues.txType; // para recetas
          row.original._id = intermediateValues._id; // para recetas
          row.original.txRxAuto = intermediateValues.txRxAuto; // para recetas
          row.original.itSeriesRx = intermediateValues.itSeriesRx; // para recetas
          Promise.all([setStoreClient(storeClient)]).then((result) => {
            openForm(null, row);
          });
        }} 
      />

      <CxDlgTable
        xref={r_dlgtable_rxs_invoice}
        open={dlgRxsInvoice}
        onSelect={openFormRxsNewAuto}
        onClose={() => setDlgRxsInvoice(false)}
        url={'rx_auto/invoices'}
        filter={`n_invoice.inAnimal='${storeClient.inAnimal}'`}
        columns={[
          { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '50%' },
          { id: "2", Header: trans('field.invoice'), accessor: "txInvoice", width: '50%' },
        ]}
        title={trans('field.invoice')}
      />

      <CxDialog
        title={'Crear fichero para Recevet'}
        open={dlgCreateRecevetFile}
        onCancel={() => setDlgCreateRecevetFile(false)}
        onOk={() => doExportRecevet()}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxDate xref={rf_create_recevet_from} label={trans('field.from')} width='var(--ibs_control_width_md)' today required />
          <CxDate xref={rf_create_recevet_to} label={trans('field.to')} width='var(--ibs_control_width_md)' today required />
        </div>
      </CxDialog>

      <CxDlgAlert xref={r_dlg_alert} />

    </div>
  );

}

export default MxActivityVettR;
