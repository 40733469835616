import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDialog from '../../cx/CxDialog';
import { StoreSettPurSurcharge } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxInput from '../../cx/CxInput';


const MxSettPurSurchargeForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  
  let r_form = React.useRef(null);
  let rf_txTypeSurchargeGroup = React.useRef(null);
  
  const r_snack_existing_type = React.useRef(null);
  const r_snack_record_used = React.useRef(null);

  const [storeSettPurSurcharge, setStoreSettPurSurcharge] = useSessionStorage('StoreSettPurSurcharge', StoreSettPurSurcharge);

  const [dlgAddNewGroup, setDlgAddNewGroup] = React.useState(false);
  const rf_group_new = React.useRef(null);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
      // no se puede crear dos veces un mismo tipo
      r_snack_existing_type.current.setOpen(true);
    } else {
      storeSettPurSurcharge.itSurcharge = lastSaveRv.theValue;
      Promise.all([setStoreSettPurSurcharge(storeSettPurSurcharge)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue==='rv_ps_using_surcharge') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  const doAddSurchargeGroup = () => {
    setDlgAddNewGroup(true);
  }

  const doCreateSurchargeGroup = (surchargeDTO) => {
    try {
      setDlgAddNewGroup(false);

      let record = {};
      if (surchargeDTO) {
        record['txTypeSurchargeGroup'] = surchargeDTO.txTypeSurchargeGroup;
      }

      Promise.all([FxFetch.doJsonX('sett/surcharge/group/c', record)]).then((result) => {
        if (result[0]) {
          rf_txTypeSurchargeGroup.current.getTable().redisplay();
        }
      });

    } catch (error) {
      FxLog.errorx("MxSettPurSurchargeForm.doCreateSurchargeGroup", error);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={trans('field.surcharge')}
        xref={r_form}
        url="sett/surcharge" 
        filterIdField='itSurcharge'
        filterIdValue={storeSettPurSurcharge.itSurcharge} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_surcharge.itSurcharge" value={storeSettPurSurcharge.itSurcharge || ''} />

        <CxChooser xref={rf_txTypeSurchargeGroup} label={trans('field.type')}
          dbfield="t_surcharge_group.txTypeSurchargeGroup" dbfieldkey={`t_surcharge.itSurchargeGroup`}
          table="t_surcharge_group"
          col_id="itSurchargeGroup"
          col_tx="txTypeSurchargeGroup"
          onButtonAdd={doAddSurchargeGroup}
          required
        />

        <CxNumber xref={useRef(null)} label={trans('field.surcharge_perc')} dbfield="t_surcharge.nuSurchargePerc"  required />
        
      </GxForm>

      <CxDialog
        title={trans('field.new_1')}
        open={dlgAddNewGroup}
        onOk={() => doCreateSurchargeGroup({ txTypeSurchargeGroup: rf_group_new.current.getVal() })}
        onCancel={() => setDlgAddNewGroup(false)}
        height='200px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_lg)' }}>
          <CxInput xref={rf_group_new} label={trans('field.group')}  />
        </div>
      </CxDialog>

      <CxSnackbar xref={r_snack_existing_type} severity="error" value={trans('msg.existing_x','field.type')} />
      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />
      
    </div>
  );

}

export default MxSettPurSurchargeForm;
