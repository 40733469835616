import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxTable from '../../cx/CxTable';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValue, StorePlan, StorePlanPs } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import { useAccess } from '../../hx/useAccess';


const MxPlanForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.plans'));
  const [{ navTo }] = useNav();
  const r_snack_ask_save = React.useRef(null);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const r_form = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_dtNext = React.useRef(null);
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);

  const [storePlan, setStorePlan] = useSessionStorage('StorePlan', StorePlan);
  const [storePlanPs, setStorePlanPs] = useSessionStorage('StorePlanPs', StorePlanPs);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
      rf_dtFrom.current.setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    storePlan.inPlan = lastSaveRv.theValue;
    Promise.all([setStorePlan(storePlan)]).then((result) => {
      forceUIUpdate();
      //navBack(); // 2023-02-18 - daq: no volvemos xq puede querer guardar pss.
    });
  }
  const formDelete = async (rv) => {
  }

  const openFormPs = async (op, row) => {
    if (!storePlan.inPlan) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storePlanPs.irPlanPs = null;
        storePlanPs.inPlan = storePlan.inPlan;
        storePlanPs.inPs = null;
        setStorePlanPs(storePlanPs);
      } else {
        storePlanPs.irPlanPs = row.original.irPlanPs;
        storePlanPs.inPlan = storePlan.inPlan;
        storePlanPs.inPs = row.original._inPs;
        setStorePlanPs(storePlanPs);
      }
      navTo("plan-ps-form");
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.plan')}`}
        xref={r_form}
        url="plans"
        filterIdField='inPlan'
        filterIdValue={storePlan.inPlan}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxSearcher xref={rf_txClient} label={trans('field.client')} dbfield={`n_client.txClient`} dbfieldkey={`n_plan.inClient`}
          id="n_client"
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          refColors={ [ 'table_cell_color_red|' + trans('field.debtor') ] }
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          readOnly={false}
          required
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_plan.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxDate xref={rf_dtNext} label={trans('field.next')} dbfield={"n_plan.dtInvoiceNext"} required />
        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_plan.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_plan.dtTo"} />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_plan.txRemarks" maxLength={255} />

        <CxTable
          url="plans/pss"
          filter={`r_plan_ps.inPlan='${storePlan.inPlan}'`}
          onOpenForm={openFormPs}
          hasButtonAdd={isFullAccess()}
          columns={[
            { id: "0", Header: "ID", accessor: "irPlanPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: "txPs", width: '70%' },
            { id: "2", Header: trans('field.quantity'), accessor: "nuQuantity", width: '30%', align: 'right' },
            { id: "3", Header: '_inPs', accessor: "inPs", show: false },
          ]}
        />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxPlanForm;
