import { BiLockOpenAlt } from "react-icons/bi"; 
import { BiLockAlt } from "react-icons/bi"; 
import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import { useTranslation } from '../hx/useTranslation';
import * as FxDat from '../fx/FxDat';
import CxIconButton from '../cx/CxIconButton';
import CxDialog from '../cx/CxDialog';
import * as FxTable from '../fx/FxTable';
import CxDlgPin from '../cx/CxDlgPin';
import CxDlgAlert from '../cx/CxDlgAlert';
import CxDlgTable from '../cx/CxDlgTable';
import CxTable from '../cx/CxTable';
import GxPrint from '../gx/GxPrint';


const CxDlgDoc = props => {
  const [{ trans }] = useTranslation();

  const [lockDelete, setLockDelete] = React.useState(true);

  let r_print = React.useRef(null);
  const r_dlg_pin = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_dlgtable_docs = React.useRef(null);
  const r_dlgtable_docs_table = React.useRef(null);
  const r_dlgtable_docs_templates = React.useRef(null);
  const r_dlgtable_employee = React.useRef(null);

  const { onClose, open, ...rest } = props;

  const [dlgDocs, setDlgDocs] = React.useState(false);
  const [dlgDocsTemplates, setDlgDocsTemplates] = React.useState(false);
  const [dlgEmployee, setDlgEmployee] = React.useState(false);
  const [sttItDoc, setSttItDoc] = React.useState(false);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setDlgDocs,
    };
  });
  const getType = () => { return 'CxDlgDoc' };

  const handleLock = () => {
    if (lockDelete) {
      r_dlg_pin.current.setOpen(true);
    } else {
      setLockDelete(!lockDelete);
    }
  };

  const doPrintX = async (itDoc, inClientDoc, inEmployee, isSigned) => {
    setDlgDocs(false);
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['docSize'] = `A4`;
    filter['itDoc'] = itDoc;
    filter['inEmployee'] = inEmployee;
    filter['inClientDoc'] = inClientDoc;
    filter['inClient'] = rest.inClient;
    filter['inAnimal'] = rest.inAnimal;
    filter['txModule'] = rest.txModule;
    let record = {};
    record['dtNow'] = FxDat.timestamp();
    let inClient = rest.inClient;
    let inAnimal = rest.inAnimal;
    r_print.current.setScale(1.5);
    r_print.current.setUrl('docs/show');
    r_print.current.setInEmployee(inEmployee);
    r_print.current.setSign(
      {
        itDoc: itDoc,
        inClientDoc: inClientDoc,
        inClient: rest.inClient,
        inAnimal: rest.inAnimal,
        inEmployee: inEmployee,
        dtSignature: FxDat.timestamp(),
        isSigned: (isSigned === '1'),
      }
    );
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient),
      r_print.current.setInAnimal(inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const doTableIsEmpty = () => {
    FxLog.infox('CxDlgDoc.doTableIsEmpty', '...:', );
    r_dlgtable_docs_table.current.getButtonAddTooltip().setTooltipType('controlled');
    r_dlgtable_docs_table.current.getButtonAddTooltip().setMillis(4000);
    r_dlgtable_docs_table.current.getButtonAddTooltip().setTooltipText(trans('msg.click_here_to_add'));
  }

  const doClientDoc = async (op, row) => {
    try {
      if (row === null) {
        setDlgDocsTemplates(true);
      } else {
        doPrintX(row.original.itDoc, row.original.inClientDoc, row.original.inEmployee, row.original._signed);
      }

    } catch (error) {
      FxLog.errorx("CxDlgDoc.doClientDoc", error);
    }
  }

  const doClientDocNew = async (op, row) => {
    let itDoc = row.original.itDoc;
    setSttItDoc(itDoc);

    if (row.original._requires_employee === '1') {
      setDlgEmployee(true);
    } else {
      setDlgDocsTemplates(false);
      saveNew(itDoc, null);
    }
  }

  const onSelectedEmployee = (row) => {
    if (row.original.inEmployee) {
      setDlgEmployee(false);
      setDlgDocsTemplates(false);
      saveNew(sttItDoc, row.original.inEmployee);
    }
  }

  const saveNew = (itDoc, inEmployee) => {

    // Guarda un registro en n_client_doc
    let record = {};
    record['n_client_doc.itDoc'] = itDoc;
    record['n_client_doc.inEmployee'] = inEmployee;
    record['n_client_doc.inClient'] = rest.inClient;
    record['n_client_doc.dtSignature'] = FxDat.timestamp();
    record['n_client_doc.inAnimal'] = rest.inAnimal;

    Promise.all([FxFetch.doJsonX('clients/doc/cu', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          // refresca el listado de documentos
          r_dlgtable_docs_table.current.refresh();
        }
      }
    });
  }

  const doClientDocDelete = (row) => {
    let record = {};
    record['idValue'] = row.inClientDoc;
    Promise.all([FxFetch.doJsonX('clients/doc/d', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_dlgtable_docs_table.current.refresh();
        }
      }
    });
  }

  return (
    <div>

      <CxDialog
        xref={r_dlgtable_docs}
        title={`${trans('field.docs')}`}
        open={dlgDocs}
        onClose={() => {
          setDlgDocs(false);
          if (onClose) {
            onClose();
          }
        }}
        height='450px'>

        <CxTable
          xref={r_dlgtable_docs_table}
          id="CxDlgDoc_table_docs"
          origin='CxDlgDoc'
          url="clients/doc"
          filter={`t_doc_tpl.txModule='${rest.txModule}'`}
          addParams={rest.txModule === 'A' ? `inAnimal='${rest.inAnimal}'` : `inClient='${rest.inClient}'`}
          onOpenForm={doClientDoc}
          onFetchEmpty={doTableIsEmpty}
          hasButtonAdd={true}
          refColors={[
            'table_cell_color_green|' + trans('field.docs_signed'),
          ]}
          columns={[
            { id: "0", Header: "ID", accessor: "inClientDoc", show: false },
            { id: "inEmployee", Header: "inEmployee", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtSignature"]), width: '30%' },
            { id: "2", Header: trans('field.doc'), accessor: "txTypeDoc", width: '70%' },
            {
              id: "BTN_DEL", icon: <BiLockAlt />, icon_alt: <BiLockOpenAlt />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => handleLock(),
              accessor: row => { return Boolean(row["inClientDoc"]) && <CxIconButton type="delete" classType="ibs_iconbutton_table_body" disabled={lockDelete} data={row} onClick={doClientDocDelete} tooltip={trans('field.remove_x', 'field.signature')} /> },
              align: 'center', width: '33px'
            },
            { id: "G", Header: "_signed", accessor: row => Boolean(row["_signed"] === '1'), show: false },
          ]}
        />
      </CxDialog>

      <CxDialog
        xref={r_dlgtable_docs_templates}
        title={trans('field.new_1')}
        open={dlgDocsTemplates}
        onClose={() => setDlgDocsTemplates(false)}
        height='450px'>

        <CxTable
          id="CxDlgDoc_table_templates"
          origin='CxDlgDoc'
          url="clients/doc/templates"
          filter={`t_doc_tpl.txModule='${rest.txModule}'`}
          onOpenForm={doClientDocNew}
          columns={[
            { id: "0", Header: "ID", accessor: "itDoc", show: false },
            { id: "1", Header: trans('field.template'), accessor: "txTypeDoc", width: '100%' },
            { id: "_requires_employee", Header: "_requires_employee", accessor: "_requires_employee", show: false },
          ]}
        />
      </CxDialog>

      <CxDlgTable
        xref={r_dlgtable_employee}
        hasSearchBox={true}
        open={dlgEmployee}
        onSelect={onSelectedEmployee}
        onClose={() => setDlgEmployee(false)}
        url={'employees'}
        columns={[
          { id: "0", Header: "ID", accessor: "inEmployee", show: false },
          { id: "1", Header: trans('field.employee'), accessor: row => r_dlgtable_employee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
        ]}
        title={trans('field.employee')}
        height='450px'
      />

      <CxDlgPin xref={r_dlg_pin} onOk={() => setLockDelete(!lockDelete)} />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

    </div>
  );
};

export default CxDlgDoc;
