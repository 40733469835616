import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import * as FxNum from '../fx/FxNum';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/main.css';
import CxButton from './CxButton';
import CxInput from './CxInput';
import CxNumber from './CxNumber';
import { useTranslation } from '../hx/useTranslation';
import CxSnackbar from './CxSnackbar';
import CxIconButton from "./CxIconButton";
import { getStoreValue } from "../gx/GxStore";


const useStyles = makeStyles(props => ({
  dlgTitle: {
    backgroundColor: 'var(--ibs_color_cy_blue)',
    color: 'var(--ibs_color_white)',
    "& .MuiBox-root": {
      fontSize: "16px",
    },
    "& .MuiIconButton-root": {
      padding: '0px',
    },
  },
  dlgContent: {
    backgroundColor: 'var(--ibs_color_white)',
    color: 'var(--ibs_color_black)',
    display: 'flex',
    flexFlow: 'column',
    gap: '10px',
    width: props => props.width,
    height: props => props.height,
  },
  dlgActions: {
    backgroundColor: 'var(--ibs_color_white)',
    display: 'flex',
    justifyContent: 'center',
  },
  dlgLabel: {
    fontSize: "12px",
    margin: "0px",
    color: 'var(--ibs_color_black)',
  },
  dlgButtonClose: {
    color: 'var(--ibs_color_white)',
    '&:hover': { backgroundColor: 'transparent', },
  },
}));

const CxDlgBarcode = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);

  const { onAddPs, ...rest } = props;

  let rf_txPsCode = React.useRef(null);
  let rf_nuQuantity = React.useRef(null);

  const [ps, setPs] = React.useState({ inPs: '', txPs: '', nuPrice: '', hasLots: ''});

  const [open, setOpen] = React.useState(false);

  // definimos dígitos: UPC se usa en EEUU y Canadá, y tiene 12 dígitos. EAN-13 en Europa y tiene 13 dígitos.
  const digits = Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('us.') || getStoreValue('StoreSession', 'txIdi').startsWith('ca.')) ? 12 : 13;

  const r_snack_error = React.useRef(null);

  const handleEntered = () => {
    if (rf_txPsCode.current != null) {
      rf_txPsCode.current.setFocus();
    }
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, getInput: getInput, openWindow,
    };
  });
  const getType = () => { return 'CxDlgBarcode' };
  const getInput = () => { return rf_txPsCode.current };

  const openWindow = (boOpen) => {
    Promise.all([setPs('')]).then((result) => {
      setOpen(boOpen);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const doClear = () => {
    setPs({ inPs: '', txPs: '', nuPrice: '', hasLots: ''});
  }

  const doDisplayPs = (val) => {
    try {
      FxLog.infox('CxDlgBarcode.doDisplayPs', '...val:', val);
      if (!val || (val && val.length!==digits)) { // si no es EAN-13 o UPC, sale.
        return;
      }
      let record = {};
      record['txPsCode'] = val; 
      Promise.all([FxFetch.doJsonX('sls/doc/get_ps_by_code', record)]).then((result) => {
        if (result[0]) {
          Promise.all([setPs({ inPs: result[0].inPs, txPs: result[0].txPs, nuPrice: result[0].nuPrice, hasLots: result[0].hasLots })]).then((result) => {
            if ( result[0] && !result[0].inPs) {
              // el producto no existe o el lector leyó mal.
              rf_txPsCode.current.clear();
              rf_txPsCode.current.setFocus();
            }
          });
        }
      });

    } catch (error) {
      FxLog.errorx("CxDlgBarcode.doDisplayPs", error);
    }
  }

  const handleAddPs = async () => {
    if (ps && ps.inPs) {
      onAddPs(ps.inPs, ps.hasLots, rf_nuQuantity.current.getVal());
      setOpen(false);
    } else {
      r_snack_error.current.setVal(trans('msg.incorrect_x','field.barcode'));
      r_snack_error.current.setOpen(true);
      rf_txPsCode.current.clear();
      rf_txPsCode.current.setFocus();
    }
  }

  return (
    <div>
      <Dialog TransitionProps={{ onEntered: handleEntered, /*onExiting: handleExiting, onExited: handleExited*/ }} open={open} {...rest}>
        <DialogTitle className={classes.dlgTitle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{trans('field.barcode')}</Box>
            <Box>
              <CxIconButton type="close" classType={classes.dlgButtonClose} onClick={handleClose} />
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dlgContent}>
          <CxInput xref={rf_txPsCode} label={trans('field.code')} width='var(--ibs_control_width_sm)' onChange={doDisplayPs} onClear={doClear} required />
          <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} decimals={2} decimalsfixed="true" width='var(--ibs_control_width_sm)' defaultValue={1} sumButton required />
          <p style={{ height: '5px', width: 'var(--ibs_control_width_sm)', fontSize: '12px', color: 'var(--ibs_color_blue)', textAlign: 'center' }} >{ps.txPs}</p>
          <p style={{ height: '5px', fontSize: '14px', color: 'var(--ibs_color_red)', textAlign: 'center' }} >{`${ps && ps.nuPrice && FxNum.toString(FxNum.to2r00(ps.nuPrice))}`}</p>
        </DialogContent>

        <DialogActions className={classes.dlgActions}>
          <CxButton label={trans('field.add')} onClick={handleAddPs} width='var(--ibs_control_width_sm)' />
        </DialogActions>
      </Dialog>

      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );
};

export default CxDlgBarcode;
