import { BiLockOpenAlt } from "react-icons/bi"; 
import { BiLockAlt } from "react-icons/bi"; 
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import GxList from '../../gx/GxList';
import GxPrint from '../../gx/GxPrint';
import { useWindowSize } from '../../hx/useWindowSize';
import CxDialog from '../../cx/CxDialog';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxStatic from '../../cx/CxStatic';
import * as FxFetch from '../../fx/FxFetch';
import * as FxDat from '../../fx/FxDat';
import * as FxTable from '../../fx/FxTable';
import * as FxNum from '../../fx/FxNum';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgTable from '../../cx/CxDlgTable';
import { StoreCash, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxIconButton from '../../cx/CxIconButton';
import { useAccess } from '../../hx/useAccess';


const MxCashList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.cash_reg'));
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_print = React.useRef(null);

  const hasColumnClient = () => { return Boolean(localStorage.getItem('MxCashList.addcol_txClient') === '1') };
  const hasColumnEmployee = () => { return Boolean(localStorage.getItem('MxCashList.addcol_txEmployee') === '1') };

  const [dlgTotal, setDlgTotal] = React.useState(false);
  const [dlgBrief, setDlgBrief] = React.useState(false);
  const [dlgCashUp, setDlgCashUp] = React.useState(false);

  const r_dlg_total = React.useRef(null);
  const r_dlg_table_brief = React.useRef(null);
  const r_dlg_cash_up = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const r_list = React.useRef(null);
  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_total = React.useRef(null);
  const rf_total_cash = React.useRef(null);
  const rf_total_card = React.useRef(null);
  const rf_total_other = React.useRef(null);

  const r_snack_success = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const [lockDelete, setLockDelete] = React.useState(true);

  const [storeCash, setStoreCash] = useSessionStorage('StoreCash', StoreCash);

  let _today = FxDat.today();
  let _now = FxDat.now();

  React.useEffect(() => {
    FxLog.infox("MxCashList.useEffect", "...dtCashItemFrom: " + storeCash.dtCashItemFrom);
    FxLog.infox("MxCashList.useEffect", "...dtCashItemTo: " + storeCash.dtCashItemTo);
    FxLog.infox("MxCashList.useEffect", "...itCash: " + storeCash.itCash);

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      storeCash.inCashItem = null;
      setStoreCash(storeCash);
      navTo("cash-form");
    } else {
      if (!row.original.inInvoice) {
        storeCash.inCashItem = row.original.inCashItem;
        setStoreCash(storeCash);
        navTo("cash-form");
      }
    }
  }

  const doCashUp = () => {
    setDlgCashUp(false);
    let dtCashUp = rf_date.current.getVal() || '';
    let tmCashUp = rf_time.current.getVal() || '';
    let record = {};
    record['itCash'] = storeCash.itCash;
    record['dtCashItemFrom'] = storeCash.dtCashItemFrom;
    record['dtCashItemTo'] = storeCash.dtCashItemTo;
    record['dtCashUp'] = dtCashUp;
    record['tmCashUp'] = tmCashUp;
    Promise.all([FxFetch.doJsonX('cashitem/cash_up', record)]).then((result) => {
      if (result[0]) {
        switch (result[0].theValue) {
          case 'rv_dates_select_one':
            r_snack_error.current.setVal(trans('msg.dates_select_one'));
            r_snack_error.current.setOpen(true);
            break;

          case 'rv_items_pending':
            r_snack_error.current.setVal(trans('msg.items_pending'));
            r_snack_error.current.setOpen(true);
            break;

          case 'rv_unconfirmed_docs':
            r_snack_error.current.setVal(trans('msg.unconfirmed_docs'));
            r_snack_error.current.setOpen(true);
            break;

          case 'rv_lack_of_records':
            r_snack_error.current.setVal(trans('msg.lack_of_records'));
            r_snack_error.current.setOpen(true);
            break;

          case 'rv_dates_mismatched_cashup':
            r_snack_error.current.setVal(trans('msg.dates_mismatched_cashup'));
            r_snack_error.current.setOpen(true);
            break;

          case 'rv_missing_information':
            r_snack_error.current.setVal(trans('msg.missing_information'));
            r_snack_error.current.setOpen(true);
            break;

          default:
            r_snack_success.current.setOpen(true);
            r_list.current.redisplay();
        }
      }
    });
  }

  const doTotal = () => {
    try {
      Promise.all([setDlgTotal(true)]).then((result) => {
        let record = {};
        record['dtCashItemFrom'] = storeCash.dtCashItemFrom;
        record['dtCashItemTo'] = storeCash.dtCashItemTo;
        record['itCash'] = storeCash.itCash;
        record['arPayments'] = storeCash.arPayments;
        Promise.all([FxFetch.doJsonX('cashitem/totals', record)]).then((result) => {
          if (result[0]) {
            if (result[0].theValue) {
              let tot_cash = result[0].theValue.split('|')[0];
              let tot_card = result[0].theValue.split('|')[1];
              let tot_other = result[0].theValue.split('|')[2];
              let tot = result[0].theValue.split('|')[3];
              rf_total_cash.current.setLabel(`${FxNum.toString(FxNum.to2r00(tot_cash))}`);
              rf_total_card.current.setLabel(`${FxNum.toString(FxNum.to2r00(tot_card))}`);
              rf_total_other.current.setLabel(`${FxNum.toString(FxNum.to2r00(tot_other))}`);
              rf_total.current.setLabel(`${FxNum.toString(FxNum.to2r00(tot))}`);
            }
          }
        });
      });

    } catch (error) {
      FxLog.errorx("MxCashList.doTotal", error);
    }
  }

  const doPrintX = async (row) => {
    if (row) {
      setDlgBrief(false);
      let filter = {};
      filter['reportFormat'] = `PDF`;
      filter['docSize'] = `A4`;
      filter['dtCashUp'] = row.original.dtCashUp.substring(0, 16);
      filter['itCash'] = row.original.itCash;
      filter['txTypeCash'] = row.original.txTypeCash;
      let record = {};
      r_print.current.setScale(1);
      r_print.current.setUrl('slsDoc/report/cash_up');
      //----------------
      Promise.all([
        r_print.current.setFilter(filter),
        r_print.current.setRecord(record),
      ]).then((result) => {
        r_print.current.doPrint();
      });
    }
  }

  const doCashUpDelete = (row) => {
    let record = {};
    record['itCash'] = row.itCash;
    record['dtCashUp'] = row.dtCashUp;
    Promise.all([FxFetch.doJsonX('cashitem/cash_up/d', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_dlg_table_brief.current.getTable().refresh();
          r_list.current.refresh();
        }
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: "ID", accessor: "inCashItem", show: false });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtCashItem"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.item'), accessor: "txItem", width: '10%' });
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%', show: hasColumnClient() });
      cols.push({ id: id++, Header: trans('field.employee'), accessor: row => r_list.current.getTable().highlightText(row["txEmployee"]), width: '20%', show: hasColumnEmployee() });
      cols.push({ id: id++, Header: trans('field.doc'), accessor: row => r_list.current.getTable().highlightText(row["txInvoice"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.cash_reg'), accessor: "txTypeCash", width: '10%' });
      cols.push({ id: id++, Header: trans('field.movement'), accessor: row => r_list.current.getTable().highlightText(row["txTypeMovement"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.payment'), accessor: "txTypePayment", width: '10%' });
      cols.push({ id: id++, Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '10%', align: 'right' });
      cols.push({ id: 'G', Header: "theStatus", accessor: row => Boolean(row["dtCashUp"]), show: false });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: "ID", accessor: "inCashItem", show: false });
      cols.push({ id: id++, Header: trans('field.item'), accessor: "txItem", width: '30%' });
      cols.push({ id: id++, Header: trans('field.movement'), accessor: row => r_list.current.getTable().highlightText(row["txTypeMovement"]), width: '40%' });
      cols.push({ id: id++, Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["nuAmount"])), width: '30%', align: 'right' });
      cols.push({ id: 'G', Header: "theStatus", accessor: row => Boolean(row["dtCashUp"]), show: false });
    }
    return cols;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txClient', name: trans('field.client'), checked: hasColumnClient() ? '1' : '0' });
    cols.push({ id: 'txEmployee', name: trans('field.employee'), checked: hasColumnEmployee() ? '1' : '0' });
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxCashList.addcol_txClient', options.includes('txClient') ? '1' : '0');
    localStorage.setItem('MxCashList.addcol_txEmployee', options.includes('txEmployee') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = '';
    tip = `${trans('field.doc')} / ${trans('field.movement')}`;
    if (hasColumnClient()) tip = tip + `${' / ' + trans('field.client')}`;
    if (hasColumnEmployee()) tip = tip + `${' / ' + trans('field.employee')}`;
    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.cash_reg')}: ${FxDat.toLocalDate(storeCash.dtCashItemFrom)} / ${FxDat.toLocalDate(storeCash.dtCashItemTo)}`}
        xref={r_list}
        tooltip={getTooltip()}
        fullscreen
        showtip
        url="cashitem"
        hasButtonAdd={isFullAccess()}
        onOpenForm={openForm}
        addParams={`dtCashItemFrom=${storeCash.dtCashItemFrom}&dtCashItemTo=${storeCash.dtCashItemTo}&itCash=${storeCash.itCash}&inClient=${storeCash.inClient}&arPayments=${storeCash.arPayments}`}
        refColors={['table_cell_color_green|' + trans('field.cash_up')]}
        columns={getTableColumns()}
        optionalColumns={getTableOptionalCols()}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="sum" classType="ibs_iconbutton_toolbar" onClick={doTotal} tooltip={trans('field.total')} />
            <CxIconButton type="coin_stack" classType="ibs_iconbutton_toolbar" onClick={() => setDlgCashUp(true)} tooltip={trans('field.cash_up')} />
            <CxIconButton type="report" classType="ibs_iconbutton_toolbar" onClick={() => setDlgBrief(true)} tooltip={trans('field.brief')} />
          </div>
        }
      >
      </GxList>

      <CxDlgTable
        xref={r_dlg_table_brief}
        open={dlgBrief}
        onSelect={doPrintX}
        onClose={() => setDlgBrief(false)}
        url={'cashitem/brief'}
        columns={[
          { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDateTime(row["dtCashUp"]), width: '30%' },
          { id: "2", Header: trans('field.cash_reg'), accessor: "txTypeCash", width: '35%', show: size.isLarge },
          { id: "3", Header: "_itCash", accessor: "itCash", show: false },
          { id: "4", Header: trans('field.quantity'), accessor: "_qy", width: '15%', align: 'right' },
          { id: "5", Header: trans('field.amount'), accessor: row => (FxTable.cellTo2r00(row["_tot"])), width: '20%', align: 'right' },
          {
            id: "BTN_DEL", icon: <BiLockAlt />, icon_alt: <BiLockOpenAlt />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockDelete(!lockDelete),
            accessor: row => { return <CxIconButton type="delete" classType="ibs_iconbutton_table_body" disabled={lockDelete} data={row} onClick={doCashUpDelete} tooltip={trans('field.delete')} /> },
            align: 'center', width: '10%'
          }
        ]}
        title={trans('field.brief')}
      />

      <CxDialog
        xref={r_dlg_cash_up}
        title={trans('field.cash_up')}
        open={dlgCashUp}
        onOk={doCashUp}
        onCancel={() => setDlgCashUp(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxDate xref={rf_date} label={trans('field.date')} defaultValue={_today} dbfield="_cashup_date" required />
          <CxTime xref={rf_time} label={trans('field.time')} defaultValue={_now} dbfield="_cashup_time" required />
        </div>

      </CxDialog>

      <CxDialog
        xref={r_dlg_total}
        title={trans('field.total')}
        open={dlgTotal}
        onOk={() => setDlgTotal(false)}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <table>
            <tbody>
              <tr><td><CxStatic label={`${trans('field.cash')}:`} height='30px' width='150px' /></td><td><CxStatic xref={rf_total_cash} alignRight height='30px' width='150px' /></td></tr>
              <tr><td><CxStatic label={`${trans('field.card')}:`} height='30px' width='150px' /></td><td><CxStatic xref={rf_total_card} alignRight height='30px' width='150px' /></td></tr>
              <tr><td><CxStatic label={`${trans('field.other_1_p')}:`} height='30px' width='150px' /></td><td><CxStatic xref={rf_total_other} alignRight height='30px' width='150px' /></td></tr>
              <tr><td><CxStatic red label={`${trans('field.total').toUpperCase()}:`} height='30px' width='150px' /></td><td><CxStatic red xref={rf_total} alignRight height='30px' width='150px' /></td></tr>
            </tbody>
          </table>
        </div>

      </CxDialog>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxCashList;
