import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxState from '../../cx/CxState';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, setStoreValue } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';


const MxSettFileForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const rf_txDefaultCity = React.useRef(null);
  const rf_txDefaultState = React.useRef(null);
  const rf_txDefaultPostalCode = React.useRef(null);
  const rf_txDefaultCountry = React.useRef(null);

  const rf_boAutoNumFiles = React.useRef(null);
  //const rf_boConfirmExit = React.useRef(null);
  const rf_boAutoSelEmployee = React.useRef(null);
  const rf_boUseUcaseForNames = React.useRef(null);
  const rf_boPreventDuplicateCli = React.useRef(null);
  const rf_boPreventDuplicatePss = React.useRef(null);
  const rf_boEnableOTF = React.useRef(null);

  const r_snack_save = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/branch/r', record)]).then((result) => {
      if (result[0]) {
        rf_txDefaultCity.current.setVal(result[0][0].txDefaultCity);
        rf_txDefaultState.current.setVal(result[0][0].txDefaultState);
        rf_txDefaultPostalCode.current.setVal(result[0][0].txDefaultPostalCode);
        rf_txDefaultCountry.current.setVal(result[0][0].txDefaultCountry);
        rf_boAutoNumFiles.current.setVal(result[0][0].boAutoNumFiles);
        //rf_boConfirmExit.current.setVal(result[0][0].boConfirmExit);
        rf_boAutoSelEmployee.current.setVal(result[0][0].boAutoSelEmployee);
        rf_boUseUcaseForNames.current.setVal(result[0][0].boUseUcaseForNames);
        rf_boPreventDuplicateCli.current.setVal(result[0][0].boPreventDuplicateCli);
        rf_boPreventDuplicatePss.current.setVal(result[0][0].boPreventDuplicatePss);
        rf_boEnableOTF.current.setVal(result[0][0].boEnableOTF);
      }
    });
  }

  const formSaveCustom = async () => {

    let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
    let record = {};
    
    record['s_branch.txDefaultCity'] = rf_txDefaultCity.current.getVal();
    setStoreValue('StoreSession', 'txDefaultCity', rf_txDefaultCity.current.getVal());

    record['s_branch.txDefaultState'] = rf_txDefaultState.current.getVal();
    setStoreValue('StoreSession', 'txDefaultState', rf_txDefaultState.current.getVal());

    record['s_branch.txDefaultPostalCode'] = rf_txDefaultPostalCode.current.getVal();
    setStoreValue('StoreSession', 'txDefaultPostalCode', rf_txDefaultPostalCode.current.getVal());

    record['s_branch.txDefaultCountry'] = rf_txDefaultCountry.current.getVal();
    setStoreValue('StoreSession', 'txDefaultCountry', rf_txDefaultCountry.current.getVal());
    
    record['s_branch.boAutoNumFiles'] = rf_boAutoNumFiles.current.getVal();
    setStoreValue('StoreSession', 'boAutoNumFiles', rf_boAutoNumFiles.current.getVal());
    
    /*record['s_branch.boConfirmExit'] = rf_boConfirmExit.current.getVal();
    setStoreValue('StoreSession', 'boConfirmExit', rf_boConfirmExit.current.getVal());*/
    
    record['s_branch.boAutoSelEmployee'] = rf_boAutoSelEmployee.current.getVal();
    setStoreValue('StoreSession', 'boAutoSelEmployee', rf_boAutoSelEmployee.current.getVal());
    
    record['s_branch.boUseUcaseForNames'] = rf_boUseUcaseForNames.current.getVal();
    setStoreValue('StoreSession', 'boUseUcaseForNames', rf_boUseUcaseForNames.current.getVal());
    
    record['s_branch.boPreventDuplicateCli'] = rf_boPreventDuplicateCli.current.getVal();
    setStoreValue('StoreSession', 'boPreventDuplicateCli', rf_boPreventDuplicateCli.current.getVal());
    
    record['s_branch.boPreventDuplicatePss'] = rf_boPreventDuplicatePss.current.getVal();
    setStoreValue('StoreSession', 'boPreventDuplicatePss', rf_boPreventDuplicatePss.current.getVal());
    
    record['s_branch.boEnableOTF'] = rf_boEnableOTF.current.getVal();
    setStoreValue('StoreSession', 'boEnableOTF', rf_boEnableOTF.current.getVal());

    Promise.all([FxFetch.doJsonF("/sett/branch/u", filter, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.files')}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <CxInput xref={rf_txDefaultCity} label={trans('field.sett_default_city')} dbfield="s_branch.txDefaultCity" maxLength={30} />
        <CxInput xref={rf_txDefaultState} label={trans('field.sett_default_state')} dbfield="s_branch.txDefaultState" maxLength={20} />
        <CxInput xref={rf_txDefaultPostalCode} label={trans('field.sett_default_postal_code')} dbfield="s_branch.txDefaultPostalCode" maxLength={10} />
        <CxInput xref={rf_txDefaultCountry} label={trans('field.sett_default_country')} dbfield="s_branch.txDefaultCountry" maxLength={30} />

        <CxState mode='checkbox' xref={rf_boAutoNumFiles} label={trans('field.sett_auto_num_files')} dbfield="s_branch.boAutoNumFiles" />
        {/* <CxState mode='checkbox' xref={rf_boConfirmExit} label={trans('field.sett_confirm_exit')} dbfield="s_branch.boConfirmExit" /> */}
        <CxState mode='checkbox' xref={rf_boAutoSelEmployee} label={trans('field.sett_auto_sel_employee')} dbfield="s_branch.boAutoSelEmployee" />
        <CxState mode='checkbox' xref={rf_boUseUcaseForNames} label={trans('field.sett_ucase_for_names')} dbfield="s_branch.boUseUcaseForNames" />
        <CxState mode='checkbox' xref={rf_boPreventDuplicateCli} label={trans('field.sett_prevent_dup_cli')} dbfield="s_branch.boPreventDuplicateCli" />
        <CxState mode='checkbox' xref={rf_boPreventDuplicatePss} label={trans('field.sett_prevent_dup_pss')} dbfield="s_branch.boPreventDuplicatePss" />
        <CxState mode='checkbox' xref={rf_boEnableOTF} label={trans('field.sett_enable_otf')} dbfield="s_branch.boEnableOTF" />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettFileForm;
