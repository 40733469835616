// Funciones generales para números.
import { getStoreValue } from "../gx/GxStore";

export const toString = (num) => {
  let separator = getStoreValue('StoreSession', 'txDecimalSeparator');
  return num.replace('.', separator);
}

export const to2r = (num) => {
  return Math.round(num * 100) / 100;
}

export const to2r00 = (num) => {
  return ((Math.round(num * 100) / 100)||0).toFixed(2);
}

export const to3r = (num) => {
  return Math.round(num * 1000) / 1000;
}

export const to4r = (num) => {
  return Math.round(num * 10000) / 10000;
}

export const to4r00 = (num) => {
  return ((Math.round(num * 10000) / 10000)||0).toFixed(4);
}

export const to2t = (num) => {
  return (num * 100) / 100;
}

export const to2t00 = (num) => {
  return (((num * 100) / 100)||0).toFixed(2);
}

export const to4t = (num) => {
  return (num * 10000) / 10000;
}

export const toInt = (num) => {
  return Math.round(num * 1) / 1;
}

export const isZero = (num) => {
  return parseFloat(num||'0')===0;
}

