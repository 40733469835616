import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxState from '../../cx/CxState';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettCusTranslation } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettCusTranslationForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  const rf_txFrom = React.useRef(null);
  const rf_boMatchWholeWord = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const [storeSettCusTranslation, setStoreSettCusTranslation] = useSessionStorage('StoreSettCusTranslation', StoreSettCusTranslation);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_boMatchWholeWord.current.setVal('1');
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
      r_snack_op_failed.current.setVal(trans('msg.x_doesnt_exist_0').replace('%s', rf_txFrom.current.getVal()));
      r_snack_op_failed.current.setOpen(true);
    } else {
      storeSettCusTranslation.isTrans = lastSaveRv.theValue;
      Promise.all([setStoreSettCusTranslation(storeSettCusTranslation)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.customization')} - ${trans('field.translation')}`}
        xref={r_form}
        url="sett/translation" 
        filterIdField='isTrans'
        filterIdValue={storeSettCusTranslation.isTrans} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="s_trans.isTrans" value={storeSettCusTranslation.isTrans || ''} />
        <CxInput xref={rf_txFrom} label={trans('field.word_original')} dbfield="s_trans.txFrom" maxLength={100} required />
        <CxInput xref={useRef(null)} label={trans('field.word_preferred')} dbfield="s_trans.txTo" maxLength={100} required />
        <CxState mode='checkbox' xref={rf_boMatchWholeWord} label={trans('field.match_whole_word')} dbfield="s_trans.boMatchWholeWord" />
        
      </GxForm>
      
      <CxSnackbar xref={r_snack_op_failed} severity="error" />

    </div>
  );

}

export default MxSettCusTranslationForm;
