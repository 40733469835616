import { MdContentCopy } from "react-icons/md"; 
import { BiPrinter } from "react-icons/bi";
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreClient, StoreRxClen, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';
import CxAccordion from '../../cx/CxAccordion';
import { useWindowSize } from '../../hx/useWindowSize';
import MxProfOTF from '../common/otf/MxProfOTF';


const MxClientRxClenForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxClen, setStoreRxClen] = useSessionStorage('StoreRxClen', StoreRxClen);

  const r_dlg_alert = React.useRef(null);
  const r_dlg_new_prof = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_txReason = React.useRef(null);
  const rf_itStatusRx = React.useRef(null);
  const rf_txProf = React.useRef(null);

  const rf_n_rx_clen_nuOdClPd = React.useRef(null);
  const rf_n_rx_clen_nuOdClPa = React.useRef(null);
  const rf_n_rx_clen_nuOdClCd = React.useRef(null);
  const rf_n_rx_clen_txOdClTe = React.useRef(null);
  const rf_n_rx_clen_nuOsClPd = React.useRef(null);
  const rf_n_rx_clen_nuOsClPa = React.useRef(null);
  const rf_n_rx_clen_nuOsClCd = React.useRef(null);
  const rf_n_rx_clen_txOsClTe = React.useRef(null);
  const rf_n_rx_clen_nuOdR1 = React.useRef(null);
  const rf_n_rx_clen_nuOdSph = React.useRef(null);
  const rf_n_rx_clen_nuOdCyl = React.useRef(null);
  const rf_n_rx_clen_nuOdAxis = React.useRef(null);
  const rf_n_rx_clen_nuOsR1 = React.useRef(null);
  const rf_n_rx_clen_nuOsSph = React.useRef(null);
  const rf_n_rx_clen_nuOsCyl = React.useRef(null);
  const rf_n_rx_clen_nuOsAxis = React.useRef(null);
  const rf_n_rx_clen_nuOdDiam = React.useRef(null);
  const rf_n_rx_clen_nuOdZone = React.useRef(null);
  const rf_n_rx_clen_nuOdAdd = React.useRef(null);
  const rf_n_rx_clen_txOdVa = React.useRef(null);
  const rf_n_rx_clen_nuOsDiam = React.useRef(null);
  const rf_n_rx_clen_nuOsZone = React.useRef(null);
  const rf_n_rx_clen_nuOsAdd = React.useRef(null);
  const rf_n_rx_clen_txOsVa = React.useRef(null);


  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusRx.current.setKeyAndVal("t_status_rx", "txTypeStatusRx", "itStatusRx", "000001.SRV");
    } else {
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxClen.inRxClen = lastSaveRv.theValue;
    Promise.all([setStoreRxClen(storeRxClen)]).then((result) => {
      r_sls_aux_table.current.setTxLinkAuxId(storeRxClen.inRxClen);
      forceUIUpdate();
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxClen'] = storeRxClen.inRxClen;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_clen/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const copyLast = () => {
    let record = {};
    record['inClient'] = storeClient.inClient;
    Promise.all([FxFetch.doJsonX('rx_clen/get_last', record)]).then((result) => {
      let inRxClen = result[0].theValue;
      if (!inRxClen) {
        r_snack_error.current.setValAndOpen(trans('msg.lack_of_previous_records'));
      } else {
        let record = {};
        record['idValue'] = inRxClen;
        Promise.all([FxFetch.doJsonX("/rx_clen/r", record)]).then((result) => {
          if (result[0]) {
            r_form.current.formReload(result[0]);
            rf_date.current.setValToday();
            rf_time.current.setValNow();
            rf_txReason.current.clear();
          }
        });
      }
    });
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client')}: ${trans('field.rx_clen')}`}
        xref={r_form}
        url="rx_clen" 
        filterIdField='inRxClen' 
        filterIdValue={storeRxClen.inRxClen} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            
            {/* botón de impresión */}
            {storeRxClen.inRxClen &&
              <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

            {/* botón de copiar último */}
            {!storeRxClen.inRxClen &&
              <CxIconButtonNew icon={<MdContentCopy fontSize="23px" />} classType="ibs_iconbutton_toolbar" onClick={copyLast} tooltip={trans('field.copy_last_1')} />}

          </div>
        }
        >
        <CxHidden xref={useRef(null)} dbfield="n_rx_clen.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_clen.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_clen.dtFrom" required />

        <CxInput xref={rf_txReason} label={trans('field.reason')} dbfield="n_rx_clen.txReason" maxLength={100} required />

        <CxChooser xref={rf_itStatusRx} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_clen.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_clen.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={rf_txProf} label={trans('field.treated_by')} dbfield="n_prof.txProf" dbfieldkey={`n_rx_clen.inProf`}
          onButtonAdd={() => {
            rf_txProf.current.setDlg(false);
            r_dlg_new_prof.current.setDlg(true);
          }}
          id="n_prof"
          table="n_prof"
          col_id="inProf"
          col_tx="txProf"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_rx_clen.txReference" maxLength={50} />

        <CxAccordion title={trans('field.cleaning')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded>
          <CxDate xref={useRef(null)} label={`${trans('field.date')}`} dbfield="n_rx_clen.dtClean" />
          <CxInput xref={useRef(null)} label={`${trans('field.fluid')}`} dbfield='n_rx_clen.txCleanFluid' maxLength={50} />
        </CxAccordion>

        <CxAccordion title={trans('field.service')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded>
          <CxDate xref={useRef(null)} label={`${trans('field.expiration')}`} dbfield="n_rx_clen.dtExpiration" />
          <CxDate xref={useRef(null)} label={`${trans('field.warranty')}`} dbfield="n_rx_clen.dtWarranty" />
        </CxAccordion>

        <span style={{ width: '100%', height: '20px'}} />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_cl_pd_s')}</th>
                <th>{trans('field.xx_cl_pa_s')}</th>
                <th>{trans('field.xx_cl_cd_s')}</th>
                <th>{trans('field.xx_cl_te_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClPd} dbfield='n_rx_clen.nuOdClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClPa} dbfield='n_rx_clen.nuOdClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClCd} dbfield='n_rx_clen.nuOdClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOdClTe} dbfield='n_rx_clen.txOdClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClPd} dbfield='n_rx_clen.nuOsClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClPa} dbfield='n_rx_clen.nuOsClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClCd} dbfield='n_rx_clen.nuOsClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOsClTe} dbfield='n_rx_clen.txOsClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_cl_pd_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClPd} dbfield='n_rx_clen.nuOdClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClPd} dbfield='n_rx_clen.nuOsClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_pa_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClPa} dbfield='n_rx_clen.nuOdClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClPa} dbfield='n_rx_clen.nuOsClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_cd_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdClCd} dbfield='n_rx_clen.nuOdClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsClCd} dbfield='n_rx_clen.nuOsClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_te_s')}</th>
                <td><CxInput xref={rf_n_rx_clen_txOdClTe} dbfield='n_rx_clen.txOdClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOsClTe} dbfield='n_rx_clen.txOsClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_rad_s')}</th>
                <th>{trans('field.xx_sph_s')}</th>
                <th>{trans('field.xx_cyl_s')}</th>
                <th>{trans('field.xx_axis_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdR1} dbfield='n_rx_clen.nuOdR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdSph} dbfield='n_rx_clen.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdCyl} dbfield='n_rx_clen.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdAxis} dbfield='n_rx_clen.nuOdAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsR1} dbfield='n_rx_clen.nuOsR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsSph} dbfield='n_rx_clen.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsCyl} dbfield='n_rx_clen.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsAxis} dbfield='n_rx_clen.nuOsAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_rad_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdR1} dbfield='n_rx_clen.nuOdR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsR1} dbfield='n_rx_clen.nuOsR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdSph} dbfield='n_rx_clen.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsSph} dbfield='n_rx_clen.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cyl_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdCyl} dbfield='n_rx_clen.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsCyl} dbfield='n_rx_clen.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_axis_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdAxis} dbfield='n_rx_clen.nuOdAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsAxis} dbfield='n_rx_clen.nuOsAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_diam_s')}</th>
                <th>{trans('field.xx_zone_s')}</th>
                <th>{trans('field.xx_add_s')}</th>
                <th>{trans('field.xx_va_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdDiam} dbfield='n_rx_clen.nuOdDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdZone} dbfield='n_rx_clen.nuOdZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOdAdd} dbfield='n_rx_clen.nuOdAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOdVa} dbfield='n_rx_clen.txOdVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsDiam} dbfield='n_rx_clen.nuOsDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsZone} dbfield='n_rx_clen.nuOsZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsAdd} dbfield='n_rx_clen.nuOsAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOsVa} dbfield='n_rx_clen.txOsVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_diam_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdDiam} dbfield='n_rx_clen.nuOdDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsDiam} dbfield='n_rx_clen.nuOsDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_zone_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdZone} dbfield='n_rx_clen.nuOdZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsZone} dbfield='n_rx_clen.nuOsZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_nuOdAdd} dbfield='n_rx_clen.nuOdAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_nuOsAdd} dbfield='n_rx_clen.nuOsAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_va_s')}</th>
                <td><CxInput xref={rf_n_rx_clen_txOdVa} dbfield='n_rx_clen.txOdVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_txOsVa} dbfield='n_rx_clen.txOsVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br/>
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_clen.txNotes" minRows={4} speech />
        
      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} txLinkAuxTable={'n_rx_clen'} txLinkAuxId={storeRxClen.inRxClen} /* rf_txEmployee={rf_txEmployee} */ />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <MxProfOTF xref={r_dlg_new_prof} onOk={(inProf, txProf) => {
        rf_txProf.current.setKey(inProf);
        rf_txProf.current.setVal(txProf);
      }} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxClientRxClenForm;
