import React from 'react';
import { useTranslation } from '../hx/useTranslation';
//import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import CxDlgTable from '../cx/CxDlgTable';
import MxClientOTF from '../modules/common/otf/MxClientOTF';
import { isApp } from "../gx/GxStore";

/** Selector independiente lanzado desde código, por ejemplo para seleccionar cliente o empleado sin necesitar un CxSearcher */

const GxDlgSelectClient = (props) => {
  const [{ trans }] = useTranslation();
  const { onSelect, onClientChanged, hasButtonAdd } = props;

  const r_dlg = React.useRef(null);
  
  const [intermediateValues, setIntermediateValues] = React.useState(null); //para guardar estructura de valores intermedios.
  const [includeAnimal, setIncludeAnimal] = React.useState(props.includeAnimal && (isApp('vett') || isApp('spaw')));

  const [dlg, setDlg] = React.useState(false);

  const r_dlg_new_client = React.useRef(null);

  React.useImperativeHandle(props.xref, () => {
    return {
      setIncludeAnimal, setOpen, setIntermediateValues
    };
  });

  const setOpen = (boOpen) => {
    if (boOpen) {
      Promise.all([setDlg(true)]).then((result) => {
        r_dlg.current.getTable().redisplay(); // 2025-01-29 - daq: para que al volver a abrirlo no recuerde la página vieja
      });
    } else {
      setDlg(false);
    }
  };

  return (
    <div>

      <CxDlgTable
        xref={r_dlg}
        title={props.title || trans('msg.select_x', includeAnimal ? `${trans('field.client')} / ${trans('field.patient')}` : `${trans('field.client')}`)}
        hasSearchBox={true}
        open={dlg}
        onButtonAddOTF={hasButtonAdd && (() => {
          setDlg(false);
          r_dlg_new_client.current.setDlg(true);
        })}
        onClose={() => setDlg(false)}
        onSelect={(row) => {
          if (onSelect) {
            onSelect(row, intermediateValues);
            setDlg(false);
          }
        }}
        url={'clients'}
        tooltip={includeAnimal ? `${trans('field.client')} / ${trans('field.patient')}` : `${trans('field.client')}`}
        refColors={[
          `table_cell_color_red|${trans('field.debtor')}`,
          `table_cell_color_gray|${trans('field.inactive')}`,
        ]}
        columns={includeAnimal ?
          [
            { id: "inClient", Header: "inClient", accessor: "inClient", show: false },
            { id: "txClient", Header: trans('field.client'), accessor: row => r_dlg.current.getTable().highlightText(row["txClient"]), width: '70%' },
            { id: "nuMailing", Header: 'nuMailing', accessor: "nuMailing", show: false },
            { id: "txMobile", Header: 'txMobile', accessor: "txMobile", show: false },
            { id: "txEmail", Header: 'txEmail', accessor: "txEmail", show: false },
            { id: "animal_inAnimal", Header: "animal_inAnimal", accessor: "animal_inAnimal", show: false },
            { id: "animal_txAnimal", Header: trans('field.patient'), accessor: "animal_txAnimal", 
                Cell: ({ value, row }) => r_dlg.current.getTable().cellFormat(value, `ht|${FxDat.isExpired(row.original.animal_dtTo) ? 'colorA' : ''}`),
                width: '30%' },
            { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ] : [
            { id: "inClient", Header: "inClient", accessor: "inClient", show: false },
            { id: "txClient", Header: trans('field.client'), accessor: row => r_dlg.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "nuMailing", Header: 'nuMailing', accessor: "nuMailing", show: false },
            { id: "txMobile", Header: 'txMobile', accessor: "txMobile", show: false },
            { id: "txEmail", Header: 'txEmail', accessor: "txEmail", show: false },
          ]
        }
        height='450px'
      />

      <MxClientOTF xref={r_dlg_new_client} onOk={(inClient, txClient) => {
        if (onSelect) {
          let row = { original: { inClient: inClient, txClient: txClient } };
          onSelect(row, intermediateValues);
        }
        if (onClientChanged) {
          onClientChanged(inClient, txClient);
        }
      }} />

    </div>
  );
}

export default GxDlgSelectClient;
