import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiTrash } from "react-icons/bi"; 
import { BiSave } from "react-icons/bi"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxIcon from '../../cx/CxIcon';
import CxDate from '../../cx/CxDate';
import CxStatic from '../../cx/CxStatic';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import GxForm from '../../gx/GxForm';
import { StoreStockLot } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { useNav } from '../../hx/useNav';


const MxStockroomLotForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  const r_form = React.useRef(null);

  const rf_txLotCode = React.useRef(null);
  const rf_dtLotExpr = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  const [storeStockLot, ] = useSessionStorage('StoreStockLot', StoreStockLot);

  React.useEffect(() => {
    formLoadCustom();
    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    rf_txLotCode.current.setVal(storeStockLot.txLotCode);
    rf_dtLotExpr.current.setVal(storeStockLot.dtLotExpr);
  }

  const formDeleteCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let record = {};
    record['inPs'] = storeStockLot.inPs;
    record['txPs'] = storeStockLot.txPs;
    record['txLotCodeOri'] = storeStockLot.txLotCode;
    Promise.all([FxFetch.doJsonX(`stock/lots/d`, record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        navBack();
      }
    });
  }
  
  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    let record = {};
    record['inPs'] = storeStockLot.inPs;
    record['txPs'] = storeStockLot.txPs;
    record['txLotCodeOri'] = storeStockLot.txLotCode;
    record['txLotCode'] = rf_txLotCode.current.getVal();
    record['dtLotExpr'] = rf_dtLotExpr.current.getVal();

    Promise.all([FxFetch.doJsonX("stock/lots/cu", record)]).then((result) => {
      if (result[0]) {
        navBack();
      }
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.stockroom')}: ${trans('field.lot')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            <CxIconButtonNew icon={<BiTrash/>} classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />

            {/* botón guardar */}
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >

        <CxStatic label={`${storeStockLot.txPs}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxInput xref={rf_txLotCode} label={trans('field.lot')} maxLength="50" required />
        <CxDate xref={rf_dtLotExpr} label={trans('field.expiration')} required />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxStockroomLotForm;
