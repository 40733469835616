import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettClient } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettClientForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const [storeSettClient, setStoreSettClient] = useSessionStorage('StoreSettClient', StoreSettClient);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettClient.itClient = lastSaveRv.theValue;
      Promise.all([setStoreSettClient(storeSettClient)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.type')} - ${trans('field.client')}`}
        xref={r_form}
        url="sett/client" 
        filterIdField='itClient'
        filterIdValue={storeSettClient.itClient} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_client.itClient" value={storeSettClient.itClient || ''} />
        <CxInput xref={useRef(null)} label={`${trans('field.type')} - ${trans('field.client')}`} dbfield="t_client.txTypeClient" required />
        
      </GxForm>

    </div>
  );

}

export default MxSettClientForm;
