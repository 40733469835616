import { MdContentCopy } from "react-icons/md"; 
import { BiPrinter } from "react-icons/bi";
import { MdOutlineAddCircleOutline } from "react-icons/md"; 
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxAccordion from '../../cx/CxAccordion';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreClient, StoreRxClenFit, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxStatic from '../../cx/CxStatic';
import { useWindowSize } from '../../hx/useWindowSize';


const MxClientRxClenFitForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo, navClear }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_form = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successfully_created = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxClenFit, setStoreRxClenFit] = useSessionStorage('StoreRxClenFit', StoreRxClenFit);

  const r_dlg_alert = React.useRef(null);
  const r_accordion_products = React.useRef(null);
  const rf_txPsOd = React.useRef(null);
  const rf_txPsOs = React.useRef(null);
  const rf_txTypePsSubgroupOd = React.useRef(null);
  const rf_txTypePsSubgroupOs = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_txReason = React.useRef(null);
  const rf_itStatusRx = React.useRef(null);
  const rf_nuHsUse = React.useRef(null);

  const rf_n_rx_clen_fit_nuOdClPd = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdClPa = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdClCd = React.useRef(null);
  const rf_n_rx_clen_fit_txOdClTe = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsClPd = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsClPa = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsClCd = React.useRef(null);
  const rf_n_rx_clen_fit_txOsClTe = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdR1 = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdSph = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdCyl = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdAxis = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsR1 = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsSph = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsCyl = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsAxis = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdDiam = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdZone = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdAdd = React.useRef(null);
  const rf_n_rx_clen_fit_txOdVa = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsDiam = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsZone = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsAdd = React.useRef(null);
  const rf_n_rx_clen_fit_txOsVa = React.useRef(null);

  const rf_n_rx_clen_fit_nuOdOcSph = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsOcSph = React.useRef(null);
	const rf_n_rx_clen_fit_nuOdOcCyl = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsOcCyl = React.useRef(null);
  const rf_n_rx_clen_fit_nuOdOcAxis = React.useRef(null);
  const rf_n_rx_clen_fit_nuOsOcAxis = React.useRef(null);
  const rf_n_rx_clen_fit_txOdOcVa = React.useRef(null);
  const rf_n_rx_clen_fit_txOsOcVa = React.useRef(null);


  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusRx.current.setKeyAndVal("t_status_rx", "txTypeStatusRx", "itStatusRx", "000001.SRV");

    } else { // registro existente
      FxLog.infox('MxClientRxClenFitForm.formLoad', '...data:', data);
      rf_txPsOd.current.setKey(data[0].inPsOd);
      rf_txPsOd.current.setVal(data[0].txPsOd);
      rf_txPsOs.current.setKey(data[0].inPsOs);
      rf_txPsOs.current.setVal(data[0].txPsOs);
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxClenFit.inRxClenFit = lastSaveRv.theValue;
    Promise.all([setStoreRxClenFit(storeRxClenFit)]).then((result) => {
      forceUIUpdate();
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxClenFit'] = storeRxClenFit.inRxClenFit;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_clen_fit/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const createRxClen = async () => {
    let filter = { "inRxClenFit": storeRxClenFit.inRxClenFit, };
    Promise.all([FxFetch.doJsonF("/rx_clen_fit/create_rx_clen", filter)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_op_successfully_created.current.setOpen(true);
        }
      }
    });
  }

  const copyLast = () => {
    let record = {};
    record['inClient'] = storeClient.inClient;
    Promise.all([FxFetch.doJsonX('rx_clen_fit/get_last', record)]).then((result) => {
      let inRxClenFit = result[0].theValue;
      if (!inRxClenFit) {
        r_snack_error.current.setValAndOpen(trans('msg.lack_of_previous_records'));
      } else {
        let record = {};
        record['idValue'] = inRxClenFit;
        Promise.all([FxFetch.doJsonX("/rx_clen_fit/r", record)]).then((result) => {
          if (result[0]) {
            r_form.current.formReload(result[0]);
            rf_date.current.setValToday();
            rf_time.current.setValNow();
            rf_txReason.current.clear();
          }
        });
      }
    });
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.client')}: ${trans('field.rx_clen_fit')}`}
        xref={r_form}
        url="rx_clen_fit" 
        filterIdField='inRxClenFit' 
        filterIdValue={storeRxClenFit.inRxClenFit} 
        onFormLoad={formLoad} 
        onFormSave={isFullAccess() && formSave} 
        onFormDelete={isFullAccess() && formDelete} 
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            
            {/* botón de impresión */}
            {storeRxClenFit.inRxClenFit &&
              <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

            {/* botón de copiar último */}
            {!storeRxClenFit.inRxClenFit &&
              <CxIconButtonNew icon={<MdContentCopy fontSize="23px" />} classType="ibs_iconbutton_toolbar" onClick={copyLast} tooltip={trans('field.copy_last_1')} />}
            
            {/* botón de crear */}
            {storeRxClenFit.inRxClenFit &&
              <CxIconButtonNew icon={<MdOutlineAddCircleOutline />} classType="ibs_iconbutton_toolbar" onClick={createRxClen} tooltip={trans("field.create_x", "field.rx_clen")} />}

          </div>
        }
        >
        <CxHidden xref={useRef(null)} dbfield="n_rx_clen_fit.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_clen_fit.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_clen_fit.dtFrom" required />

        <CxInput xref={rf_txReason} label={trans('field.reason')} dbfield="n_rx_clen_fit.txReason" maxLength={100} required />

        <CxChooser xref={rf_itStatusRx} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_clen_fit.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_clen_fit.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxNumber xref={rf_nuHsUse} label={trans('field.hs_use')} dbfield='n_rx_clen_fit.nuHsUse' decimals={2} decimalsfixed="true" defaultValue="1" />

        <span style={{ width: '100%', height: '20px'}} />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_cl_pd_s')}</th>
                <th>{trans('field.xx_cl_pa_s')}</th>
                <th>{trans('field.xx_cl_cd_s')}</th>
                <th>{trans('field.xx_cl_te_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClPd} dbfield='n_rx_clen_fit.nuOdClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClPa} dbfield='n_rx_clen_fit.nuOdClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClCd} dbfield='n_rx_clen_fit.nuOdClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdClTe} dbfield='n_rx_clen_fit.txOdClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClPd} dbfield='n_rx_clen_fit.nuOsClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClPa} dbfield='n_rx_clen_fit.nuOsClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClCd} dbfield='n_rx_clen_fit.nuOsClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsClTe} dbfield='n_rx_clen_fit.txOsClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_cl_pd_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClPd} dbfield='n_rx_clen_fit.nuOdClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClPd} dbfield='n_rx_clen_fit.nuOsClPd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_pa_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClPa} dbfield='n_rx_clen_fit.nuOdClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClPa} dbfield='n_rx_clen_fit.nuOsClPa' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_cd_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdClCd} dbfield='n_rx_clen_fit.nuOdClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsClCd} dbfield='n_rx_clen_fit.nuOsClCd' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cl_te_s')}</th>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdClTe} dbfield='n_rx_clen_fit.txOdClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsClTe} dbfield='n_rx_clen_fit.txOsClTe' maxLength={13} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_rad_s')}</th>
                <th>{trans('field.xx_sph_s')}</th>
                <th>{trans('field.xx_cyl_s')}</th>
                <th>{trans('field.xx_axis_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdR1} dbfield='n_rx_clen_fit.nuOdR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdSph} dbfield='n_rx_clen_fit.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdCyl} dbfield='n_rx_clen_fit.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdAxis} dbfield='n_rx_clen_fit.nuOdAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsR1} dbfield='n_rx_clen_fit.nuOsR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsSph} dbfield='n_rx_clen_fit.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsCyl} dbfield='n_rx_clen_fit.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsAxis} dbfield='n_rx_clen_fit.nuOsAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_rad_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdR1} dbfield='n_rx_clen_fit.nuOdR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsR1} dbfield='n_rx_clen_fit.nuOsR1' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdSph} dbfield='n_rx_clen_fit.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsSph} dbfield='n_rx_clen_fit.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cyl_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdCyl} dbfield='n_rx_clen_fit.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsCyl} dbfield='n_rx_clen_fit.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_axis_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdAxis} dbfield='n_rx_clen_fit.nuOdAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsAxis} dbfield='n_rx_clen_fit.nuOsAxis' decimals={0} min={0} max={180} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_diam_s')}</th>
                <th>{trans('field.xx_zone_s')}</th>
                <th>{trans('field.xx_add_s')}</th>
                <th>{trans('field.xx_va_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdDiam} dbfield='n_rx_clen_fit.nuOdDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdZone} dbfield='n_rx_clen_fit.nuOdZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdAdd} dbfield='n_rx_clen_fit.nuOdAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdVa} dbfield='n_rx_clen_fit.txOdVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsDiam} dbfield='n_rx_clen_fit.nuOsDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsZone} dbfield='n_rx_clen_fit.nuOsZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsAdd} dbfield='n_rx_clen_fit.nuOsAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsVa} dbfield='n_rx_clen_fit.txOsVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_diam_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdDiam} dbfield='n_rx_clen_fit.nuOdDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsDiam} dbfield='n_rx_clen_fit.nuOsDiam' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_zone_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdZone} dbfield='n_rx_clen_fit.nuOdZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsZone} dbfield='n_rx_clen_fit.nuOsZone' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdAdd} dbfield='n_rx_clen_fit.nuOdAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsAdd} dbfield='n_rx_clen_fit.nuOsAdd' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_va_s')}</th>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdVa} dbfield='n_rx_clen_fit.txOdVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsVa} dbfield='n_rx_clen_fit.txOsVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_overcorrection_sph_s')}</th>
                <th>{trans('field.xx_overcorrection_cyl_s')}</th>
                <th>{trans('field.xx_overcorrection_axis_s')}</th>
                <th>{trans('field.xx_overcorrection_va_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcSph} dbfield='n_rx_clen_fit.nuOdOcSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcCyl} dbfield='n_rx_clen_fit.nuOdOcCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcAxis} dbfield='n_rx_clen_fit.nuOdOcAxis' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdOcVa} dbfield='n_rx_clen_fit.txOdOcVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcSph} dbfield='n_rx_clen_fit.nuOsOcSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcCyl} dbfield='n_rx_clen_fit.nuOsOcCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcAxis} dbfield='n_rx_clen_fit.nuOsOcAxis' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsOcVa} dbfield='n_rx_clen_fit.txOsOcVa' maxLength={10} framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_overcorrection_sph_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcSph} dbfield="n_rx_clen_fit.nuOdOcSph" signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcSph} dbfield="n_rx_clen_fit.nuOsOcSph" signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_overcorrection_cyl_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcCyl} dbfield="n_rx_clen_fit.nuOdOcCyl" signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcCyl} dbfield="n_rx_clen_fit.nuOsOcCyl" signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_overcorrection_axis_s')}</th>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOdOcAxis} dbfield="n_rx_clen_fit.nuOdOcAxis" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_clen_fit_nuOsOcAxis} dbfield="n_rx_clen_fit.nuOsOcAxis" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_overcorrection_va_s')}</th>
                <td><CxInput xref={rf_n_rx_clen_fit_txOdOcVa} dbfield="n_rx_clen_fit.txOdOcVa" maxLength={10} framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_clen_fit_txOsOcVa} dbfield="n_rx_clen_fit.txOsOcVa" maxLength={10} framesimple="true" hidebuttons="true" width="100%" autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br/>
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_clen_fit.txNotes" minRows={4} speech />
        
        <span style={{ width: '100%', height: '20px'}} />

        <CxAccordion xref={r_accordion_products} title={trans('field.products')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >

          <CxSearcher xref={rf_txPsOd} label={trans("field.xx_eye_right_s") +"-"+ trans("field.product")}
            id='inPsOd'
            dbfield="txPsOd" dbfieldkey={`n_rx_clen_fit.inPsOd`}
            url="pss"
            col_id="inPs"
            col_tx="txPs"
            columns={[
              { id: "0", Header: "ID", accessor: "inPs", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txPsOd.current.getTable().highlightText(row["txPs"]), width: '100%' },
            ]}
          />
          <CxChooser xref={rf_txTypePsSubgroupOd}
            label={trans("field.xx_eye_right_s") +"-"+ trans("field.subgroup")}
            dbfield='txTypePsSubgroupOd' dbfieldkey='n_rx_clen_fit.itPsSubgroupOd'
            table="t_ps_subgroup"
            col_id="itPsSubgroup"
            col_tx="txTypePsSubgroup"
            filter={`t_ps_subgroup.boActive='${'1'}'`}
          />

          <CxSearcher xref={rf_txPsOs} label={trans("field.xx_eye_right_s") +"-"+ trans("field.product")}
            id='inPsOs'
            dbfield="txPsOs" dbfieldkey={`n_rx_clen_fit.inPsOs`}
            url="pss"
            col_id="inPs"
            col_tx="txPs"
            columns={[
              { id: "0", Header: "ID", accessor: "inPs", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txPsOs.current.getTable().highlightText(row["txPs"]), width: '100%' },
            ]}
          />
          <CxChooser xref={rf_txTypePsSubgroupOs}
            label={trans("field.xx_eye_right_s") +"-"+ trans("field.subgroup")}
            dbfield='txTypePsSubgroupOs' dbfieldkey='n_rx_clen_fit.itPsSubgroupOs'
            table="t_ps_subgroup"
            col_id="itPsSubgroup"
            col_tx="txTypePsSubgroup"
            filter={`t_ps_subgroup.boActive='${'1'}'`}
          />

        </CxAccordion>

        <span style={{ width: '100%', height: '20px'}} />

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_op_successfully_created} severity="success" value={trans('msg.operation_successful')} 
        onClose={() => { 
          navClear(); 
          Promise.all([navTo('blank')]).then((result) => {
            navTo('activity-opti-c-list'); 
          });
        }} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxClientRxClenFitForm;
