import { MdContentCopy } from "react-icons/md"; 
import { BiPrinter } from "react-icons/bi";
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxLink from '../../cx/CxLink';
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreClient, StoreRxGlas, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';
import MxProfOTF from '../common/otf/MxProfOTF';
import '../../../styles/TableFixed/TableFixed.css';
import { useWindowSize } from '../../hx/useWindowSize';


const MxClientRxGlasForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const rf_n_rx_glas_nuOdSph = React.useRef(null);
  const rf_n_rx_glas_nuOsSph = React.useRef(null);
  const rf_n_rx_glas_nuOdCyl = useRef(null);
  const rf_n_rx_glas_nuOdAxis = useRef(null);
  const rf_n_rx_glas_nuOdAddNe = useRef(null);
  const rf_nuOdSphN = useRef(null);
  const rf_n_rx_glas_nuOdAddIn = useRef(null);
  const rf_nuOdSphI = useRef(null);
  const rf_n_rx_glas_nuOsCyl = useRef(null);
  const rf_n_rx_glas_nuOsAxis = useRef(null);
  const rf_n_rx_glas_nuOsAddNe = useRef(null);
  const rf_nuOsSphN = useRef(null);
  const rf_n_rx_glas_nuOsAddIn = useRef(null);
  const rf_nuOsSphI = useRef(null);
  const rf_n_rx_glas_txOdVaSc = useRef(null);
  const rf_n_rx_glas_txOdVaCc = useRef(null);
  const rf_n_rx_glas_nuOdPdDi = useRef(null);
  const rf_n_rx_glas_nuOdPdNe = useRef(null);
  const rf_n_rx_glas_nuOdIop = useRef(null);
  const rf_n_rx_glas_txOdPrism = useRef(null);
  const rf_n_rx_glas_txOsVaSc = useRef(null);
  const rf_n_rx_glas_txOsVaCc = useRef(null);
  const rf_n_rx_glas_nuOsPdDi = useRef(null);
  const rf_n_rx_glas_nuOsPdNe = useRef(null);
  const rf_n_rx_glas_nuOsIop = useRef(null);
  const rf_n_rx_glas_txOsPrism = useRef(null);
  const rf_n_rx_glas_txOuVa = useRef(null);
  const rf_nuOuPdDi = useRef(null);
  const rf_nuOuPdNe = useRef(null);

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxGlas, setStoreRxGlas] = useSessionStorage('StoreRxGlas', StoreRxGlas);

  const [isRecalc, setRecalc] = React.useState(false);

  const r_dlg_alert = React.useRef(null);
  const r_dlg_new_prof = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_txReason = React.useRef(null);
  const rf_itStatusRx = React.useRef(null);
  const rf_txProf = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusRx.current.setKeyAndVal("t_status_rx", "txTypeStatusRx", "itStatusRx", "000001.SRV");
    } else {
      calcSphN();
      calcSphI();
      calcOu();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxGlas.inRxGlas = lastSaveRv.theValue;
    Promise.all([setStoreRxGlas(storeRxGlas)]).then((result) => {
      r_sls_aux_table.current.setTxLinkAuxId(storeRxGlas.inRxGlas);
      forceUIUpdate();
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  React.useEffect(() => {
    setRecalc(false);
    calcSphN();
    calcSphI();

  }, [isRecalc]);

  const doTranspose = () => {
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;
    let _nuOdCyl = rf_n_rx_glas_nuOdCyl.current;
    let _nuOdAxis = rf_n_rx_glas_nuOdAxis.current;

    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;
    let _nuOsCyl = rf_n_rx_glas_nuOsCyl.current;
    let _nuOsAxis = rf_n_rx_glas_nuOsAxis.current;

    //Od
    _nuOdSphD.setFloatVal(transpose('SPH', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdCyl.setFloatVal(transpose('CYL', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdAxis.setFloatVal(transpose('AXIS', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    //Os
    _nuOsSphD.setFloatVal(transpose('SPH', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsCyl.setFloatVal(transpose('CYL', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsAxis.setFloatVal(transpose('AXIS', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));

    //recálculos
    setRecalc(true);
  }

  const transpose = (tr, sph, cyl, axis) => {
    let rv = 0.0;
    try {
      let nuSph = (sph);
      let nuCyl = (cyl);
      let nuAxis = (axis);

      nuCyl = nuCyl * -1;
      nuSph = nuSph - nuCyl;
      if (nuAxis > 90) {
        nuAxis = nuAxis - 90;
      } else {
        nuAxis = nuAxis + 90;
      }
      switch (tr) {
        case 'SPH':
          rv = nuSph;
          break;
        case 'CYL':
          rv = nuCyl;
          break;
        case 'AXIS':
          rv = nuAxis;
          break;
        default:
          rv = '0.0';
          break;
      }

    } catch (error) {
      FxLog.errorx("MxClientRxGlasForm.transpose", error);
    }
    return rv;
  }

  const calcSphN = () => {
    let _nuOdAddNe = rf_n_rx_glas_nuOdAddNe.current;
    let _nuOdSphN = rf_nuOdSphN.current;
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;

    let _nuOsAddNe = rf_n_rx_glas_nuOsAddNe.current;
    let _nuOsSphN = rf_nuOsSphN.current;
    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;

    if (_nuOdAddNe.getFloatVal() === 0.0) {
      _nuOdSphN.clear();
    } else {
      _nuOdSphN.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddNe.getFloatVal());
    }
    if (_nuOsAddNe.getFloatVal() === 0.0) {
      _nuOsSphN.clear();
    } else {
      _nuOsSphN.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddNe.getFloatVal());
    }
  }

  const calcSphI = () => {
    let _nuOdAddIn = rf_n_rx_glas_nuOdAddIn.current;
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;
    let _nuOdSphI = rf_nuOdSphI.current;

    let _nuOsAddIn = rf_n_rx_glas_nuOsAddIn.current;
    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;
    let _nuOsSphI = rf_nuOsSphI.current;

    if (_nuOdAddIn.getFloatVal() === 0.0) {
      _nuOdSphI.clear();
    } else {
      _nuOdSphI.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddIn.getFloatVal());
    }
    if (_nuOsAddIn.getFloatVal() === 0.0) {
      _nuOsSphI.clear();
    } else {
      _nuOsSphI.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddIn.getFloatVal());
    }
  }

  const calcOu = () => {
    let _nuOuPdDi = rf_nuOuPdDi.current;
    let _nuOuPdNe = rf_nuOuPdNe.current;
    let _nuOdPdDi = rf_n_rx_glas_nuOdPdDi.current;
    let _nuOsPdDi = rf_n_rx_glas_nuOsPdDi.current;
    let _nuOdPdNe = rf_n_rx_glas_nuOdPdNe.current;
    let _nuOsPdNe = rf_n_rx_glas_nuOsPdNe.current;

    _nuOuPdDi.setFloatVal(_nuOdPdDi.getFloatVal() + _nuOsPdDi.getFloatVal());
    _nuOuPdNe.setFloatVal(_nuOdPdNe.getFloatVal() + _nuOsPdNe.getFloatVal());
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxGlas'] = storeRxGlas.inRxGlas;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_glas/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const copyLast = () => {
    let record = {};
    record['inClient'] = storeClient.inClient;
    Promise.all([FxFetch.doJsonX('rx_glas/get_last', record)]).then((result) => {
      let inRxGlas = result[0].theValue;
      if (!inRxGlas) {
        r_snack_error.current.setValAndOpen(trans('msg.lack_of_previous_records'));
      } else {
        let record = {};
        record['idValue'] = inRxGlas;
        Promise.all([FxFetch.doJsonX("/rx_glas/r", record)]).then((result) => {
          if (result[0]) {
            r_form.current.formReload(result[0]);
            rf_date.current.setValToday();
            rf_time.current.setValNow();
            rf_txReason.current.clear();
          }
        });
      }
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.client')}: ${trans('field.rx_glas')}`}
        xref={r_form}
        url="rx_glas"
        filterIdField='inRxGlas'
        filterIdValue={storeRxGlas.inRxGlas}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeRxGlas.inRxGlas &&
              <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

            {/* botón de copiar último */}
            {!storeRxGlas.inRxGlas &&
              <CxIconButtonNew icon={<MdContentCopy fontSize="23px" />} classType="ibs_iconbutton_toolbar" onClick={copyLast} tooltip={trans('field.copy_last_1')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_rx_glas.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_glas.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_glas.dtFrom" required />

        <CxInput xref={rf_txReason} label={trans('field.reason')} dbfield="n_rx_glas.txReason" maxLength={100} required />

        <CxChooser xref={rf_itStatusRx} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_glas.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_glas.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={rf_txProf} label={trans('field.treated_by')} dbfield="n_prof.txProf" dbfieldkey={`n_rx_glas.inProf`}
          onButtonAdd={() => {
            rf_txProf.current.setDlg(false);
            r_dlg_new_prof.current.setDlg(true);
          }}
          id="n_prof"
          table="n_prof"
          col_id="inProf"
          col_tx="txProf"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_rx_glas.txReference" maxLength={50} />

        <span style={{ width: '100%'}} />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_sph_dist_s')}</th>
                <th>{trans('field.xx_cyl_s')}</th>
                <th>{trans('field.xx_axis_s')}</th>
                <th>{trans('field.xx_add_near_s')}</th>
                <th>{trans('field.xx_sph_near_s')}</th>
                <th>{trans('field.xx_add_inter_s')}</th>
                <th>{trans('field.xx_sph_inter_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdSph} dbfield='n_rx_glas.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdCyl} dbfield='n_rx_glas.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAxis} dbfield='n_rx_glas.nuOdAxis' decimals={0} min={0} max={180} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddNe} dbfield='n_rx_glas.nuOdAddNe' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOdSphN} dbfield='_nuOdSphN' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddIn} dbfield='n_rx_glas.nuOdAddIn' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOdSphI} dbfield='_nuOdSphI' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsSph} dbfield='n_rx_glas.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsCyl} dbfield='n_rx_glas.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAxis} dbfield='n_rx_glas.nuOsAxis' decimals={0} min={0} max={180} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddNe} dbfield='n_rx_glas.nuOsAddNe' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOsSphN} dbfield='_nuOsSphN' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddIn} dbfield='n_rx_glas.nuOsAddIn' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOsSphI} dbfield='_nuOsSphI' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_sph_dist_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdSph} dbfield='n_rx_glas.nuOdSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsSph} dbfield='n_rx_glas.nuOsSph' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cyl_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdCyl} dbfield='n_rx_glas.nuOdCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsCyl} dbfield='n_rx_glas.nuOsCyl' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_axis_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAxis} dbfield='n_rx_glas.nuOdAxis' decimals={0} min={0} max={180} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAxis} dbfield='n_rx_glas.nuOsAxis' decimals={0} min={0} max={180} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_near_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddNe} dbfield='n_rx_glas.nuOdAddNe' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddNe} dbfield='n_rx_glas.nuOsAddNe' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_near_s')}</th>
                <td><CxNumber xref={rf_nuOdSphN} dbfield='_nuOdSphN' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_nuOsSphN} dbfield='_nuOsSphN' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_inter_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddIn} dbfield='n_rx_glas.nuOdAddIn' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddIn} dbfield='n_rx_glas.nuOsAddIn' signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_inter_s')}</th>
                <td><CxNumber xref={rf_nuOdSphI} dbfield='_nuOdSphI' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_nuOsSphI} dbfield='_nuOsSphI' disabled signed="true" decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <CxLink label={`[${trans('field.xx_transpose').toUpperCase()}]`} width='120px' style={{ margin: '0 0 0 5px', fontSize: '12px' }}  onClick={doTranspose} />
        <br /><br /><br />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_vasc_s')}</th>
                <th>{trans('field.xx_vacc_s')}</th>
                <th>{trans('field.xx_pd_dist_s')}</th>
                <th>{trans('field.xx_pd_near_s')}</th>
                <th>{trans('field.xx_iop_s')}</th>
                <th>{trans('field.xx_prism_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxInput xref={rf_n_rx_glas_txOdVaSc} maxLength={10} dbfield='n_rx_glas.txOdVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOdVaCc} maxLength={10} dbfield='n_rx_glas.txOdVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdDi} dbfield='n_rx_glas.nuOdPdDi' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdNe} dbfield='n_rx_glas.nuOdPdNe' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdIop} dbfield='n_rx_glas.nuOdIop' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOdPrism} maxLength={16} dbfield='n_rx_glas.txOdPrism' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaSc} maxLength={10} dbfield='n_rx_glas.txOsVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaCc} maxLength={10} dbfield='n_rx_glas.txOsVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdDi} dbfield='n_rx_glas.nuOsPdDi' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdNe} dbfield='n_rx_glas.nuOsPdNe' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsIop} dbfield='n_rx_glas.nuOsIop' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsPrism} maxLength={16} dbfield='n_rx_glas.txOsPrism' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_both_s').toUpperCase()}</td>
                <td colSpan="2"><CxInput xref={rf_n_rx_glas_txOuVa} maxLength={10} dbfield='n_rx_glas.txOuVa' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_nuOuPdDi} dbfield='_nuOuPdDi' disabled decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_nuOuPdNe} dbfield='_nuOuPdNe' disabled decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
                <th>{trans('field.xx_eye_both_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_vasc_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdVaSc} maxLength={10} dbfield='n_rx_glas.txOdVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaSc} maxLength={10} dbfield='n_rx_glas.txOsVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td rowSpan="2" style={{verticalAlign: 'top'}}><CxInput xref={rf_n_rx_glas_txOuVa} maxLength={10} dbfield='n_rx_glas.txOuVa' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_vacc_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdVaCc} maxLength={10} dbfield='n_rx_glas.txOdVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaCc} maxLength={10} dbfield='n_rx_glas.txOsVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_pd_dist_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdDi} dbfield='n_rx_glas.nuOdPdDi' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdDi} dbfield='n_rx_glas.nuOsPdDi' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOuPdDi} dbfield='_nuOuPdDi' disabled decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_pd_near_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdNe} dbfield='n_rx_glas.nuOdPdNe' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdNe} dbfield='n_rx_glas.nuOsPdNe' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} /></td>
                <td><CxNumber xref={rf_nuOuPdNe} dbfield='_nuOuPdNe' disabled decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_iop_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdIop} dbfield='n_rx_glas.nuOdIop' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsIop} dbfield='n_rx_glas.nuOsIop' decimals={2} decimalsfixed="true" defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td></td>
              </tr>
              <tr>
                <th>{trans('field.xx_prism_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdPrism} maxLength={16} dbfield='n_rx_glas.txOdPrism' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsPrism} maxLength={16} dbfield='n_rx_glas.txOsPrism' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        }


        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_glas.txNotes" minRows={4} speech />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} txLinkAuxTable={'n_rx_glas'} txLinkAuxId={storeRxGlas.inRxGlas} /* rf_txEmployee={rf_txEmployee} */ />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <MxProfOTF xref={r_dlg_new_prof} onOk={(inProf, txProf) => {
        rf_txProf.current.setKey(inProf);
        rf_txProf.current.setVal(txProf);
      }} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxClientRxGlasForm;
