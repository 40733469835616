import { BiLockOpenAlt } from "react-icons/bi"; 
import { BiLockAlt } from "react-icons/bi"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import GxList from '../../gx/GxList';
import GxPrint from '../../gx/GxPrint';
import CxDlgAlert from '../../cx/CxDlgAlert';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import { StoreMassBilling, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import { useNav } from '../../hx/useNav';


const MxMassBillingList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const r_list = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_success = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  // eslint-disable-next-line
  const [storeMassBilling, setStoreMassBilling] = useSessionStorage('StoreMassBilling', StoreMassBilling);

  const [lockIssue, setLockIssue] = React.useState(true);
  const [lockExclude, setLockExclude] = React.useState(true);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doIssueDoc = (row) => {
    try {

      let record = {};
      record['inInvoice'] = row.inInvoice;
      record['itSeries'] = row._itSeries;
      record['dtReceipt'] = row.dtInvoice;
      Promise.all([FxFetch.doJsonX(`/sls/doc/issue_receipt_from_invoice`, record)]).then((result) => {
        r_list.current.refresh();
      });

      r_snack_success.current.setOpen(true);

    } catch (error) {
      FxLog.errorx("MxMassBillingList.doIssueDoc", error);
    }
  }

  const doPrintX = async (op, row) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = row.original.inClient;
    filter['inXXX'] = row.original.inInvoice;
    filter['docType'] = 'i';
    filter['docSize'] = size.isLarge ? 'A4' : 'A8';
    filter['salesSlipWidth'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_width') || '60';
    filter['salesSlipMarginLeft'] = localStorage.getItem('MxSettSlsPrinting.sales_slip_margin_left') || '0';
    let record = {};
    if (filter['docSize'] === 'A8') {
      r_print.current.setScale(1.8);
    }
    if (filter['docSize'] === 'A4') {
      r_print.current.setScale(1.5);
    }
    r_print.current.setUrl('slsDoc/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(row.original.inClient),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const doExcludeInvoice = (row) => {
    let inInvoice = row.inInvoice;
    storeMassBilling.arExcludedId += "|" + inInvoice;
    Promise.all([setStoreMassBilling(storeMassBilling)]).then((result) => {
      Promise.all([r_list.current.setdeepAddParams(getAddParams())]).then((result) => {
        r_list.current.refresh();
      });
    });
  }

  const goNext = async () => {
    r_dlg_alert.current.setOpen(true);
    Promise.all([setListedId()]).then((result) => {
      r_dlg_alert.current.setOpen(false);
      navTo("mass-billing-option");
    });
  }

  const setListedId = () => {
    let record = {};
    record['pageNo'] = '0';
    record['pageSize'] = '1000';
    record['sortBy'] = '';
    record['filter'] = '';
    record['group'] = '';
    let addParams = getAddParams();
    if (addParams) {
      let params = addParams.split("&");
      params.forEach(function (item, index) {
        record[item.split("=")[0]] = item.split("=")[1];
      });
    }
    return Promise.all([FxFetch.doJsonX("mass_billing", record)]).then((result) => {
      let rs = result[0];
      let all = '';
      if (rs) {
        for (let i = 0; i < rs.length; i++) {
          all += "|" + rs[i].inInvoice + "-" + (rs[i].inClient || '');
        }
        storeMassBilling.arListedId = all;
        return Promise.all([setStoreMassBilling(storeMassBilling)]).then((result) => {
          return;
        });
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inInvoice", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.doc'), accessor: "txInvoice", width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '10%' });
      cols.push({ id: id++, Header: "_itSeries", accessor: "itSeries", show: false });
      cols.push({ id: id++, Header: "_inClient", accessor: "inClient", show: false });
      cols.push({ id: id++, Header: trans('field.client'), accessor: "txClient", width: '20%' });
      cols.push({ id: id++, Header: trans('field.insurance'), accessor: "txTypeInsurPlan", show: false });

      cols.push({ id: id++, Header: trans("field.taxable") + "-1", accessor: row => (FxTable.cellTo2r00(row["nuTaxable1"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.taxable") + "-2", accessor: row => (FxTable.cellTo2r00(row["nuTaxable2"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.taxable") + "-3", accessor: row => (FxTable.cellTo2r00(row["nuTaxable3"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.taxable") + "-4", accessor: row => (FxTable.cellTo2r00(row["nuTaxable4"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.taxable") + "-5", accessor: row => (FxTable.cellTo2r00(row["nuTaxable5"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax_perc") + "-1", accessor: row => (FxTable.cellTo2r00(row["nuTaxPerc1"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax_perc") + "-2", accessor: row => (FxTable.cellTo2r00(row["nuTaxPerc2"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax_perc") + "-3", accessor: row => (FxTable.cellTo2r00(row["nuTaxPerc3"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax_perc") + "-4", accessor: row => (FxTable.cellTo2r00(row["nuTaxPerc4"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax_perc") + "-5", accessor: row => (FxTable.cellTo2r00(row["nuTaxPerc5"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax") + "-1", accessor: row => (FxTable.cellTo2r00(row["nuTax1"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax") + "-2", accessor: row => (FxTable.cellTo2r00(row["nuTax2"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax") + "-3", accessor: row => (FxTable.cellTo2r00(row["nuTax3"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax") + "-4", accessor: row => (FxTable.cellTo2r00(row["nuTax4"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.tax") + "-5", accessor: row => (FxTable.cellTo2r00(row["nuTax5"])), align: 'right', show: false });

      cols.push({ id: id++, Header: trans("field.taxable"), accessor: row => (FxTable.cellTo2r00(row["nuTotalTaxable"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans("field.discount"), accessor: row => (FxTable.cellTo2r00(row["nuTotalDiscount"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans("field.tax"), accessor: row => (FxTable.cellTo2r00(row["nuTotalTax"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans("field.amount"), accessor: row => (FxTable.cellTo2r00(row["nuTotalAmount"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.wtax_s"), accessor: row => (FxTable.cellTo2r00(row["nuTotalWtax"])), align: 'right', show: false });
      cols.push({ id: id++, Header: trans("field.total"), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans("field.paid"), accessor: row => (FxTable.cellTo2r00(row["nuTotalPayment"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans("field.balance"), accessor: row => (FxTable.cellTo2r00(row["theBalance"])), align: 'right', show: false });

      cols.push({ id: id++, Header: trans('field.notes'), accessor: "txNotes", show: false });

      cols.push({ id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.doc'), accessor: "txInvoice", width: '30%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtInvoice"]), width: '30%' });
      cols.push({ id: id++, Header: "_itSeries", accessor: "itSeries", show: false });
      cols.push({ id: id++, Header: trans("field.total"), accessor: row => (FxTable.cellTo2r00(row["nuTotalFinal"])), width: '20%', align: 'right' });

      cols.push({ id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false });
    }

    cols.push({
      id: "BTN_I11", icon: <BiLockAlt />, icon_alt: <BiLockOpenAlt />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockIssue(!lockIssue),
      accessor: row => { return <CxIconButton type="check" classType="ibs_iconbutton_table_body" disabled={lockIssue} data={row} onClick={doIssueDoc} tooltip={trans("field.issue_x", 'field.receipt')} /> },
      align: 'center', width: '33px'
    });
    cols.push({
      id: "BTN_EXC", icon: <BiLockAlt />, icon_alt: <BiLockOpenAlt />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockExclude(!lockExclude),
      accessor: row => { return <CxIconButton type="cancel" classType="ibs_iconbutton_table_body" disabled={lockExclude} data={row} onClick={doExcludeInvoice} tooltip={trans("field.exclude_from_x", 'field.list')} /> },
      align: 'center', width: '33px'
    });

    return cols;
  }

  const getAddParams = () => {
    return "dtInvoiceFrom=" + storeMassBilling.dtInvoiceFrom + ""
      + "&dtInvoiceTo=" + storeMassBilling.dtInvoiceTo + ""
      + "&inClient=" + storeMassBilling.inClient + ""
      + "&inEmployee=" + storeMassBilling.inEmployee + ""
      + "&inBranch=" + storeMassBilling.inBranch + ""
      + "&txInvoice=" + storeMassBilling.txInvoice + ""
      + "&nuTotalFinalFrom=" + storeMassBilling.nuTotalFinalFrom + ""
      + "&nuTotalFinalTo=" + storeMassBilling.nuTotalFinalTo + ""
      + "&nuTotalTaxFrom=" + storeMassBilling.nuTotalTaxFrom + ""
      + "&nuTotalTaxTo=" + storeMassBilling.nuTotalTaxTo + ""
      + "&boPaid=" + storeMassBilling.boPaid + ""
      + "&arPayments=" + storeMassBilling.arPayments + ""
      + "&arSeries=" + storeMassBilling.arSeries + ""
      + "&arCash=" + storeMassBilling.arCash + ""
      + "&arExcludedId=" + storeMassBilling.arExcludedId + "";
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.mass_billing')}: ${trans('field.list')}`}
        xref={r_list}
        fullscreen
        hasSearchBox={false}
        hasButtonAdd={false}
        url="mass_billing"
        addParams={getAddParams()}
        onOpenForm={doPrintX}
        refColors={['table_cell_color_red|' + trans('field.debtor')]}
        columns={getTableColumns()}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </div>
        }
      >
      </GxList>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMassBillingList;
