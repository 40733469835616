import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettPaymentDet } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettSlsPaymDetForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  let rf_txTypePayment = React.useRef(null);

  const [storeSettPaymentDet, setStoreSettPaymentDet] = useSessionStorage('StoreSettPaymentDet', StoreSettPaymentDet);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettPaymentDet.itPaymentDet = lastSaveRv.theValue;
      Promise.all([setStoreSettPaymentDet(storeSettPaymentDet)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.type')} - ${trans('field.payment')}`}
        xref={r_form}
        url="sett/pay_det" 
        filterIdField='itPaymentDet'
        filterIdValue={storeSettPaymentDet.itPaymentDet} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_payment_det.itPaymentDet" value={storeSettPaymentDet.itPaymentDet || ''} />

        <CxInput xref={useRef(null)} label={trans('field.detail')} dbfield="t_payment_det.txTypePaymentDet" required />

        <CxSearcher xref={rf_txTypePayment} label={trans('field.payment')}
          dbfield="t_payment.txTypePayment" dbfieldkey={`t_payment_det.itPayment`}
          url="sett/pay"
          col_id="itPayment"
          col_tx="txTypePayment"
          columns={[
            { id: "0", Header: "ID", accessor: "itPayment", show: false },
            { id: "1", Header: trans('field.payment'), accessor: row => rf_txTypePayment.current.getTable().highlightText(row["txTypePayment"]), width: '100%' },
          ]}
          required
        />
        
      </GxForm>

    </div>
  );

}

export default MxSettSlsPaymDetForm;
