import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxColor from '../../cx/CxColor';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettCalendarGrp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettCalendarGrpForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_record_used = React.useRef(null);

  let r_form = React.useRef(null);

  const [storeSettCalendarGrp, setStoreSettCalendarGrp] = useSessionStorage('StoreSettCalendarGrp', StoreSettCalendarGrp);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettCalendarGrp.itCalendarGrp = lastSaveRv.theValue;
      Promise.all([setStoreSettCalendarGrp(storeSettCalendarGrp)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue==='rv_error') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.calendars')}: ${trans('field.group')}`}
        xref={r_form}
        url="sett/cal_grp" 
        filterIdField='itCalendarGrp'
        filterIdValue={storeSettCalendarGrp.itCalendarGrp} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_calendar_grp.itCalendarGrp" value={storeSettCalendarGrp.itCalendarGrp || ''} />
        <CxInput xref={useRef(null)} label={trans('field.group')} dbfield="t_calendar_grp.txTypeCalendarGrp" maxLength="100" required />
        <CxInput xref={useRef(null)} label={trans('field.letter')} dbfield="t_calendar_grp.txLetterGrp" maxLength='1' upperCase required />
        <CxColor xref={useRef(null)} label={trans('field.color')} dbfield="t_calendar_grp.txRgb" />
        
      </GxForm>

      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />

    </div>
  );

}

export default MxSettCalendarGrpForm;
