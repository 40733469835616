// funciones para internet.
//import * as FxLog from './FxLog';

export const windowOpen = async (url) => {
  // Open a new window directly in response to a user action
  const newWindow = window.open(url, '_blank');

  // Check if the window was blocked
  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    // SAFARI TIENE UN BLOQUEADOR DE POPUPS INCORPORADO.
    // Se puede intentar quitarlo: https://support.apple.com/es-es/102524#:~:text=Ve%20a%20Ajustes%20%3E%20Apps%20%3E%20Safari,o%20desactiva%20Bloquear%20ventanas%20emergentes.
    alert('The new window was blocked by a pop-up blocker.');
  }
}
