import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxTextEditor from '../../cx/CxTextEditor';
import CxState from '../../cx/CxState';
import CxCombo from '../../cx/CxCombo';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettCusDoc } from "../../gx/GxStore";
import { isApp } from '../../gx/GxStore';
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettCusDocForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const rf_boNotifyPending = React.useRef(null);
  const rf_txModule = React.useRef(null);

  const [storeSettCusDoc, setStoreSettCusDoc] = useSessionStorage('StoreSettCusDoc', StoreSettCusDoc);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettCusDoc.itDoc = lastSaveRv.theValue;
      Promise.all([setStoreSettCusDoc(storeSettCusDoc)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.customization')} - ${trans('field.doc')}`}
        xref={r_form}
        url="sett/cus_doc" 
        filterIdField='itDoc'
        filterIdValue={storeSettCusDoc.itDoc} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_doc.itDoc" value={storeSettCusDoc.itDoc || ''} />
        <CxInput xref={useRef(null)} label={`${trans('field.doc')}`} dbfield="t_doc.txTypeDoc" maxLength={100} required />
        
        <CxCombo xref={rf_txModule}
          label={`${trans('field.module')}`}
          dbfield='t_doc_tpl.txModule'
          list={
            (isApp('vett') || isApp('spaw')) ? 
              [ { id: 'C', type: trans('field.client') }, { id: 'A', type: trans('field.patient') } ]
            :
              [ { id: 'C', type: trans('field.client') } ]
          }
          required
          autoSelectFirst
        />
        <CxState mode='checkbox' xref={rf_boNotifyPending} dbfield="t_doc.boNotifyPending" label={trans('field.sett_notify_pending')} />

        <CxInput xref={useRef(null)} label={`${trans('field.doc_title')}`} dbfield="t_doc_tpl.txTitle" maxLength={50} />
        <CxInput xref={useRef(null)} label={`${trans('field.doc_subtitle')}`} dbfield="t_doc_tpl.txSubtitle" maxLength={50} />
        <CxState mode='checkbox' xref={useRef(null)} dbfield="t_doc_tpl.boAddHeader" label={trans('field.layout_header')} />
        <CxState mode='checkbox' xref={useRef(null)} dbfield="t_doc_tpl.boAddFooter" label={trans('field.layout_footer')} />
        <CxTextEditor xref={useRef(null)} label={trans('field.content')} dbfield="t_doc_tpl.txContent" minRows={20} />
        
      </GxForm>

    </div>
  );

}

export default MxSettCusDocForm;
