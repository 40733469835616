// Funciones de geolocalización.

export const getGeolocation = () => {
  return Promise.all([getCoordinates()]).then((result1) => {
    let latitude = result1[0].latitude;
    let longitude = result1[0].longitude;
    return Promise.all([coordinatesToCity(latitude, longitude)]).then((result2) => {
      return {
        status: 'OK',
        latitude: latitude,
        longitude: longitude,
        city: result2[0],
      };
    })
    .catch((error) => {
      return { status: error.message };
    });
  }).catch((error) => {
    return { status: error.message };
  });
}

// Función para obtener la geolocalización por coordenadas
export const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            reject(new Error("The user has not granted geolocation permissions."));
          } else {
            reject(error);
          }
        },
        { enableHighAccuracy: true } // Solicitar alta precisión
      );
    } else {
      reject(new Error("Geolocation not supported by the browser."));
    }
  });
};

// Función para transformar coordenadas en ciudad
export const coordinatesToCity = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();

    if (data.address) {
      return data.address.city || data.address.town || data.address.village;
    } else {
      return "City not found";
    }
  } catch (error) {
    throw new Error("Error getting city.");
  }
};