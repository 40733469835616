import { BiSearch } from "react-icons/bi"; 
import { MdClear } from "react-icons/md"; 
import React, { useImperativeHandle } from "react";
import '../../styles/main.css';
import * as FxLog from '../fx/FxLog';
import GxDlgSelectClient from './GxDlgSelectClient';
import { isApp } from './GxStore';
import { useTranslation } from '../hx/useTranslation';
import { useStyles } from "../cx/StylesCx";
import TextField from '@material-ui/core/TextField';
import CxIconButtonNew from "../cx/CxIconButtonNew";


const GxSearcherClient = props => {
  const classes = useStyles(props);
  const [{ trans }] = useTranslation();
  const { animalRef, onClear, onSelection, InputLabelProps, ...rest } = props;

  const [wasChanged, setChanged] = React.useState(false);
  
  const inputRef = React.useRef(null);
  
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  
  const [isRequired, ] = React.useState(props.required);
  
  const r_dlg_select_client = React.useRef(null);

  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, setKey, getKey, clear, setClient, isDisabled, getIsRequired, getLabel, isEmpty, wasChanged, setChanged
    };
  });
  const getType = () => { return 'GxSearcherClient' };
  const getLabel = () => { return trans('field.client') };
  const getIsRequired = () => { return isRequired && (myValue===null || myValue==='') };
  const isDisabled = () => { return props.disabled };
  const getVal = () => { return myValue||'' };
  const getKey = () => { return myKey||'' };
  const setKey = (newId) => { setMyKey(newId) };
  const setVal = (newValue) => { 
    setMyValue(newValue); 
  };
  const clear = () => {
    if (!props.disabled) {
      setChanged(true);
      setKey('');
      setVal('');
      if (isApp('vett') || isApp('spaw')) animalRef.current.clear();
      if (onClear) onClear();
    }
  };
  const isEmpty = () => { return myValue.length === 0 };

  const setClient = (inClient, txClient) => {
    setKey(inClient);
    setVal(txClient);
    if (isApp('vett') || isApp('spaw')) {
      animalRef.current.clear();
    }
  }

  const clientSelected = (row) => {
    if (row.original.inClient !== null) {
      setKey(row.original.inClient);
      setVal(row.original.txClient);
      if (isApp('vett') || isApp('spaw')) {
        if (row.original.animal_inAnimal) {
          animalRef.current.setKey(row.original.animal_inAnimal);
          animalRef.current.setVal(row.original.animal_txAnimal);
        } else {
          animalRef.current.clear();
        }
      }
      setChanged(true);
      if (onSelection) {
        onSelection(row);
      }
    }
  }

  const doSelectClient = () => {
    if (!props.disabled) {
      r_dlg_select_client.current.setOpen(true);
    }
  }

  return (
    <div>
      <div id='div_Cxinput' className={classes.frame} style={{ display: '' }}>
        <div className={classes.frame_content}>
          <TextField
            inputRef={inputRef}
            className={classes.control}
            autoComplete='new-password'
            label={trans('field.client')}
            InputLabelProps={{
              classes: { root: classes.root, },
              shrink: true,
              ...InputLabelProps
            }}
            InputProps={{ //refers to the Input component
              disableUnderline: true,
              classes: { root: classes.root, },
              inputProps: { //refers to the input element
                style: { cursor: 'default' },
                readOnly: true,
              },
            }}
            value={myValue||''}
            onClick={doSelectClient} // 2025-02-13 - daq: mismo efecto que darle al botoncito, para que la gente no se frustre cuando tratan de darle foco para escribir.
            {...rest}
          />
        </div>
        <div className={classes.frame_buttons}>
          <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={clear} disabled={props.disabled} />
          <CxIconButtonNew icon={<BiSearch fontSize={20} />} classType={classes.frame_icons_new} onClick={doSelectClient} disabled={props.disabled} />
        </div>
      </div>

      <GxDlgSelectClient xref={r_dlg_select_client}
        defaultSearch={myValue||''}
        includeAnimal={(isApp('vett') || isApp('spaw'))}
        hasButtonAdd={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('GxSearcherClient.return.GxDlgSelectClient', '...row:', row);
          clientSelected(row);
        }}
      />
      
    </div>
  );
};

export default GxSearcherClient;
