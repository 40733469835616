import { MdOutlinePets } from "react-icons/md"; 
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrintLong from '../../gx/GxPrintLong';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxState from '../../cx/CxState';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxAccordion from '../../cx/CxAccordion';
import CxTable from '../../cx/CxTable';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValue, getStoreValueBo, StoreClient, StoreCns } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import { BiPrinter } from "react-icons/bi";
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';
import { useNav } from '../../hx/useNav';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import * as FxStr from '../../fx/FxStr';
import { useWindowSize } from '../../hx/useWindowSize';


const MxAnimalCnsHosForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo, navStack }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);

  const r_table_images = React.useRef(null);
  
  const r_snack_ask_save = React.useRef(null);
  
  let r_form = React.useRef(null);
  let r_gx_print_long = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txEmployee = React.useRef(null);
  const rf_itStatusCns = React.useRef(null);
  const rf_txTypeAnimalLocation = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusCns.current.setKeyAndVal("t_status_cns", "txTypeStatusCns", "itStatusCns", "000001.SRV");
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
      if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
        rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
        rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    storeCns.inCns = lastSaveRv.theValue;
    Promise.all([setStoreCns(storeCns)]).then((result) => {
      forceUIUpdate();
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let filter = {};
    filter['inCns'] = storeCns.inCns;
    let record = {};
    let inClient = storeClient.inClient;
    if (option === 'hos') {
      r_gx_print_long.current.setUrlBuild('cnss/report/hos_build');
      r_gx_print_long.current.setUrlGet('cnss/report/hos_get');
    };
    //----------------
    Promise.all([
      r_gx_print_long.current.setFilter(filter),
      r_gx_print_long.current.setRecord(record),
      r_gx_print_long.current.setInClient(inClient)
    ]).then((result) => {
      r_gx_print_long.current.doPrint();
    });
  }

  const openFormHosGuidelines = async (op, row) => {
    if (!storeCns.inCns) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeCns.inCnsHospitalItem = null;
      } else {
        storeCns.inCnsHospitalItem = row.original.inCnsHospitalItem;
      }
      setStoreCns(storeCns);
      navTo("animal-cns-hos-form-item");
    }
  }

  const openFormImage = async (op, row) => {
    if (!storeCns.inCns) {
      r_snack_ask_save.current.setOpen(true);
    } else {
      if (row === null) {
        storeClient.inImage = null;
      } else {
        storeClient.inImage = row.original.inImage;
      }
      setStoreClient(storeClient);
      navTo("client-image-form");
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}: ${trans('field.cns')}-${trans('field.cns_hos')}`}
        xref={r_form}
        url="cnss"
        filterIdField='inCns'
        filterIdValue={storeCns.inCns}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonMenu onSelection={doPrintX} className="ibs_iconbutton_toolbar" icon={<BiPrinter />}
              options={[
                { id: 'hos', text: trans('field.print_x','field.cns_hos'), },
              ]}
            />
            {storeCns.inCns && navStack().includes('activity-vett-c-list') && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={() => navTo("animal-form")} tooltip={trans('field.patient')} />}
          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_cns.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.inAnimal" value={storeClient.inAnimal || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.itStatusCns" value={'000001.SRV'} />
        <CxHidden xref={useRef(null)} dbfield="n_cns.txType" value={'H'} />

        <CxStatic label={`${FxStr.join(' / ', storeClient.txClient, storeClient.txAnimal)}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_cns.dtFrom`} required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield={`n_cns.dtFrom`} required />

        <CxChooser xref={rf_itStatusCns} label={trans('field.status')}
          dbfield="t_status_cns.txTypeStatusCns" dbfieldkey={`n_cns.itStatusCns`}
          table="t_status_cns"
          col_id="itStatusCns"
          col_tx="txTypeStatusCns"
          required
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_cns.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_cns.txRemarks" maxLength={255} />

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_cns.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxDate xref={useRef(null)} label={trans('field.to')} dbfield={'n_cns_hospital.dtTo'} />

        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.discharged')} dbfield="n_cns_hospital.boDischarged" />

        <CxInput xref={useRef(null)} label={trans('field.status')} dbfield="n_cns_hospital.txStatus" maxLength={30} />

        <CxSearcher xref={rf_txTypeAnimalLocation} label={trans('field.location')}
          dbfield="t_animal_location.txTypeAnimalLocation" dbfieldkey="n_cns_hospital.itAnimalLocation"
          tooltip={trans('field.location')}
          url="sett/animal_location"
          col_id="itAnimalLocation"
          col_tx="txTypeAnimalLocation"
          filter="txType='X' or txType='H'"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalLocation", show: false },
            { id: "1", Header: trans('field.location'), accessor: row => rf_txTypeAnimalLocation.current.getTable().highlightText(row["txTypeAnimalLocation"]), width: '100%' },
          ]}
          bottomLegend={`${trans('msg.setup_new_1')}: ${trans('field.settings')} > ${trans('field.options')} > ${trans('field.patients')} > ${trans('field.locations')}`}
          required
        />


        <CxInput xref={useRef(null)} label={trans('field.referring_vet')} dbfield="n_cns_hospital.txRefVet" maxLength={100} />

        <CxAccordion title={trans('field.registry')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxInput xref={useRef(null)} label={trans('field.reg_in_loc')} dbfield="n_cns_hospital.txIncomingLoc" maxLength={20} />
          <CxInput xref={useRef(null)} label={trans('field.reg_in_doc')} dbfield="n_cns_hospital.txIncomingDoc" maxLength={20} />
          <CxInput xref={useRef(null)} label={trans('field.reg_out_loc')} dbfield="n_cns_hospital.txOutgoingLoc" maxLength={20} />
          <CxInput xref={useRef(null)} label={trans('field.reg_out_doc')} dbfield="n_cns_hospital.txOutgoingDoc" maxLength={20} />
        </CxAccordion>

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_cns.txNotes" minRows={4} speech />
        <CxInput xref={useRef(null)} label={trans('field.conclusion')} dbfield="n_cns_hospital.txConclusion" minRows={4} speech />

        <CxAccordion title={trans('field.guidelines')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxTable
            xref={r_table_images}
            margin="-15px 0px 0px 0px"
            url="cnss/hos_guidelines"
            filter={`n_cns_hospital_item.inCns='${storeCns.inCns}'`}
            onOpenForm={openFormHosGuidelines}
            hasButtonAdd={isFullAccess()}
            columns={[
              { id: "0", Header: "ID", accessor: "inCnsHospitalItem", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
              { id: "2", Header: trans('field.guideline'), accessor: "txGuideline", width: '15%' },
              { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
            ]}
          />
        </CxAccordion>

        <CxAccordion title={trans('field.images')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxTable
            xref={r_table_images}
            margin="-15px 0px 0px 0px"
            url="images"
            filter={`n_image.inCns='${storeCns.inCns}'`}
            onOpenForm={openFormImage}
            hasButtonAdd={isFullAccess()}
            disabledUntilUpgrade={!FxEdition.isInEdition('CLI_img')}
            columns={[
              { id: "0", Header: "ID", accessor: "inImage", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
              { id: "2", Header: trans('field.type'), accessor: "txTypeImage", width: '15%' },
              { id: "3", Header: trans('field.remarks'), accessor: "txRemarks", width: '72%' /* 2% adicional para alineación */, show: size.isLarge },
            ]}
          />
        </CxAccordion>

      </GxForm>

      <GxSlsAuxTable txLinkAuxTable={'n_cns'} txLinkAuxId={storeCns.inCns} rf_txEmployee={rf_txEmployee} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <GxPrintLong xref={r_gx_print_long} />

    </div>
  );

}

export default MxAnimalCnsHosForm;
