import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxSnackbar from '../../../cx/CxSnackbar';
import CxSearcher from '../../../cx/CxSearcher';
import '../../../../styles/main.css';


const MxLabTechOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, ] = React.useState(false);

  const rf_txLabTech = React.useRef(null);
  const rf_txLab = React.useRef(null);
  const rf_txNotes = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txLabTech.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txLabTech.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txLab.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txLab.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txLabTech = rf_txLabTech.current.getVal();
    let inLab = rf_txLab.current.getKey();
    let txNotes = rf_txNotes.current.getVal();

    let record = {};
    record['txLabTech'] = txLabTech;
    record['inLab'] = inLab;
    record['txNotes'] = txNotes;

    Promise.all([FxFetch.doJsonX("lab_techs/otf", record)]).then((result) => {
      FxLog.infox("MxLabTechOTF.formSave", "...result[0]:", result[0]);
      if (result[0]) {
        if (result[0].theValue === null) {
          FxLog.infox("MxLabTechOTF.formSave", "El tech ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists','field.lab_tech'));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxLabTechOTF.formSave", "El tech ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let inProf = result[0].theValue;
          if (props.onOk) props.onOk(inProf, txLabTech);
          setDlg(false);
        }
      }
    });

  }

  return (
    <div>
      <CxDialog title={trans('field.create_x','field.lab_tech')} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

        <CxInput xref={rf_txLabTech} label={trans('field.lab_tech')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={100} required />
        
        <CxSearcher xref={rf_txLab} label={trans('field.lab')}
          dbfield="n_lab.txLab" dbfieldkey={`n_lab_tech.inLab`}
          url="labs"
          col_id="inLab"
          col_tx="txLab"
          columns={[
            { id: "0", Header: "ID", accessor: "inLab", show: false },
            { id: "1", Header: trans('field.lab'), accessor: row => rf_txLab.current.getTable().highlightText(row["txLab"]), width: '100%' },
          ]}
           width='var(--ibs_control_width_md)'
          required
        />

        <CxInput xref={rf_txNotes} label={trans('field.notes')} width='var(--ibs_control_width_md)' minRows={4} />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />
    </div>
  );

}

export default MxLabTechOTF;
