import { MdOutlinePets } from "react-icons/md"; 
import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxState from '../../cx/CxState';
import GxForm from '../../gx/GxForm';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import CxSnackbar from '../../cx/CxSnackbar';
import CxStatic from '../../cx/CxStatic';
import { getStoreValue, getStoreValueBo, StoreClient, StoreBoarding } from "../../gx/GxStore";
import { useAccess } from '../../hx/useAccess';
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import * as FxStr from '../../fx/FxStr';


const MxAnimalBrdgForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const [{ navTo, navStack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeBoarding, setStoreBoarding] = useSessionStorage('StoreBoarding', StoreBoarding);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);

  const rf_from = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_to = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txEmployee = React.useRef(null);
  const rf_txTypeAnimalLocation = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_from.current.setValToday();
      rf_time.current.setValNow();
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
      if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
        rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
        rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    storeBoarding.inBoarding = lastSaveRv.theValue;
    Promise.all([setStoreBoarding(storeBoarding)]).then((result) => {
      r_sls_aux_table.current.setTxLinkAuxId(storeBoarding.inBoarding);
      forceUIUpdate();
    });
    //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}: ${trans('field.boarding')}`}
        xref={r_form}
        url="brdg"
        filterIdField='inBoarding'
        filterIdValue={storeBoarding.inBoarding}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {storeBoarding.inBoarding && navStack()[navStack().length-2]==='activity-vett-b-list' && <CxIconButtonNew icon={<MdOutlinePets fontSize={23} />} classType="ibs_iconbutton_toolbar" onClick={() => navTo("animal-form")} tooltip={trans('field.patient')} />}
          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_boarding.inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_boarding.inAnimal" value={storeClient.inAnimal || ''} />

        <CxStatic label={`${FxStr.join(' / ', storeClient.txClient, storeClient.txAnimal)}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_from} label={trans('field.from')} dbfield={`n_boarding.dtFrom`} required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield={`n_boarding.dtFrom`} required />
        <CxDate xref={rf_to} label={trans('field.to')} dbfield={`n_boarding.dtTo`} required />
        <CxInput xref={useRef(null)} label={trans('field.type')} dbfield="n_boarding.txType" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_boarding.txRemarks" maxLength={150} />
        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_boarding.txReference" maxLength={50} />

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_boarding.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_boarding.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxSearcher xref={rf_txTypeAnimalLocation} label={trans('field.location')}
          dbfield="t_animal_location.txTypeAnimalLocation" dbfieldkey="n_boarding.itAnimalLocation"
          tooltip={trans('field.location')}
          url="sett/animal_location"
          col_id="itAnimalLocation"
          col_tx="txTypeAnimalLocation"
          filter="txType='X' or txType='B'"
          columns={[
            { id: "0", Header: "ID", accessor: "itAnimalLocation", show: false },
            { id: "1", Header: trans('field.location'), accessor: row => rf_txTypeAnimalLocation.current.getTable().highlightText(row["txTypeAnimalLocation"]), width: '100%' },
          ]}
          bottomLegend={`${trans('msg.setup_new_1')}: ${trans('field.settings')} > ${trans('field.options')} > ${trans('field.patients')} > ${trans('field.locations')}`}
          required
        />


        <CxInput xref={useRef(null)} label={trans('field.feeding')} dbfield="n_boarding.txFeeding" maxLength={100} />
        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.bathing')} dbfield="n_boarding.boBathing" />
        <CxState mode='checkbox' xref={useRef(null)} label={trans('field.grooming')} dbfield="n_boarding.boGrooming" />


        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_boarding.txNotes" minRows={4} />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} txLinkAuxTable={'n_boarding'} txLinkAuxId={storeBoarding.inBoarding} rf_txEmployee={rf_txEmployee} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxAnimalBrdgForm;
