import { BsFillFolderFill } from "react-icons/bs"; 
import { MdOutlineDevices } from "react-icons/md"; 
import { FaBoxes } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { MdCenterFocusStrong } from "react-icons/md";
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxEdition from '../../fx/FxEdition';
import { useNav } from '../../hx/useNav';
import { isApp } from "../../gx/GxStore";
import CxTree from '../../cx/CxTree';
import CxTreeItem from '../../cx/CxTreeItem';
import { MdPets, MdPermMedia, MdImage, MdSend, } from "react-icons/md";
import { BsFillCalendarFill } from "react-icons/bs";
import { FaCashRegister, FaCoins, FaTruck, FaBox, FaFilePrescription, FaMicroscope, FaPuzzlePiece, FaListAlt, FaShoppingBasket } from "react-icons/fa";
import { ImUser, ImUserTie, ImProfile, ImImage } from "react-icons/im";
import { makeStyles } from '@material-ui/core/styles';
import CxToolbar from '../../cx/CxToolbar';
import { StoreLongTask, clearStore } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';


const useStyles = makeStyles((props) => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    width: 'var(--ibs_control_width_lg)',
    height: '100%',
    margin: '20px',
  },
}));

const MxOptions = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const [{ navTo }] = useNav();

  const [storeLongTask, setStoreLongTask] = useSessionStorage('StoreLongTask', StoreLongTask);

  let r_dlg_upgrade = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreLongTask', storeLongTask);
    // eslint-disable-next-line
  }, []);

  return (
    <div id='div_MxOptions1'>
      <CxToolbar
        moduleName={`${trans('field.options')}`}
      />

      <div id='div_MxOptions2' style={{ margin: '2px 10px 0px 10px' }}>
        <div className={classes.container}>

          <CxTree id='MxOptions'>

            <CxTreeItem
              nodeId="logo"
              labelText={trans('field.logo')}
              icon={<ImImage />}
              onLabelClick={(e) => navTo('sett-logo')}
            />

            <CxTreeItem
              nodeId="device"
              labelText={trans('field.device')}
              icon={<MdOutlineDevices fontSize={18} />}
              onLabelClick={(e) => navTo('sett-device-form')}
            />

            <CxTreeItem
              nodeId="file"
              labelText={trans('field.files')}
              icon={<ImProfile />}
              onLabelClick={(e) => navTo('sett-file-form')}
            />

            <CxTreeItem nodeId="branch" labelText={trans('field.branch')} icon={<MdCenterFocusStrong fontSize={20} />}>
              <CxTreeItem
                nodeId="branch_header_footer"
                labelText={`${trans('field.branch_header')} / ${trans('field.branch_footer')}`}
                onLabelClick={(e) => navTo('sett-sls-header-footer')}
              />
            </CxTreeItem>

            <CxTreeItem
              nodeId="bank"
              labelText={trans('field.banks')}
              icon={<AiFillBank fontSize={20} />}
              onLabelClick={(e) => navTo('sett-bank-list')}
            />

            <CxTreeItem nodeId="client" labelText={trans('field.clients')} icon={<ImUser />}>
              <CxTreeItem
                nodeId="client_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-client-list')}
              />
              <CxTreeItem
                nodeId="client_restore"
                labelText={trans('field.restore')}
                onLabelClick={(e) => navTo('sett-client-restore-list')}
              />
              <CxTreeItem
                nodeId="client_merge"
                labelText={trans('field.merge_x', 'field.clients')}
                onLabelClick={(e) => navTo('sett-client-merge-form')}
              />
              <CxTreeItem
                nodeId="client_portal"
                labelText={trans('field.client_portal')}
                onLabelClick={(e) => navTo('sett-client-portal-list')}
              />
            </CxTreeItem>

            {(isApp('vett') || isApp('spaw')) &&
              <CxTreeItem nodeId="patient" labelText={trans('field.patients')} icon={<MdPets />}>
                <CxTreeItem
                  nodeId="patient_locations"
                  labelText={trans('field.locations')}
                  onLabelClick={(e) => navTo('sett-animal-location-list')}
                />
                <CxTreeItem
                  nodeId="patient_species"
                  labelText={trans('field.species_p')}
                  onLabelClick={(e) => navTo('sett-animal-species-list')}
                />
                <CxTreeItem
                  nodeId="patient_breeds"
                  labelText={trans('field.breeds')}
                  onLabelClick={(e) => navTo('sett-animal-breed-list')}
                />
                <CxTreeItem
                  nodeId="patient_coats"
                  labelText={trans('field.coats')}
                  onLabelClick={(e) => navTo('sett-animal-coat-list')}
                />
                <CxTreeItem
                  nodeId="patient_restore"
                  labelText={trans('field.restore')}
                  onLabelClick={(e) => navTo('sett-animal-restore-list')}
                />
                <CxTreeItem
                  nodeId="patient_merge"
                  labelText={`${trans('field.merge_x', 'field.patients')}`}
                  onLabelClick={(e) => navTo('sett-animal-merge-form')}
                />
                <CxTreeItem
                  nodeId="patient_merge_species"
                  labelText={`${trans('field.merge_x', 'field.species_p')}`}
                  onLabelClick={(e) => navTo('sett-animal-merge-species-form')}
                />
                <CxTreeItem
                  nodeId="patient_merge_breeds"
                  labelText={`${trans('field.merge_x', 'field.breeds')}`}
                  onLabelClick={(e) => navTo('sett-animal-merge-breed-form')}
                />
              </CxTreeItem>
            }

            <CxTreeItem nodeId="multimedia" labelText={trans('field.files2')} icon={<MdPermMedia />}>
              <CxTreeItem
                nodeId="multimedia_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-multimedia-list')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="image" labelText={trans('field.images')} icon={<MdImage />}>
              <CxTreeItem
                nodeId="image_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-image-list')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="employee" labelText={trans('field.employees')} icon={<ImUserTie />}>
              <CxTreeItem
                nodeId="employee_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-employee-list')}
              />
            </CxTreeItem>

            {isApp('opti') &&
              <CxTreeItem nodeId="profs" labelText={trans('field.professionals')} icon={<ImUserTie />}>
                <CxTreeItem
                  nodeId="profs_profs"
                  labelText={trans('field.professionals')}
                  onLabelClick={(e) => navTo('sett-prof-list')}
                />
              </CxTreeItem>
            }

            <CxTreeItem nodeId="supplier" labelText={trans('field.suppliers')} icon={<FaTruck />}>
              <CxTreeItem
                nodeId="supplier_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-supplier-list')}
              />
            </CxTreeItem>

            {isApp('opti') &&
              <CxTreeItem nodeId="lab" labelText={trans('field.labs')} icon={<FaMicroscope />}>
                <CxTreeItem
                  nodeId="lab_types"
                  labelText={trans('field.types')}
                  onLabelClick={(e) => navTo('sett-lab-list')}
                />
                <CxTreeItem
                  nodeId="lab_techs"
                  labelText={trans('field.lab_techs')}
                  onLabelClick={(e) => navTo('sett-lab-tech-list')}
                />
              </CxTreeItem>
            }

            <CxTreeItem nodeId="ps" labelText={trans('field.pss')} icon={<FaBox />}>
              <CxTreeItem
                nodeId="ps_groups"
                labelText={trans('field.groups')}
                onLabelClick={(e) => navTo('sett-ps-group-list')}
              />
              <CxTreeItem
                nodeId="ps_subgroups"
                labelText={trans('field.subgroups')}
                onLabelClick={(e) => navTo('sett-ps-subgroup-list')}
              />
              <CxTreeItem
                nodeId="ps_restore"
                labelText={trans('field.restore')}
                onLabelClick={(e) => navTo('sett-ps-restore-list')}
              />
              <CxTreeItem
                nodeId="ps_billing_code"
                labelText={trans('field.billing_code')}
                onLabelClick={(e) => navTo('sett-ps-billing-code')}
              />
              <CxTreeItem
                nodeId="ps_merge"
                labelText={`${trans('field.merge_x', 'field.pss')}`}
                onLabelClick={(e) => navTo('sett-ps-merge-form')}
              />
              <CxTreeItem
                nodeId="ps_merge_group"
                labelText={`${trans('field.merge_x', 'field.groups')}`}
                onLabelClick={(e) => navTo('sett-ps-merge-group-form')}
              />
              <CxTreeItem
                nodeId="ps_merge_subgroup"
                labelText={`${trans('field.merge_x', 'field.subgroups')}`}
                onLabelClick={(e) => navTo('sett-ps-merge-subgroup-form')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="stock" labelText={trans('field.stockrooms')} icon={<FaBoxes />}>
              <CxTreeItem
                nodeId="stock_reset"
                labelText={trans('field.stock_op_reset')}
                onLabelClick={(e) => {
                  storeLongTask._url = 'sett/stock/reset';
                  storeLongTask._warning = trans('msg.stock_op_reset_warning');
                  storeLongTask._record = { txTaskName: "stock_reset" };
                  storeLongTask._canCancel = false;
                  Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                    navTo('sett-stock-op-reset');
                  });
                }}
              />
              <CxTreeItem nodeId="stock_advanced" labelText={trans('field.advanced')} icon={<BsFillFolderFill />}>
                <CxTreeItem
                  nodeId="stock_truncate"
                  labelText={trans('field.stock_op_truncate')}
                  onLabelClick={(e) => {
                    storeLongTask._url = 'sett/stock/truncate';
                    storeLongTask._warning = trans('msg.stock_op_truncate_warning');
                    storeLongTask._record = { txTaskName: "stock_truncate" };
                    storeLongTask._canCancel = false;
                    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                      navTo('sett-stock-op-truncate');
                    });
                  }}
                />
                <CxTreeItem
                  nodeId="stock_fill"
                  labelText={trans('field.stock_op_fill')}
                  onLabelClick={(e) => {
                    storeLongTask._url = 'sett/stock/fill';
                    storeLongTask._warning = trans('msg.stock_op_fill_warning');
                    storeLongTask._record = { txTaskName: "stock_fill" };
                    storeLongTask._canCancel = false;
                    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                      navTo('sett-stock-op-fill');
                    });
                  }}
                />
                <CxTreeItem
                  nodeId="stock_rebuild"
                  labelText={trans('field.stock_op_rebuild')}
                  onLabelClick={(e) => {
                    storeLongTask._url = 'sett/stock/rebuild';
                    storeLongTask._warning = trans('msg.stock_op_rebuild_warning');
                    storeLongTask._record = { txTaskName: "stock_rebuild" };
                    storeLongTask._canCancel = false;
                    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                      navTo('sett-stock-op-rebuild');
                    });
                  }}
                />
                <CxTreeItem
                  nodeId="stock_settle"
                  labelText={trans('field.stock_op_settle')}
                  onLabelClick={(e) => {
                    storeLongTask._url = 'sett/stock/settle';
                    storeLongTask._warning = trans('msg.stock_op_settle_warning');
                    storeLongTask._record = { txTaskName: "stock_settle" };
                    storeLongTask._canCancel = false;
                    Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                      navTo('sett-stock-op-settle');
                    });
                  }}
                />
              </CxTreeItem>
            </CxTreeItem>
            
            <CxTreeItem nodeId="pos" labelText={trans('field.sales')} icon={<FaCoins />}>
              <CxTreeItem
                nodeId="pos_general"
                labelText={trans('field.general')}
                onLabelClick={(e) => navTo('sett-sls-general')}
              />
              <CxTreeItem
                nodeId="pos_printing"
                labelText={`${trans('field.printing')} - ${trans('field.local')}`}
                onLabelClick={(e) => navTo('sett-sls-printing')}
              />
              <CxTreeItem
                nodeId="pos_tax"
                labelText={trans('field.taxes')}
                onLabelClick={(e) => navTo('sett-sls-tax-list')}
              />
              <CxTreeItem
                nodeId="pos_wtax"
                labelText={trans('field.wtax')}
                onLabelClick={(e) => navTo('sett-sls-wtax')}
              />
              <CxTreeItem
                nodeId="pos_series"
                labelText={trans('field.series_p')}
                onLabelClick={(e) => navTo('sett-sls-series-list')}
              />
              <CxTreeItem
                nodeId="pos_counter"
                labelText={trans('field.counters')}
                onLabelClick={(e) => navTo('sett-sls-counter-list')}
              />
              <CxTreeItem
                nodeId="pos_new_year"
                labelText={trans('field.new_year_restart')}
                onLabelClick={(e) => {
                  storeLongTask._url = 'sett/new_year';
                  storeLongTask._warning = trans('msg.new_year_warning');
                  storeLongTask._record = { txTaskName: "new_year_restart" };
                  storeLongTask._canCancel = false;
                  Promise.all([setStoreLongTask(storeLongTask)]).then((result) => {
                    navTo('sett-sls-new-year-restart');
                  });
                }}
              />
              <CxTreeItem
                nodeId="pos_payment_det"
                labelText={trans('field.payments')}
                onLabelClick={(e) => navTo('sett-sls-payment-det-list')}
              />
              <CxTreeItem
                nodeId="pos_rate"
                labelText={trans('field.rates')}
                labelColor={!FxEdition.isInEdition('SLS_rat') && 'var(--ibs_color_gray)'}
                onLabelClick={(e) => {
                  if (!FxEdition.isInEdition('SLS_rat')) {
                    r_dlg_upgrade.current.setOpen(true);
                  } else {
                    navTo('sett-sls-rate-list');
                  }
                }}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="pur" labelText={trans('field.purchases')} icon={<FaShoppingBasket />}>
              <CxTreeItem
                nodeId="pur_surcharge"
                labelText={trans('field.surcharges')}
                onLabelClick={(e) => navTo('sett-pur-surcharge-list')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="cash" labelText={trans('field.cash_regs')} icon={<FaCashRegister />}>
              <CxTreeItem
                nodeId="cash_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-cash-list')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="calendar" labelText={trans('field.calendars')} icon={<BsFillCalendarFill />}>
              <CxTreeItem
                nodeId="calendar_general"
                labelText={trans('field.general')}
                onLabelClick={(e) => navTo('sett-calendar-general')}
              />
              <CxTreeItem
                nodeId="calendar_working_hours"
                labelText={trans('field.working_hours')}
                onLabelClick={(e) => navTo('sett-calendar-working-hours')}
              />
              <CxTreeItem
                nodeId="calendar_visible_days"
                labelText={trans('field.visible_days')}
                onLabelClick={(e) => navTo('sett-calendar-visible-days')}
              />
              <CxTreeItem
                nodeId="calendar_col"
                labelText={trans('field.columns')}
                onLabelClick={(e) => navTo('sett-calendar-col-list')}
              />
              <CxTreeItem
                nodeId="calendar_grp"
                labelText={trans('field.groups')}
                onLabelClick={(e) => navTo('sett-calendar-grp-list')}
              />
            </CxTreeItem>

            <CxTreeItem nodeId="comm" labelText={trans('field.comms')} icon={<MdSend />}>
              <CxTreeItem
                nodeId="comm_general"
                labelText={trans('field.general')}
                onLabelClick={(e) => navTo('sett-comm-general')}
              />
              <CxTreeItem
                nodeId="comm_types"
                labelText={trans('field.types')}
                onLabelClick={(e) => navTo('sett-comm-list')}
              />
              {(isApp('opti') || isApp('vett') || isApp('spaw')) &&
                <CxTreeItem
                  nodeId="comm_autogen"
                  labelText={trans('field.autogen')}
                  onLabelClick={(e) => navTo('sett-comm-autogen')}
                />
              }
              <CxTreeItem
                nodeId="comm_email"
                labelText={trans('field.email')}
                onLabelClick={(e) => navTo('sett-comm-email-list')}
              />
              <CxTreeItem
                nodeId="comm_sms"
                labelText={trans('field.sms')}
                onLabelClick={(e) => navTo('sett-comm-sms-list')}
              />
            </CxTreeItem>

            {isApp('vett') &&
              <CxTreeItem nodeId="rxs" labelText={trans('field.rxs')} icon={<FaFilePrescription />}>
                <CxTreeItem
                  nodeId="rxs_series"
                  labelText={trans('field.series_p')}
                  onLabelClick={(e) => navTo('sett-rxs-series-list')}
                />
              </CxTreeItem>
            }

            <CxTreeItem nodeId="cus" labelText={trans('field.customization')} icon={<FaPuzzlePiece />}>
              <CxTreeItem
                nodeId="cus_docs"
                labelText={trans('field.docs')}
                onLabelClick={(e) => navTo('sett-cus-doc-list')}
              />
              <CxTreeItem
                nodeId="cus_trans"
                labelText={trans('field.translations')}
                onLabelClick={(e) => navTo('sett-cus-trans-list')}
              />
            </CxTreeItem>

            <CxTreeItem
              nodeId="usr_actions"
              labelText={trans('field.sett_user_actions')}
              icon={<FaListAlt />}
              onLabelClick={(e) => navTo('sett-user-action-list')}
            />

          </CxTree>

        </div>

      </div>

      <CxDlgUpgrade xref={r_dlg_upgrade} />

    </div>
  );

}

export default MxOptions;
