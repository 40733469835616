import React, { useRef } from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxForm from '../../gx/GxForm';
import { StoreSettBank } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettBankForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const r_snack_error = React.useRef(null);
  let r_error_message = React.useRef(null);

  const [storeSettBank, setStoreSettBank] = useSessionStorage('StoreSettBank', StoreSettBank);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    } else { // registro existente
    }
  }
  const formSave = async (lastSaveRv) => {

    if (lastSaveRv.theStatus === 'error') {
    } else {
      // se guardó exitosamente
      storeSettBank.inBank = lastSaveRv.theValue;
      Promise.all([setStoreSettBank(storeSettBank)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.bank')}
        xref={r_form}
        url="sett/banks"
        filterIdField='inBank'
        filterIdValue={storeSettBank.inBank}
        onFormLoad={formLoad}
        onFormSave={formSave}
        saveAndExit
        onFormDelete={formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_bank.inBank" value={storeSettBank.inBank || ''} />

        <CxInput xref={useRef(null)} label={trans('field.bank')} dbfield="n_bank.txBank" required maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.ein')} dbfield="n_bank.txEin" maxLength={15} />
        <CxInput xref={useRef(null)} label={trans('field.agent')} dbfield="n_bank.txAgent" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.bic')} dbfield="n_bank.txBic" maxLength={11} />
        <CxInput xref={useRef(null)} label={trans('field.account')} dbfield="n_bank.txAccount" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.holder')} dbfield="n_bank.txHolder" maxLength={100} />

        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_bank.txPhone1" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.phone2')} dbfield="n_bank.txPhone2" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_bank.txMobile" maxLength={20} />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_bank.txEmail" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.web')} dbfield="n_bank.txWeb" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_bank.txAddress" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_bank.txCity" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_bank.txState" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_bank.txPostalCode"  maxLength={10} />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="n_bank.txRemarks" minRows={4} maxLength={255} />

      </GxForm>

      <CxSnackbar xref={r_snack_error} severity="error" value={r_error_message.current} />

    </div>
  );

}

export default MxSettBankForm;
