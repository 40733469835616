import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxDat from '../../fx/FxDat';
import * as FxFetch from '../../fx/FxFetch';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StorePlan } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import CxDialog from '../../cx/CxDialog';
import CxRadio from '../../cx/CxRadio';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';


const MxPlanList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storePlan, setStorePlan] = useSessionStorage('StorePlan', StorePlan);

  const [dlgIssue, setDlgIssue] = React.useState(false);
  
  const r_list = React.useRef(null);
  
  const rf_year = React.useRef(null);
  const rf_month = React.useRef(null);
  const rf_doctype = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_op_in_progress = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);

  React.useEffect(() => {
    clearStore('StorePlan', storePlan);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storePlan.inPlan = null;
    } else {
      storePlan.inPlan = row.original.inPlan;
    }
    setStorePlan(storePlan);

    navTo("plan-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inPlan", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '40%' });
        cols.push({ id: id++, Header: trans('field.next'), accessor: row => ( FxTable.cellToLocalDate(row["dtInvoiceNext"]) ), width: '10%' });
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '10%' });
        cols.push({ id: id++, Header: trans('field.to'), accessor: row => ( FxTable.cellToLocalDate(row["dtTo"]) ), width: '10%' });
        cols.push({ id: id++, Header: trans('field.remarks'), accessor: "txRemarks", width: '30%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.remarks'), accessor: "txRemarks", width: '30%' });
      }
      cols.push({ id: "A", Header: "past", accessor: row => Boolean(row["dtTo"] && (FxDat.toIso(row["dtTo"]) < FxDat.today())), show: false });
      cols.push({ id: "B", Header: "future", accessor: row => Boolean(row["dtFrom"] && (FxDat.toIso(row["dtFrom"]) > FxDat.today())), show: false });

    } catch(error) {
      FxLog.errorx('MxPlanList.getTableColumns', error)
    }
    return cols;
  }
  
  const doIssue = () => {
    // chequea requeridos
    if (rf_year.current.isEmpty() || rf_year.current.getVal()<2000) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_year.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_month.current.isEmpty() || rf_month.current.getVal()<1 || rf_month.current.getVal()>12) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_month.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    
    let year = rf_year.current.getVal();
    let month = rf_month.current.getVal();
    let doctype = rf_doctype.current.getVal();

    let record = {};
    record['year'] = year;
    record['month'] = month;
    record['doctype'] = doctype;
    r_snack_op_in_progress.current.setOpen(true);
    Promise.all([FxFetch.doJsonX(`/plans/create_docs`, record)]).then((result) => {
      r_snack_op_in_progress.current.setOpen(false);
      r_snack_op_successful.current.setOpen(true);
      r_list.current.redisplay();
    });

    setDlgIssue(false);
  }

  const doUpdatePrices = () => {
    let record = {};
    r_snack_op_in_progress.current.setOpen(true);
    Promise.all([FxFetch.doJsonX(`/plans/update_prices`, record)]).then((result) => {
      r_snack_op_in_progress.current.setOpen(false);
      r_snack_op_successful.current.setOpen(true);
      r_list.current.redisplay();
    });
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.plans')}`}
        xref={r_list}
        tooltip={`${trans('field.client')}`}
        fullscreen
        url="plans"
        onOpenForm={openForm}
        refColors={ [ 'table_cell_black|' + trans('field.current'), 'table_cell_color_gray|' + trans('field.past_1'), 'table_cell_color_blue|' + trans('field.future_1') ] }
        columns={getTableColumns()}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButton type="price_change" classType="ibs_iconbutton_toolbar" onClick={doUpdatePrices} tooltip={trans('field.update_prices_for_plans')} />
            <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={() => setDlgIssue(true)} tooltip={trans('field.create_x','field.docs')} />
          </div>
        }
      />

      <CxDialog title={trans('field.create_x','field.docs')} open={dlgIssue} onOk={doIssue} onCancel={() => setDlgIssue(false)} height='350px'>

        <CxNumber xref={rf_year}
          label={`${trans('field.year')}`}
          decimals={0}
          required
          width='var(--ibs_control_width_md)'
          defaultValue={FxDat.getDateToday().split('-')[0]}
        />

        <CxNumber xref={rf_month}
          label={`${trans('field.month')}`}
          decimals={0}
          required
          width='var(--ibs_control_width_md)'
          defaultValue={FxDat.getDateToday().split('-')[1]}
        />

        <CxRadio xref={rf_doctype}
          list={[
            { id: '0', type: trans('field.issue_x','field.invoices') }, 
            { id: '1', type: trans('field.issue_x','field.receipts') },
          ]}
          required
          autoSelectFirst
          width='var(--ibs_control_width_md)'
        />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_op_in_progress} severity="info" value={trans('msg.operation_in_progress')} autoHideDuration={10000} />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxPlanList;
