import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxInput from '../../cx/CxInput';
import CxIconButton from '../../cx/CxIconButton';
import CxToolbar from '../../cx/CxToolbar';
import CxState from '../../cx/CxState';
import CxCombo from '../../cx/CxCombo';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreMarketing, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxMultiCheck from '../../cx/CxMultiCheck';
import CxSnackbar from '../../cx/CxSnackbar';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
}));

const MxMarketingFilter = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const [{ navTo }] = useNav();
  const rf_type = React.useRef(null);
  const rf_dtRegisteredFrom = React.useRef(null);
  const rf_dtRegisteredTo = React.useRef(null);
  const rf_dtBirthFrom = React.useRef(null);
  const rf_dtBirthTo = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txCity = React.useRef(null);
  const rf_txState = React.useRef(null);
  const rf_txPostalCode = React.useRef(null);
  const rf_txCountry = React.useRef(null);
  const rf_nuMailing = React.useRef(null);
  const rf_txLang = React.useRef(null);
  const rf_boDebtor = React.useRef(null);
  const rf_txTypeDoc = React.useRef(null);
  const rf_PssFq = React.useRef(null);
  /* OPTI + VETT + SPAW */
  const rf_dtPssFqFrom = React.useRef(null);
  const rf_dtPssFqTo = React.useRef(null);
  /* VETT + SPAW */
  const rf_boGroupByClient = React.useRef(null);
  const rf_boVaccLast = React.useRef(null);
  const rf_dtAnimalBirthFrom = React.useRef(null);
  const rf_dtAnimalBirthTo = React.useRef(null);
  const rf_txTypeAnimalSpecies = React.useRef(null);
  const rf_txTypeAnimalBreed = React.useRef(null);

  const [storeMarketing, setStoreMarketing] = useSessionStorage('StoreMarketing', StoreMarketing);

  let r_snack_error = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    rf_type.current.setVal(localStorage.getItem('MxMarketingFilter.marketingType'));
    rf_dtRegisteredFrom.current.setVal(storeMarketing.dtRegisteredFrom);
    rf_dtRegisteredTo.current.setVal(storeMarketing.dtRegisteredTo);
    rf_dtBirthFrom.current.setVal(storeMarketing.dtBirthFrom);
    rf_dtBirthTo.current.setVal(storeMarketing.dtBirthTo);
    rf_txClient.current.setKey(storeMarketing.inClient);
    rf_txClient.current.setVal(storeMarketing.txClient);
    rf_txBranch.current.setKey(storeMarketing.inBranch);
    rf_txBranch.current.setVal(storeMarketing.txBranch);
    rf_txCity.current.setVal(storeMarketing.txCity);
    rf_txState.current.setVal(storeMarketing.txState);
    rf_txPostalCode.current.setVal(storeMarketing.txPostalCode);
    rf_txCountry.current.setVal(storeMarketing.txCountry);
    rf_nuMailing.current.setVal(storeMarketing.nuMailing);
    rf_txLang.current.setKeyAndVal('t_client_lang', 'txTypeClientLang', 'txLang', storeMarketing.txLang);
    rf_boDebtor.current.setVal(storeMarketing.boDebtor);
    rf_txTypeDoc.current.setVal(storeMarketing.arDocsSigned);
    if (isApp('opti') || isApp('vett') || isApp('spaw')) {
      rf_dtPssFqFrom.current.setVal(storeMarketing.dtPssFqFrom);
      rf_dtPssFqTo.current.setVal(storeMarketing.dtPssFqTo);
      rf_PssFq.current.setVal(storeMarketing.arPssFq);
    }
    if (isApp('vett') || isApp('spaw')) {
      rf_boGroupByClient.current.setVal(storeMarketing.boGroupByClient);
      rf_dtAnimalBirthFrom.current.setVal(storeMarketing.dtAnimalBirthFrom);
      rf_dtAnimalBirthTo.current.setVal(storeMarketing.dtAnimalBirthTo);
      rf_txTypeAnimalSpecies.current.setVal(storeMarketing.txTypeAnimalSpecies);
      rf_txTypeAnimalBreed.current.setVal(storeMarketing.txTypeAnimalBreed);
    }
    if (isApp('vett')) {
      rf_boVaccLast.current.setVal(storeMarketing.boVaccLast);
    }
  }

  const goNext = async () => {
    localStorage.setItem('MxMarketingFilter.marketingType', rf_type.current.getVal());
    storeMarketing.marketingType = rf_type.current.getVal();
    storeMarketing.dtRegisteredFrom = rf_dtRegisteredFrom.current.getVal();
    storeMarketing.dtRegisteredTo = rf_dtRegisteredTo.current.getVal();
    storeMarketing.dtBirthFrom = rf_dtBirthFrom.current.getVal();
    storeMarketing.dtBirthTo = rf_dtBirthTo.current.getVal();
    storeMarketing.inClient = rf_txClient.current.getKey();
    storeMarketing.txClient = rf_txClient.current.getVal();
    storeMarketing.inBranch = rf_txBranch.current.getKey();
    storeMarketing.txBranch = rf_txBranch.current.getVal();
    storeMarketing.txCity = rf_txCity.current.getVal();
    storeMarketing.txState = rf_txState.current.getVal();
    storeMarketing.txPostalCode = rf_txPostalCode.current.getVal();
    storeMarketing.txCountry = rf_txCountry.current.getVal();
    storeMarketing.nuMailing = rf_nuMailing.current.getVal();
    storeMarketing.txLang = rf_txLang.current.getKey();
    storeMarketing.boDebtor = rf_boDebtor.current.getVal();
    storeMarketing.arDocsSigned = rf_txTypeDoc.current.getVal();
    if (isApp('opti') || isApp('vett') || isApp('spaw')) {
      storeMarketing.dtPssFqFrom = rf_dtPssFqFrom.current.getVal();
      storeMarketing.dtPssFqTo = rf_dtPssFqTo.current.getVal();
      storeMarketing.arPssFq = rf_PssFq.current.getVal();
    }
    if (isApp('vett') || isApp('spaw')) {
      storeMarketing.boGroupByClient = rf_boGroupByClient.current.getVal();
      storeMarketing.dtAnimalBirthFrom = rf_dtAnimalBirthFrom.current.getVal();
      storeMarketing.dtAnimalBirthTo = rf_dtAnimalBirthTo.current.getVal();
      storeMarketing.txTypeAnimalSpecies = rf_txTypeAnimalSpecies.current.getVal();
      storeMarketing.txTypeAnimalBreed = rf_txTypeAnimalBreed.current.getVal();
    }
    if (isApp('vett') ) {
      storeMarketing.boVaccLast = rf_boVaccLast.current.getVal();
    }
    setStoreMarketing(storeMarketing);

    navTo("marketing-list");
  }

  return (
    <div>
      <CxToolbar
        //help="marketing"
        moduleName={`${trans('field.marketing')}: ${trans('field.filter')}`}
        addToolbarButtons={
          <>
            <GxIconButtonLastClient theInput={rf_txClient} />
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </>
        }
      />

      <div className={classes.container}>

        <CxCombo xref={rf_type}
          label={`${trans('field.type')}`}
          list={[
            { id: 'E', type: trans('field.email') },
            { id: 'S', type: trans('field.sms') },
          ]}
          clearDisabled
          required
          autoSelectFirst
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
        />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} dbfield={`n_client.txClient`} dbfieldkey={`n_cash_item.inClient`}
          id="n_client"
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          refColors={['table_cell_color_red|' + trans('field.debtor')]}
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          readOnly={false}
        />

        <CxDate xref={rf_dtRegisteredFrom} label={trans('field.registered') +'-'+ trans('field.from')} />
        <CxDate xref={rf_dtRegisteredTo} label={trans('field.registered') +'-'+ trans('field.to')} />
        <CxDate xref={rf_dtBirthFrom} label={trans('field.birth') +'-'+ trans('field.from')} />
        <CxDate xref={rf_dtBirthTo} label={trans('field.birth') +'-'+ trans('field.to')} />

        <CxInput xref={rf_txCity} label={trans('field.city')} />
        <CxInput xref={rf_txState} label={trans('field.state')} />
        <CxInput xref={rf_txPostalCode} label={trans('field.postal_code')} />
        <CxInput xref={rf_txCountry} label={trans('field.country')} />

        <CxCombo xref={rf_nuMailing}
            label={`${trans('field.mailing')}`}
            list={[
              { id: '0', type: trans('field.no') },
              { id: '1', type: trans('field.yes') },
              { id: '2', type: trans('field.email') }, 
              { id: '3', type: trans('field.postal') }, 
              { id: '4', type: trans('field.sms') }, 
            ]}
        />

        <CxChooser xref={rf_txLang}
          label={trans('field.language')}
          table="t_client_lang"
          col_id="txLang"
          col_tx="txTypeClientLang"
          extra_filter_1={"boActive='1'"}
        />

        <CxState mode='checkbox' xref={rf_boDebtor} label={trans('field.debtor')} />
        {(isApp('vett') || isApp('spaw')) && <CxState mode='checkbox' xref={rf_boGroupByClient} label={trans('field.group_by') +' '+ trans('field.client')} />}

        <CxMultiCheck mode='checkbox' xref={rf_txTypeDoc} label={trans('field.docs_signed')} 
          minRows={4} 
          itemWidth="290px"
          tableName="t_doc" 
          idField="itDoc" 
          txField="txTypeDoc"
          fontSize="11px"
        />

        {/* OPTI + VETT + SPAW */}
        {(isApp('vett') || isApp('spaw')) && <CxDate xref={rf_dtAnimalBirthFrom} label={trans('field.patient') +'-'+ trans('field.birth') +'-'+ trans('field.from')} />}
        {(isApp('vett') || isApp('spaw')) && <CxDate xref={rf_dtAnimalBirthTo} label={trans('field.patient') +'-'+ trans('field.birth') +'-'+ trans('field.to')} />}
        {(isApp('vett') || isApp('spaw')) && <CxInput xref={rf_txTypeAnimalSpecies} label={trans('field.species')} lowerCase/>}
        {(isApp('vett') || isApp('spaw')) && <CxInput xref={rf_txTypeAnimalBreed} label={trans('field.breed')} lowerCase />}

        {(isApp('opti') || isApp('vett')) && <CxDate xref={rf_dtPssFqFrom} label={trans('field.' + (isApp('opti') ? 'repl' : 'vacc')) +'-'+ trans('field.from')} />}
        {(isApp('opti') || isApp('vett')) && <CxDate xref={rf_dtPssFqTo} label={trans('field.' + (isApp('opti') ? 'repl' : 'vacc')) +'-'+ trans('field.to')} />}

        {(isApp('opti') || isApp('vett')) && 
          <CxMultiCheck mode='checkbox' xref={rf_PssFq} label={trans('field.' + (isApp('opti') ? 'repls' : 'vaccs'))} 
            minRows={4} 
            itemWidth="290px"
            tableName="n_ps" 
            idField="inPs" 
            txField="txPs" 
            txExtraFilter1="nuFrequency>0 and boActive=1"
            fontSize="11px"
          />}
        {isApp('vett') && <CxState mode='checkbox' xref={rf_boVaccLast} label={trans('field.vacc_last')} />}

      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxMarketingFilter;
