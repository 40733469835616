import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettLabTech } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettLabTechForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  const rf_txLab = React.useRef(null);

  const [storeSettLabTech, setStoreSettLabTech] = useSessionStorage('StoreSettLabTech', StoreSettLabTech);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettLabTech.inLabTech = lastSaveRv.theValue;
      Promise.all([setStoreSettLabTech(storeSettLabTech)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.lab_tech')}`}
        xref={r_form}
        url="sett/labs/tech" 
        filterIdField='inLabTech'
        filterIdValue={storeSettLabTech.inLabTech} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="n_lab_tech.inLabTech" value={storeSettLabTech.inLabTech || ''} />

        <CxInput xref={useRef(null)} label={`${trans('field.lab_tech')}`} dbfield="n_lab_tech.txLabTech" required />
        
        <CxSearcher xref={rf_txLab} label={trans('field.lab')}
          dbfield="n_lab.txLab" dbfieldkey={`n_lab_tech.inLab`}
          url="labs"
          col_id="inLab"
          col_tx="txLab"
          columns={[
            { id: "0", Header: "ID", accessor: "inLab", show: false },
            { id: "1", Header: trans('field.lab'), accessor: row => rf_txLab.current.getTable().highlightText(row["txLab"]), width: '100%' },
          ]}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_lab_tech.txNotes" minRows={4} />
        
      </GxForm>

    </div>
  );

}

export default MxSettLabTechForm;
