import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettAnimalLocation } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettAnimalLocationList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);

  const [storeSettAnimalLocation, setStoreSettAnimalLocation] = useSessionStorage('StoreSettAnimalLocation', StoreSettAnimalLocation);

  React.useEffect(() => {
    clearStore('StoreSettAnimalLocation', storeSettAnimalLocation);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettAnimalLocation.itAnimalLocation = null;
    } else {
      storeSettAnimalLocation.itAnimalLocation = row.original.itAnimalLocation;
    }
    setStoreSettAnimalLocation(storeSettAnimalLocation);
    navTo("sett-animal-location-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.patients')}: ${trans('field.locations')}`}
        xref={r_list}
        tooltip={`${trans('field.location')}`}
        fullscreen
        url="sett/animal_location"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itAnimalLocation", show: false },
          { id: "3", Header: trans('field.branch_id'), accessor: "txBranchId", width: '20%' },
          { id: "2", Header: trans('field.type'), accessor: "txType", width: '20%' },
          { id: "1", Header: trans('field.location'), accessor: row => r_list.current.getTable().highlightText(row["txTypeAnimalLocation"]), width: '60%' },
        ]}
      />
    </div>
  );
}

export default MxSettAnimalLocationList;
