import { MdContentCopy } from "react-icons/md"; 
import { BiPrinter } from "react-icons/bi";
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
//import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreClient, StoreRxHaid, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDlgAlert from '../../cx/CxDlgAlert';
import GxSlsAuxTable from '../../gx/GxSlsAuxTable';
import CxStatic from '../../cx/CxStatic';
import MxProfOTF from '../common/otf/MxProfOTF';
import '../../../styles/TableFixed/TableFixedHaid.css';
import { useWindowSize } from '../../hx/useWindowSize';


const MxClientRxHaidForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_form = React.useRef(null);
  let r_sls_aux_table = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const rf_n_rx_haid_nuOdA0125 = React.useRef(null);
  const rf_n_rx_haid_nuOsA0125 = React.useRef(null);
  const rf_n_rx_haid_nuOdA0250 = React.useRef(null);
  const rf_n_rx_haid_nuOsA0250 = React.useRef(null);
  const rf_n_rx_haid_nuOdA0500 = React.useRef(null);
  const rf_n_rx_haid_nuOsA0500 = React.useRef(null);
  const rf_n_rx_haid_nuOdA0750 = React.useRef(null);
  const rf_n_rx_haid_nuOsA0750 = React.useRef(null);
  const rf_n_rx_haid_nuOdA1000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA1000 = React.useRef(null);
  const rf_n_rx_haid_nuOdA1500 = React.useRef(null);
  const rf_n_rx_haid_nuOsA1500 = React.useRef(null);
  const rf_n_rx_haid_nuOdA2000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA2000 = React.useRef(null);
  const rf_n_rx_haid_nuOdA3000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA3000 = React.useRef(null);
  const rf_n_rx_haid_nuOdA4000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA4000 = React.useRef(null);
  const rf_n_rx_haid_nuOdA6000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA6000 = React.useRef(null);
  const rf_n_rx_haid_nuOdA8000 = React.useRef(null);
  const rf_n_rx_haid_nuOsA8000 = React.useRef(null);

  const rf_n_rx_haid_nuOdB0125 = React.useRef(null);
  const rf_n_rx_haid_nuOsB0125 = React.useRef(null);
  const rf_n_rx_haid_nuOdB0250 = React.useRef(null);
  const rf_n_rx_haid_nuOsB0250 = React.useRef(null);
  const rf_n_rx_haid_nuOdB0500 = React.useRef(null);
  const rf_n_rx_haid_nuOsB0500 = React.useRef(null);
  const rf_n_rx_haid_nuOdB0750 = React.useRef(null);
  const rf_n_rx_haid_nuOsB0750 = React.useRef(null);
  const rf_n_rx_haid_nuOdB1000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB1000 = React.useRef(null);
  const rf_n_rx_haid_nuOdB1500 = React.useRef(null);
  const rf_n_rx_haid_nuOsB1500 = React.useRef(null);
  const rf_n_rx_haid_nuOdB2000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB2000 = React.useRef(null);
  const rf_n_rx_haid_nuOdB3000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB3000 = React.useRef(null);
  const rf_n_rx_haid_nuOdB4000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB4000 = React.useRef(null);
  const rf_n_rx_haid_nuOdB6000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB6000 = React.useRef(null);
  const rf_n_rx_haid_nuOdB8000 = React.useRef(null);
  const rf_n_rx_haid_nuOsB8000 = React.useRef(null);
  
  const rf_n_rx_haid_nuOdT0125 = React.useRef(null);
  const rf_n_rx_haid_nuOsT0125 = React.useRef(null);
  const rf_n_rx_haid_nuOdT0250 = React.useRef(null);
  const rf_n_rx_haid_nuOsT0250 = React.useRef(null);
  const rf_n_rx_haid_nuOdT0500 = React.useRef(null);
  const rf_n_rx_haid_nuOsT0500 = React.useRef(null);
  const rf_n_rx_haid_nuOdT0750 = React.useRef(null);
  const rf_n_rx_haid_nuOsT0750 = React.useRef(null);
  const rf_n_rx_haid_nuOdT1000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT1000 = React.useRef(null);
  const rf_n_rx_haid_nuOdT1500 = React.useRef(null);
  const rf_n_rx_haid_nuOsT1500 = React.useRef(null);
  const rf_n_rx_haid_nuOdT2000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT2000 = React.useRef(null);
  const rf_n_rx_haid_nuOdT3000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT3000 = React.useRef(null);
  const rf_n_rx_haid_nuOdT4000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT4000 = React.useRef(null);
  const rf_n_rx_haid_nuOdT6000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT6000 = React.useRef(null);
  const rf_n_rx_haid_nuOdT8000 = React.useRef(null);
  const rf_n_rx_haid_nuOsT8000 = React.useRef(null);

	const rf_n_rx_haid_txOdHypoacusis = React.useRef(null);
	const rf_n_rx_haid_txOsHypoacusis = React.useRef(null);
  const rf_n_rx_haid_nuOdG0125 = React.useRef(null);
  const rf_n_rx_haid_nuOsG0125 = React.useRef(null);
  const rf_n_rx_haid_nuOdG0250 = React.useRef(null);
  const rf_n_rx_haid_nuOsG0250 = React.useRef(null);
  const rf_n_rx_haid_nuOdG0500 = React.useRef(null);
  const rf_n_rx_haid_nuOsG0500 = React.useRef(null);
  const rf_n_rx_haid_nuOdG0750 = React.useRef(null);
  const rf_n_rx_haid_nuOsG0750 = React.useRef(null);
  const rf_n_rx_haid_nuOdG1000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG1000 = React.useRef(null);
  const rf_n_rx_haid_nuOdG1500 = React.useRef(null);
  const rf_n_rx_haid_nuOsG1500 = React.useRef(null);
  const rf_n_rx_haid_nuOdG2000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG2000 = React.useRef(null);
  const rf_n_rx_haid_nuOdG3000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG3000 = React.useRef(null);
  const rf_n_rx_haid_nuOdG4000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG4000 = React.useRef(null);
  const rf_n_rx_haid_nuOdG6000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG6000 = React.useRef(null);
  const rf_n_rx_haid_nuOdG8000 = React.useRef(null);
  const rf_n_rx_haid_nuOsG8000 = React.useRef(null);

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxHaid, setStoreRxHaid] = useSessionStorage('StoreRxHaid', StoreRxHaid);

  const r_dlg_alert = React.useRef(null);
  const r_dlg_new_prof = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_txReason = React.useRef(null);
  const rf_itStatusRx = React.useRef(null);
  const rf_txProf = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_itStatusRx.current.setKeyAndVal("t_status_rx", "txTypeStatusRx", "itStatusRx", "000001.SRV");
    } else {
    }
  }
  const formSave = async (lastSaveRv) => {
    storeRxHaid.inRxHaid = lastSaveRv.theValue;
    Promise.all([setStoreRxHaid(storeRxHaid)]).then((result) => {
      r_sls_aux_table.current.setTxLinkAuxId(storeRxHaid.inRxHaid);
      forceUIUpdate();
      //navBack(); // 2024-07-02 - daq: xq puede querer añadir ps.
    });
  }
  const formDelete = async (rv) => {
  }

  const doPrintX = async (option) => {
    let inClient = storeClient.inClient;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = inClient;
    filter['inRxHaid'] = storeRxHaid.inRxHaid;
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_haid/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const copyLast = () => {
    let record = {};
    record['inClient'] = storeClient.inClient;
    Promise.all([FxFetch.doJsonX('rx_haid/get_last', record)]).then((result) => {
      let inRxHaid = result[0].theValue;
      if (!inRxHaid) {
        r_snack_error.current.setValAndOpen(trans('msg.lack_of_previous_records'));
      } else {
        let record = {};
        record['idValue'] = inRxHaid;
        Promise.all([FxFetch.doJsonX("/rx_haid/r", record)]).then((result) => {
          if (result[0]) {
            r_form.current.formReload(result[0]);
            rf_date.current.setValToday();
            rf_time.current.setValNow();
            rf_txReason.current.clear();
          }
        });
      }
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.client')}: ${trans('field.rx_haid')}`}
        xref={r_form}
        url="rx_haid"
        filterIdField='inRxHaid'
        filterIdValue={storeRxHaid.inRxHaid}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeRxHaid.inRxHaid &&
              <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

            {/* botón de copiar último */}
            {!storeRxHaid.inRxHaid &&
              <CxIconButtonNew icon={<MdContentCopy fontSize="23px" />} classType="ibs_iconbutton_toolbar" onClick={copyLast} tooltip={trans('field.copy_last_1')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_rx_haid.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield="n_rx_haid.dtFrom" required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield="n_rx_haid.dtFrom" required />

        <CxInput xref={rf_txReason} label={trans('field.reason')} dbfield="n_rx_haid.txReason" maxLength={100} required />

        <CxChooser xref={rf_itStatusRx} label={trans('field.status')}
          dbfield="t_status_rx.txTypeStatusRx" dbfieldkey={`n_rx_haid.itStatusRx`}
          table="t_status_rx"
          col_id="itStatusRx"
          col_tx="txTypeStatusRx"
          required
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_rx_haid.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={rf_txProf} label={trans('field.treated_by')} dbfield="n_prof.txProf" dbfieldkey={`n_rx_haid.inProf`}
          onButtonAdd={() => {
            rf_txProf.current.setDlg(false);
            r_dlg_new_prof.current.setDlg(true);
          }}
          id="n_prof"
          table="n_prof"
          col_id="inProf"
          col_tx="txProf"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_rx_haid.txReference" maxLength={50} />

        
        {/* AÉREA */}
        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <span className="TableTitle">{trans('field.xx_au_air').toUpperCase()}</span>

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>125</th>
                <th>250</th>
                <th>500</th>
                <th>750</th>
                <th>1000</th>
                <th>1500</th>
                <th>2000</th>
                <th>3000</th>
                <th>4000</th>
                <th>6000</th>
                <th>8000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_ear_right_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0125} dbfield='n_rx_haid.nuOdA0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0250} dbfield='n_rx_haid.nuOdA0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0500} dbfield='n_rx_haid.nuOdA0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0750} dbfield='n_rx_haid.nuOdA0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA1000} dbfield='n_rx_haid.nuOdA1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA1500} dbfield='n_rx_haid.nuOdA1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA2000} dbfield='n_rx_haid.nuOdA2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA3000} dbfield='n_rx_haid.nuOdA3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA4000} dbfield='n_rx_haid.nuOdA4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA6000} dbfield='n_rx_haid.nuOdA6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA8000} dbfield='n_rx_haid.nuOdA8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_ear_left_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0125} dbfield='n_rx_haid.nuOsA0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0250} dbfield='n_rx_haid.nuOsA0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0500} dbfield='n_rx_haid.nuOsA0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0750} dbfield='n_rx_haid.nuOsA0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA1000} dbfield='n_rx_haid.nuOsA1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA1500} dbfield='n_rx_haid.nuOsA1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA2000} dbfield='n_rx_haid.nuOsA2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA3000} dbfield='n_rx_haid.nuOsA3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA4000} dbfield='n_rx_haid.nuOsA4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA6000} dbfield='n_rx_haid.nuOsA6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA8000} dbfield='n_rx_haid.nuOsA8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_ear_right_s').toUpperCase()}</th>
                <th>{trans('field.xx_ear_left_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>125</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0125} dbfield='n_rx_haid.nuOdA0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0125} dbfield='n_rx_haid.nuOsA0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>250</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0250} dbfield='n_rx_haid.nuOdA0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0250} dbfield='n_rx_haid.nuOsA0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0500} dbfield='n_rx_haid.nuOdA0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0500} dbfield='n_rx_haid.nuOsA0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>750</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA0750} dbfield='n_rx_haid.nuOdA0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA0750} dbfield='n_rx_haid.nuOsA0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA1000} dbfield='n_rx_haid.nuOdA1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA1000} dbfield='n_rx_haid.nuOsA1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA1500} dbfield='n_rx_haid.nuOdA1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA1500} dbfield='n_rx_haid.nuOsA1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>2000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA2000} dbfield='n_rx_haid.nuOdA2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA2000} dbfield='n_rx_haid.nuOsA2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>3000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA3000} dbfield='n_rx_haid.nuOdA3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA3000} dbfield='n_rx_haid.nuOsA3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>4000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA4000} dbfield='n_rx_haid.nuOdA4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA4000} dbfield='n_rx_haid.nuOsA4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>6000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA6000} dbfield='n_rx_haid.nuOdA6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA6000} dbfield='n_rx_haid.nuOsA6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>8000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdA8000} dbfield='n_rx_haid.nuOdA8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsA8000} dbfield='n_rx_haid.nuOsA8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }
        
        {/* ÓSEA */}
        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <span className="TableTitle">{trans('field.xx_au_bone').toUpperCase()}</span>

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>125</th>
                <th>250</th>
                <th>500</th>
                <th>750</th>
                <th>1000</th>
                <th>1500</th>
                <th>2000</th>
                <th>3000</th>
                <th>4000</th>
                <th>6000</th>
                <th>8000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_ear_right_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0125} dbfield='n_rx_haid.nuOdB0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0250} dbfield='n_rx_haid.nuOdB0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0500} dbfield='n_rx_haid.nuOdB0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0750} dbfield='n_rx_haid.nuOdB0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB1000} dbfield='n_rx_haid.nuOdB1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB1500} dbfield='n_rx_haid.nuOdB1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB2000} dbfield='n_rx_haid.nuOdB2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB3000} dbfield='n_rx_haid.nuOdB3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB4000} dbfield='n_rx_haid.nuOdB4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB6000} dbfield='n_rx_haid.nuOdB6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB8000} dbfield='n_rx_haid.nuOdB8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_ear_left_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0125} dbfield='n_rx_haid.nuOsB0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0250} dbfield='n_rx_haid.nuOsB0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0500} dbfield='n_rx_haid.nuOsB0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0750} dbfield='n_rx_haid.nuOsB0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB1000} dbfield='n_rx_haid.nuOsB1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB1500} dbfield='n_rx_haid.nuOsB1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB2000} dbfield='n_rx_haid.nuOsB2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB3000} dbfield='n_rx_haid.nuOsB3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB4000} dbfield='n_rx_haid.nuOsB4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB6000} dbfield='n_rx_haid.nuOsB6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB8000} dbfield='n_rx_haid.nuOsB8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_ear_right_s').toUpperCase()}</th>
                <th>{trans('field.xx_ear_left_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>125</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0125} dbfield='n_rx_haid.nuOdB0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0125} dbfield='n_rx_haid.nuOsB0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>250</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0250} dbfield='n_rx_haid.nuOdB0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0250} dbfield='n_rx_haid.nuOsB0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0500} dbfield='n_rx_haid.nuOdB0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0500} dbfield='n_rx_haid.nuOsB0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>750</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB0750} dbfield='n_rx_haid.nuOdB0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB0750} dbfield='n_rx_haid.nuOsB0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB1000} dbfield='n_rx_haid.nuOdB1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB1000} dbfield='n_rx_haid.nuOsB1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB1500} dbfield='n_rx_haid.nuOdB1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB1500} dbfield='n_rx_haid.nuOsB1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>2000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB2000} dbfield='n_rx_haid.nuOdB2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB2000} dbfield='n_rx_haid.nuOsB2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>3000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB3000} dbfield='n_rx_haid.nuOdB3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB3000} dbfield='n_rx_haid.nuOsB3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>4000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB4000} dbfield='n_rx_haid.nuOdB4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB4000} dbfield='n_rx_haid.nuOsB4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>6000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB6000} dbfield='n_rx_haid.nuOdB6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB6000} dbfield='n_rx_haid.nuOsB6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>8000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdB8000} dbfield='n_rx_haid.nuOdB8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsB8000} dbfield='n_rx_haid.nuOsB8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }
        
        {/* UMBRAL */}
        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <span className="TableTitle">{trans('field.xx_au_threshold').toUpperCase()}</span>

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>125</th>
                <th>250</th>
                <th>500</th>
                <th>750</th>
                <th>1000</th>
                <th>1500</th>
                <th>2000</th>
                <th>3000</th>
                <th>4000</th>
                <th>6000</th>
                <th>8000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_ear_right_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0125} dbfield='n_rx_haid.nuOdT0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0250} dbfield='n_rx_haid.nuOdT0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0500} dbfield='n_rx_haid.nuOdT0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0750} dbfield='n_rx_haid.nuOdT0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT1000} dbfield='n_rx_haid.nuOdT1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT1500} dbfield='n_rx_haid.nuOdT1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT2000} dbfield='n_rx_haid.nuOdT2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT3000} dbfield='n_rx_haid.nuOdT3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT4000} dbfield='n_rx_haid.nuOdT4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT6000} dbfield='n_rx_haid.nuOdT6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT8000} dbfield='n_rx_haid.nuOdT8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_ear_left_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0125} dbfield='n_rx_haid.nuOsT0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0250} dbfield='n_rx_haid.nuOsT0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0500} dbfield='n_rx_haid.nuOsT0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0750} dbfield='n_rx_haid.nuOsT0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT1000} dbfield='n_rx_haid.nuOsT1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT1500} dbfield='n_rx_haid.nuOsT1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT2000} dbfield='n_rx_haid.nuOsT2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT3000} dbfield='n_rx_haid.nuOsT3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT4000} dbfield='n_rx_haid.nuOsT4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT6000} dbfield='n_rx_haid.nuOsT6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT8000} dbfield='n_rx_haid.nuOsT8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_ear_right_s').toUpperCase()}</th>
                <th>{trans('field.xx_ear_left_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>125</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0125} dbfield='n_rx_haid.nuOdT0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0125} dbfield='n_rx_haid.nuOsT0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>250</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0250} dbfield='n_rx_haid.nuOdT0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0250} dbfield='n_rx_haid.nuOsT0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0500} dbfield='n_rx_haid.nuOdT0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0500} dbfield='n_rx_haid.nuOsT0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>750</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT0750} dbfield='n_rx_haid.nuOdT0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT0750} dbfield='n_rx_haid.nuOsT0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT1000} dbfield='n_rx_haid.nuOdT1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT1000} dbfield='n_rx_haid.nuOsT1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT1500} dbfield='n_rx_haid.nuOdT1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT1500} dbfield='n_rx_haid.nuOsT1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>2000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT2000} dbfield='n_rx_haid.nuOdT2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT2000} dbfield='n_rx_haid.nuOsT2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>3000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT3000} dbfield='n_rx_haid.nuOdT3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT3000} dbfield='n_rx_haid.nuOsT3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>4000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT4000} dbfield='n_rx_haid.nuOdT4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT4000} dbfield='n_rx_haid.nuOsT4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>6000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT6000} dbfield='n_rx_haid.nuOdT6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT6000} dbfield='n_rx_haid.nuOsT6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>8000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdT8000} dbfield='n_rx_haid.nuOdT8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsT8000} dbfield='n_rx_haid.nuOsT8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }
        
        {/* HIPOACUSIA */}
        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <span className="TableTitle">{trans('field.xx_au_hypoacusis').toUpperCase()}</span>

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_ear_right_s').toUpperCase()}</td>
                <td><CxInput xref={rf_n_rx_haid_txOdHypoacusis} maxLength={100} dbfield='n_rx_haid.txOdHypoacusis' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_ear_left_s').toUpperCase()}</td>
                <td><CxInput xref={rf_n_rx_haid_txOsHypoacusis} maxLength={100} dbfield='n_rx_haid.txOsHypoacusis' framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixedHaid2">
            <thead>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_ear_right_s').toUpperCase()}</th>
                <th>{trans('field.xx_ear_left_s').toUpperCase()}</th>
              </tr>
              <tr>
                <td>
                  <CxInput xref={rf_n_rx_haid_txOdHypoacusis} maxLength={100} dbfield="n_rx_haid.txOdHypoacusis" framesimple="true" hidebuttons="true" width="100%" autoselect="true" />
                </td>
                <td>
                  <CxInput xref={rf_n_rx_haid_txOsHypoacusis} maxLength={100} dbfield="n_rx_haid.txOsHypoacusis" framesimple="true" hidebuttons="true" width="100%" autoselect="true" />
                </td>
              </tr>
            </tbody>
          </table>
        }
        
        {/* GANANCIA */}
        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <span className="TableTitle">{trans('field.xx_au_gain').toUpperCase()}</span>

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>125</th>
                <th>250</th>
                <th>500</th>
                <th>750</th>
                <th>1000</th>
                <th>1500</th>
                <th>2000</th>
                <th>3000</th>
                <th>4000</th>
                <th>6000</th>
                <th>8000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_ear_right_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0125} dbfield='n_rx_haid.nuOdG0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0250} dbfield='n_rx_haid.nuOdG0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0500} dbfield='n_rx_haid.nuOdG0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0750} dbfield='n_rx_haid.nuOdG0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG1000} dbfield='n_rx_haid.nuOdG1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG1500} dbfield='n_rx_haid.nuOdG1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG2000} dbfield='n_rx_haid.nuOdG2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG3000} dbfield='n_rx_haid.nuOdG3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG4000} dbfield='n_rx_haid.nuOdG4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG6000} dbfield='n_rx_haid.nuOdG6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG8000} dbfield='n_rx_haid.nuOdG8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_ear_left_s').toUpperCase()}</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0125} dbfield='n_rx_haid.nuOsG0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0250} dbfield='n_rx_haid.nuOsG0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0500} dbfield='n_rx_haid.nuOsG0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0750} dbfield='n_rx_haid.nuOsG0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG1000} dbfield='n_rx_haid.nuOsG1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG1500} dbfield='n_rx_haid.nuOsG1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG2000} dbfield='n_rx_haid.nuOsG2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG3000} dbfield='n_rx_haid.nuOsG3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG4000} dbfield='n_rx_haid.nuOsG4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG6000} dbfield='n_rx_haid.nuOsG6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG8000} dbfield='n_rx_haid.nuOsG8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixedHaid">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_ear_right_s').toUpperCase()}</th>
                <th>{trans('field.xx_ear_left_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>125</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0125} dbfield='n_rx_haid.nuOdG0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0125} dbfield='n_rx_haid.nuOsG0125' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>250</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0250} dbfield='n_rx_haid.nuOdG0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0250} dbfield='n_rx_haid.nuOsG0250' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0500} dbfield='n_rx_haid.nuOdG0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0500} dbfield='n_rx_haid.nuOsG0500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>750</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG0750} dbfield='n_rx_haid.nuOdG0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG0750} dbfield='n_rx_haid.nuOsG0750' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG1000} dbfield='n_rx_haid.nuOdG1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG1000} dbfield='n_rx_haid.nuOsG1000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>1500</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG1500} dbfield='n_rx_haid.nuOdG1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG1500} dbfield='n_rx_haid.nuOsG1500' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>2000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG2000} dbfield='n_rx_haid.nuOdG2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG2000} dbfield='n_rx_haid.nuOsG2000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>3000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG3000} dbfield='n_rx_haid.nuOdG3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG3000} dbfield='n_rx_haid.nuOsG3000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>4000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG4000} dbfield='n_rx_haid.nuOdG4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG4000} dbfield='n_rx_haid.nuOsG4000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>6000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG6000} dbfield='n_rx_haid.nuOdG6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG6000} dbfield='n_rx_haid.nuOsG6000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
              <tr>
                <td>8000</td>
                <td><CxNumber xref={rf_n_rx_haid_nuOdG8000} dbfield='n_rx_haid.nuOdG8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
                <td><CxNumber xref={rf_n_rx_haid_nuOsG8000} dbfield='n_rx_haid.nuOsG8000' decimals={0} defaultValue="0" framesimple="true" hidebuttons="true" width='100%' autoselect="true" /></td>
              </tr>
            </tbody>
          </table>
        }


        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_rx_haid.txNotes" minRows={4} speech />

      </GxForm>

      <GxSlsAuxTable xref={r_sls_aux_table} txLinkAuxTable={'n_rx_haid'} txLinkAuxId={storeRxHaid.inRxHaid} /* rf_txEmployee={rf_txEmployee} */ />

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <MxProfOTF xref={r_dlg_new_prof} onOk={(inProf, txProf) => {
        rf_txProf.current.setKey(inProf);
        rf_txProf.current.setVal(txProf);
      }} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxClientRxHaidForm;
