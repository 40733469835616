import React, { useRef } from 'react';
import GxPrint from '../../gx/GxPrint';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxSearcher from '../../cx/CxSearcher';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxSnackbar from '../../cx/CxSnackbar';
import CxLink from '../../cx/CxLink';
import * as FxLog from '../../fx/FxLog';
import { StoreClient, StoreLabOrder } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import * as FxTable from '../../fx/FxTable';
import CxIconButton from '../../cx/CxIconButton';
import CxStatic from '../../cx/CxStatic';
import MxLabTechOTF from '../common/otf/MxLabTechOTF';
import '../../../styles/TableFixed/TableFixed.css';
import { useWindowSize } from '../../hx/useWindowSize';


const MxClientLabOrderForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  let r_print = React.useRef(null);

  const r_dlg_alert = React.useRef(null);
  const r_dlg_new_lab_tech = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);

  const [storeClient,] = useSessionStorage('StoreClient', StoreClient);
  const [storeLabOrder, setStoreLabOrder] = useSessionStorage('StoreLabOrder', StoreLabOrder);

  const [isRecalc, setRecalc] = React.useState(false);

  let r_form = React.useRef(null);

  const rf_itStatusLabOrder = React.useRef(null);
  
  const rf_from = React.useRef(null);
  const rf_to = React.useRef(null);
  const rf_txLab = React.useRef(null);
  const rf_inRxGlas = React.useRef(null);
  const rf_txLabTech = React.useRef(null);

  const rf_n_rx_glas_nuOdSph = React.useRef(null);
  const rf_n_rx_glas_nuOsSph = React.useRef(null);
  const rf_n_rx_glas_nuOdCyl = useRef(null);
  const rf_n_rx_glas_nuOdAxis = useRef(null);
  const rf_n_rx_glas_nuOdAddNe = useRef(null);
  const rf_nuOdSphN = useRef(null);
  const rf_n_rx_glas_nuOdAddIn = useRef(null);
  const rf_nuOdSphI = useRef(null);
  const rf_n_rx_glas_nuOsCyl = useRef(null);
  const rf_n_rx_glas_nuOsAxis = useRef(null);
  const rf_n_rx_glas_nuOsAddNe = useRef(null);
  const rf_nuOsSphN = useRef(null);
  const rf_n_rx_glas_nuOsAddIn = useRef(null);
  const rf_nuOsSphI = useRef(null);
  const rf_n_rx_glas_txOdVaSc = useRef(null);
  const rf_n_rx_glas_txOdVaCc = useRef(null);
  const rf_n_rx_glas_nuOdPdDi = useRef(null);
  const rf_n_rx_glas_nuOdPdNe = useRef(null);
  const rf_n_rx_glas_nuOdIop = useRef(null);
  const rf_n_rx_glas_txOdPrism = useRef(null);
  const rf_n_rx_glas_txOsVaSc = useRef(null);
  const rf_n_rx_glas_txOsVaCc = useRef(null);
  const rf_n_rx_glas_nuOsPdDi = useRef(null);
  const rf_n_rx_glas_nuOsPdNe = useRef(null);
  const rf_n_rx_glas_nuOsIop = useRef(null);
  const rf_n_rx_glas_txOsPrism = useRef(null);
  const rf_n_rx_glas_txOuVa = useRef(null);
  const rf_nuOuPdDi = useRef(null);
  const rf_nuOuPdNe = useRef(null);
  
  
  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_from.current.setValToday();
      rxGlasCleared();
      rf_itStatusLabOrder.current.setKeyAndVal("t_status_lab_order", "txTypeStatusLabOrder", "itStatusLabOrder", "000001.SRV");
    } else {
      calcSphN();
      calcSphI();
      calcOu();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeLabOrder.inLabOrder = lastSaveRv.theValue;
    Promise.all([setStoreLabOrder(storeLabOrder)]).then((result) => {
      forceUIUpdate();
    });
  }
  const formDelete = async (rv) => {
  }

  React.useEffect(() => {
    setRecalc(false);
    calcSphN();
    calcSphI();

  }, [isRecalc]);

  const doTranspose = () => {
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;
    let _nuOdCyl = rf_n_rx_glas_nuOdCyl.current;
    let _nuOdAxis = rf_n_rx_glas_nuOdAxis.current;

    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;
    let _nuOsCyl = rf_n_rx_glas_nuOsCyl.current;
    let _nuOsAxis = rf_n_rx_glas_nuOsAxis.current;

    //Od
    _nuOdSphD.setFloatVal(transpose('SPH', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdCyl.setFloatVal(transpose('CYL', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    _nuOdAxis.setFloatVal(transpose('AXIS', _nuOdSphD.getFloatVal(), _nuOdCyl.getFloatVal(), _nuOdAxis.getFloatVal()));
    //Os
    _nuOsSphD.setFloatVal(transpose('SPH', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsCyl.setFloatVal(transpose('CYL', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));
    _nuOsAxis.setFloatVal(transpose('AXIS', _nuOsSphD.getFloatVal(), _nuOsCyl.getFloatVal(), _nuOsAxis.getFloatVal()));

    //recálculos
    setRecalc(true);
  }

  const transpose = (tr, sph, cyl, axis) => {
    let rv = 0.0;
    try {
      let nuSph = (sph);
      let nuCyl = (cyl);
      let nuAxis = (axis);

      nuCyl = nuCyl * -1;
      nuSph = nuSph - nuCyl;
      if (nuAxis > 90) {
        nuAxis = nuAxis - 90;
      } else {
        nuAxis = nuAxis + 90;
      }
      switch (tr) {
        case 'SPH':
          rv = nuSph;
          break;
        case 'CYL':
          rv = nuCyl;
          break;
        case 'AXIS':
          rv = nuAxis;
          break;
        default:
          rv = '0.0';
          break;
      }

    } catch (error) {
      FxLog.errorx("MxClientLabOrderForm.transpose", error);
    }
    return rv;
  }

  const calcSphN = () => {
    let _nuOdAddNe = rf_n_rx_glas_nuOdAddNe.current;
    let _nuOdSphN = rf_nuOdSphN.current;
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;

    let _nuOsAddNe = rf_n_rx_glas_nuOsAddNe.current;
    let _nuOsSphN = rf_nuOsSphN.current;
    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;

    if (_nuOdAddNe.getFloatVal() === 0.0) {
      _nuOdSphN.clear();
    } else {
      _nuOdSphN.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddNe.getFloatVal());
    }
    if (_nuOsAddNe.getFloatVal() === 0.0) {
      _nuOsSphN.clear();
    } else {
      _nuOsSphN.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddNe.getFloatVal());
    }
  }

  const calcSphI = () => {
    let _nuOdAddIn = rf_n_rx_glas_nuOdAddIn.current;
    let _nuOdSphD = rf_n_rx_glas_nuOdSph.current;
    let _nuOdSphI = rf_nuOdSphI.current;

    let _nuOsAddIn = rf_n_rx_glas_nuOsAddIn.current;
    let _nuOsSphD = rf_n_rx_glas_nuOsSph.current;
    let _nuOsSphI = rf_nuOsSphI.current;

    if (_nuOdAddIn.getFloatVal() === 0.0) {
      _nuOdSphI.clear();
    } else {
      _nuOdSphI.setFloatVal(_nuOdSphD.getFloatVal() + _nuOdAddIn.getFloatVal());
    }
    if (_nuOsAddIn.getFloatVal() === 0.0) {
      _nuOsSphI.clear();
    } else {
      _nuOsSphI.setFloatVal(_nuOsSphD.getFloatVal() + _nuOsAddIn.getFloatVal());
    }
  }

  const calcOu = () => {
    let _nuOuPdDi = rf_nuOuPdDi.current;
    let _nuOuPdNe = rf_nuOuPdNe.current;
    let _nuOdPdDi = rf_n_rx_glas_nuOdPdDi.current;
    let _nuOsPdDi = rf_n_rx_glas_nuOsPdDi.current;
    let _nuOdPdNe = rf_n_rx_glas_nuOdPdNe.current;
    let _nuOsPdNe = rf_n_rx_glas_nuOsPdNe.current;

    _nuOuPdDi.setFloatVal(_nuOdPdDi.getFloatVal() + _nuOsPdDi.getFloatVal());
    _nuOuPdNe.setFloatVal(_nuOdPdNe.getFloatVal() + _nuOsPdNe.getFloatVal());
  }

  const rxGlasCleared = () => {
    // 2023-01-05 - daq: limpia datos de graduación.
    rf_n_rx_glas_nuOdSph.current.clear();
    rf_n_rx_glas_nuOdCyl.current.clear();
    rf_n_rx_glas_nuOdAxis.current.clear();
    rf_n_rx_glas_nuOdAddNe.current.clear();
    rf_nuOdSphN.current.clear();
    rf_n_rx_glas_nuOdAddIn.current.clear();
    rf_nuOdSphI.current.clear();

    rf_n_rx_glas_nuOsSph.current.clear();
    rf_n_rx_glas_nuOsCyl.current.clear();
    rf_n_rx_glas_nuOsAxis.current.clear();
    rf_n_rx_glas_nuOsAddNe.current.clear();
    rf_nuOsSphN.current.clear();
    rf_n_rx_glas_nuOsAddIn.current.clear();
    rf_nuOsSphI.current.clear();

    rf_n_rx_glas_txOdVaSc.current.clear();
    rf_n_rx_glas_txOdVaCc.current.clear();
    rf_n_rx_glas_nuOdPdDi.current.clear();
    rf_n_rx_glas_nuOdPdNe.current.clear();
    rf_n_rx_glas_nuOdIop.current.clear();
    rf_n_rx_glas_txOdPrism.current.clear();

    rf_n_rx_glas_txOsVaSc.current.clear();
    rf_n_rx_glas_txOsVaCc.current.clear();
    rf_n_rx_glas_nuOsPdDi.current.clear();
    rf_n_rx_glas_nuOsPdNe.current.clear();
    rf_n_rx_glas_nuOsIop.current.clear();
    rf_n_rx_glas_txOsPrism.current.clear();

    rf_n_rx_glas_txOuVa.current.clear();
    rf_nuOuPdDi.current.clear();
    rf_nuOuPdNe.current.clear();
  }

  const rxGlasSelected = (row) => {
    // 2023-01-05 - daq: completa datos de graduación.
    rf_n_rx_glas_nuOdSph.current.setFloatVal(row.original.nuOdSph);
    rf_n_rx_glas_nuOdCyl.current.setFloatVal(row.original.nuOdCyl);
    rf_n_rx_glas_nuOdAxis.current.setFloatVal(row.original.nuOdAxis);
    rf_n_rx_glas_nuOdAddNe.current.setFloatVal(row.original.nuOdAddNe);
    rf_n_rx_glas_nuOdAddIn.current.setFloatVal(row.original.nuOdAddIn);

    rf_n_rx_glas_nuOsSph.current.setFloatVal(row.original.nuOsSph);
    rf_n_rx_glas_nuOsCyl.current.setFloatVal(row.original.nuOsCyl);
    rf_n_rx_glas_nuOsAxis.current.setFloatVal(row.original.nuOsAxis);
    rf_n_rx_glas_nuOsAddNe.current.setFloatVal(row.original.nuOsAddNe);
    rf_n_rx_glas_nuOsAddIn.current.setFloatVal(row.original.nuOsAddIn);

    rf_n_rx_glas_txOdVaSc.current.setVal(row.original.txOdVaSc);
    rf_n_rx_glas_txOdVaCc.current.setVal(row.original.txOdVaCc);
    rf_n_rx_glas_nuOdPdDi.current.setFloatVal(row.original.nuOdPdDi);
    rf_n_rx_glas_nuOdPdNe.current.setFloatVal(row.original.nuOdPdNe);
    rf_n_rx_glas_nuOdIop.current.setFloatVal(row.original.nuOdIop);
    rf_n_rx_glas_txOdPrism.current.setVal(row.original.txOdPrism);

    rf_n_rx_glas_txOsVaSc.current.setVal(row.original.txOsVaSc);
    rf_n_rx_glas_txOsVaCc.current.setVal(row.original.txOsVaCc);
    rf_n_rx_glas_nuOsPdDi.current.setFloatVal(row.original.nuOsPdDi);
    rf_n_rx_glas_nuOsPdNe.current.setFloatVal(row.original.nuOsPdNe);
    rf_n_rx_glas_nuOsIop.current.setFloatVal(row.original.nuOsIop);
    rf_n_rx_glas_txOsPrism.current.setVal(row.original.txOsPrism);

    rf_n_rx_glas_txOuVa.current.setVal(row.original.txOuVa);

    setRecalc(false);
    calcSphN();
    calcSphI();
    calcOu();
  }

  const doPrintX = async (option) => {
    let inLab = rf_txLab.current.getKey();
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inLabOrder'] = `${storeLabOrder.inLabOrder}`;
    filter['inRxGlas'] = rf_inRxGlas.current.getKey();
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('lab_order/report');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInLab(inLab)
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.client')}: ${trans('field.lab_order')}`}
        xref={r_form}
        url="lab_order"
        filterIdField='inLabOrder'
        filterIdValue={storeLabOrder.inLabOrder}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {storeLabOrder.inLabOrder
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inLabOrder" value={storeLabOrder.inLabOrder || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inRxGlas" value={storeLabOrder.inRxGlas || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_lab_order.inClient" value={storeClient.inClient || ''} />

        <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_from} label={trans('field.from')} dbfield="n_lab_order.dtFrom" required />

        <CxSearcher xref={rf_txLab} label={trans('field.lab')}
          dbfield="n_lab.txLab" dbfieldkey={`n_lab_order.inLab`}
          url="labs"
          col_id="inLab"
          col_tx="txLab"
          columns={[
            { id: "0", Header: "ID", accessor: "inLab", show: false },
            { id: "1", Header: trans('field.lab'), accessor: row => rf_txLab.current.getTable().highlightText(row["txLab"]), width: '100%' },
          ]}
          required
        />

        <CxSearcher xref={rf_inRxGlas} label={trans('field.rx_glas')}
          dbfield="txReasonRxGlas" dbfieldkey={`n_lab_order.inRxGlas`}
          url="lab_order/rx_glas"
          tooltip={`${trans('field.reason')}`}
          filter={`inClient='${storeClient.inClient}'`}
          col_id="inRxGlas"
          col_tx="txReason"
          onClear={rxGlasCleared}
          onSelection={rxGlasSelected}
          columns={[
            { id: "0", Header: "ID", accessor: "inRxGlas", show: false },
            { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtFrom"]), width: '15%' },
            { id: "2", Header: trans('field.reason'), accessor: row => rf_inRxGlas.current.getTable().highlightText(row["txReason"]), width: '75%' },
          ]}
          required
        />

        <CxChooser xref={rf_itStatusLabOrder} label={trans('field.status')}
          dbfield="t_status_lab_order.txTypeStatusLabOrder" dbfieldkey={`n_lab_order.itStatusLabOrder`}
          table="t_status_lab_order"
          col_id="itStatusLabOrder"
          col_tx="txTypeStatusLabOrder"
          required
        />

        <CxDate xref={rf_to} label={trans('field.to')} dbfield="n_lab_order.dtTo" />

        <CxInput xref={useRef(null)} label={trans('field.reason')} dbfield="n_lab_order.txReason" maxLength={100} />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_lab_order.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxChooser xref={rf_txLabTech} label={trans('field.lab_tech')} dbfield="n_lab_tech.txLabTech" dbfieldkey={`n_lab_order.inLabTech`}
          onButtonAdd={() => {
            rf_txLabTech.current.setDlg(false);
            r_dlg_new_lab_tech.current.setDlg(true);
          }}
          id="n_lab_tech"
          table="n_lab_tech"
          col_id="inLabTech"
          col_tx="txLabTech"
        />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_lab_order.txReference" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.special_instruction')} dbfield="n_lab_order.txSpecialInstruction" maxLength={100} />

        <span style={{ width: '100%'}} />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_sph_dist_s')}</th>
                <th>{trans('field.xx_cyl_s')}</th>
                <th>{trans('field.xx_axis_s')}</th>
                <th>{trans('field.xx_add_near_s')}</th>
                <th>{trans('field.xx_sph_near_s')}</th>
                <th>{trans('field.xx_add_inter_s')}</th>
                <th>{trans('field.xx_sph_inter_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdSph} dbfield='n_rx_glas.nuOdSph' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdCyl} dbfield='n_rx_glas.nuOdCyl' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAxis} dbfield='n_rx_glas.nuOdAxis' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddNe} dbfield='n_rx_glas.nuOdAddNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOdSphN} dbfield='_nuOdSphN' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddIn} dbfield='n_rx_glas.nuOdAddIn' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOdSphI} dbfield='_nuOdSphI' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsSph} dbfield='n_rx_glas.nuOsSph' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsCyl} dbfield='n_rx_glas.nuOsCyl' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAxis} dbfield='n_rx_glas.nuOsAxis' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddNe} dbfield='n_rx_glas.nuOsAddNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOsSphN} dbfield='_nuOsSphN' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddIn} dbfield='n_rx_glas.nuOsAddIn' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOsSphI} dbfield='_nuOsSphI' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_sph_dist_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdSph} dbfield='n_rx_glas.nuOdSph' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsSph} dbfield='n_rx_glas.nuOsSph' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcSphI(); }} disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_cyl_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdCyl} dbfield='n_rx_glas.nuOdCyl' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsCyl} dbfield='n_rx_glas.nuOsCyl' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_axis_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAxis} dbfield='n_rx_glas.nuOdAxis' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAxis} dbfield='n_rx_glas.nuOsAxis' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_near_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddNe} dbfield='n_rx_glas.nuOdAddNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddNe} dbfield='n_rx_glas.nuOsAddNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphN(); calcOu(); }} disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_near_s')}</th>
                <td><CxNumber xref={rf_nuOdSphN} dbfield='_nuOdSphN' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_nuOsSphN} dbfield='_nuOsSphN' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_add_inter_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdAddIn} dbfield='n_rx_glas.nuOdAddIn' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsAddIn} dbfield='n_rx_glas.nuOsAddIn' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcSphI(); calcOu(); }} disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_sph_inter_s')}</th>
                <td><CxNumber xref={rf_nuOdSphI} dbfield='_nuOdSphI' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_nuOsSphI} dbfield='_nuOsSphI' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <CxLink label={`[${trans('field.xx_transpose').toUpperCase()}]`} width='120px' style={{ margin: '0 0 0 5px', fontSize: '12px' }}  onClick={doTranspose} />
        <br /><br /><br />

        {/* DESKTOP */}
        {size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_vasc_s')}</th>
                <th>{trans('field.xx_vacc_s')}</th>
                <th>{trans('field.xx_pd_dist_s')}</th>
                <th>{trans('field.xx_pd_near_s')}</th>
                <th>{trans('field.xx_iop_s')}</th>
                <th>{trans('field.xx_prism_s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trans('field.xx_eye_right_s')}</td>
                <td><CxInput xref={rf_n_rx_glas_txOdVaSc} maxLength={10} dbfield='n_rx_glas.txOdVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOdVaCc} maxLength={10} dbfield='n_rx_glas.txOdVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdDi} dbfield='n_rx_glas.nuOdPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdNe} dbfield='n_rx_glas.nuOdPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOdIop} dbfield='n_rx_glas.nuOdIop' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOdPrism} maxLength={16} dbfield='n_rx_glas.txOdPrism' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_left_s')}</td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaSc} maxLength={10} dbfield='n_rx_glas.txOsVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaCc} maxLength={10} dbfield='n_rx_glas.txOsVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdDi} dbfield='n_rx_glas.nuOsPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdNe} dbfield='n_rx_glas.nuOsPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsIop} dbfield='n_rx_glas.nuOsIop' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsPrism} maxLength={16} dbfield='n_rx_glas.txOsPrism' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <td>{trans('field.xx_eye_both_s').toUpperCase()}</td>
                <td colSpan="2"><CxInput xref={rf_n_rx_glas_txOuVa} maxLength={10} dbfield='n_rx_glas.txOuVa' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_nuOuPdDi} dbfield='_nuOuPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_nuOuPdNe} dbfield='_nuOuPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
            </tbody>
          </table>
        }

        {/* MOBILE */}
        {!size.isLarge &&
          <table className="TableFixed">
            <thead>
              <tr>
                <th></th>
                <th>{trans('field.xx_eye_right_s')}</th>
                <th>{trans('field.xx_eye_left_s')}</th>
                <th>{trans('field.xx_eye_both_s').toUpperCase()}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('field.xx_vasc_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdVaSc} maxLength={10} dbfield='n_rx_glas.txOdVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaSc} maxLength={10} dbfield='n_rx_glas.txOsVaSc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td rowSpan="2" style={{verticalAlign: 'top'}}><CxInput xref={rf_n_rx_glas_txOuVa} maxLength={10} dbfield='n_rx_glas.txOuVa' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_vacc_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdVaCc} maxLength={10} dbfield='n_rx_glas.txOdVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsVaCc} maxLength={10} dbfield='n_rx_glas.txOsVaCc' framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_pd_dist_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdDi} dbfield='n_rx_glas.nuOdPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdDi} dbfield='n_rx_glas.nuOsPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOuPdDi} dbfield='_nuOuPdDi' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_pd_near_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdPdNe} dbfield='n_rx_glas.nuOdPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsPdNe} dbfield='n_rx_glas.nuOsPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" onBlur={() => { calcOu(); }} disabled /></td>
                <td><CxNumber xref={rf_nuOuPdNe} dbfield='_nuOuPdNe' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
              </tr>
              <tr>
                <th>{trans('field.xx_iop_s')}</th>
                <td><CxNumber xref={rf_n_rx_glas_nuOdIop} dbfield='n_rx_glas.nuOdIop' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxNumber xref={rf_n_rx_glas_nuOsIop} dbfield='n_rx_glas.nuOsIop' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td></td>
              </tr>
              <tr>
                <th>{trans('field.xx_prism_s')}</th>
                <td><CxInput xref={rf_n_rx_glas_txOdPrism} maxLength={16} dbfield='n_rx_glas.txOdPrism' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td><CxInput xref={rf_n_rx_glas_txOsPrism} maxLength={16} dbfield='n_rx_glas.txOsPrism' decimals={2} decimalsfixed="true" framesimple="true" hidebuttons="true" width='100%' autoselect="true" disabled /></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        }

        {/*------------------------------------------------------------------------------------------------------------------------*/}
        <br />
        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_lab_order.txNotes" minRows={4} speech />

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />
      <GxPrint xref={r_print} />

      <MxLabTechOTF xref={r_dlg_new_lab_tech} onOk={(inProf, txProf) => {
        rf_txLabTech.current.setKey(inProf);
        rf_txLabTech.current.setVal(txProf);
      }} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />

    </div>
  );

}

export default MxClientLabOrderForm;
