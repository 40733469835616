import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { useStyles } from "./StylesCx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";
import { IoRadioButtonOn } from "react-icons/io5";
import CxTooltip from './CxTooltip';


const useStylesLocal = makeStyles({
  ctl_label: {
    padding: '3px 0 0 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 0px',
    cursor: 'default',
    textAlign: 'left',
    //color: 'var(--ibs_color_gray)',
    "& .MuiTypography-body1": {
      fontSize: 'var(--ibs_control_label_font_size_radio)',
    },
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },
});

const CxState = props => {
  const classes = useStyles(props);
  const classesL = useStylesLocal(props);
  const [myValue, setMyValue] = React.useState('0');
  const [labelColor, setLabelColor] = React.useState('var(--ibs_color_gray)');
  const [isRequired, setIsRequired] = React.useState(false);
  const [wasChanged, setChanged] = React.useState(false);

  const rf_label = React.useRef('');

  React.useEffect(() => {
    setIsRequired(props.required);

    if (props.value) {
      setVal(props.value);
    }

    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, setValBoolean, getVal, getValBoolean, getIsRequired, getLabel, getDbfield, isEmpty, wasChanged, setChanged, isChecked
    };
  });
  const getType = () => { return 'CxState' };
  const setVal = (newValue) => {
    setMyValue(newValue||'0');
    if ((props.mode==='checkbox' || props.mode==='bicolor') && newValue<='0') setMyValue('0'); 
    if ((props.mode==='checkbox' || props.mode==='bicolor') && newValue>='1') setMyValue('1');
  };
  const setValBoolean = (boValue) => {
    setMyValue(boValue ? '1' : '0');
  };
  const getVal = () => { 
    return myValue||'0' ;
  };
  const getValBoolean = () => { 
    return (myValue === '1' ? true : false); 
  };
  const getIsRequired = () => { return isRequired };
  const getLabel = () => { return '' };
  const getDbfield = () => { return props.dbfield };
  const isEmpty = () => { return myValue.length === 0 };
  const isChecked = () => { return (myValue === '1' ? true : false) }; //OJO: no siempre da resultado actualizado.

  const doFocus = (action) => {
    if (action === 'focused') setLabelColor('var(--ibs_color_focused)');
    if (action === 'unfocused') setLabelColor('var(--ibs_color_unfocused)');
  }

  const doClick = (e) => {
    e.preventDefault();

    let newValue = '';
    switch (myValue) {
      case '0': 
        newValue = '1'; 
        break;
      case '1': 
        if (props.mode==='tricolor') {
          newValue = '2'; 
        } else {
          newValue = '0'; 
        }
        break;
      case '2': 
        newValue = '0'; 
        break;

      default: break;
    }
    setVal(newValue)
    setChanged(true);

    if (props.onClick !== undefined) {
      props.onClick(newValue);
    }

    if (props.onChange) {
      props.onChange(props.id, newValue);
    }

  };

  return (
    <CxTooltip text={props.tooltip ? props.tooltip : null} tooltipType={props.tooltipType}>
      <div id='div_CxState' className={classes.frame}>
        <div className={classes.frame_content} style={{ padding: '0px', }}>
          <FormControlLabel ref={rf_label}
            classes={{ root: classesL.ctl_label, }}
            style={{ color: labelColor, }}
            label={props.label.split('|')[Number(myValue)] || props.label}
            labelPlacement="start"
            control={
              <div className={classes.frame_buttons}
                style={{
                  //background: 'yellow',
                  marginTop: '-4px',
                  marginRight: '5px',
                  height: '47px',
                  width: '26px',
                }}>
                <IconButton
                  style={{
                    marginTop: '0px',
                    marginLeft: '1px',
                    borderRadius: '4px',
                    padding: "2px",
                  }}
                  disableRipple
                  disableFocusRipple
                  onClick={e => doClick(e)}
                  disabled={props.disabled} 
                  >

                  {props.mode==='checkbox' && myValue<='0' && <BiCheckbox fontSize="28px" />}
                  {props.mode==='checkbox' && myValue>='1' && <BiCheckboxSquare fontSize="28px" />}

                  {props.mode==='bicolor' && myValue<='0' && <IoRadioButtonOn fontSize="20px" style={{ color: props.disabled ? 'Gray' : 'DarkGreen' }} />}
                  {props.mode==='bicolor' && myValue>='1' && <IoRadioButtonOn fontSize="20px" style={{ color: props.disabled ? 'Gray' : 'FireBrick' }} />}

                  {props.mode==='tricolor' && myValue==='0' && <IoRadioButtonOn fontSize="20px" style={{ color: props.disabled ? 'Gray' : 'DarkGreen' }} />}
                  {props.mode==='tricolor' && myValue==='1' && <IoRadioButtonOn fontSize="20px" style={{ color: props.disabled ? 'Gray' : 'FireBrick' }} />}
                  {props.mode==='tricolor' && myValue==='2' && <IoRadioButtonOn fontSize="20px" style={{ color: props.disabled ? 'Gray' : 'Gold' }} />}

                </IconButton>
              </div>
            }
            onFocus={() => doFocus('focused')}
            onBlur={() => doFocus('unfocused')}
          />
        </div>
      </div>
    </CxTooltip>
  );

};

export default CxState;
