import React from "react";
import { useTable } from "react-table";
import { useTranslation } from '../hx/useTranslation';
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import '../../styles/main.css';
import '../../styles/Table/Table.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import CxIconButtonNew from "./CxIconButtonNew";
import CxHr from "./CxHr";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { IoPlaySkipBackCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";


const CxTableMem = ({ onOpenForm, onFetchPage, columns, list, pageSize, ...rest }) => {
  const [{ trans }] = useTranslation();
  const [theList, setTheList] = React.useState(list || []);
  const [pageNo, setPageNo] = React.useState(0);
  const [data, setPageData] = React.useState([]);

  const BUTTON_FONTSIZE = 25;

  let txUrl = React.useRef(rest.url);

  const r_is_editing = React.useRef(false);

  const [isRequired, setIsRequired] =  React.useState(false);
  const [statusbarText, setStatusbarText] =  React.useState(null);

  const [disabled, setDisabled] = React.useState(rest.disabled);
  React.useEffect(() => setDisabled(rest.disabled), [rest.disabled]); // mantiene actualizado la habilitación, si se cambia el valor isDisabled en la llamada.

  const [cursor, setCursor] = React.useState(rest.cursor || 'pointer');
  
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: columns.map(column => {
        if (column.show === false) { return column.id } else { return null };
      }),
    }
  });

  React.useEffect(() => {
    setIsRequired(rest.required!==undefined);
    let pageno = Number(sessionStorage.getItem('CxTableMem.' + (rest.id||'') + '_pageno') || "0");
    fetchPage(pageno);

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    function handleResize() {
      fetchPage(0);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      if (!r_is_editing.current) {
        sessionStorage.removeItem('CxTableMem.' + (rest.id||'') + '_pageno');
      }
      r_is_editing.current = false;
    };
    // eslint-disable-next-line
  }, []);


  React.useImperativeHandle(rest.xref, () => {
    return {
      getType, redisplay, refresh, isEmpty, getIsRequired, getLabel, setStatusbarText, setTheList, setDisabled, setCursor, 
    };
  });
  const getType = () => { return 'CxTableMem' };
  const getIsRequired = () => { return isRequired && isEmpty() };
  const getLabel = () => { return rest.label||'' }; /* DAQ 2022.05.13 - trabaja con required */

  const redisplay = (url) => {
    if (url) {
      txUrl.current = url;
    }
    fetchPage(0);
  };

  const refresh = () => {
    // 2024-01-07 - daq: IMPORTANTE! A veces es necesario utilizar setTheList antes de llamar esta fx.
    fetchPage(pageNo);
  };

  const isEmpty = () => {
    return (theList.length === 0);
  };

  /* DAQ 2021.09.01 -  */
  const getCellClassName = (cell, colIndex) => {
    let rv = 'table_cell';
    FxLog.infox('CxTable.getCellClassName', '...cursor:', cursor);

    // para cada columna, la muestra con cursor correspondiente
    switch (cell.column.cursor) {
      case 'notallowed':
        rv += ' table_cell_cursor_notallowed';
        break;
    
      default:
        if (disabled) {
          rv += ' table_cell_cursor_default';
        } else {
          if (cursor==='pointer') rv += ' table_cell_cursor_pointer';
          if (cursor==='default') rv += ' table_cell_cursor_default';
        }
        break;
    }

    // para cada fila, la muestra con el color correspondiente
    allColumns.forEach((column, iCol) => {
      let cellValue = cell.row.allCells[iCol].value;
      //FxLog.infox('CxTable.getCellClassName', '...cellValue:', cellValue);
      if (cellValue) {
        if (column.id === 'R' && !rv.includes('table_cell_color')) rv += ' table_cell_color_red';
        if (column.id === 'B' && !rv.includes('table_cell_color')) rv += ' table_cell_color_blue';
        if (column.id === 'Y' && !rv.includes('table_cell_color')) rv += ' table_cell_color_yellow';
        if (column.id === 'W' && !rv.includes('table_cell_color')) rv += ' table_cell_color_brown';
        if (column.id === 'P' && !rv.includes('table_cell_color')) rv += ' table_cell_color_pink';
        if (column.id === 'A' && !rv.includes('table_cell_color')) rv += ' table_cell_color_gray';
        if (column.id === 'G' && !rv.includes('table_cell_color')) rv += ' table_cell_color_green';
      }
    });
    return rv;
  }

  /* by daq 06.08.21 */
  const getHeaderSetting = (colIndex, setting) => {
    let rv = '';
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach((column, iCol) => {
        if (colIndex === iCol) {
          rv = column[setting];
        }
      });
    });
    return rv;
  }

  const handleChangePage = (newPage) => {
    fetchPage(newPage);
  };

  const formAdd = () => {
    if (!disabled) {
      r_is_editing.current = true;
      onOpenForm("c", null);
    }
  };

  const formUpdate = (row) => {
    if (!disabled) {
      r_is_editing.current = true;
      onOpenForm("u", row);
    }
  };

  // Fetch page
  const fetchPage = async (newPage) => {
    sessionStorage.setItem('CxTableMem.' + (rest.id||'') + '_pageno', newPage);
    setPageNo(newPage);
    let pageNumber = newPage + 1;
    setPageData(theList.slice((pageNumber - 1) * pageSize, pageNumber * pageSize));
    if (onFetchPage) {
      onFetchPage(pageNumber);
    }
  }

  const getStyle = () => {
    let style = 'ibs_table';
    if (disabled) style += ' disabled';
    return style;
  }

  return (
    <div id='CxTableMem'>
      {rest.title && <CxHr title={rest.title} />}
      <div id='CxTableMem_header' style={{ display: rest.children ? 'flex' : 'none', justifyContent: 'space-between', flexWrap: 'wrap', margin: '0px' }}>
        <div>
          {rest.children}
        </div>
      </div>
      <div id='CxTableMem_MuiTable'>
        <MuiTable className={getStyle()} {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell align={column.align} width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} onClick={() => formUpdate(row)}>
                  {row.cells.map((cell, iCell) => {
                    return (
                      <TableCell
                        className={getCellClassName(cell, iCell)}
                        align={getHeaderSetting(iCell, 'align')}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>

        {statusbarText && <div id='CxTable_bar' className={'table_toolbar'} >
          <div style={{ 
            textAlign: 'right', 
            margin: '0px 12px 0px 12px', 
            width: '100%', 
            height: '20px',
            fontSize: '14px',
            color: 'var(--ibs_color_black)',
            fontFamily: 'var(--ibs_control_label_font_family)',
            }}>{statusbarText}</div>
        </div>}

        <div id='CxTable_buttons' className={'table_button_container'} >
          {rest.addTableButtonsLeft}
          <CxIconButtonNew icon={<IoPlaySkipBackCircleOutline fontSize={BUTTON_FONTSIZE-1} />} classType={'table_button'} onClick={() => handleChangePage(0)} tooltip={trans('field.page_first')} />
          <CxIconButtonNew icon={<IoCaretBackCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={() => handleChangePage(pageNo === 0 ? pageNo : pageNo - 1)} tooltip={trans('field.page_prev')} />
          <TextField variant="standard"
            value={pageNo + 1}
            disabled
            style={{ marginLeft: '5px', marginRight: '5px', width: '100px', height: '20px' }}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
          />
          <CxIconButtonNew icon={<IoCaretForwardCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={() => handleChangePage(pageNo + 1)} tooltip={trans('field.page_next')} />
          {rest.hasButtonAdd && <CxIconButtonNew icon={<IoAddCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={() => formAdd()} disabled={Boolean(disabled)}tooltip={trans('field.add')} />}
          {rest.addTableButtonsRight}
        </div>
        
      </div>

    </div>
  );

};

export default CxTableMem;
