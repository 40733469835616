import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettCalendarCol } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettCalendarColForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_record_used = React.useRef(null);

  let r_form = React.useRef(null);

  const [storeSettCalendarCol, setStoreSettCalendarCol] = useSessionStorage('StoreSettCalendarCol', StoreSettCalendarCol);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus === 'error') {
    } else {
      storeSettCalendarCol.itCalendarCol = lastSaveRv.theValue;
      Promise.all([setStoreSettCalendarCol(storeSettCalendarCol)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue === 'rv_error') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.calendars')}: ${trans('field.column')}`}
        xref={r_form}
        url="sett/cal_col"
        filterIdField='itCalendarCol'
        filterIdValue={storeSettCalendarCol.itCalendarCol}
        onFormLoad={formLoad}
        onFormSave={formSave}
        saveAndExit
        onFormDelete={formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="t_calendar_col.itCalendarCol" value={storeSettCalendarCol.itCalendarCol || ''} />
        <CxInput xref={useRef(null)} label={trans('field.name')} dbfield="t_calendar_col.txTypeCalendarCol" maxLength="30" required />
        <CxNumber xref={useRef(null)} label={trans('field.sequence')} dbfield="t_calendar_col.nuSequence" required />

      </GxForm>

      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />

    </div>
  );

}

export default MxSettCalendarColForm;
