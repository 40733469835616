import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettProf, getStoreValue } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettProfForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);
  const rf_txBranch = React.useRef(null);

  const [storeSettProf, setStoreSettProf] = useSessionStorage('StoreSettProf', StoreSettProf);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettProf.inProf = lastSaveRv.theValue;
      Promise.all([setStoreSettProf(storeSettProf)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.professional')}`}
        xref={r_form}
        url="sett/prof" 
        filterIdField='inProf'
        filterIdValue={storeSettProf.inProf} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="n_prof.inProf" value={storeSettProf.inProf || ''} />

        <CxInput xref={useRef(null)} label={`${trans('field.professional')}`} dbfield="n_prof.txProf" required />
        <CxInput xref={useRef(null)} label={trans('field.license')} dbfield="n_prof.txLicense" />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="n_prof.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_prof.txNotes" minRows={4} />
        
      </GxForm>

    </div>
  );

}

export default MxSettProfForm;
