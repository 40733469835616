import { BiSearch } from "react-icons/bi"; 
import { MdClear } from "react-icons/md"; 
import React from "react";
import TextField from '@material-ui/core/TextField';
import * as FxLog from '../fx/FxLog';
import CxDialog from "./CxDialog";
import CxTable from "./CxTable";
import CxIconButtonNew from "./CxIconButtonNew";
import '../../styles/main.css';
import * as FxFetch from '../fx/FxFetch';
import { useStyles } from "./StylesCx";
import { getStoreValueBo } from "../gx/GxStore";


const CxSearcher = props => {
  const classes = useStyles(props);
  const { onBlur, onClear, onSelection, onButtonAddOTF, onOptions, canOpenDialog, InputLabelProps, refColors,
    itemIndex, xref, focusSearchBox, bottomLegend, optionalColumns, optionalSorting, custom1, custom2, custom3, ...rest } = props;
  const [myRow, setMyRow] = React.useState('');
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  const [myCustom1, setMyCustom1] = React.useState('');
  const [myCustom2, setMyCustom2] = React.useState('');
  const [myCustom3, setMyCustom3] = React.useState('');
  const [myItemIndex, setMyItemIndex] = React.useState(''); /* DAQ 2022.05.12 - se usa cuando el control es parte de un array */
  const [isRequired, setIsRequired] = React.useState(false);
  const [dlg, setDlg] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [wasChanged, setChanged] = React.useState(false);

  const r_table = React.useRef(null);

  React.useEffect(() => {
    try {
      setIsRequired(props.required);
      if (props.value) {
        setMyValue(props.value);
      }
      if (rest.filter) {
        setFilter(rest.filter);
      }
      if (itemIndex !== undefined) {
        setMyItemIndex(itemIndex);
      }
      if (custom1) {
        setMyCustom1(custom1);
      }
      if (custom2) {
        setMyCustom2(custom2);
      }
      if (custom3) {
        setMyCustom3(custom3);
      }
      
    } catch (error) {
      FxLog.errorx("CxSearcher.", error);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(xref, () => {
    return {
      getType, getRow, setVal, getVal, setKey, getKey, getIsRequired, getLabel, getDbfield, setFilter, clear, isEmpty, isDisabled,
      setKeyAndVal, wasChanged, setChanged, setItemIndex, getItemIndex, getTable, setDlg, getMyCustom1, getMyCustom2, getMyCustom3, search
    };
  });
  const setItemIndex = (newItemIndex) => { setMyItemIndex(newItemIndex) };
  const getItemIndex = () => { return myItemIndex };
  const getMyCustom1 = () => { return myCustom1 };
  const getMyCustom2 = () => { return myCustom2 };
  const getMyCustom3 = () => { return myCustom3 };
  const setVal = (newValue) => { setMyValue(newValue) };
  const getVal = () => { return myValue };
  const setKey = (newId) => { setMyKey(newId) };
  const getKey = () => { return myKey };
  const getRow = () => { return myRow };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const clear = () => {
    if (!props.readOnly && !props.disabled) {
      setChanged(true);
      setMyRow('');
      setMyValue('');
      Promise.all([setMyKey('')]).then((result) => {
        if (onClear !== undefined) onClear();
      });
    }
  };
  const isEmpty = () => { return myValue.length === 0 };
  const isDisabled = () => { return props.disabled };
  const getType = () => { return 'CxSearcher' };
  const getTable = () => { return r_table.current };

  const setKeyAndVal = async (table, txFieldToGet, idField, idValue) => {
    try {
      setMyKey(idValue);
      let where = idField + "='" + idValue + "'";
      let record = {};
      record['table'] = table;
      record['field'] = txFieldToGet;
      record['where'] = where;
      Promise.all([FxFetch.doJsonX("general/r", record)]).then((result) => {
        if (result[0]) {
          let newValue = result[0].theValue;
          setMyValue(newValue);
        }
      });
      // -------------------------------------------------------------

    } catch (error) {
      FxLog.errorx("CxSearcher.setKeyAndVal", error);
    }
  }

  const search = async () => {
    if (!props.readOnly && !props.disabled) {
      let boOpen = (canOpenDialog === undefined || canOpenDialog());
      if (boOpen) {
        Promise.all([setDlg(true)]).then((result) => {
          r_table.current.redisplay(); // 2025-01-29 - daq: para que al volver a abrirlo no recuerde la página vieja
        });
      }
    }
  }

  const doClose = () => {
    setDlg(false);
  }

  const doOperation = async (op, row) => {
    if (op === 'c') {
      if (onButtonAddOTF !== undefined) {
        onButtonAddOTF();
      }
    } else {
      let id = row.original[props.col_id];
      let tx = row.original[props.col_tx];
      setMyRow(row);
      setMyKey(id);
      setMyValue(tx);
      setDlg(false);
      setChanged(true);
      sessionStorage.removeItem('CxTable.' + props.url + (props.id || '') + '_search');
      sessionStorage.removeItem('CxTable.' + props.url + (props.id || '') + '_pageno');
      if (props.onSelection !== undefined) {
        props.onSelection(row);
      }
    }
  }

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          minRows={props.minRows}
          multiline={props.minRows !== undefined ? true : false}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            disableUnderline: true,
            required: false,
            classes: { root: classes.root, },
            inputProps: { //refers to the input element
              style: { cursor: 'default' },
              maxLength: props.maxLength,
              readOnly: true,
            },
          }}
          value={myValue || ''}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => setMyValue(e.target.value)}
          onClick={() => search()} // 2025-02-13 - daq: mismo efecto que darle al botoncito, para que la gente no se frustre cuando tratan de darle foco para escribir.
          {...rest}
        />
        <div>
          <CxDialog title={rest.label} open={dlg} onClose={doClose} height='600px'>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
              <CxTable
                xref={r_table}
                id={props.id}
                origin='CxSearcher'
                fullscreen
                windowHeight={600}
                refColors={refColors}
                hasButtonAdd={onButtonAddOTF && getStoreValueBo('StoreSession', 'boEnableOTF')} // NO tiene botón de manera predeterminada
                hasSearchBox={true}
                focusSearchBox={focusSearchBox}
                tooltip={props.tooltip}
                url={props.url}
                filter={filter || ''}
                onOpenForm={doOperation}
                columns={props.columns}
                optionalColumns={optionalColumns}
                optionalSorting={optionalSorting}
                onOptions={onOptions}
                action1={props.action1}
              />
            </div>
            {bottomLegend && <span style={{
              margin: '5px 2px 2px 2px',
              paddingTop: '5px',
              paddingBottom: '5px',
              background: 'var(--ibs_color_red_dark)',
              color: 'var(--ibs_color_white)',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '12px',
            }}>{bottomLegend}</span>}
          </CxDialog>
        </div>
      </div>

      {!props.readOnly && props.minRows === undefined &&
        <div className={classes.frame_buttons}>
          <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={clear} disabled={props.disabled} />
          <CxIconButtonNew icon={<BiSearch fontSize={20} />} classType={classes.frame_icons_new} onClick={() => search()} disabled={props.disabled} />
        </div>}
    </div>

  );
};

export default CxSearcher; 
