import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import IconButton from "@material-ui/core/IconButton";
import IconClear from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import CxTooltip from './CxTooltip';

import IconAdd from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';

import { BsList, } from "react-icons/bs";
import { ImPrevious, ImNext, ImBackward, ImForward2, } from "react-icons/im";
import { CgTag } from "react-icons/cg";
import { FaRegFilePdf, FaRegFileExcel, FaTrashRestore, FaDoorOpen, FaUserClock, FaUserEdit, } from "react-icons/fa";
import { BiCalendarAlt, BiReset, BiCheckCircle, BiPlusCircle, BiEraser, BiPencil, BiLockAlt, BiLockOpenAlt } from "react-icons/bi";
import { BiSave, BiArchiveIn, BiArchiveOut, BiTrash, BiPrinter, BiAnalyse, BiSearch, BiTask, BiCoinStack, BiLabel, BiCheckShield, BiCheck } from "react-icons/bi";
import { FiDownload, FiInbox, FiZoomIn, FiZoomOut, } from "react-icons/fi";
import { AiFillMinusSquare, AiOutlineFileSearch, } from "react-icons/ai";
import { MdImageSearch, MdDeleteOutline, MdOutlinePriceChange, MdOutlineCheckCircleOutline, MdOutlineCancel, MdSend, MdHistory } from "react-icons/md";
import { IoMdInformationCircleOutline, } from "react-icons/io";
import { HiOutlineDocumentText, HiChevronDoubleRight, } from "react-icons/hi";
import { TbSum, } from "react-icons/tb";
import { RiAsterisk, RiMailSendLine } from "react-icons/ri";
import { FaExclamation } from "react-icons/fa6";

const CxIconButton = props => {
  const { type, classType, onClick, data, ...rest } = props;

  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(data);
    }
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'CxIconButton' };

  return (
    <div id='div_CxIconButton'>
      <CxTooltip text={rest.tooltip ? rest.tooltip : null}>
      <span>
      <IconButton className={classType ? classType : "ibs_iconbutton"} onClick={(e) => doClick(e)} disabled={rest.disabled} tabIndex='-1' {...rest} >
        {type === 'accept' && <BiCheckCircle />}
        {type === 'add_to' && <BiArchiveIn />}
        {type === 'add' && <IconAdd />}
        {type === 'calendar' && <BiCalendarAlt />}
        {type === 'cancel' && <MdOutlineCancel />}
        {type === 'check' && <MdOutlineCheckCircleOutline />}
        {type === 'change_client_info' && <FaUserEdit />}
        {type === 'chevron_right' && <HiChevronDoubleRight />}
        {type === 'clear' && <IconClear />}
        {type === 'close' && <CloseIcon />}
        {type === 'coin_stack' && <BiCoinStack />}
        {type === 'comms' && <MdSend />}
        {type === 'delete' && <BiTrash />}
        {type === 'delete2' && <MdDeleteOutline />}
        {type === 'document' && <HiOutlineDocumentText />}
        {type === 'download' && <FiDownload />}
        {type === 'eraser' && <BiEraser />}
        {type === 'excel' && <FaRegFileExcel fontSize="22px" style={{ marginTop: '1px' }} />}
        {type === 'exclamation' && <FaExclamation fontSize="20px" />}
        {type === 'getval' && <GetAppIcon />}
        {type === 'history' && <MdHistory />}
        {type === 'info' && <IoMdInformationCircleOutline />}
        {type === 'issue' && <BiAnalyse />}
        {type === 'last_client_search' && <FaUserClock />}
        {type === 'list' && <BsList />}
        {type === 'lock' && <BiLockAlt />}
        {type === 'minus' && <AiFillMinusSquare />}
        {type === 'move_first' && <ImPrevious />}
        {type === 'move_left' && <ImBackward />}
        {type === 'move_right' && <ImForward2 />}
        {type === 'move_last' && <ImNext />}
        {type === 'move' && <BiLabel />}
        {type === 'open' && <FaDoorOpen />}
        {type === 'pdf' && <FaRegFilePdf fontSize="22px" style={{ marginTop: '1px' }} />}
        {type === 'pencil' && <BiPencil />}
        {type === 'placeholder' && <RiAsterisk />}
        {type === 'plus' && <BiPlusCircle />}
        {type === 'price_change' && <MdOutlinePriceChange />}
        {type === 'price_label' && <CgTag />}
        {type === 'print' && <BiPrinter />}
        {type === 'refresh' && <CachedIcon />}
        {type === 'remove_from' && <BiArchiveOut />}
        {type === 'report' && <BiTask />}
        {type === 'reset' && <BiReset />}
        {type === 'restore' && <FaTrashRestore />}
        {type === 'save' && <BiSave />}
        {type === 'search' && <BiSearch />}
        {type === 'search_file' && <AiOutlineFileSearch />}
        {type === 'search_image' && <MdImageSearch />}
        {type === 'select' && <BiCheck />}
        {type === 'send' && <RiMailSendLine />}
        {type === 'sum' && <TbSum />}
        {type === 'template' && <FiInbox />}
        {type === 'test' && <BiCheckShield />}
        {type === 'unlock' && <BiLockOpenAlt />}
        {type === 'zoom_in' && <FiZoomIn />}
        {type === 'zoom_out' && <FiZoomOut />}

      </IconButton>
      </span>
    </CxTooltip>

    </div >
  );

};

export default CxIconButton;
