import { BiLockOpenAlt } from "react-icons/bi"; 
import { BiLockAlt } from "react-icons/bi"; 
import { BiTrash } from "react-icons/bi";
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';
import GxList from '../../gx/GxList';
import { clearStore, StoreStockLot } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButtonNew from '../../cx/CxIconButtonNew';


const MxStockroomLotList = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ isFullAccess }] = useAccess(trans('field.stockrooms'));
  const [{ navTo }] = useNav();

  const size = useWindowSize();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [lockDelete, setLockDelete] = React.useState(true);
  
  const hasColumnPsCode = () => { return Boolean(localStorage.getItem('MxStockroomLotList.addcol_txPsCode')==='1')};
  const hasColumnPurInv = () => { return Boolean(localStorage.getItem('MxStockroomLotList.addcol_txPurInvoice')==='1')};
  const hasColumnPurInvDate = () => { return Boolean(localStorage.getItem('MxStockroomLotList.addcol_dtPurInvoice')==='1')};

  const r_list = React.useRef(null);

  const [storeStockLot, setStoreStockLot] = useSessionStorage('StoreStockLot', StoreStockLot);

  const r_snack_op_successful = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreStockLot', storeStockLot);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    storeStockLot.inPs = row.original.inPs;
    storeStockLot.txPs = row.original.txPs;
    storeStockLot.txLotCode = row.original.txLotCode;
    storeStockLot.dtLotExpr = row.original.dtLotExpr;
    Promise.all([setStoreStockLot(storeStockLot)]).then((result) => {
      navTo("stock-lot-form");
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inPs", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.code'), accessor: row => r_list.current.getTable().highlightText(row["txPsCode"]), width: '15%', show: hasColumnPsCode() });
      cols.push({ id: id++, Header: trans('field.lot'), accessor: row => r_list.current.getTable().highlightText(row["txLotCode"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.expiration'), accessor: row => r_list.current.getTable().highlightDate(row["dtLotExpr"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.purchase_invoice_s'), accessor: row => r_list.current.getTable().highlightText(row["txPurInvoice"]), width: '15%', show: hasColumnPurInv() });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => r_list.current.getTable().highlightDate(row["dtPurInvoice"]), width: '15%', show: hasColumnPurInvDate() });
      cols.push({ id: id++, Header: trans('field.purchases'), accessor: row => (FxTable.cellTo2r00(row["qyPur"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.sales'), accessor: row => (FxTable.cellTo2r00(row["qySls"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.stock_lot'), accessor: row => (FxTable.cellTo2r00(row["qyStock"])), width: '10%', align: 'right' });
      
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.ps'), accessor: row => r_list.current.getTable().highlightText(row["txPs"]), width: '70%' });
      cols.push({ id: id++, Header: trans('field.lot'), accessor: row => r_list.current.getTable().highlightText(row["txLotCode"]), width: '30%' });
    }

    cols.push({
      id: "BTN_DEL", icon: <BiLockAlt />, icon_alt: <BiLockOpenAlt />, iconTooltip: trans('field.column_lock_unlock'), onIconClick: () => setLockDelete(!lockDelete),
      accessor: row => { return <CxIconButtonNew icon={<BiTrash fontSize={24} />} classType="ibs_iconbutton_table_body" data={row} onClick={doDelete} disabled={lockDelete} tooltip={trans('field.delete')} /> },
      align: 'center', width: '33px'
    });
    
    return cols;
  }

  const doDelete = (row) => {
    let record = {};
    record['inPs'] = row.inPs;
    record['txPs'] = row.txPs;
    record['txLotCodeOri'] = row.txLotCode;
    Promise.all([FxFetch.doJsonX(`stock/lots/d`, record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        r_list.current.redisplay();
      }
    });
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({id: 'txPsCode', name: trans('field.code'), checked: hasColumnPsCode() ? '1' : '0'}); 
    cols.push({id: 'txPurInvoice', name: trans('field.purchase_invoice_s'), checked: hasColumnPurInv() ? '1' : '0'}); 
    cols.push({id: 'dtPurInvoice', name: trans('field.date'), checked: hasColumnPurInvDate() ? '1' : '0'}); 
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxStockroomLotList.addcol_txPsCode', options.includes('txPsCode') ? '1' : '0');
    localStorage.setItem('MxStockroomLotList.addcol_txPurInvoice', options.includes('txPurInvoice') ? '1' : '0');
    localStorage.setItem('MxStockroomLotList.addcol_dtPurInvoice', options.includes('dtPurInvoice') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  const getTooltip = () => {
    let tip = `${trans('field.ps')}`;
    tip = tip + `${' / ' + trans('field.lot')}`;
    if (size.isLarge) {
      tip = tip + `${' / ' + trans('field.expiration')}`;
      if (hasColumnPsCode()) tip = tip + `${' / ' + trans('field.code')}`;
      if (hasColumnPurInv()) tip = tip + `${' / ' + trans('field.purchase_invoice_s')}`;
      if (hasColumnPurInvDate()) tip = tip + `${' / ' + trans('field.date')}`;
    }
    
    return tip;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.stockroom')}: ${trans('field.lots')}`}
        xref={r_list}
        id="MxStockroomLotList"
        tooltip={getTooltip()}
        fullscreen
        url="stock/lots"
        onOpenForm={openForm}
        hasButtonAdd={false}
        columns={getTableColumns()}
        optionalColumns={getTableOptionalCols()}
        optionalSorting={[
          { id: 'txPs', type: trans('field.ps') },
        ]}
        onOptions={optionsSelected}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          </div>
        }
      />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxStockroomLotList;
