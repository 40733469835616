import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../hx/useTranslation';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxStr from '../fx/FxStr';
import CxSnackbar from './CxSnackbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 'var(--ibs_control_width_lg)',
  },
  divLeft: {
    width: '80px',
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: props => { return (props.disabled ? 'var(--ibs_color_gray_light)' : props.background) },
  },
  divRight: {
    width: '100%',
    background: 'var(--ibs_color_white)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '10px',
  },
  title: {
    fontSize: '13px',
    color: 'var(--ibs_color_black)',
    paddingBottom: '5px',
    textAlign: 'left',
  },
  typo: {
    fontSize: '13px',
    color: 'var(--ibs_color_black)',
    paddingBottom: '5px',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '12px',
    color: 'var(--ibs_color_gray)',
    textAlign: 'left',
  },
});

const CxCard1 = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);

  const r_snack_ask_upgrade = React.useRef(null);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const doClick = (event) => {
    if (props.disabled) {
      r_snack_ask_upgrade.current.setOpen(true);
    } else {
      props.onClick(props.path);
    }
  }

  const getTitle = () => {
    return <Typography className={classes.typo}>{FxStr.highlightSearch(props.title, props.search)}</Typography>;
  }

  return (
    <Card>
      <CardActionArea onClick={event => { doClick(event) }}>
        <div className={classes.root}>
          <div className={classes.divLeft}>
            {props.icon}
          </div>
          <div className={classes.divRight}>
            <div className={classes.title}>
              {getTitle()}
            </div>
            <div className={classes.subtitle}>
              {props.subtitle}
            </div>
          </div>
        </div>
      </CardActionArea>
      <CxSnackbar xref={r_snack_ask_upgrade} severity="warning" value={trans('msg.ask_upgrade_s')} />
    </Card>
  );
}

export default CxCard1;