// Input para formularios
import React, { useState, useImperativeHandle } from "react";
import '../../styles/main.css';
import i18n from '../../i18n';
import { useTranslation } from '../hx/useTranslation';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import CxTooltip from './CxTooltip';
import { useStyles } from "./StylesCx";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { plugin_submenu } from "./CxTextEditorCustom";
import { fontSize, fontColor, hiliteColor } from 'suneditor/src/plugins'
import list from 'suneditor/src/plugins/submenu/list'

const CxTextEditor = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  // eslint-disable-next-line no-unused-vars
  const { onGetValue, onBlur, onClear, onSearch, InputLabelProps, defaultValue, redText, upperCase, lowerCase, capitalize, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = React.useState(false);

  const r_clearing = React.useRef(false);

  const rf_control = React.useRef(null);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    if (props.value) {
      setMyValue(props.value);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    FxLog.infox('CxTextEditor.useEffect', '...myValue:', myValue);
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, getDbfield, setFocus, isEmpty, clear, wasChanged, setChanged,
    };
  });
  const setVal = (newValue) => {
    setMyValue(newValue)
  };
  const getVal = () => {
    return myValue;
  };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal() || '').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const isEmpty = () => { return myValue.length === 0 };
  const clear = () => { setMyValue(''); setChanged(true); };
  const getType = () => { return 'CxTextEditor' };

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    rf_control.current = sunEditor;
  };

  return (
    <CxTooltip text={props.tooltip ? props.tooltip : null}>
      <div id='div_CxTextEditor' style={{ textAlign: 'left' }} className={classes.frame}>
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          lang={i18n.language.substring(0, 2)}
          height="100%"
          setDefaultStyle="font-family: sans-serif; font-size: 14px;" /** OJO, el tipo y tamaño de letra deben ser iguales que en el campo MY_CONTENT del jrxml */
          setOptions={{
            /* height: 200, */
            plugins: [plugin_submenu, fontSize, fontColor, hiliteColor, list],
            buttonList: [
              ['undo', 'redo', 'fontSize', 'fontColor', 'hiliteColor'],
              ['bold', 'underline', 'italic', 'list', 'removeFormat'],
              [
                {
                  name: 'custom_plugin_submenu',
                  dataDisplay: 'submenu',
                  title: trans('field.placeholders'),
                  buttonClass: '',
                  //innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>'
                  innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M1.728 20.992q-0.416 1.6 0.416 3.008 0.832 1.44 2.432 1.856t3.040-0.384q0.832-0.48 2.56-1.92t3.168-2.912q-0.608 2.016-0.96 4.192t-0.384 3.168q0 1.664 1.184 2.848t2.816 1.152 2.816-1.152 1.184-2.848q0-0.96-0.384-3.168t-0.928-4.192q1.44 1.504 3.168 2.944t2.528 1.888q1.44 0.832 3.040 0.384t2.432-1.856 0.416-3.008-1.888-2.464q-0.864-0.48-2.944-1.248t-4.064-1.28q2.016-0.512 4.096-1.28t2.912-1.248q1.44-0.832 1.888-2.432t-0.416-3.040q-0.832-1.44-2.432-1.856t-3.040 0.384q-0.832 0.512-2.528 1.92t-3.168 2.912q0.576-1.984 0.928-4.192t0.384-3.168q0-1.632-1.184-2.816t-2.816-1.184-2.816 1.184-1.184 2.816q0 0.992 0.384 3.168t0.96 4.192q-1.44-1.472-3.168-2.88t-2.56-1.952q-1.44-0.8-3.040-0.384t-2.432 1.856-0.416 3.040 1.888 2.432q0.832 0.48 2.912 1.248t4.128 1.28q-2.016 0.512-4.096 1.28t-2.944 1.248q-1.44 0.832-1.888 2.464z"/></svg>'
                }
              ],
            ],
            fontSize: [
              14, 18, 24, 36
            ],
          }}
          value={myValue || ''}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          setContents={myValue}
          onChange={setMyValue}
          onKeyDown={() => setChanged(true)}
        />
      </div>
    </CxTooltip>
  );
};

export default CxTextEditor;
