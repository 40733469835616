import moment from 'moment';
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import Button from '@material-ui/core/Button';
import CxDialog from '../../cx/CxDialog';
import CxInput from '../../cx/CxInput';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import * as FxFetch from '../../fx/FxFetch';
import * as FxLog from '../../fx/FxLog';
import * as FxEdition from '../../fx/FxEdition';
import * as FxDat from '../../fx/FxDat';
import * as FxInet from '../../fx/FxInet';
import * as FxBrowser from '../../fx/FxBrowser';
import { getStoreValue, hasSessionStarted } from "../../gx/GxStore";
import { BsWhatsapp } from "react-icons/bs";


/* Componente para las comunicaciones */
const TxTimerComms = (props) => {
  const [{ navExit }] = useNav();
  const [{ trans }] = useTranslation();
  const SEC = 1000; // un segundo en milisegundos
  const timer = React.useRef(null); // ventana de recordatorios

  const [dlg, setDlg] = React.useState(false);

  const r_dlg = React.useRef(null);

  const r_inComm = React.useRef(null);
  const r_attachments = React.useRef(null);
  const r_mobile = React.useRef(null);
  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);
  const rf_content = React.useRef(null);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (hasSessionStarted() && FxInet.isOnline() && !dlg && !getStoreValue('StoreComm', 'inComm') && !FxInet.isDebug()) {
        let record = {};
        record['process'] = 'silent_comms_popup_get_data';
        record['dtNow'] = FxDat.timestamp();
        Promise.all([FxFetch.doJsonX('comms/popup/get_data', record)]).then((result) => {
          if (result[0]) {
            if (result[0][0]) {
              doOpen(result[0][0]);
            }

          } else {
            doExit();
          }
        });
      }
    }, 60 * SEC);

    return () => {
      FxLog.infox("TxTimerComms.useEffect.return()", "cleaning up timer...");
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, [dlg]);

  const doExit = () => {
    FxLog.infox("TxTimerComms.doExit", "exiting...");
    clearInterval(timer.current);
    navExit();
  };

  const doOpen = (data) => {
    try {
      FxLog.infox("TxTimerComms.doOpen", "...data:", data);
      let mDate = moment(data.dtFrom);
      let theDate = mDate.format(moment.HTML5_FMT.DATE);
      let theTime = mDate.format(moment.HTML5_FMT.TIME);

      setDlg(true);
      r_inComm.current = data.inComm;
      r_attachments.current = data.attachments;
      r_mobile.current = data.txMobile;
      rf_date.current.setVal(theDate);
      rf_time.current.setVal(theTime);
      rf_content.current.setVal(data.txContent);

    } catch (error) {
      FxLog.errorx("TxTimerComms.doOpen", error);
    }
  };

  const doDismissThisOne = () => {
    setDlg(false);

    // requiere que el comm cambie status.
    let record = {};
    record['idValue'] = r_inComm.current;
    Promise.all([FxFetch.doJsonX('comms/popup/confirmation', record)]).then((result) => {
      if (result[0]) {
        FxLog.infox("TxTimerComms.doDismissThisOne", "Comm was CONFIRMED...result[0]:", result[0]);
      }
    });
  }

  const doDismissAll = () => {
    setDlg(false);

    // requiere que el comm cambie status.
    let record = {};
    record['idValue'] = 'ALL';
    Promise.all([FxFetch.doJsonX('comms/popup/confirmation', record)]).then((result) => {
      if (result[0]) {
        FxLog.infox("TxTimerComms.doDismissAll", "Comm was CONFIRMED...result[0]:", result[0]);
      }
    });
  }

  const doWhatsapp = () => {
    if (r_mobile.current) {
      doDismissThisOne();
      FxLog.infox("TxTimerComms.doWhatsapp", r_mobile.current);
      let hasAttachments = (r_attachments.current > 0);
      let theMobile = r_mobile.current;
      let theContent = encodeURI(rf_content.current.getVal());
      if (hasAttachments) {
        FxLog.infox("TxTimerComms.doWhatsapp", "...HAS ATTACHMENTS!");
        let record = {};
        record['inComm'] = r_inComm.current;
        Promise.all([FxFetch.doJsonX('comms/get_attachments', record)]).then((result) => {
          if (result[0]) {
            FxLog.infox("TxTimerComms.doWhatsapp", "...result[0]:", result[0]);
            let urls = '';
            for (let i = 0; i < result[0].length; i++) {
              let theFilePath = result[0][i];
              let theUrl = 'https://www.iberical.com/' + theFilePath;
              urls = urls + '%0a' + theUrl;
            }
            theContent = theContent + urls;
            // abre whatsapp
            let url = `https://web.whatsapp.com/send?phone=${theMobile}&text=${theContent}`;
            FxBrowser.windowOpen(url);
          }
        });
      } else {
        // abre whatsapp
        let url = `https://web.whatsapp.com/send?phone=${theMobile}&text=${theContent}`;
        FxBrowser.windowOpen(url);
      }
    }
  }

  return (
    <div>
      <CxDialog
        xref={r_dlg}
        title={trans('field.comm')}
        open={dlg}
        addDiv={
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', width: '100%' }}>
            <Button onClick={doDismissThisOne}>{trans('field.dismiss_this_one')}</Button>
            <Button onClick={doDismissAll}>{trans('field.dismiss_all')}</Button>
            <Button onClick={doWhatsapp} disabled={!FxEdition.isInEdition('COM_wha')}><BsWhatsapp fontSize={16} />&nbsp;{trans('field.send_whatsapp')}</Button>
          </div>
        }
        height='520px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', margin: '5px' }} >
          <CxDate xref={rf_date} label={trans('field.date')} width="var(--ibs_control_width_md)" />
          <CxTime xref={rf_time} label={trans('field.from')} width="var(--ibs_control_width_md)" />
          <CxInput xref={rf_content} label={trans('field.message')} minRows={8} maxLength={500} width="var(--ibs_control_width_md)" />
        </div>

      </CxDialog>
    </div>
  );
}

export default TxTimerComms;
