import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import { clearStore, StoreSettBranch } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';
import { FaSquareFull } from "react-icons/fa";
import CxIconButtonNew from '../../cx/CxIconButtonNew';


const MxSettBranchList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ navTo }] = useNav();

  const [storeSettBranch, setStoreSettBranch] = useSessionStorage('StoreSettBranch', StoreSettBranch);
  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettBranch', storeSettBranch);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettBranch.inBranch = null;
    } else {
      storeSettBranch.inBranch = row.original.inBranch;
    }
    setStoreSettBranch(storeSettBranch);
    navTo("sett-branch-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inBranch", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.branch'), accessor: row => r_list.current.getTable().highlightText(row["txBranch"]), width: '40%' });
        cols.push({ id: id++, Header: `${trans('field.branch_s')}-ID`, accessor: "txBranchId", width: '10%' });
        cols.push({ id: id++, Header: trans('field.cash_reg'), accessor: "txTypeCash", width: '10%' });
        cols.push({ id: id++, Header: trans('field.email'), accessor: "txEmail", width: '20%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '10%' });
        cols.push({
          id: id++, Header: trans('field.color'), accessor: row => {
            return <CxIconButtonNew icon={<FaSquareFull fontSize="20px" style={{ color: FxStr.rgb2hex(row["txBranchRgb"]), border: '1px solid gray' }} />} />
          }, 
          width: '10px', align: 'center'
        });
    
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.branch'), accessor: row => r_list.current.getTable().highlightText(row["txBranch"]), width: '80%' });
        cols.push({ id: id++, Header: `${trans('field.branch_s')}-ID`, accessor: "txBranchId", width: '20%' });
      
      }
      cols.push({ id: "B", Header: trans('field.office_main'), accessor: row => Boolean((row["boMainOffice"]) === '1' ? true : false), show: false });

    } catch(error) {
      FxLog.errorx('MxSettBranchList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.branches')}
        xref={r_list}
        tooltip={`${trans('field.branch')}`}
        fullscreen
        url="sett/branches"
        onOpenForm={openForm}
        refColors={ [ 'table_cell_color_blue|' + trans('field.office_main') ] }
        columns={getTableColumns()}
      />

    </div>
  );
}

export default MxSettBranchList;
