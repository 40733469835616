import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import '../../../styles/main.css';
import CxDate from '../../cx/CxDate';
import CxCombo from '../../cx/CxCombo';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import CxIconButton from '../../cx/CxIconButton';
import CxToolbar from '../../cx/CxToolbar';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxDat from '../../fx/FxDat';
import { isApp, StoreHistory } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxIconButtonLastClient2 from '../../gx/GxIconButtonLastClient2';
import GxSearcherClient from '../../gx/GxSearcherClient';
import GxSearcherAnimal from '../../gx/GxSearcherAnimal';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
  },
}));

const MxHistoryFilter = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const [{ navTo }] = useNav();
  const { state } = useLocation(); //captura los parámetros de navBack()

  const [typeList, setTypeList] = React.useState('S');

  let r_snack_error = React.useRef(null);

  const rf_type = React.useRef(null);
  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);
  const rf_txSupplier = React.useRef(null);
  const rf_txPs = React.useRef(null);
  const rf_txTypePsGroup = React.useRef(null);
  const rf_txTypePsSubgroup = React.useRef(null);

  const [storeHistory, setStoreHistory] = useSessionStorage('StoreHistory', StoreHistory);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let theDateFrom = FxDat.addDays(FxDat.today(), -30);
    let theDateTo = FxDat.today();

    let type = storeHistory.historyType || 'S';
    rf_type.current.setVal(type);
    Promise.all([setTypeList(type)]).then((result) => {
      if (rf_txClient.current) {
        if (state) {
          FxLog.infox('MxHistoryFilter.useEffect', '...state:', state);
          rf_txClient.current.setKey(state.inClient);
          rf_txClient.current.setVal(state.txClient);
        } else {
          rf_txClient.current.setKey(storeHistory.inClient);
          rf_txClient.current.setVal(storeHistory.txClient);
        }
        rf_txClient.current.setClient(storeHistory.inClient, storeHistory.txClient);
        if (rf_txAnimal.current) {
          if (state && state.inAnimal) {
            FxLog.infox('MxHistoryFilter.useEffect', '...state:', state);
            rf_txAnimal.current.setKey(state.inAnimal);
            rf_txAnimal.current.setVal(state.txAnimal);
          } else {
            rf_txAnimal.current.setKey(storeHistory.inAnimal);
            rf_txAnimal.current.setVal(storeHistory.txAnimal);
          }
        }
      }
      if (rf_txSupplier.current) {
        rf_txSupplier.current.setKey(storeHistory.inSupplier);
        rf_txSupplier.current.setVal(storeHistory.txSupplier);
      }
    });

    rf_dtFrom.current.setVal(storeHistory.dtFrom || theDateFrom);
    rf_dtTo.current.setVal(storeHistory.dtTo || theDateTo);
    rf_txBranch.current.setKey(storeHistory.inBranch);
    rf_txBranch.current.setVal(storeHistory.txBranch);

    rf_txPs.current.setVal(storeHistory.txPs);
    rf_txTypePsGroup.current.setKey(storeHistory.itPsGroup);
    rf_txTypePsGroup.current.setVal(storeHistory.txTypePsGroup);
    rf_txTypePsSubgroup.current.setKey(storeHistory.itPsSubgroup);
    rf_txTypePsSubgroup.current.setVal(storeHistory.txTypePsSubgroup);
  }

  const goNext = async () => {
    storeHistory.historyType = rf_type.current.getVal();
    storeHistory.dtFrom = rf_dtFrom.current.getVal();
    storeHistory.dtTo = rf_dtTo.current.getVal();
    storeHistory.inBranch = rf_txBranch.current.getKey();
    storeHistory.txBranch = rf_txBranch.current.getVal();
    storeHistory.txPs = rf_txPs.current.getVal();
    storeHistory.itPsGroup = rf_txTypePsGroup.current.getKey();
    storeHistory.txTypePsGroup = rf_txTypePsGroup.current.getVal();
    storeHistory.itPsSubgroup = rf_txTypePsSubgroup.current.getKey();
    storeHistory.txTypePsSubgroup = rf_txTypePsSubgroup.current.getVal();
    if (rf_txClient.current) {
      storeHistory.inClient = rf_txClient.current.getKey();
      storeHistory.txClient = rf_txClient.current.getVal();
      if (rf_txAnimal.current) {
        storeHistory.inAnimal = rf_txAnimal.current.getKey();
        storeHistory.txAnimal = rf_txAnimal.current.getVal();
      }
    }
    if (rf_txSupplier.current) {
      storeHistory.inSupplier = rf_txSupplier.current.getKey();
      storeHistory.txSupplier = rf_txSupplier.current.getVal();
    }
    setStoreHistory(storeHistory);

    if (rf_type.current.getKey() === 'S') {
      navTo("history-sls-list");
    }
    if (rf_type.current.getKey() === 'P') {
      navTo("history-pur-list");
    }
  }

  const groupCleared = () => {
    rf_txTypePsSubgroup.current.clear();
    rf_txTypePsSubgroup.current.setFilter(`undefined='${undefined}'`);
  }

  const groupSelected = (row) => {
    rf_txTypePsSubgroup.current.clear();
  }

  const subgroupOpen = () => {
    let itPsGroup = rf_txTypePsGroup.current.getKey();
    rf_txTypePsSubgroup.current.setFilter(`t_ps_subgroup.itPsGroup='${itPsGroup}'`);
    return true; // para que abra la ventana de búsqueda.
  }

  const typeCleared = async () => {
    storeHistory.inClient = '';
    storeHistory.txClient = '';
    storeHistory.inAnimal = '';
    storeHistory.txAnimal = '';
    storeHistory.inSupplier = '';
    storeHistory.txSupplier = '';
    setStoreHistory(storeHistory);

    rf_type.current.setVal('S');
    setTypeList('S');
  }

  const typeSelected = async (val) => {
    storeHistory.inClient = '';
    storeHistory.txClient = '';
    storeHistory.inAnimal = '';
    storeHistory.txAnimal = '';
    storeHistory.inSupplier = '';
    storeHistory.txSupplier = '';
    setStoreHistory(storeHistory);

    setTypeList(val.substring(0, 1));
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.history')}: ${trans('field.filter')}`}
        addToolbarButtons={
          <>
            {typeList === 'S' && <GxIconButtonLastClient2 theInput={rf_txClient} />}
            <CxIconButton type="chevron_right" classType="ibs_iconbutton_toolbar" onClick={goNext} tooltip={trans('field.next')} />
          </>
        }
      />

      <div className={classes.container}>

        <CxCombo xref={rf_type}
          label={`${trans('field.type')}`}
          list={[
            { id: 'S', type: trans('field.sales') },
            { id: 'P', type: trans('field.purchases') },
          ]}
          onSelection={typeSelected}
          onClear={typeCleared}
          clearDisabled
          required
          autoSelectFirst
        />

        <CxDate xref={rf_dtFrom} label={trans('field.from')} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} required />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
        />

        <CxInput xref={rf_txPs} label={trans('field.ps')} speech />

        <CxSearcher xref={rf_txTypePsGroup}
          label={trans('field.group')}
          dbfield='t_ps_group.txTypePsGroup' dbfieldkey='n_ps.itPsGroup'
          onClear={groupCleared}
          onSelection={groupSelected}
          url="sett/ps_group"
          col_id="itPsGroup"
          col_tx="txTypePsGroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsGroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsGroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '100%' },
          ]}
        />

        <CxSearcher xref={rf_txTypePsSubgroup}
          label={trans('field.subgroup')}
          dbfield='t_ps_subgroup.txTypePsSubgroup' dbfieldkey='n_ps.itPsSubgroup'
          canOpenDialog={subgroupOpen}
          url="sett/ps_subgroup"
          col_id="itPsSubgroup"
          col_tx="txTypePsSubgroup"
          columns={[
            { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '100%' },
          ]}
        />

        {typeList === 'S' && <GxSearcherClient xref={rf_txClient} animalRef={rf_txAnimal} />}
        {typeList === 'S' && (isApp('vett') || isApp('spaw')) && <GxSearcherAnimal xref={rf_txAnimal} clientRef={rf_txClient} />}

        {typeList === 'P' && <CxSearcher xref={rf_txSupplier} label={trans('field.supplier')}
          dbfield="n_supplier.txSupplier" dbfieldkey={`n_ps.inSupplier`}
          url="suppliers"
          col_id="inSupplier"
          col_tx="txSupplier"
          columns={[
            { id: "0", Header: "ID", accessor: "inSupplier", show: false },
            { id: "1", Header: trans('field.supplier'), accessor: row => rf_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
          ]}
        />}

      </div>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxHistoryFilter;
