import { BiReset } from "react-icons/bi"; 
import { BsCartFill } from "react-icons/bs";
import React from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import * as FxSms from '../../fx/FxSms';
import * as FxFetch from '../../fx/FxFetch';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxHidden from '../../cx/CxHidden';
import CxInputTemplate from '../../cx/CxInputTemplate';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgSmsPurchase from '../../gx/GxDlgSmsPurchase';
//import { useNav } from '../../hx/useNav';
import CxSwitch from '../../cx/CxSwitch';
import { StoreComm, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxTableAttachmentBlob from '../../cx/CxTableAttachmentBlob';
import GxIconButtonLastClient2 from '../../gx/GxIconButtonLastClient2';
import GxSearcherClient from '../../gx/GxSearcherClient';
import GxSearcherAnimal from '../../gx/GxSearcherAnimal';


const MxCommForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.comms'));
  //const [{ navBack }] = useNav();

  const r_form = React.useRef(null);
  const r_table_attachment_blob = React.useRef(null);
  const r_dlg_sms_purchase = React.useRef(null);

  const rf_txTypeComm = React.useRef(null);
  const rf_txContent = React.useRef(null);

  const rf_inPs = React.useRef(null);

  const rf_inRepl = React.useRef(null);
  const rf_inVacc = React.useRef(null);

  const rf_ori_mailing = React.useRef(null);
  const rf_ori_mobile = React.useRef(null);
  const rf_ori_email = React.useRef(null);

  const rf_boPopup = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);

  const rf_txSms = React.useRef(null);
  const rf_txEmail = React.useRef(null);

  const rf_date_informed = React.useRef(null);
  const rf_time_informed = React.useRef(null);
  const rf_txResponse = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isMethodPopup, setIsMethodPopUp] = React.useState(false);

  const [smsCredits, setSmsCredits] = React.useState('');

  const [storeComm, setStoreComm] = useSessionStorage('StoreComm', StoreComm);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_snack_warning = React.useRef(null);


  React.useEffect(() => {
    doCheckSmsCredits();

    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      setIsDisabled(false);
      r_table_attachment_blob.current.setIsDisabled(false);
      
      // para poder guardar adjuntos cuando el registro es nuevo. 
      // se complementa en el servidor con LXXX.recSave(), que cuando es un registro nuevo, lo crea artificialmente para luego actualizarlo.
      // los registros que pudieran quedar huérfanos de n_xxx_blob porque el usuario se fué luego de adjuntar y sin guardar, se borrarán en el servidor LMaintenance.doOps().
      storeComm.inComm = FxStr.getRandomID();
      Promise.all([setStoreComm(storeComm)]).then((result) => {
        r_form.current.setPropFilter('inComm', storeComm.inComm);
        r_table_attachment_blob.current.setInXxx(storeComm.inComm);
      });

      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_txTypeComm.current.setKeyAndVal('t_comm', 'txTypeComm', 'itComm', '000001.SRV');
      //rf_message.current.setFocus();

      if (storeComm.inClient) {
        let url = "clients/r";
        let record = {};
        record['idValue'] = storeComm.inClient;
        Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
          if (result[0]) {
            FxLog.infox('MxCommForm.formLoad', '...result[0][0]:', result[0][0]);
            rf_txClient.current.setKey(result[0][0].inClient || '');
            rf_txClient.current.setVal(result[0][0].txClient || '');
            let nuMailing = result[0][0].nuMailing || '';
            let txMobile = result[0][0].txMobile || '';
            let txEmail = result[0][0].txEmail || '';
            rf_ori_mailing.current.setVal(nuMailing);
            rf_ori_mobile.current.setVal(txMobile);
            rf_ori_email.current.setVal(txEmail);
            setMethodAccordingToClientFile(nuMailing, txMobile, txEmail);
          }
        });
      }
      if ((isApp('vett') || isApp('spaw'))) {
        if (storeComm.inAnimal) {
          rf_txAnimal.current.setKeyAndVal('n_animal', 'txAnimal', 'inAnimal', storeComm.inAnimal);
        }
      }
      if (storeComm.dtFrom) {
        rf_date.current.setVal(storeComm.dtFrom);
        rf_time.current.setVal('08:00');
      }
      if (isApp('opti')) {
        if (storeComm.inRepl) {
          rf_inRepl.current.setVal(storeComm.inRepl);
        }
      }
      if (isApp('vett')) {
        if (storeComm.inVacc) {
          rf_inVacc.current.setVal(storeComm.inVacc);
        }
      }

    } else {
      let isDisabled = data[0] && data[0].dtInformed ? true : false;
      FxLog.infox('MxCommForm.formLoad', '...isDisabled:', isDisabled);
      r_table_attachment_blob.current.setIsDisabled(isDisabled);

      // 2024-01-31 - daq: si es de tipo "Pendiente" no deshabilita - Para Inés Laguna.
      let type = rf_txTypeComm.current.getVal();
      if (type === trans('field.pending')) {
        setIsDisabled(false);
      } else {
        setIsDisabled(isDisabled);
      }

      setIsMethodPopUp(rf_boPopup.current.isChecked());
    }
  }
  const formSave = async (lastSaveRv) => {
    storeComm.inComm = lastSaveRv.theValue;
    Promise.all([setStoreComm(storeComm)]).then((result) => {
      //navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  const clientChanged = async (inClient, txClient, nuMailing, txMobile, txEmail) => {
    if (inClient !== null) {
      if (!isMethodPopup) {
        r_form.current.getRef(`n_comm.txSms`).clear();
        r_form.current.getRef(`n_comm.txEmail`).clear();
      }
      FxLog.infox('MxCommForm.clientChanged', '...nuMailing/txMobile/txEmail:', `${nuMailing}/${txMobile}/${txEmail}`);
      rf_ori_mailing.current.setVal(nuMailing||'');
      rf_ori_mobile.current.setVal(txMobile||'');
      rf_ori_email.current.setVal(txEmail||'');
      if (nuMailing) {
        setMethodAccordingToClientFile(nuMailing, txMobile, txEmail);
      }
    }
  }

  const setMethodAccordingToClientFile = (nuMailing, txMobile, txEmail) => {
    // cambia método de envío de acuerdo a lo configurado en campo Mailing de ficha de cliente.
    FxLog.infox('MxCommForm.setMethodAccordingToClientFile', '...nuMailing', nuMailing);
    switch (nuMailing) {
      case '0': // No
        // Avisa que el cliente está configurado para no recibir notificaciones
        r_snack_error.current.setVal(trans('msg.client_mailing_no'));
        r_snack_error.current.setOpen(true);
        break;

      case '1': // Yes
        // No hace nada
        break;

      case '2': // Email
        // Cambia a método de envío por email
        r_snack_warning.current.setVal(trans('msg.client_mailing_method', 'field.email'));
        r_snack_warning.current.setOpen(true);
        rf_boPopup.current.setVal('0');
        Promise.all([setIsMethodPopUp(false)]).then((result) => {
          rf_txEmail.current.setVal(txEmail);
        });
        break;
        
        case '3': // Postal
        // Avisa que el cliente está configurado para envío postal.
        r_snack_warning.current.setVal(trans('msg.client_mailing_method', 'field.postal'));
        r_snack_warning.current.setOpen(true);
        break;
        
        case '4': // SMS
        // Cambia a método de envío por sms.
        r_snack_warning.current.setVal(trans('msg.client_mailing_method', 'field.sms'));
        r_snack_warning.current.setOpen(true);
        rf_boPopup.current.setVal('0');
        Promise.all([setIsMethodPopUp(false)]).then((result) => {
          rf_txSms.current.setVal(txMobile);
        });
        break;

      default: break;
    }
  }

  const getValueEmail = () => {
    if (rf_txClient.current.isEmpty()) {
      r_snack_error.current.setVal(trans('msg.select_x', 'field.client'));
      r_snack_error.current.setOpen(true);
      return;
    }
    if (!rf_ori_email.current.getVal()) {
      r_snack_error.current.setVal(trans('msg.no_x_email_in_file', 'field.client'));
      r_snack_error.current.setOpen(true);
      return;
    }
    r_form.current.getRef(`n_comm.txEmail`).setVal(rf_ori_email.current.getVal());
  }

  const getValueSms = () => {
    if (rf_txClient.current.isEmpty()) {
      r_snack_error.current.setVal(trans('msg.select_x', 'field.client'));
      r_snack_error.current.setOpen(true);
      return;
    }
    if (!rf_ori_mobile.current.getVal()) {
      r_snack_error.current.setVal(trans('msg.no_x_mobile_in_file', 'field.client'));
      r_snack_error.current.setOpen(true);
      return;
    }
    r_form.current.getRef(`n_comm.txSms`).setVal(rf_ori_mobile.current.getVal());
  }

  const changedSendMethod = (isChecked) => {
    if (isChecked) {
      //rf_txSms.current.clear();
      //rf_txEmail.current.clear();
      setIsMethodPopUp(true);
    } else {
      setIsMethodPopUp(false);
    }
  }

  const doTemplateSelected = (name, cont) => {
    let record = {};
    record['date'] = rf_date.current.getVal();
    record['time'] = rf_time.current.getVal();
    record['txContent'] = cont;
    record['inClient'] = rf_txClient.current.getKey();
    if ((isApp('vett') || isApp('spaw'))) {
      record['inAnimal'] = rf_txAnimal.current.getKey();
    }
    Promise.all([FxFetch.doJsonX('comms/replace_placeholders', record)]).then((result) => {
      if (result[0]) {
        if (result[0].theStatus === 'error' && result[0].theValue === 'rv_template_needs_date') {
          r_snack_error.current.setVal(trans('msg.template_requires_x', 'field.date'));
          r_snack_error.current.setOpen(true);
          rf_txContent.current.clear();
        }
        if (result[0].theStatus === 'error' && result[0].theValue === 'rv_template_needs_time') {
          r_snack_error.current.setVal(trans('msg.template_requires_x', 'field.time'));
          r_snack_error.current.setOpen(true);
          rf_txContent.current.clear();
        }
        if (result[0].theStatus === 'error' && result[0].theValue === 'rv_template_needs_client') {
          r_snack_error.current.setVal(trans('msg.template_requires_x', 'field.client'));
          r_snack_error.current.setOpen(true);
          rf_txContent.current.clear();
        }
        if (result[0].theStatus === 'error' && result[0].theValue === 'rv_template_needs_animal') {
          r_snack_error.current.setVal(trans('msg.template_requires_x', 'field.patient'));
          r_snack_error.current.setOpen(true);
          rf_txContent.current.clear();
        }
        if (result[0].theStatus === 'OK') {
          rf_txContent.current.setVal(result[0].theValue);
        }
      }
    });
  }

  const doCheckSmsCredits = () => {
    try {
      Promise.all([FxSms.getCredits()]).then((result) => {
        setSmsCredits(result);
      });

    } catch (error) {
      FxLog.errorx("MxCommForm.doCheckSmsCredits", error);
    }
  };

  const reenable = () => {
    try {
      let record = {};
      record['inComm'] = storeComm.inComm;
      Promise.all([FxFetch.doJsonX('comms/reenable', record)]).then((result) => {
        if (result[0]) {
          if (result[0].theStatus === 'OK') {
            FxLog.infox('MxCommForm.reenable', '...', );
            setIsDisabled(false);
            rf_date_informed.current.clear();
            rf_time_informed.current.clear();
            rf_txResponse.current.clear();
            r_table_attachment_blob.current.setIsDisabled(false);
          }
        }
      });

    } catch (error) {
      FxLog.errorx('MxCommForm.reenable', error)
    }
  }

  return (
    <div>
      <GxForm
        xref={r_form}
        moduleName={`${trans('field.comm')}`}
        url="comms"
        filterIdField='inComm'
        filterIdValue={storeComm.inComm}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave} // 2024-05-03 - daq: aunque haya sido enviado, debo poder guardar el campo Respuesta.
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de cargar último cliente utilizado */}
            <GxIconButtonLastClient2 theInput={rf_txClient} onClientChanged={clientChanged} />

            {/* botón para compra de sms */}
            <CxIconButtonNew icon={<BsCartFill fontSize={22} />} classType="ibs_iconbutton_toolbar" onClick={() => r_dlg_sms_purchase.current.setOpen(true)} tooltip={`SMS - ${trans('field.purchase')}`} />

            {/* botón para renovar */}
            {isDisabled && <CxIconButtonNew icon={<BiReset fontSize={24} />} classType="ibs_iconbutton_toolbar" onClick={reenable} tooltip={`${trans('field.reenable')}`} />}

          </div>
        }

      >
        <CxHidden xref={rf_inPs} dbfield="n_comm.inPs" value={storeComm.inPs} />
        
        <CxHidden xref={rf_inRepl} dbfield="_inRepl" />
        <CxHidden xref={rf_inVacc} dbfield="_inVacc" />

        <CxHidden xref={rf_ori_mailing} dbfield="oriMailing" />
        <CxHidden xref={rf_ori_mobile} dbfield="oriMobile" />
        <CxHidden xref={rf_ori_email} dbfield="oriEmail" />


        <CxDate xref={rf_date} label={`${trans('field.date')} - ${trans('field.dispatch')}`} dbfield={`n_comm.dtFrom`} required disabled={isDisabled} />
        <CxTime xref={rf_time} label={`${trans('field.time')} - ${trans('field.dispatch')}`} dbfield={`n_comm.dtFrom`} required disabled={isDisabled} />

        <CxChooser xref={rf_txTypeComm} label={trans('field.type')}
          dbfield="t_comm.txTypeComm" dbfieldkey={`n_comm.itComm`}
          table="t_comm"
          col_id="itComm"
          col_tx="txTypeComm"
          required
          disabled={isDisabled}
        />

        <GxSearcherClient xref={rf_txClient} 
          dbfield={`n_comm.txClient`} dbfieldkey={`n_comm.inClient`}
          animalRef={rf_txAnimal}
          onSelection={(row) => clientChanged(row.original.inClient, row.original.txClient, row.original.nuMailing, row.original.txMobile, row.original.txEmail)}
          onClear={() => { 
            if (rf_txEmail.current) rf_txEmail.current.clear(); 
            if (rf_txSms.current) rf_txSms.current.clear(); 
          }}
          disabled={isDisabled}
        />
        {(isApp('vett') || isApp('spaw')) && 
          <GxSearcherAnimal xref={rf_txAnimal} 
            dbfield={`n_comm.txAnimal`} dbfieldkey={`n_comm.inAnimal`} 
            clientRef={rf_txClient} 
            disabled={isDisabled} 
        />}

        <CxSwitch xref={rf_boPopup} label={`${trans('field.popup_window')} + Whatsapp`} dbfield="n_comm.boPopup" onChange={changedSendMethod} disabled={isDisabled} />

        {!isMethodPopup &&
          <CxInput xref={rf_txEmail} label={trans('field.email')} dbfield="n_comm.txEmail" onGetValue={getValueEmail} disabled={isDisabled} />}
        {!isMethodPopup &&
          <CxInput xref={rf_txSms} label={`${trans('field.sms')} - ${trans('field.credits')}: ${(smsCredits || '0.00')}`} dbfield="n_comm.txSms" onGetValue={getValueSms} disabled={isDisabled} />}

        <CxInputTemplate xref={rf_txContent} label={trans('field.message')} dbfield={`n_comm.txContent`} minRows={8} disabled={isDisabled} required
          tpl_table={"n_comm_tpl"} tpl_col_id={"inCommTpl"} tpl_col_tx={"txName"} tpl_filter={""} tpl_extra_filter_1={""} tpl_content_field={"txContent"}
          hasTplButtonAdd
          onTemplateSelected={doTemplateSelected}
          placeholders={
            (isApp('vett') || isApp('spaw')) ?
              [
                { id: "[date]", type: trans('field.date') },
                { id: "[time]", type: trans('field.time') },
                { id: "[name]", type: trans('field.name') },
                { id: "[first_name]", type: trans('field.name_first') },
                { id: "[last_name]", type: trans('field.name_last') },
                { id: "[contact]", type: trans('field.contact') },
                { id: "[animal]", type: trans('field.patient') },
                { id: "[chip]", type: trans('field.chip') },
              ] : [
                { id: "[date]", type: trans('field.date') },
                { id: "[time]", type: trans('field.time') },
                { id: "[name]", type: trans('field.name') },
                { id: "[first_name]", type: trans('field.name_first') },
                { id: "[last_name]", type: trans('field.name_last') },
                { id: "[contact]", type: trans('field.contact') },
              ]
          }
          speech
        />

        <CxTableAttachmentBlob xref={r_table_attachment_blob}
          title={`${trans('field.attachments')} PDF`}
          fileFilter=".pdf"
          margin='5px 0 30px 0'
          inXxx={storeComm.inComm}
          url={'comms'}
          table={'n_comm_blob'}
          fld_inXxx={'inComm'}
          fld_inXxxBlob={'inCommBlob'}
          fld_txXxxBlob={'txCommBlob'}
          disabled={isDisabled}
        />

        <CxDate xref={rf_date_informed} label={`${trans('field.date')} - ${trans('field.informed')}`} dbfield={`n_comm.dtInformed`} disabled={isDisabled} />
        <CxTime xref={rf_time_informed} label={`${trans('field.time')} - ${trans('field.informed')}`} dbfield={`n_comm.dtInformed`} disabled={isDisabled} />

        <CxInput xref={rf_txResponse} label={trans('field.response')} dbfield={`n_comm.txResponse`} minRows={4} speech />

      </GxForm>

      <CxDlgSmsPurchase xref={r_dlg_sms_purchase} />

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_warning} severity="warning" />

    </div>
  );

}

export default MxCommForm;
