import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreSettEmployee } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxSettEmployeeForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const [storeSettEmployee, setStoreSettEmployee] = useSessionStorage('StoreSettEmployee', StoreSettEmployee);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettEmployee.itEmployee = lastSaveRv.theValue;
      Promise.all([setStoreSettEmployee(storeSettEmployee)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.type')} - ${trans('field.employee')}`}
        xref={r_form}
        url="sett/employee" 
        filterIdField='itEmployee'
        filterIdValue={storeSettEmployee.itEmployee} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_employee.itEmployee" value={storeSettEmployee.itEmployee || ''} />
        <CxInput xref={useRef(null)} label={`${trans('field.type')} - ${trans('field.employee')}`} dbfield="t_employee.txTypeEmployee" required />
        
      </GxForm>

    </div>
  );

}

export default MxSettEmployeeForm;
