import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import CxIconButton from '../cx/CxIconButton';
import CxSnackbar from '../cx/CxSnackbar';
import { useTranslation } from '../hx/useTranslation';


const GxIconButtonLastClient2 = props => {
  const { theList, theTable, theInput, onClientChanged, ...rest } = props;
  const [{ trans }] = useTranslation();
  const [visible, setVisible] = React.useState(rest.visible || true);

  let r_snack_error = React.useRef(null);
  
  
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setVisible
    };
  });
  const getType = () => { return 'GxIconButtonLastClient2' };

  const doLastClientSearch = () => {
    let lastInClient = (localStorage.getItem('MxClientForm.last_client') || '');
    if (lastInClient) {
        let record = {};
        record['idValue'] = lastInClient;
        Promise.all([FxFetch.doJsonF("clients/r", null, record)]).then((result) => {
          if (result[0]) {
            let r = result[0][0];
            if (r) {
              let lastTxClient = r.txClient;
              theInput.current.setClient(lastInClient, lastTxClient);
              if (onClientChanged) {
                onClientChanged(lastInClient, lastTxClient, r.nuMailing, r.txMobile, r.txEmail);
              }
            }
          }
        });

    } else {
      r_snack_error.current.setVal(trans('msg.no_x_file_opened','field.client'));
      r_snack_error.current.setOpen(true);
    }
  }

  return (
    <div>
      {visible && 
        <CxIconButton type="last_client_search" classType="ibs_iconbutton_toolbar" onClick={doLastClientSearch} tooltip={trans('field.search_last_x', 'field.client')} />
      }
      <CxSnackbar xref={r_snack_error} severity="error" />
    </div>
  );

};

export default GxIconButtonLastClient2;
