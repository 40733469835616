import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxCombo from '../../../cx/CxCombo';
import CxSnackbar from '../../../cx/CxSnackbar';
import '../../../../styles/main.css';
import { getStoreValue, } from "../../../gx/GxStore";


const MxAnimalOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, ] = React.useState(false);
  const [inClient, setInClient] = React.useState('');

  const rf_txAnimal = React.useRef(null);
  const rf_txNumberAnimals = React.useRef(null);
  const rf_boGender = React.useRef(null);
  const rf_txChip = React.useRef(null);
  const rf_txNotes = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg, setInClient,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txAnimal.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txAnimal.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txAnimal = rf_txAnimal.current.getVal();
    let txNumberAnimals = rf_txNumberAnimals.current.getVal();
    let boGender = rf_boGender.current.getVal();
    let txChip = rf_txChip.current.getVal();
    let txNotes = rf_txNotes.current.getVal();

    //setDisabled(true);

    let record = {};
    record['inClient'] = inClient;
    record['inEmployee'] = getStoreValue('StoreSession', 'inEmployee');
    record['txAnimal'] = txAnimal;
    record['txNumberAnimals'] = txNumberAnimals;
    record['boGender'] = boGender;
    record['txChip'] = txChip;
    record['txNotes'] = txNotes;

    Promise.all([FxFetch.doJsonX("animals/otf", record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === null) {
          FxLog.infox("MxAnimalOTF.formSave", "El animal ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists', 'field.patient'));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxAnimalOTF.formSave", "El animal ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let inAnimal = result[0].theValue;
          if (props.onOk) props.onOk(inAnimal, txAnimal);
          setDlg(false);
        }
      }
    });

  }

  return (
    <div>
      <CxDialog title={trans('field.create_x', 'field.patient')} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

        <CxInput xref={rf_txAnimal} label={trans('field.patient')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={50} required />
        <CxInput xref={rf_txNumberAnimals} label={trans('field.number_animals')} disabled={disabled} width='var(--ibs_control_width_md)' defaultValue={'1'} maxLength={10} />
        <CxCombo xref={rf_boGender}
          label={`${trans('field.gender')}`}
          dbfield='n_animal.boGender'
          list={[
            { id: '0', type: trans('field.animal_female') },
            { id: '1', type: trans('field.animal_male') },
            { id: '2', type: trans('field.na') }, //para animales no sexados
          ]}
          width='var(--ibs_control_width_md)'
        />
        <CxInput xref={rf_txChip} label={trans('field.chip')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={23} />
        <CxInput xref={rf_txNotes} label={trans('field.notes')} disabled={disabled} width='var(--ibs_control_width_md)' minRows={4} />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />

    </div>
  );

}

export default MxAnimalOTF;
