import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxDat from '../../fx/FxDat';
import { useNav } from '../../hx/useNav';

import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreReferrer } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';


const MxReferrerList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeReferrer, setStoreReferrer] = useSessionStorage('StoreReferrer', StoreReferrer);

  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreReferrer', storeReferrer);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    if (row === null) {
      storeReferrer.inReferrer = null;
    } else {
      storeReferrer.inReferrer = row.original.inReferrer;
    }
    setStoreReferrer(storeReferrer);

    navTo("referrer-form");
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: "inReferrer", show: false });
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: trans('field.referrer'), accessor: row => r_list.current.getTable().highlightText(row["txReferrer"]), width: '40%' });
        cols.push({ id: id++, Header: trans('field.contact'), accessor: "txContact", width: '20%' });
        cols.push({ id: id++, Header: trans('field.email'), accessor: "txEmail", width: '20%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '10%' });
        cols.push({ id: id++, Header: trans('field.from'), accessor: row => ( FxTable.cellToLocalDate(row["dtFrom"]) ), width: '10%' });
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: trans('field.referrer'), accessor: row => r_list.current.getTable().highlightText(row["txReferrer"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.mobile'), accessor: "txMobile", width: '30%' });
      }
      cols.push({ id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false });

    } catch(error) {
      FxLog.errorx('MxReferrerList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.referrers')}`}
        xref={r_list}
        tooltip={`${trans('field.referrer')}`}
        fullscreen
        url="referrers"
        onOpenForm={openForm}
        refColors={ [ 'table_cell_color_gray|' + trans('field.inactive') ] }
        columns={getTableColumns()}
      />

    </div>
  );

}

export default MxReferrerList;
