import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxState from '../../cx/CxState';
import CxBackdrop from '../../cx/CxBackdrop';
import CxIconButton from '../../cx/CxIconButton';
import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import { StoreSettSecGroup } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import CxAccordion from '../../cx/CxAccordion';
import CxDialog from '../../cx/CxDialog';
import * as FxFetch from '../../fx/FxFetch';
import { isApp } from '../../gx/GxStore';
import { useAccess } from '../../hx/useAccess';
import { useNav } from '../../hx/useNav';


const MxSettSecGroupForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.security'));
  const [{ navBack }] = useNav();

  const [lsItems, setLsItems] = React.useState([]);
  const [backdrop, setBackdrop] = React.useState(false);

  const [dlgDelete, setDlgDelete] = React.useState(false);

  let r_form = React.useRef(null);

  const rf_txSecGroup = React.useRef(null);

  const r_snack_save = React.useRef(null);
  const r_snack_deactivated = React.useRef(null);
  const r_snack_error = React.useRef(null);
  let r_error_message = React.useRef(null);

  const [storeSettSecGroup, setStoreSettSecGroup] = useSessionStorage('StoreSettSecGroup', StoreSettSecGroup);

  let arBicolor = [ 
    trans('field.stats'), 
    trans('field.options'), 
    trans('field.marketing'), 
    trans('field.analytics'), 
    trans('field.mass_billing'), 
    trans('field.plans'), 
  ];


  React.useEffect(() => {
    if (storeSettSecGroup.inSecGroup) {
      fillAccess();
    }
    // eslint-disable-next-line
  }, []);

  const fillAccess = () => {
    try {
      // MÓDULOS QUE YA ESTÁN EN FLOW. Lista sacada del backend: LSecurityAccess.getSAX()
      let arFlowCurrentModules = [];
      arFlowCurrentModules.push(trans('field.clients'));
      arFlowCurrentModules.push(trans('field.employees'));
      arFlowCurrentModules.push(trans('field.suppliers'));
      arFlowCurrentModules.push(trans('field.pss'));
      arFlowCurrentModules.push(trans('field.pos'));
      arFlowCurrentModules.push(trans('field.cash_reg'));
      arFlowCurrentModules.push(trans('field.stockrooms'));
      arFlowCurrentModules.push(trans('field.stats'));
      arFlowCurrentModules.push(trans('field.comms'));
      arFlowCurrentModules.push(trans('field.calendar'));
      arFlowCurrentModules.push(trans('field.branches'));
      arFlowCurrentModules.push(trans('field.options'));
      arFlowCurrentModules.push(trans('field.security'));
      arFlowCurrentModules.push(trans('field.images'));
      arFlowCurrentModules.push(trans('field.multimedia'));
      arFlowCurrentModules.push(trans('field.marketing'));
      arFlowCurrentModules.push(trans('field.analytics'));
      arFlowCurrentModules.push(trans('field.mass_billing'));
      arFlowCurrentModules.push(trans('field.plans'));
      if (isApp('opti')) {
        arFlowCurrentModules.push(trans('field.rx_glas'));
        arFlowCurrentModules.push(trans('field.rx_clen'));
        arFlowCurrentModules.push(trans('field.rx_haid'));
        arFlowCurrentModules.push(trans('field.repls'));
        arFlowCurrentModules.push(trans('field.labs'));
        arFlowCurrentModules.push(trans('field.lab_orders'));
      }
      if (isApp('vett')) {
        arFlowCurrentModules.push(trans('field.patients'));
        arFlowCurrentModules.push(trans('field.waiting_room'));
        arFlowCurrentModules.push(trans('field.cnss'));
        arFlowCurrentModules.push(trans('field.vaccs'));
        arFlowCurrentModules.push(trans('field.grooming'));
        arFlowCurrentModules.push(trans('field.rxs'));
      }
      if (isApp('spaw')) {
        arFlowCurrentModules.push(trans('field.patients'));
        arFlowCurrentModules.push(trans('field.waiting_room'));
        arFlowCurrentModules.push(trans('field.grooming'));
      }

      let record = {};
      record['idValue'] = storeSettSecGroup.inSecGroup;
      Promise.all([FxFetch.doJsonX('sett/sec/access', record)]).then((result) => {
        if (result[0]) {
          let arFinal = result[0].filter(mod => {
            return arFlowCurrentModules.includes(mod.txSecAccess)
          });
          setLsItems(arFinal);
        }
      });
      
    } catch (error) {
      FxLog.errorx("MxSettSecGroupForm.fillAccess", error);
    }
  }

  const isMasterGroup = () => { return Boolean(storeSettSecGroup.inSecGroup==='000001.SRV') };

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
    } else { // registro existente
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }
    setBackdrop(true);

    let filter = {};
    filter['inSecGroup'] = storeSettSecGroup.inSecGroup;
    let record = {};
    record['n_sec_group.txSecGroup'] = rf_txSecGroup.current.getVal();
    Promise.all([FxFetch.doJsonF(`sett/sec/groups/cu`, filter, record)]).then((resultSave) => {
      if (resultSave[0]) {
        if (resultSave[0].theStatus === 'error') {
          if (resultSave[0].theValue.startsWith("rv_")) {
            // errores al guardar
            if (resultSave[0].theValue.startsWith("rv_name_exists")) {
              r_snack_error.current.setVal(trans('msg.existing_x','field.name'));
            }
            r_snack_error.current.setOpen(true);
            setBackdrop(false);
          }
        } else {
          // se guardó exitosamente
          storeSettSecGroup.inSecGroup = resultSave[0].theValue;
          Promise.all([setStoreSettSecGroup(storeSettSecGroup)]).then((result) => {
            fillAccess();
            r_snack_save.current.setOpen(true);
            setBackdrop(false);
          });
        }
      }
    });
  }

  const formDeleteCustom = () => {
    setDlgDelete(true);
  }

  const doDelete = () => {

    //-----------------------------------------------------------------------
    setBackdrop(true);

    let filter = {}; let record = {};
    filter['inSecGroup'] = storeSettSecGroup.inSecGroup;
    Promise.all([FxFetch.doJsonF(`sett/sec/groups/d`, filter, record)]).then((result) => {
      if (result[0]) {
      } else {
      }
      r_snack_deactivated.current.setOpen(true);
    });
    //-----------------------------------------------------------------------
  }

  const getValue = (option) => {
    let rv = '0';
    switch (option.txType) {
      case 'W': rv = '0'; break;
      case 'N': rv = '1'; break;
      case 'R': rv = '2'; break;
      default: break;
    }
    return rv;
  }

  const saveAccessItem = (id, newValue) => {
    let txType = 'W';
    switch (newValue) {
      case '0': txType = 'W'; break;
      case '1': txType = 'N'; break;
      case '2': txType = 'R'; break;
      default: break;
    }
    try {
      let filter = {};
      filter['irGroupAccess'] = id;
      let record = {};
      record['r_group_access.txType'] = txType;
      Promise.all([FxFetch.doJsonF("sett/sec/groups/set_access", filter, record)]).then((result) => {
        if (result[0]) {
        }
      });

    } catch (error) {
      FxLog.errorx('MxSettSecGroupForm.saveAccessItem', error);
    }
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.group')}
        xref={r_form}
        url="sett/sec/groups"
        filterIdField='inSecGroup'
        filterIdValue={storeSettSecGroup.inSecGroup}
        onFormLoad={formLoad}
        /* onFormSave={isFullAccess() && !isMasterGroup() && formSave} */
        /* onFormDelete={isFullAccess() && !isMasterGroup() && formDelete} */
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón eliminar. Sólo eliminar lo que no es factura ni relacionado a factura. */}
            {isFullAccess() && !isMasterGroup() &&  <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}

            {/* botón guardar. Incluso la factura puede guardar cambios para poder cambiar las notas. */}
            {isFullAccess() && !isMasterGroup() && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_sec_group.inSecGroup" value={storeSettSecGroup.inSecGroup || ''} />
        <CxInput xref={rf_txSecGroup} label={trans('field.group')} dbfield="n_sec_group.txSecGroup" required maxLength={10} disabled={isMasterGroup()} />

      </GxForm>

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxAccordion defaultExpanded title={trans('field.access')}>
          {lsItems.map((option) => (
            <CxState
              mode={option.txSecAccess.includes(':') || arBicolor.includes(option.txSecAccess) ? 'bicolor' : 'tricolor'}
              id={option.irGroupAccess}
              key={option.irGroupAccess} // necesario para react
              label={option.txSecAccess}
              value={getValue(option)}
              onChange={saveAccessItem}
              disabled={!isFullAccess() || isMasterGroup()}
            />
          ))}
        </CxAccordion>
      </div>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <CxBackdrop open={backdrop} />
      <CxSnackbar xref={r_snack_error} severity="error" value={r_error_message.current} />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} />
      <CxSnackbar xref={r_snack_deactivated} severity="warning" value={trans('msg.deactivated')} onClose={() => { navBack(); }} />

    </div>
  );

}

export default MxSettSecGroupForm;
