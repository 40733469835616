import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import GxList from '../../gx/GxList';
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { clearStore, StoreSettSecUser } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useWindowSize } from '../../hx/useWindowSize';


const MxSettSecUserList = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ navTo }] = useNav();

  const [storeSettSecUser, setStoreSettSecUser] = useSessionStorage('StoreSettSecUser', StoreSettSecUser);
  const r_list = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreSettSecUser', storeSettSecUser);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettSecUser.inUser = null;
    } else {
      storeSettSecUser.inUser = row.original.inUser;
    }
    setStoreSettSecUser(storeSettSecUser);
    navTo("sett-sec-user-form");
  }

  const getOptions = () => {
    return [
      { id: 'E', text: trans('field.employee') },
      { id: 'C', text: trans('field.client') },
    ];
  }

  const getTableColumns = () => {
    let cols = [];
    try{
      let id = -1;
      if (size.isLarge) { // PANTALLA GRANDE
        cols.push({ id: id++, Header: "ID", accessor: "inUser", show: false });
        cols.push({ id: id++, Header: trans('field.user'), accessor: row => r_list.current.getTable().highlightText(row["txUser"]), width: '34%' });
        cols.push({ id: id++, Header: trans('field.type'), accessor:  row => FxTable.replace(row["txType"], getOptions()), width: '33%' });
        cols.push({ id: id++, Header: trans('field.group'), accessor:  row => (row["txType"]==='E' && row["txSecGroup"]), width: '33%' }); // no muestro xq si es un Cliente, no debe verse del grupo Master.
        
      } else { // PANTALLA PEQUEÑA
        cols.push({ id: id++, Header: "ID", accessor: "inUser", show: false });
        cols.push({ id: id++, Header: trans('field.user'), accessor: row => r_list.current.getTable().highlightText(row["txUser"]), width: '70%' });
        cols.push({ id: id++, Header: trans('field.type'), accessor:  row => FxTable.replace(row["txType"], getOptions()), width: '30%' });
        
      }

      cols.push({ id: "B", Header: "is_client", accessor: row => Boolean(row["txType"]==='C'), show: false });

    } catch(error) {
      FxLog.errorx('MxSettSecUserList.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={trans('field.users')}
        xref={r_list}
        tooltip={`${trans('field.user')}`}
        fullscreen
        url="sett/sec/users"
        onOpenForm={openForm}
        refColors={ [ 
          'table_cell_black|' + trans('field.employee'), 
          'table_cell_color_blue|' + trans('field.client'), 
        ] }
        columns={getTableColumns()}
      />

    </div>
  );
}

export default MxSettSecUserList;
