import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
//import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxTime from '../../cx/CxTime';
import CxState from '../../cx/CxState';
import CxAccordion from '../../cx/CxAccordion';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import { StoreClient, StoreCns } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';
import CxStatic from '../../cx/CxStatic';


const MxAnimalCnsHosFormItem = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);
  const [storeCns, setStoreCns] = useSessionStorage('StoreCns', StoreCns);

  const r_snack_ask_save = React.useRef(null);
  
  let r_form = React.useRef(null);

  let rf_date = React.useRef(null);
  let rf_time = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      rf_time.current.setValNow();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeCns.inCnsHospitalItem = lastSaveRv.theValue;
    Promise.all([setStoreCns(storeCns)]).then((result) => {
      //navBack(); 
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.patient')}: ${trans('field.cns')}-${trans('field.cns_hos')}`}
        xref={r_form}
        url="cnss/hos_guidelines"
        filterIdField='inCnsHospitalItem'
        filterIdValue={storeCns.inCnsHospitalItem}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_cns_hospital_item.inCns" value={storeCns.inCns || ''} />

        <CxStatic label={`${FxStr.join(' / ', storeClient.txClient, storeClient.txAnimal)}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_cns_hospital_item.dtFrom`} required />
        <CxTime xref={rf_time} label={trans('field.time')} dbfield={`n_cns_hospital_item.dtFrom`} required />

        <CxInput xref={useRef(null)} label={trans('field.guideline')} dbfield="n_cns_hospital_item.txGuideline" maxLength={100} minRows={1} required />

        <CxAccordion title={trans('field.hos_gln_gpe')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_muc')} dbfield="n_cns_hospital_item.txGpeMuc" minRows={4} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_crt')} dbfield="n_cns_hospital_item.txGpeCrt" maxLength={25} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_tmp')} dbfield="n_cns_hospital_item.txGpeHea" maxLength={25} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_hea')} dbfield="n_cns_hospital_item.txGpeTmp" maxLength={25} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_pul')} dbfield="n_cns_hospital_item.txGpePul" maxLength={25} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_cpa')} dbfield="n_cns_hospital_item.txGpeCpa" minRows={4} />
          <CxInput xref={useRef(null)} label={trans('field.hos_gln_gpe_abd')} dbfield="n_cns_hospital_item.txGpeAbd" minRows={4} />
        </CxAccordion>

        <CxAccordion title={trans('field.hos_gln_vdus')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txVdus" minRows={4} />
        </CxAccordion>

        <CxAccordion title={trans('field.hos_gln_medic')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed01" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed01" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed02" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed02" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed03" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed03" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed04" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed04" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed05" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed05" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed06" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed06" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed07" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed07" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed08" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed08" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed09" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed09" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed10" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed10" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed11" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed11" label={trans('field.administered')} width='150px' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
            <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txMed12" label={trans('field.drug')} maxLength={200} width='100%' />
            <CxState mode='checkbox' xref={useRef(null)} dbfield="n_cns_hospital_item.boMed12" label={trans('field.administered')} width='150px' />
          </div>

        </CxAccordion>

        <CxAccordion title={trans('field.hos_gln_analysis')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txAnalysis" minRows={4} />
        </CxAccordion>

        <CxAccordion title={trans('field.hos_gln_remarks')} background={"var(--ibs_color_gray_light)"} color={"var(--ibs_color_black)"} defaultExpanded >
          <CxInput xref={useRef(null)} dbfield="n_cns_hospital_item.txRemarks" minRows={4} />
        </CxAccordion>

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxAnimalCnsHosFormItem;
