import { BiTrash } from "react-icons/bi";
import { BiSave } from "react-icons/bi";
import { AiFillCaretLeft } from "react-icons/ai"; 
import CxIcon from '../cx/CxIcon.jsx';
import CxIconButtonNew from '../cx/CxIconButtonNew';
import React, { useEffect, useRef } from 'react';
import { useNav } from './../hx/useNav';
import { useTranslation } from '../hx/useTranslation';
import '../../styles/main.css';
import * as FxFetch from '../fx/FxFetch';
import * as FxLog from '../fx/FxLog';
import CxDialog from './../cx/CxDialog';
import CxSnackbar from './../cx/CxSnackbar';
import CxBackdrop from './../cx/CxBackdrop';
import CxToolbar from '../cx/CxToolbar';
import CxProgress from '../cx/CxProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    marginTop: '-15px', // 2024.07.05
  },
  toolbar: {
    background: 'var(--ibs_color_white)',
    position: 'fixed',// 2024.07.05
    zIndex: 1,// 2024.07.05
    padding: '5px 0 0 0', // 2024.07.05
    width: '100%',
    [theme.breakpoints.up('sm')]: { // 2024.07.05
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    padding: '55px 0 0 0', // 2024.07.05
    margin: 'var(--ibs_container_margin)',
  },
  alert: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    gap: 'var(--ibs_container_gap)',
    margin: 'var(--ibs_container_margin)',
    background: 'var(--ibs_color_red)',
    color: 'white',
    padding: '5px 10px 5px 10px',
  },
}));

const GxForm = (props) => {
  const [{ trans }] = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ navBack }] = useNav();

  let propFilter = { idField: props.filterIdField, idValue: props.filterIdValue };

  let recIdValue = React.useRef(null);

  let lastSaveRv = null;
  let boSaving = React.useRef(false);

  const refs = useRef([]);

  const r_snack_required = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_snack_deactivate = React.useRef(null);

  const [moduleName, setModuleName] = React.useState(props.moduleName);
  const [progress, setProgress] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);
  const [dlgDelete, setDlgDelete] = React.useState(false);

  React.useImperativeHandle(props.xref, () => {
    return {
      getRef, formSave, wasChanged, resetChanged, hasIncompleteRequired, formReload, setPropFilter, setModuleName
    };
  });

  const setPropFilter = (filterIdField, filterIdValue) => {
    propFilter.idField = filterIdField;
    propFilter.idValue = filterIdValue;
    recIdValue.current = filterIdValue;
  }

  /* Permite obtener una referencia a un control, así: r_form.current.getRef("n_cns.dtFrom").setValToday(); */
  const getRef = (dbfield) => {
    let rv = null;
    refs.current.forEach((ref, index) => {
      if (ref) {
        if (ref.props !== undefined && ref.props.xref !== undefined && ref.props.xref.current.getDbfield !== undefined) {
          if (ref.props.xref.current.getDbfield() === dbfield) {
            rv = ref.props.xref.current;
          }
        }
      }
    });
    return rv;
  };

  useEffect(() => {
    try {
      // 2022-09-30 - daq: sube al inicio de la página
      window.scrollTo(0, 0);

      //enlaza refs a controles
      //desde afuera, puede accederse a ellas así: -> refs.current[3].props.xref.current.setValToday();
      if (Array.isArray(props.children)) {
        props.children.forEach(
          child => {
            if (child && child.props && child.props.children) {
              Array.from(child.props.children).forEach(
                child2 => {
                  refs.current.push(child2);
                }
              )
            } else {
              refs.current.push(child);
            }
          }
        )
      } else {
        // child no es un array!
        refs.current.push(props.children);
      }

      recIdValue.current = propFilter.idValue;
      if (recIdValue.current) {
        // reg existente
        let url = props.url + "/r";
        if (props.url) {
          setProgress(true);
          let record = {};
          record['idValue'] = recIdValue.current;
          Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
            if (result[0]) {
              sendObjToFrm(result[0], props.children);
              if (props.onFormLoad) {
                props.onFormLoad(result[0]); //callback
              }
            }
            setProgress(false);
          });
        }
      } else {
        // reg nuevo
        if (props.onFormLoad) {
          props.onFormLoad(null); //callback
        }
      }

    } catch (error) {
      FxLog.errorx('GxForm.useEffect', error);
    }

    // eslint-disable-next-line
  }, []);

  const formReload = (data) => {
    sendObjToFrm(data, props.children);
  }

  const sendObjToFrm = (data, children) => {
    try {
      if (Array.isArray(children)) {
        children.forEach(
          child => {
            if (child) {
              if (child.props && child.props.children) {
                sendObjToFrm(data, child.props.children); // recurrencia cuando están dentro de div
              }
              // valores de los controles
              if (child.props !== undefined && child.props.dbfield) {
                sendObjToFrm_helper1(data, child);
              }
              // ids de los controles
              if (child.props !== undefined && child.props.dbfieldkey) {
                sendObjToFrm_helper2(data, child);
              }
            }
          }
        )
      } else {
        // child no es un array!
        FxLog.infox("GxForm.sendObjToFrm", "...children no es un array! children:" + children, children);
        if (children.props !== undefined && children.props.dbfield) {
          sendObjToFrm_helper1(data, children);
        }
      }

    } catch (error) {
      FxLog.errorx("GxForm.sendObjToFrm", error);
    }
  }

  const sendObjToFrm_helper1 = (data, child) => {
    //FxLog.infox('GxForm.sendObjToFrm', '...child.props.dbfield:', child.props.dbfield);
    if (data[0]) {
      Object.entries(data[0]).forEach(([field, value]) => {
        let fieldName = child.props.dbfield.substring(child.props.dbfield.indexOf('.') + 1);
        if (field === fieldName) {
          if (child.props.xref.current) {
            FxLog.infox('GxForm.sendObjToFrm', '...child.props.dbfield->value:', child.props.dbfield + '->' + value);
            child.props.xref.current.setVal(value);
          }
        }
      })
    }
  }

  const sendObjToFrm_helper2 = (data, child) => {
    FxLog.infox('GxForm.sendObjToFrm', '...child.props.dbfieldkey:', child.props.dbfieldkey);
    if (data[0]) {
      Object.entries(data[0]).forEach(([field, value]) => {
        let fieldKeyName = child.props.dbfieldkey.substring(child.props.dbfieldkey.indexOf('.') + 1);
        if (field === fieldKeyName) {
          FxLog.infox('GxForm.sendObjToFrm', '...value:', value);
          if (child.props.xref.current) {
            child.props.xref.current.setKey(value);
          }
        }
      })
    }
  }

  const formDelete = async () => {
    if (recIdValue.current !== null) {
      setDlgDelete(true);
    }
  }

  const doDelete = () => {
    //-----------------------------------------------------------------------
    setBackdrop(true);

    let url = props.url + "/d";
    let record = {};
    record['idValue'] = recIdValue.current;
    Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
      if (result[0] && (result[0].theValue === 'OK' || result[0].theValue === 'rv_deactivated')) {
        if (result[0].theValue === 'rv_deactivated') {
          // deactivated
          r_snack_deactivate.current.setOpen(true);
        } else {
          // deleted
          r_snack_delete.current.setOpen(true);
        }
      } else {
        // error
        setBackdrop(false);
      }
      if (props.onFormDelete) {
        props.onFormDelete(result[0]); //callback
      }
    });
    //-----------------------------------------------------------------------
  }

  const formSave = async (boSnack = true) => {
    if (props.onFormBeforeSave) {
      let isOk = props.onFormBeforeSave(); //callback
      if (!isOk) {
        return;
      }
    }
    if (!hasIncompleteRequired()) {
      let record = {};
      if (sendFrmToObj(record, props.children)) {
        doSave(record, boSnack);
      }
    }
  }

  const hasIncompleteRequired = () => {
    let rv = false;
    refs.current.slice().reverse().forEach((ref, index) => {
      if (ref) {
        if (ref.props !== undefined && ref.props.xref !== undefined) {
          if (ref.props.xref.current !== null && ref.props.xref.current.getIsRequired !== undefined && ref.props.xref.current.getIsRequired()) {
            r_snack_required.current.setVal(trans('field.required') + ": " + ref.props.xref.current.getLabel());
            r_snack_required.current.setOpen(true);
            rv = true;
          }
        }
      }
    });
    return rv;
  }

  const doSave = (record, boSnack) => {
    if (boSaving.current) {
      return;
    }
    boSaving.current = true;
    //-----------------------------------------------------------------------
    let filter = {
      [propFilter.idField]: recIdValue.current,
    };


    Promise.all([FxFetch.doJsonF(props.url + "/cu", filter, record)]).then((result) => {
      if (result[0]) {
        lastSaveRv = result[0];
        if (lastSaveRv.theStatus === 'error') {
          props.onFormSave(lastSaveRv); //callback para mostrar snack de error en form cliente.
          boSaving.current = false;
        } else {
          recIdValue.current = lastSaveRv.theValue; //evita que se guarde varias veces el mismo al pulsar el botón guardar reiteradamente.
          if (boSnack) {
            r_snack_save.current.setOpen(true);
          } else {
            cbFormSave();
          }
        }
      }
    });
  }

  const cbFormSave = (record) => {
    props.onFormSave(lastSaveRv); //callback por si es necesaria alguna operación adicional en form cliente.
    boSaving.current = false;
  }

  const sendFrmToObj = (record, children) => {
    let rv = true;
    if (Array.isArray(children)) {
      children.forEach(
        child => {
          // valores de los controles
          if (child) {
            if (child.props !== undefined && child.props.children) {
              //FxLog.infox("GxForm.sendFrmToObj", "...child.props.children:", child.props.children);
              sendFrmToObj(record, child.props.children); // recurrencia cuando están dentro de div
            }
            if (rv === true && child.props !== undefined && child.props.dbfield !== undefined) {
              sendFrmToObj_helper1(record, child);
            }
            // ids de los controles
            if (child.props !== undefined && child.props.dbfieldkey !== undefined) {
              sendFrmToObj_helper2(record, child);
            }
          }
        }
      )
    } else {
      // child no es un array!
      //FxLog.infox("GxForm.sendFrmToObj", "...children no es un array! children:" + children, children);
      if (children.props !== undefined && children.props.dbfield !== undefined) {
        sendFrmToObj_helper1(record, children);
      }
    }
    return rv;
  }

  const sendFrmToObj_helper1 = (record, child) => {
    // eslint-disable-next-line no-unused-vars
    let fieldName = child.props.dbfield.substring(child.props.dbfield.indexOf('.') + 1);
    let val = child.props.xref.current.getVal();
    FxLog.infox("GxForm.sendFrmToObj", "...fieldName/value(1):", `${fieldName}/${val}`);
    if (record[child.props.dbfield] !== undefined) {
      // une valores para casos de fecha y hora en dos controles distintos pero con igual dbfield.
      if (val) {
        record[child.props.dbfield] = record[child.props.dbfield] + " " + val;
      }
    } else {
      record[child.props.dbfield] = val;
    }
  }
  const sendFrmToObj_helper2 = (record, child) => {
    let key = child.props.xref.current.getKey();
    // eslint-disable-next-line no-unused-vars
    let fieldKeyName = child.props.dbfieldkey.substring(child.props.dbfieldkey.indexOf('.') + 1);
    FxLog.infox("GxForm.sendFrmToObj", "...fieldKeyName/value(2):", `${fieldKeyName}/${key}`);
    record[child.props.dbfieldkey] = key;
  }

  /** marca todos los controles como NO modificados. */
  const resetChanged = () => {
    return resetChangedHelper(props.children);
  }
  const resetChangedHelper = (children) => {
    if (Array.isArray(children)) {
      children.forEach(
        child => {
          if (child) {
            if (child.props.children) {
              return resetChangedHelper(child.props.children); // recurrencia cuando están dentro de div
            }
            if (child.props.xref) {
              let type = child.props.xref.current.getType();
              if (type !== 'CxChip' && type !== 'CxTable' && type !== 'CxTableMem') {
                child.props.xref.current.setChanged(false);
              }
            }
          }
        }
      );
    } else {
      // child no es un array!
    }
  }
  /** Devuelve si el usuario modificó algún valor de algún control. */
  const wasChanged = () => {
    return wasChangedHelper(props.children);
  }
  const wasChangedHelper = (children) => {
    let rv = false;
    if (Array.isArray(children)) {
      children.forEach(
        child => {
          if (child) {
            if (child.props.children) {
              return wasChangedHelper(child.props.children); // recurrencia cuando están dentro de div
            }
            if (child.props.xref) {
              let type = child.props.xref.current.getType();
              if (type !== 'CxChip' && type !== 'CxTable' && type !== 'CxTableMem') {
                if (child.props.xref.current.wasChanged) {
                  FxLog.infox("GxForm.wasChangedHelper", "control was changed...child.props.label:", child.props.label);
                  rv = true;
                }
              }
            }
          }
        }
      );
    } else {
      // child no es un array!
    }
    return rv;
  }

  return (
    <div id='myGxForm' className={classes.parentDiv}>
      <div id='myCxToolbar' className={classes.toolbar}>
        <CxToolbar
          moduleName={moduleName}
          addToolbarButtons={props.addToolbarButtons}
          canGoBack={props.canGoBack}
          onGoBack={props.onGoBack}
          background={props.toolbarBackground}
          gap={"0px"}
        >
          {props.onFormDelete && recIdValue.current && 
            <CxIconButtonNew icon={<BiTrash fontSize={24} />} classType="ibs_iconbutton_toolbar" onClick={formDelete} tooltip={trans('field.delete')} />
          }
          {props.onFormSave && !props.saveAndExit && 
            <CxIconButtonNew icon={<BiSave fontSize={24} />} classType="ibs_iconbutton_toolbar" onClick={formSave} tooltip={trans('field.save')} />
          }
          {props.onFormSave && props.saveAndExit && 
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={formSave} tooltip={trans('field.save_and_exit')} />
          }
        </CxToolbar>
        <CxProgress wait={progress} />
      </div>

      {props.alert && <div className={classes.alert}>{props.alert}</div>}

      <div className={classes.container}>
        {props.children}
      </div>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={cbFormSave} />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => { navBack(); }} />
      <CxSnackbar xref={r_snack_deactivate} severity="warning" value={trans('msg.deactivated')} onClose={() => { navBack(); }} />

      <CxBackdrop open={backdrop} />

    </div>
  );
}

export default GxForm;
