import React from 'react';

const MxBlank = (props) => {
  return (
    <div style={{ overflow: 'hidden' }}>
    </div>
  );
}

export default MxBlank;
