import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';
import { clearStore, StoreSettCusDoc } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettCusDocList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettCusDoc, setStoreSettCusDoc] = useSessionStorage('StoreSettCusDoc', StoreSettCusDoc);

  React.useEffect(() => {
    clearStore('StoreSettCusDoc', storeSettCusDoc);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettCusDoc.itDoc = null;
    } else {
      storeSettCusDoc.itDoc = row.original.itDoc;
    }
    setStoreSettCusDoc(storeSettCusDoc);
    navTo("sett-cus-doc-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.customization')} - ${trans('field.docs')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/cus_doc"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itDoc", show: false },
          { id: "1", Header: `${trans('field.doc')}`, accessor: "txTypeDoc", width: '50%' },
          {
            id: "2", Header: trans('field.module'), accessor: row =>
              FxStr.choose(row["txModule"], 'C|A',
                trans('field.client'),
                trans('field.patient'),
              ),
            width: '25%', 
          },
          { id: "3", Header: `${trans('field.sett_notify_pending')}`, accessor: row => (Boolean(row["boNotifyPending"] === '1') ? trans('field.yes') : trans('field.no')), width: '25%' },
        ]}
      />
    </div>
  );
}

export default MxSettCusDocList;
