import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import GxForm from '../../gx/GxForm';
import { StoreStock } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { getStoreValue } from "../../gx/GxStore";
import { useAccess } from '../../hx/useAccess';
import { RiPlayList2Fill } from "react-icons/ri";
import GxPrintLong from '../../gx/GxPrintLong';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { useNav } from '../../hx/useNav';


const MxStockroomForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.stockrooms'));
  const [{ navBack }] = useNav();

  //const [, updateState] = React.useState();
  //const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const r_form = React.useRef(null);
  let r_gx_print_long = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);

  const [storeStock, setStoreStock] = useSessionStorage('StoreStock', StoreStock);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
  }
  const formSave = async (lastSaveRv) => {
    storeStock.inStock = lastSaveRv.theValue;
    Promise.all([setStoreStock(storeStock)]).then((result) => {
      //forceUIUpdate();
      navBack(); // 2025-03-10 - daq: para permitir hacer el inventario más rápido.
    });
  }

  const doPrintX = async (option) => {
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inPs'] = storeStock.inPs;
    filter['txPs'] = storeStock.txPs;
    let record = {};
    r_gx_print_long.current.setScale(1.5);
    r_gx_print_long.current.setUrlBuild('pss/report/movs_build');
    r_gx_print_long.current.setUrlGet('pss/report/movs_get');
     //----------------
    Promise.all([
      r_gx_print_long.current.setFilter(filter),
      r_gx_print_long.current.setRecord(record),
    ]).then((result) => {
      r_gx_print_long.current.doPrint();
    });
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.stockroom')}: ${getStoreValue('StoreSession', 'txBranchId')}: ${trans('field.item')}`}
        xref={r_form}
        url="stock"
        filterIdField='inStock'
        filterIdValue={storeStock.inStock}
        onFormLoad={isFullAccess() && formLoad}
        onFormSave={isFullAccess() && formSave}
        saveAndExit
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de movimientos */}
            <CxIconButtonNew icon={<RiPlayList2Fill />} classType="ibs_iconbutton_toolbar" onClick={doPrintX} tooltip={trans('rpt.ps_stock_movs')} />

          </div>
        }
      >
        <CxInput xref={React.useRef(null)} label={trans('field.ps')} dbfield="n_stock.txPs" disabled />

        <CxNumber xref={React.useRef(null)}
          label={trans('field.stock_min')}
          dbfield="n_stock.nuStockMin"
          decimals={2}
          decimalsfixed="true"
          sumButton
        />

        <CxNumber xref={React.useRef(null)}
          label={trans('field.stock_max')}
          dbfield="n_stock.nuStockMax"
          decimals={2}
          decimalsfixed="true"
          sumButton
        />

        <CxNumber xref={React.useRef(null)}
          label={trans('field.stock')}
          dbfield="n_stock.nuStock"
          decimals={2}
          decimalsfixed="true"
          sumButton
          required
        />

      </GxForm>

      <GxPrintLong xref={r_gx_print_long} />
      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxStockroomForm;
