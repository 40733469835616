import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { useTranslation } from '../../hx/useTranslation';
import CxDialog from '../../cx/CxDialog';
import CxDate from '../../cx/CxDate';
import CxRadio from '../../cx/CxRadio';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';
import '../../../styles/main.css';
import { getStoreValue } from "../../gx/GxStore";
import CxStatic from '../../cx/CxStatic';


const MxSlsDocListQuickPaymDlg = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);

  const [row, setRow] = React.useState(null);
  const [listType, setListType] = React.useState(null);
  const [tableToRefresh, setTableToRefresh] = React.useState(null);
  const [amountOne, setAmountOne] = React.useState(0);
  const [amountAll, setAmountAll] = React.useState(0);
  const [clientName, setClientName] = React.useState(null);

  const [disabled, setDisabled] = React.useState(false);

  const rf_dtPayment = React.useRef(null);
  const rf_txApplyTo = React.useRef(null);
  const rf_txTypePayment = React.useRef(null);
  const rf_txTypeCash = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  const r_snack_error = React.useRef(null);

  React.useEffect(() => {
    formLoad();

    return () => {
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg, setRow, setListType, setTableToRefresh, setAmountOne, setAmountAll, setClientName,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {

    // chequea requeridos
    if (rf_dtPayment.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_dtPayment.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypeCash.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypeCash.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_txTypePayment.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txTypePayment.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_nuAmount.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuAmount.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let record = {};
    record['txApplyTo'] = rf_txApplyTo.current.getVal();
    record['dtPayment'] = rf_dtPayment.current.getVal();
    record['itCash'] = rf_txTypeCash.current.getKey();
    record['itPayment'] = rf_txTypePayment.current.getKey();
    record['nuAmount'] = rf_nuAmount.current.getVal();

    record['itSeries'] = row.itSeries;
    record['inInvoice'] = row.inInvoice;
    record['dtInvoice'] = row.dtInvoice;
    record['txInvoice'] = row.txInvoice;
    record['inReceipt'] = row.inReceipt;
    record['dtReceipt'] = row.dtReceipt;
    record['txReceipt'] = row.txReceipt;
    record['inClient'] = row.inClient;

    setDisabled(true);
    Promise.all([FxFetch.doJsonX(`sls/doclist/${listType}/pay`, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'rv_pending_receipts') {
          r_snack_error.current.setVal(trans('msg.client_has_unpaid_receipts'));
          r_snack_error.current.setOpen(true);
          finish();
        }
        if (result[0].theValue === 'OK') {
          r_snack_op_successful.current.setOpen(true);
        }
      }
    });

  }

  const applyToSelected = (id) => {
    try {
      if (id === 'one') {
        rf_nuAmount.current.setVal(amountOne);
      }
      if (id === 'all') {
        rf_nuAmount.current.setVal(amountAll);
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocListQuickPaymDlg.applyToSelected", error);
    }
  }

  const finish = () => {
    try {
      setDisabled(false);
      setDlg(false);
      if (tableToRefresh) {
        tableToRefresh.current.refresh();
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocListQuickPaymDlg.finish", error);
    }
  }

  return (
    <div>
      <CxDialog title={trans('field.quick_x_1', 'field.payment')} open={dlg} onOk={formSave} onCancel={() => setDlg(false)} height='500px' disabled={disabled} >

        <CxStatic label={clientName} height='25px' width='var(--ibs_control_width_md)' />
        <CxRadio xref={rf_txApplyTo}
          list={listType === 'i' ?
            [
              { id: 'one', type: `${trans('field.apply_to')}: ${trans('field.selected_1')}` },
              { id: 'all', type: `${trans('field.apply_to')}: ${trans('field.all_of_the_x_1', 'field.client')}` },
            ]
            :
            [
              { id: 'one', type: `${trans('field.apply_to')}: ${trans('field.selected_1')}` },
            ]
          }
          required
          autoSelectFirst
          width='var(--ibs_control_width_md)'
          disabled={disabled}
          onSelection={applyToSelected}
        />

        <CxDate xref={rf_dtPayment}
          label={trans('field.date')}
          width='var(--ibs_control_width_md)'
          disabled={disabled}
          today
          required />

        <CxChooser xref={rf_txTypeCash}
          label={trans('field.cash_reg')}
          table="t_cash"
          col_id="itCash"
          col_tx="txTypeCash"
          readOnly={false}
          width='var(--ibs_control_width_md)'
          defaultKeyAndValue={getStoreValue('StoreSession', 'itCash') + '|' + getStoreValue('StoreSession', 'txTypeCash')}
          disabled={disabled}
          required
        />

        <CxChooser xref={rf_txTypePayment} label={trans('field.payment')}
          table="t_payment"
          col_id="itPayment"
          col_tx="txTypePayment"
          extra_filter_1={"not txTypePayment like 'OLD-%'"}
          readOnly={false}
          width='var(--ibs_control_width_md)'
          defaultKeyAndValue={'000001.SRV|' + trans('field.cash')}
          disabled={disabled}
          required
        />

        <CxNumber xref={rf_nuAmount}
          label={`${trans('field.amount')}`}
          decimals={2}
          decimalsfixed="true"
          width='var(--ibs_control_width_md)'
          disabled={disabled}
          defaultValue={amountOne}
          required
        />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} onClose={finish} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxSlsDocListQuickPaymDlg;
