import { MdClear } from "react-icons/md"; 
import { BiSearch } from "react-icons/bi"; 
import React, { useImperativeHandle } from "react";
import '../../styles/main.css';
import * as FxLog from '../fx/FxLog';
import * as FxDat from '../fx/FxDat';
import { useTranslation } from '../hx/useTranslation';
import { useStyles } from "../cx/StylesCx";
import TextField from '@material-ui/core/TextField';
import CxIconButtonNew from "../cx/CxIconButtonNew";
import CxDlgTable from "../cx/CxDlgTable";
import CxSnackbar from '../cx/CxSnackbar';
import MxAnimalOTF from '../modules/common/otf/MxAnimalOTF';
import * as FxFetch from '../fx/FxFetch';


const GxSearcherAnimal = props => {
  const classes = useStyles(props);
  const [{ trans }] = useTranslation();
  const { clientRef, onClear, onSelection, InputLabelProps, ...rest } = props;

  const [wasChanged, setChanged] = React.useState(false);

  let r_snack_error = React.useRef(null);
  
  const inputRef = React.useRef(null);

  const [isRequired, ] = React.useState(props.required);

  const r_dlg = React.useRef(null);
  const [dlg, setDlg] = React.useState(false);
  
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  
  const r_dlg_new_animal = React.useRef(null);
  
  // para poder usar setContent desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, setKey, getKey, clear, getIsRequired, getLabel, isEmpty, wasChanged, setChanged, setKeyAndVal,
    };
  });
  const getType = () => { return 'GxSearcherAnimal' };
  const getLabel = () => { return trans('field.patient') };
  const getIsRequired = () => { return isRequired && (myValue===null || myValue==='') };
  const getVal = () => { return myValue||'' };
  const getKey = () => { return myKey||'' };
  const setKey = (newId) => { setMyKey(newId) };
  const setVal = (newValue) => { 
    setMyValue(newValue); 
  };
  const clear = () => {
    if (!props.disabled) {
      setChanged(true);
      setKey('');
      setVal('');
      if (onClear) {
        let inClient = clientRef.current.getKey();
        let txClient = clientRef.current.getVal();
        onClear(inClient, txClient); //pasa el cliente para en ventas tratar las tasas.
      }
    }
  };
  const isEmpty = () => { return myValue.length === 0 };

  const setKeyAndVal = async (table, txFieldToGet, idField, idValue) => {
    try {
      setMyKey(idValue);
      let where = idField + "='" + idValue + "'";
      let record = {};
      record['table'] = table;
      record['field'] = txFieldToGet;
      record['where'] = where;
      Promise.all([FxFetch.doJsonX("general/r", record)]).then((result) => {
        if (result[0]) {
          let newValue = result[0].theValue;
          setMyValue(newValue);
        }
      });
      // -------------------------------------------------------------

    } catch (error) {
      FxLog.errorx("GxSearcherAnimal.setKeyAndVal", error);
    }
  }

  const animalSelected = (row) => {
    let inAnimal = row.original.inAnimal;
    let txAnimal = row.original.txAnimal;
    setKey(inAnimal);
    setVal(txAnimal);
    setDlg(false);
    setChanged(true);
    if (onSelection) {
      let inClient = clientRef.current.getKey();
      let txClient = clientRef.current.getVal();
      onSelection(inClient, txClient, inAnimal, txAnimal);
    }
  }

  const doSelectPatient = () => {
    if (!props.disabled) {
      let inClient = clientRef.current.getKey();
      if (inClient) {
        r_dlg.current.setFilter(`n_animal.inClient='${inClient}'`);
        setDlg(true);
      } else {
        r_snack_error.current.setValAndOpen(trans('msg.select_x','field.client'));
      }
    }
  };

  return (
    <div>
      <div id='div_Cxinput' className={classes.frame} style={{ display: '' }}>
        <div className={classes.frame_content}>
          <TextField
            inputRef={inputRef}
            className={classes.control}
            autoComplete='new-password'
            label={trans('field.patient')}
            InputLabelProps={{
              classes: { root: classes.root, },
              shrink: true,
              ...InputLabelProps
            }}
            InputProps={{ //refers to the Input component
              disableUnderline: true,
              classes: { root: classes.root, },
              inputProps: { //refers to the input element
                style: { cursor: 'default' },
                readOnly: true,
              },
            }}
            value={myValue||''}
            onClick={doSelectPatient} // 2025-02-13 - daq: mismo efecto que darle al botoncito, para que la gente no se frustre cuando tratan de darle foco para escribir.
            {...rest}
          />
        </div>
        <div className={classes.frame_buttons}>
          <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={clear} disabled={props.disabled} />
          <CxIconButtonNew icon={<BiSearch fontSize={20} />} classType={classes.frame_icons_new} onClick={doSelectPatient} disabled={props.disabled} />
        </div>
      </div>
      
      <CxDlgTable
        xref={r_dlg}
        title={trans('msg.select_x', trans('field.patient'))}
        hasSearchBox={true}
        open={dlg}
        onButtonAddOTF={() => {
          setDlg(false);
          r_dlg_new_animal.current.setInClient(clientRef.current.getKey());
          r_dlg_new_animal.current.setDlg(true);
        }}
        onClose={() => setDlg(false)}
        onSelect={(row) => {
          FxLog.infox('GxSearcherAnimal.return.CxDlgTable', '...row:', row);
          animalSelected(row);
        }}
        url={'animals'}
        tooltip={`${trans('field.patient')}`}
        refColors={[
          'table_cell_color_gray|' + trans('field.inactive'),
        ]}
        columns={
          [
            { id: "inAnimal", Header: "_inAnimal", accessor: "inAnimal", show: false },
            { id: "txAnimal", Header: trans('field.patient'), accessor: row => r_dlg.current.getTable().highlightText(row["txAnimal"]), width: '50%' },
            { id: "txTypeAnimalBreed", Header: trans('field.breed'), accessor: "txTypeAnimalBreed", width: '50%' },
            { id: "A", Header: "inactive", accessor: row => FxDat.isExpired(row["dtTo"]), show: false },
          ]
        }
        height='450px'
      />

      <MxAnimalOTF xref={r_dlg_new_animal} onOk={(inAnimal, txAnimal) => {
        setKey(inAnimal);
        setVal(txAnimal);
      }}/>

      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );
};

export default GxSearcherAnimal;
