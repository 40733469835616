// Funciones generales para cadenas de texto.
import Highlighter from "react-highlight-words";
import latinize from 'latinize';
import '../../styles/main.css';

/** pasada una lista de psv (pipe separated values), devuelve el valor relacionado a la clave seleccionada.
 *  Ej: FxStr.choose(key, 'a|b|c', val1, val2, val3)
 */
export const choose = (key, sequence, ...val) => {
  let rv;
  let x = sequence.split('|');
  for (let index = 0; index < x.length; index++) {
    const element = x[index];
    if (key === element) {
      rv = val[index];
    }
  }
  return rv;
}

export const rgb2hex = c => '#' + (c||'255-255-255').match(/\d+/g).map(x => (+x).toString(16).padStart(2, 0)).join``;

export const highlightSearch = (text, searchWords) => {
  // Split on highlight term and include term into parts, ignore case
  if (text) {
    return <Highlighter
      highlightClassName="ibs_highlight"
      searchWords={[searchWords]}
      autoEscape={true}
      textToHighlight={text}
      sanitize={latinize}
    />
  }
}

export const getRandomID = () => {
  let len = 10;
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = len; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export const isNumeric = (value) => {
  /*
  console.log(isNumeric('abcd'));         // false
  console.log(isNumeric('123a'));         // false
  console.log(isNumeric('1'));            // true
  console.log(isNumeric('1234567890'));   // true
  console.log(isNumeric('-23'));          // true
  console.log(isNumeric(1234));           // true
  console.log(isNumeric(1234n));          // true
  console.log(isNumeric('123.4'));        // false
  console.log(isNumeric(''));             // false
  console.log(isNumeric(undefined));      // false
  console.log(isNumeric(null));           // false
  */
  return /^\d+$/.test(value);
}

export const substringBefore = (text, separator) => {
  text = text||'';
  return text.substring(0, text.indexOf(separator));
}

export const substringAfter = (text, separator) => {
  text = text||'';
  return text.substring(text.indexOf(separator) + 1);
}

export const join = (separator, ...strings) => {
  return strings.filter(Boolean).join(separator);
}

/**
 * Normalizes spaces in a string by replacing multiple consecutive spaces with a single space,
 * and trimming leading/trailing spaces.
 */
export const normalizeSpaces = (str) => {
  if (typeof str !== 'string') {
    return ''; // Or throw an error, depending on desired behavior
  }
  return str.replace(/\s+/g, ' ').trim();
};