// funciones para determinar región
//import * as FxLog from './FxLog';
import { getStoreValue } from "../gx/GxStore";

export const isSpain = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('es.') || getStoreValue('StoreSession', 'txIdi').startsWith('xx.') ) }

export const isUsAu = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('us.') || getStoreValue('StoreSession', 'txIdi').startsWith('au.') ) }

export const isAndalusia = () => { return Boolean(
  isSpain() && (
    getStoreValue('StoreSession', 'txState').toLowerCase()==='almería' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='cádiz' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='córdoba' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='granada' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='huelva' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='jaén' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='málaga' || 
    getStoreValue('StoreSession', 'txState').toLowerCase()==='sevilla' 
  )
) }
