import React from 'react';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../../fx/FxLog';
import * as FxFetch from '../../../fx/FxFetch';
import { useTranslation } from '../../../hx/useTranslation';
import CxDialog from '../../../cx/CxDialog';
import CxInput from '../../../cx/CxInput';
import CxSnackbar from '../../../cx/CxSnackbar';
import '../../../../styles/main.css';


const MxProfOTF = (props) => {
  const [{ trans }] = useTranslation();
  const [dlg, setDlg] = React.useState(false);
  const [disabled, ] = React.useState(false);

  const rf_txProf = React.useRef(null);
  const rf_txLicense = React.useRef(null);

  const r_snack_required = React.useRef(null);
  const r_snack_exists = React.useRef(null);
  const r_snack_created = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      setDlg,
    };
  });

  const formLoad = async () => {
  }
  const formSave = async () => {
    // chequea requeridos
    if (rf_txProf.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_txProf.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let txProf = rf_txProf.current.getVal();
    let txLicense = rf_txLicense.current.getVal();

    let record = {};
    record['txProf'] = txProf;
    record['txLicense'] = txLicense;

    Promise.all([FxFetch.doJsonX("profs/otf", record)]).then((result) => {
      FxLog.infox("MxProfOTF.formSave", "...result[0]:", result[0]);
      if (result[0]) {
        if (result[0].theValue === null) {
          FxLog.infox("MxProfOTF.formSave", "El prof ya existe!!!");
          r_snack_exists.current.setVal(trans('msg.x_exists','field.professional'));
          r_snack_exists.current.setOpen(true);
        } else {
          FxLog.infox("MxProfOTF.formSave", "El prof ha sido creado.");
          r_snack_created.current.setVal(trans('msg.operation_successful'));
          r_snack_created.current.setOpen(true);
          let inProf = result[0].theValue;
          if (props.onOk) props.onOk(inProf, txProf);
          setDlg(false);
        }
      }
    });

  }

  return (
    <div>
      <CxDialog title={trans('field.create_x','field.professional')} open={dlg} onOk={formSave} onClose={() => setDlg(false)} >

        <CxInput xref={rf_txProf} label={trans('field.professional')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={100} required />
        <CxInput xref={rf_txLicense} label={trans('field.license')} disabled={disabled} width='var(--ibs_control_width_md)' maxLength={15} />

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_exists} severity="error" />
      <CxSnackbar xref={r_snack_created} severity="success" onClose={() => setDlg(false)} />
    </div>
  );

}

export default MxProfOTF;
