import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxState from '../../cx/CxState';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { StoreReferrer } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';


const MxReferrerForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.referrers'));
  const r_snack_ask_save = React.useRef(null);

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const r_form = React.useRef(null);

  const rf_dtFrom = React.useRef(null);
  const rf_dtTo = React.useRef(null);

  const [storeReferrer, setStoreReferrer] = useSessionStorage('StoreReferrer', StoreReferrer);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_dtFrom.current.setValToday();
    }
  }
  const formSave = async (lastSaveRv) => {
    storeReferrer.inReferrer = lastSaveRv.theValue;
    Promise.all([setStoreReferrer(storeReferrer)]).then((result) => {
      forceUIUpdate();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.referrer')}`}
        xref={r_form}
        url="referrers"
        filterIdField='inReferrer'
        filterIdValue={storeReferrer.inReferrer}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxInput xref={useRef(null)} label={trans('field.referrer')} dbfield="n_referrer.txReferrer" capitalize normalizeSpaces required />
        <CxInput xref={useRef(null)} label={trans('field.contact')} dbfield="n_referrer.txContact" maxLength={100} />
        <CxState mode='checkbox' xref={useRef(null)} label={`${trans('field.female')}|${trans('field.male')}`} dbfield="n_referrer.boGender" />
        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_referrer.txReference" maxLength={50} />

        <CxDate xref={rf_dtFrom} label={trans('field.from')} dbfield={"n_referrer.dtFrom"} required />
        <CxDate xref={rf_dtTo} label={trans('field.to')} dbfield={"n_referrer.dtTo"} />

        <CxInput xref={useRef(null)} label={trans('field.ic')} dbfield="n_referrer.txIc" maxLength={15} />
        <CxInput xref={useRef(null)} label={trans('field.phone1')} dbfield="n_referrer.txPhone1" maxLength={20}  />
        <CxInput xref={useRef(null)} label={trans('field.mobile')} dbfield="n_referrer.txMobile" maxLength={20}  />
        <CxInput xref={useRef(null)} label={trans('field.email')} dbfield="n_referrer.txEmail" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.web')} dbfield="n_referrer.txWeb" maxLength={255} />
        <CxInput xref={useRef(null)} label={trans('field.address')} dbfield="n_referrer.txAddress" maxLength={100} />
        <CxInput xref={useRef(null)} label={trans('field.city')} dbfield="n_referrer.txCity" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.state')} dbfield="n_referrer.txState" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.postal_code')} dbfield="n_referrer.txPostalCode" maxLength={10}  />
        <CxInput xref={useRef(null)} label={trans('field.country')} dbfield="n_referrer.txCountry" maxLength={50} />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_referrer.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxReferrerForm;
