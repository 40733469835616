import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxSnackbar from '../../cx/CxSnackbar';
import CxCombo from '../../cx/CxCombo';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import CxStatic from '../../cx/CxStatic';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, isApp } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';


const MxSettCommAutogen = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_required = React.useRef(null);
  const r_snack_save = React.useRef(null);
  let r_form = React.useRef(null);

  const rf_txTypeComm = React.useRef(null);
  const rf_txContent = React.useRef(null);
  const rf_txSendType = React.useRef(null);

  const [isDisabled,] = React.useState(getStoreValue('StoreSession', 'boAutoCreateComm') === '0');


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/autogen/r', record)]).then((result) => {
      if (result[0].length > 0) {
        rf_txTypeComm.current.setKey(result[0][0].itComm);
        rf_txTypeComm.current.setVal(result[0][0].txTypeComm);
        rf_txSendType.current.setVal(result[0][0].txSendType);
        rf_txContent.current.setVal(result[0][0].txContent);
      }
    });
  }

  const formSaveCustom = async () => {
    if (!isRequired()) {
      let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
      let record = {};
      record['n_comm_tpl_fq.itComm'] = rf_txTypeComm.current.getKey();
      record['n_comm_tpl_fq.txContent'] = rf_txContent.current.getVal();
      record['n_comm_tpl_fq.txSendType'] = rf_txSendType.current.getVal();
      Promise.all([FxFetch.doJsonF("/sett/autogen/u", filter, record)]).then((result) => {
        if (result[0]) {
          if (result[0].theValue === 'OK') {
            r_snack_save.current.setOpen(true);
          }
        }
      });

      r_snack_save.current.setOpen(true);
    }
  }

  const isRequired = () => {
    let rv = false;
    let cc = [rf_txTypeComm, rf_txSendType, rf_txContent];
    cc.reverse().forEach((c, index) => {
      if (c.current.getIsRequired()) {
        let label = c.current.getLabel();
        r_snack_required.current.setVal(trans('field.required') + ": " + label);
        r_snack_required.current.setOpen(true);
        rv = true;
      }
    });
    return rv;
  };

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.comms')} - ${trans('field.autogen')} - ${trans('field.template')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        {getStoreValue('StoreSession', 'boAutoCreateComm') === '0' &&
          <CxStatic fontcolor='var(--ibs_color_red)'
            label={trans('msg.check_x_settings').replace('%s', "\"" + trans('field.sett_auto_create_comm') + "\"") + ` ${trans('field.settings')} > ${trans('field.options')} > ${trans('field.sales')} > ${trans('field.general')}`}
            border='red' />
        }

        <CxCombo xref={rf_txSendType}
          label={`${trans('field.type_sending')}`}
          list={[
            { id: 'p', type: `${trans('field.popup_window')} + Whatsapp` },
            { id: 'e', type: trans('field.email') },
            { id: 's', type: trans('field.sms') },
          ]}
          defaultValue={'p'}
          required
          disabled={isDisabled}
        />

        <CxChooser xref={rf_txTypeComm} label={`${trans('field.type_comm')}`}
          dbfield="t_comm.txTypeComm" dbfieldkey={`n_comm.itComm`}
          table="t_comm"
          col_id="itComm"
          col_tx="txTypeComm"
          required
          disabled={isDisabled}
        />

        <CxInput xref={rf_txContent}
          label={trans('field.message')}
          dbfield="n_comm_tpl_fq.txContent"
          placeholders={
            (isApp('vett') || isApp('spaw')) ?
              [
                { id: "[name]", type: trans('field.name') },
                { id: "[animal]", type: trans('field.patient') },
                { id: "[ps]", type: trans('field.ps') },
                { id: "[alias]", type: `${trans('field.alias')}-${trans('field.invoicing')}` },
              ] : [
                { id: "[name]", type: trans('field.name') },
                { id: "[ps]", type: trans('field.ps') },
                { id: "[alias]", type: `${trans('field.alias')}-${trans('field.invoicing')}` },
              ]
          }
          minRows={10}
          required
          disabled={isDisabled}
        />

      </GxForm>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />

    </div>
  );

}

export default MxSettCommAutogen;
