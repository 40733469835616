import React from 'react';
import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import CxTooltip from './CxTooltip';


export default function CxIconButtonNew(props) {
  const { xref, onClick, onMouseDown, onMouseUp, classType, data, ...rest } = props;

  const [state, setState] = React.useState(rest.state || 'OFF'); // 2024-07-05 - daq: si es que el botón utiliza estado prendido/apagado.
  const [icon, setIcon] = React.useState(rest.icon);
  const [icon_ori, ] = React.useState(rest.icon);
  const [icon_alt, ] = React.useState(rest.icon_alt);
  
  const [disabled, setDisabled] = React.useState(rest.disabled);
  React.useEffect(() => setDisabled(rest.disabled), [rest.disabled]); // mantiene actualizado la habilitación, si se cambia el valor isDisabled en la llamada.

  React.useImperativeHandle(xref, () => {
    return {
      getState, setIcon, setDisabled,
    };
  });
  const getState = () => { return state };

  const doClick = (event) => {
    if (state==='ON') {
      setState('OFF');
      if (icon_alt) setIcon(icon_ori);
    } else {
      setState('ON');
      if (icon_alt) setIcon(icon_alt);
    }

    if (onClick) {
      onClick(data||event);
    }
  };

  const doMouseDown = (event) => {
    if (onMouseDown) {
      onMouseDown(data||event);
    }
  };

  const doMouseUp = (event) => {
    if (onMouseUp) {
      onMouseUp(data||event);
    }
  };

  return (
    <div>
      <CxTooltip text={rest.tooltip ? rest.tooltip : null}>
        <span>
          <IconButton className={classType ? classType : "ibs_iconbutton"} 
            onMouseDown={doMouseDown}
            onMouseUp={doMouseUp}
            onClick={doClick} 
            disabled={disabled} 
            tabIndex={-1} 
            {...rest}
          >
            {icon}
          </IconButton>
        </span>
      </CxTooltip>
    </div>
  );
}
