import { BiMicrophone } from "react-icons/bi"; 
import { FiInbox } from "react-icons/fi"; 
import { BiEdit } from "react-icons/bi"; 
import { BiTrash } from "react-icons/bi"; 
// Input para formularios
import React, { useState, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import '../../styles/main.css';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import * as FxFetch from '../fx/FxFetch';
import CxIconButton from "./CxIconButton";
import CxTooltip from './CxTooltip';
import CxDialog from './CxDialog';
import CxTable from './CxTable';
import CxButton from './CxButton';
import CxSnackbar from './CxSnackbar';
import CxIconButtonNew from './CxIconButtonNew';
import { useStyles } from "./StylesCx";
import { useTranslation } from '../hx/useTranslation';
import CxDlgRadioList from "./CxDlgRadioList";
import CxSpeech from './CxSpeech';


/** Es un input con un botón para crear plantillas */
const CxInputTemplate = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const { onGetValue, onBlur, onClear, onSearch, onTemplateSelected, InputLabelProps, defaultValue, redText, upperCase, lowerCase, capitalize,
    hasTplButtonAdd, labelFontSize, labelFontFamily, placeholders, speech, onSpeechFinish, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = React.useState(false);

  const [dlgTpl, setDlgTpl] = React.useState(false);
  const [editTemplate, setEditTemplate] = React.useState(false);
  const [tplFilter, setTplFilter] = React.useState('');

  const r_clearing = React.useRef(false);
  const r_speech = React.useRef(null);

  const r_table = React.useRef(null);
  const r_dlg_placeholders = React.useRef(null);
  const rf_control = React.useRef(null);

  const rf_edit_tpl_name = React.useRef(null);
  const rf_edit_tpl_cont = React.useRef(null);
  const [tplId, setTplId] = useState('');
  const [tplName, setTplName] = useState('');
  const [tplCont, setTplCont] = useState('');

  const r_snack_required = React.useRef(null);


  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    if (props.value) {
      setMyValue(props.value);
    }
    if (rest.tplFilter) {
      setTplFilter(rest.tplFilter);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  const doClear = () => {
    if (!props.readOnly && !props.disabled) {
      r_clearing.current = true;
      setMyValue('');
      setChanged(true);
      setFocus();
    }
  }

  const doGetValue = () => {
    if (!props.readOnly && !props.disabled) {
      props.onGetValue();
    }
  }

  const doSearch = () => {
    if (!props.readOnly && !props.disabled) {
      props.onSearch();
    }
  }

  const getTextField = (ref, label, rows, maxLength, setMyValue, myValue) => {
    return <>
      <div id='div_Cxinput' className={classes.frame}>
        <div className={classes.frame_content}>
          <TextField
            inputRef={ref}
            minRows={rows}
            maxRows={rows}
            multiline={rows > 1 ? true : false}
            className={classes.control}
            autoComplete='new-password'
            InputLabelProps={{
              classes: { root: classes.root, },
              shrink: true,
              ...InputLabelProps
            }}
            InputProps={{ //refers to the Input component
              disableUnderline: true,
              required: false,
              classes: { root: classes.root, },
              inputProps: { //refers to the input element
                maxLength: { maxLength },
                style: {
                  textTransform: upperCase ? 'uppercase' : lowerCase ? 'lowercase' : capitalize ? 'capitalize' : '',
                },
              },
            }}
            {...rest}
            onChange={(newValue) => setMyValue(newValue.target.value)}
            value={myValue}
            label={label}
          />
        </div>
      </div>
    </>
  }

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, getDbfield, setFocus, doClear, isEmpty, clear, wasChanged, setChanged, setTplFilter
    };
  });
  const setVal = (newValue) => {
    if (newValue) {
      newValue = newValue.toString().replace(/['"]+/g, "`"); // 2023-10-02 - daq: quita comillas simples y dobles para evitar errores en queries

      if (upperCase) newValue = newValue.toUpperCase();
      if (lowerCase) newValue = newValue.toLowerCase();
      if (capitalize) newValue = newValue.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    setMyValue(newValue)
  };
  const getVal = () => {
    return myValue;
  };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const isEmpty = () => { return myValue.length === 0 };
  const clear = () => { setMyValue(''); setChanged(true); };
  const getType = () => { return 'CxInputTemplate' };

  const doTemplateWindowClose = async () => {
    if (editTemplate) {
      setEditTemplate(false);
    } else {
      setDlgTpl(false);
    }
  }

  const doPlaceholderInsert = (option) => {
    FxLog.infox("CxInputTemplate", "doPlaceholderInsert", "...option:", option);

    Promise.all([r_dlg_placeholders.current.setOpenAndFill(false)]).then(() => {
      if (option) {
        rf_edit_tpl_cont.current.focus();
        let textToInsert = option;
        let cursorPosition = rf_edit_tpl_cont.current.selectionStart;
        let textBeforeCursorPosition = tplCont.substring(0, cursorPosition);
        let textAfterCursorPosition = tplCont.substring(cursorPosition, tplCont.length);
        let newText = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        Promise.all([setTplCont(newText)]).then(() => {
          //let posFrom = textBeforeCursorPosition.length;
          let posTo = textBeforeCursorPosition.length + textToInsert.length;
          rf_edit_tpl_cont.current.selectionStart = posTo;
          rf_edit_tpl_cont.current.selectionEnd = posTo;
        });
      }
    });
  }

  const doTemplateSave = () => {
    if (rf_edit_tpl_name.current.value.length === 0 || rf_edit_tpl_cont.current.value.length === 0) {
      r_snack_required.current.setVal(trans('msg.required_controls'));
      r_snack_required.current.setOpen(true);
      return;
    }

    let filter = {};
    let record = {};
    filter['table'] = rest.tpl_table;
    filter['where'] = tplId ? (rest.tpl_col_id + "='" + tplId + "'") : "";
    record['txName'] = rf_edit_tpl_name.current.value;
    record['txContent'] = rf_edit_tpl_cont.current.value;
    Promise.all([FxFetch.doJsonF('general/cu', filter, record)]).then((result) => {
      if (result[0].theValue === 'OK') {
        setEditTemplate(false);
        r_table.current.refresh();
      }
    });
  }

  const doTemplateOperation = (op, row) => {
    FxLog.infox("CxInputTemplate.doTemplateOperation", "...row", row);
    if (row === null) {
      // añadiendo nuevo
      FxLog.infox("CxInputTemplate.doTemplateOperation", "...adding");
      setTplId("");
      setTplName("");
      setTplCont("");
      setEditTemplate(true);

    } else {
      let where = null;
      let record = {};
      let id = row.original[rest.tpl_col_id];
      switch (row.col_id) {
        case 'DEL':
          // eliminando plantilla
          where = rest.tpl_col_id + "='" + id + "'";
          record['table'] = rest.tpl_table;
          record['where'] = where;
          Promise.all([FxFetch.doJsonX('general/d', record)]).then((result) => {
            if (result[0].theValue === 'OK') {
              r_table.current.refresh();
            }
          });
          break;

        case 'EDI':
          // editando plantilla
          FxLog.infox("CxInputTemplate", "doTemplateOperation", "...editing");
          where = rest.tpl_col_id + "='" + id + "'";
          record['table'] = rest.tpl_table;
          record['field'] = rest.tpl_content_field;
          record['where'] = where;
          Promise.all([FxFetch.doJsonX("general/r", record)]).then((result) => {
            if (result[0]) {
              let cont = result[0].theValue;
              setTplId(id);
              setTplName(row.original[rest.tpl_col_tx]);
              setTplCont(cont);
              FxLog.infox("CxInputTemplate", "doTemplateOperation", `...editing ${tplId} ${tplName} ${tplCont}`);
              setEditTemplate(true);
            }
          });
          break;

        default:
          // cargando plantilla en input
          FxLog.infox("CxInputTemplate", "doTemplateOperation", "...loading");
          where = rest.tpl_col_id + "='" + id + "'";
          record['table'] = rest.tpl_table;
          record['field'] = rest.tpl_content_field;
          record['where'] = where;
          Promise.all([FxFetch.doJsonX("general/r", record)]).then((result) => {
            if (result[0]) {
              let name = row.original[rest.tpl_col_tx];
              let cont = result[0].theValue;
              //setVal(cont);
              setDlgTpl(false);
              if (onTemplateSelected) {
                onTemplateSelected(name, cont);
              }
            }
          });
          break;
      }
    }
  }

  const doSpeech = (startStop) => {
    r_speech.current.doStartStop(props.label, startStop);
  }

  return (
    <CxTooltip text={props.tooltip ? props.tooltip : null}>
      <div id='div_CxInputTemplate' className={classes.frame}>
        <div className={classes.frame_content}>
          <TextField
            inputRef={rf_control}
            minRows={props.minRows}
            multiline={props.minRows !== undefined ? true : false}
            className={classes.control}
            autoComplete='new-password'
            InputLabelProps={{
              classes: { root: classes.root, },
              shrink: true,
              style: {
                fontSize: props.labelFontSize || 'var(--ibs_control_label_font_size)',
                fontFamily: props.labelFontFamily || 'var(--ibs_control_label_font_family)',
              },
              ...InputLabelProps
            }}
            InputProps={{ //refers to the Input component
              disableUnderline: true,
              required: false,
              classes: { root: classes.root, },
              inputProps: { //refers to the input element
                maxLength: props.maxLength,
                readOnly: props.readOnly,
                style: {
                  color: redText ? 'var(--ibs_color_red)' : (props.disabled ? 'var(--ibs_color_disabled)' : 'var(--ibs_color_black)'),
                  textTransform: upperCase ? 'uppercase' : lowerCase ? 'lowercase' : capitalize ? 'capitalize' : '',
                },
              },
            }}
            value={myValue || ''}
            onBlur={() => {
              if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
            }}
            onChange={(e) => setVal(e.target.value)}
            onFocus={event => {
              if (rest.autoselect) event.target.select();
            }}
            onKeyDown={() => setChanged(true)}
            {...rest}
          />
        </div>

        {!props.hidebuttons &&
          <div className={classes.frame_buttons}>
            {!props.readOnly && <CxIconButton type="clear" classType={classes.frame_icons} onClick={doClear} disabled={props.disabled} />}
            {props.onGetValue && <CxIconButton type="getval" classType={classes.frame_icons} onClick={doGetValue} disabled={props.disabled} />}
            {props.onSearch && <CxIconButton type="search" classType={classes.frame_icons} onClick={doSearch} disabled={props.disabled} />}
            {rest.tpl_table && <CxIconButtonNew icon={<FiInbox fontSize={18} />} classType={classes.frame_icons_new} onClick={() => setDlgTpl(true)} tooltip={trans('field.templates')} />}
            {speech && 
              <CxIconButtonNew icon={<BiMicrophone fontSize={20} />} 
                classType={classes.frame_icons_new} 
                disabled={props.disabled} 
                onMouseDown={() => doSpeech(1)} 
                onMouseUp={() => doSpeech(0)} 
              />
            } {/* OJO ESTE BOTÓN NO ES EL MISMO QUE HAY EN CXTABLE */}
          </div>
        }

        <div>
          {rest.tpl_table &&
            <>
              <CxDialog title={trans('field.templates')} open={dlgTpl} onClose={() => doTemplateWindowClose()} height='430px'>
                <div style={{ width: "var(--ibs_control_width_lg)" }}>
                  {!editTemplate && <CxTable
                    xref={r_table}
                    origin='CxInputTemplate'
                    id={rest.id}
                    /* fullscreen */
                    /* windowHeight={400} */
                    hasButtonAdd={hasTplButtonAdd === true ? true : false} // NO tiene botón de manera predeterminada
                    url="general/page"
                    filter={tplFilter || ''}
                    addParams={`tableName=${rest.tpl_table}&idField=${rest.tpl_col_id}&txField=${rest.tpl_col_tx}&txExtraFilter1=${rest.tpl_extra_filter_1 || ''}`}
                    onOpenForm={doTemplateOperation}
                    columns={[
                      { id: "0", Header: "ID", accessor: rest.tpl_col_id, show: false },
                      { id: "1", Header: trans('field.name'), accessor: row => row[rest.tpl_col_tx], width: '80%' },
                      /* { id: "DEL", accessor: row => { return <CxIconButton type="delete" classType="ibs_iconbutton_table" /> }, align: 'center', width: '10%' }, */
                      { id: "DEL", accessor: row => { return <CxIconButtonNew icon={<BiTrash fontSize={25} />} classType={'ibs_iconbutton_table'} tooltip={trans('field.delete')} /> }, align: 'center', width: '10%' },
                      { id: "EDI", accessor: row => { return <CxIconButtonNew icon={<BiEdit fontSize={25} />} classType={'ibs_iconbutton_table'} tooltip={trans('field.edit')} /> }, align: 'center', width: '10%' }
                    ]}
                  />}
                  {editTemplate && <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {getTextField(rf_edit_tpl_name, trans('field.name'), 1, 50, setTplName, tplName)}
                    {getTextField(rf_edit_tpl_cont, trans('field.content'), 8, 500, setTplCont, tplCont)}
                    {placeholders && <CxButton label={trans('field.add') +' '+ trans('field.placeholder')} onClick={() => r_dlg_placeholders.current.setOpenAndFill(true)} style={{ width: 'var(--ibs_control_width_lg)', marginTop: '0px' }} />}
                    <CxButton label={trans('field.save')} onClick={doTemplateSave} style={{ width: 'var(--ibs_control_width_lg)', marginTop: '5px' }} />
                  </div>}
                </div>
                <CxSnackbar xref={r_snack_required} severity="error" />
              </CxDialog>

              <CxDlgRadioList
                xref={r_dlg_placeholders}
                onOk={doPlaceholderInsert}
                onOpen={() => { return placeholders || [] }}
                title={trans('field.placeholders')}
              />

              {speech &&
                <CxSpeech xref={r_speech} id={props.label}
                  setVal={(newValue) => {
                    if (newValue) {
                      setMyValue(newValue);
                    }
                  }}
                  onSpeechFinish={onSpeechFinish}
                />
              }

            </>
          }
        </div>

      </div>
    </CxTooltip>
  );
};

export default CxInputTemplate;
