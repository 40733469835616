import React, { useImperativeHandle } from "react";
import '../../styles/main.css';
import CxSnackbar from './CxSnackbar';
import { useTranslation } from '../hx/useTranslation';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';


const CxSpeech = props => {
  const [{ trans }] = useTranslation();
  const { id, setVal, onSpeechFinish } = props;

  const r_active_id = React.useRef(null); //sirve para evitar que si hay varios inputs con reconocimiento de voz, escriba a todos juntos en lugar que al que corresponde.
  const r_snack_speak_now = React.useRef(null);
  const r_snack_browser_does_not_support = React.useRef(null);

  const {
    transcript: srecon_transcript,
    listening: srecon_listening,
    resetTranscript,
    browserSupportsSpeechRecognition: srecon_browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  React.useEffect(() => {
    if (r_active_id.current===id) {
      Promise.all([setVal(srecon_transcript)]).then((result) => {
        FxLog.infox('CxSpeech.useEffect', '...srecon_transcript:', srecon_transcript);
      });
    }
    // eslint-disable-next-line
  }, [srecon_transcript]);
  
  React.useEffect(() => {
    if (!srecon_listening) {
      FxLog.infox('CxSpeech.useEffect', '...:', 'Microphone stopped listening.');

      if (onSpeechFinish) {
        onSpeechFinish();
      }
    }
    // eslint-disable-next-line
  }, [srecon_listening]);

  useImperativeHandle(props.xref, () => {
    return {
      getType, doStartStop, 
    };
  });
  const getType = () => { return 'CxSpeech' };

  const doStartStop = (activeId, boStart) => {
    if (boStart) {
      r_active_id.current = activeId;
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
      if (srecon_browserSupportsSpeechRecognition) {
        r_snack_speak_now.current.setOpen(true);
      } else {
        r_snack_browser_does_not_support.current.setOpen(true);
      }

    } else {
      r_active_id.current = '';
      SpeechRecognition.stopListening();
      r_snack_speak_now.current.setOpen(false);
    }
  };

  return (
    <>
      <CxSnackbar xref={r_snack_speak_now} severity="warning" value={trans('msg.speak_now')} autoHideDuration={1000000} />
      <CxSnackbar xref={r_snack_browser_does_not_support} severity="error" value={trans('msg.browser_doesnt_support_x', 'field.voice_recognition')} />
    </>
  );

};

export default CxSpeech;
