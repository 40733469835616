import React, { useImperativeHandle } from "react";
import CxDialog from "../cx/CxDialog";
import * as FxLog from '../fx/FxLog';
import * as FxSms from '../fx/FxSms';
import * as FxNum from '../fx/FxNum';
import * as FxRegion from '../fx/FxRegion';
import '../../styles/main.css';
import CxInput from '../cx/CxInput';
import CxNumber from '../cx/CxNumber';
import CxStatic from '../cx/CxStatic';
import { useTranslation } from '../hx/useTranslation';
import CxSnackbar from '../cx/CxSnackbar';


const GxDlgSmsPurchase = props => {
  const [{ trans }] = useTranslation();

  const r_dlg = React.useRef(null);

  const r_snack_success = React.useRef(null);
  const r_snack_error = React.useRef(null);

  let rf_your_name = React.useRef(null);
  let rf_your_email = React.useRef(null);
  let rf_quantity = React.useRef(null);
  let rf_total = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const r_snack_required = React.useRef(null);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType, setOpen,
    };
  });
  const getType = () => { return 'GxDlgSmsPurchase' };


  const doOk = () => {
    try {
      if (rf_your_name.current.isEmpty()) {
        r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.your_name'));
        return;
      }
      if (rf_your_email.current.isEmpty()) {
        r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.your_email'));
        return;
      }
      if (rf_quantity.current.isEmpty()) {
        r_snack_required.current.setValAndOpen(trans('field.required') + ": " + trans('field.quantity'));
        return;
      }
      if (rf_quantity.current.getIntVal() < 250) {
        r_snack_required.current.setValAndOpen(trans('msg.value_greater_than_x', '250'));
        return;
      }

      let your_name = rf_your_name.current.getVal();
      let your_email = rf_your_email.current.getVal();
      let quantity = rf_quantity.current.getVal();
      Promise.all([FxSms.purchaseCredits(your_name, your_email, quantity)]).then((result) => {
        if (result[0] === 'OK') {
          r_snack_success.current.setValAndOpen(trans('msg.request_sent'));
        } else {
          r_snack_error.current.setOpen(true);
        }
        setOpen(false);
      });

    } catch (error) {
      FxLog.errorx("GxDlgSmsPurchase.doOk", error);
    }
  }

  return (
    <div>
      <CxDialog
        xref={r_dlg}
        title={`SMS - ${trans('field.purchase')}`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={doOk}
        height='310px'
      >

        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxInput xref={rf_your_name} label={trans('field.your_name')} width='var(--ibs_control_width_md)' maxLength={50} required />
          <CxInput xref={rf_your_email} label={trans('field.your_email')} width='var(--ibs_control_width_md)' maxLength={50} required />

          <CxNumber xref={rf_quantity} label={trans('field.quantity')}
            decimals={0}
            width='var(--ibs_control_width_md)'
            defaultValue={250}
            required
            onChange={(newValue) => {
              if (FxRegion.isSpain()) {
                let price = 0.13;
                let theTotal = FxNum.toString(FxNum.to2r00(rf_quantity.current.getFloatVal() * (price+(price*21/100))));
                rf_total.current.setLabel(trans('field.total') + ': € ' + (theTotal||'0.00'));
              }
            }}
          />
          <CxStatic xref={rf_total} border='none' fontcolor='var(--ibs_color_blue)' fontsize='14px' />

        </div>

      </CxDialog>

      <CxSnackbar xref={r_snack_required} severity="error" />
      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );
};

export default GxDlgSmsPurchase;
