import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient, StoreRxGlas } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxActivityOptiG = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const size = useWindowSize();
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeRxGlas, setStoreRxGlas] = useSessionStorage('StoreRxGlas', StoreRxGlas);
  
  const r_list = React.useRef(null);
  const r_dlg_select_client = React.useRef(null);

  React.useEffect(() => {
    clearStore('StoreRxGlas', storeRxGlas);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      r_dlg_select_client.current.setIntermediateValues({});
      r_dlg_select_client.current.setOpen(true);
    } else {
      FxLog.infox('MxActivityOptiG.openForm', '...row:', row);
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        FxLog.infox('MxActivityOptiG.openForm', '...storeClient:', storeClient);
        storeRxGlas.inRxGlas = row.original._id;
        setStoreRxGlas(storeRxGlas);
        navTo("client-rx-glas-form");
      });
    }
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    try{
    cols.push({ id: id++, Header: "ID", accessor: "_id", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%' });
    cols.push({ id: id++, Header: trans('field.date'), accessor: row => r_list.current.getTable().highlightDate(row["dtFrom"]), width: '10%' });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.reason'), accessor: row => r_list.current.getTable().highlightText(row["txReason"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.status'), accessor: row => row["txTypeStatusRx"], width: '15%' });
      cols.push({ id: id++, Header: trans('field.treated_by'), accessor: row => row["txProf"], width: '15%' });

    } else { // PANTALLA PEQUEÑA
    }

    cols.push({ id: "G", Header: "finished", accessor: row => Boolean(row["itStatusRx"] === '000002.SRV'), show: false });
    cols.push({ id: "B", Header: "delivered", accessor: row => Boolean(row["itStatusRx"] === '000003.SRV'), show: false });
    return cols;

    } catch(error) {
      FxLog.errorx('MxActivityOptiG.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.activity')}: ${trans('field.rx_glas')}`}
        xref={r_list}
        tooltip={`${trans('field.client')} / ${trans('field.date')} / ${trans('field.reason')}`}
        fullscreen
        url={`activity/opti/typelist/g`}
        onOpenForm={openForm}
        refColors={[
          'table_cell_color_black|' + trans('field.pending'),
          'table_cell_color_green|' + trans('field.finished_1'),
          'table_cell_color_blue|' + trans('field.delivered_1'),
        ]}
        columns={getTableColumns()}
        onOptions={() => {}} // para que muestre botón de opciones.
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonLast7Days={true}
        optionalFromToButtonLast30Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />
          </div>
        }
        speechExternal
      />

      <GxDlgSelectClient xref={r_dlg_select_client} 
        includeAnimal={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('MxActivityOptiG.return.GxDlgSelectClient', '...row:', row);
          Promise.all([setStoreClient(storeClient)]).then((result) => {
            openForm(null, row);
          });
        }} 
      />

    </div>
  );

}

export default MxActivityOptiG;
