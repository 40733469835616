import { useNavigate } from 'react-router-dom';
import * as FxLog from '../fx/FxLog';
import { getStoreValue } from "../gx/GxStore";

export const useNav = () => {
  let navigate = useNavigate();
  
  const isCP = () => { return Boolean(getStoreValue('StoreSession', 'inClientCP')) } // 2023-05-26 - daq: Portal del Cliente

  const _nav = (to, options) => {
    FxLog.infox('useNav._nav', '..._navStack():', _navStack());
    navigate(to, options);
  }

  const _navExit = () => {
    let path = isCP() ? 'cp' : 'login';
    sessionStorage.clear();
    _nav(`/${path}`, { replace: true });
  }

  const _navTo = (url, navParams) => {
    let arUrl = JSON.parse(sessionStorage.getItem('useNav.urlStack'))||[];
    arUrl.push(url);
    sessionStorage.setItem('useNav.urlStack', JSON.stringify(arUrl));
    sessionStorage.setItem('useNav.count', (Number(sessionStorage.getItem('useNav.count')||0) + 1).toString());
    _nav(`/${isCP() ? 'main_cp' : 'main'}/${url}`, { replace: true, state: navParams }); //useLocation() captura los params
  }

  const _navBack = (navParams) => {
    let arUrl = JSON.parse(sessionStorage.getItem('useNav.urlStack'))||[];
    arUrl.pop();
    let url = arUrl.slice(-1)[0];
    sessionStorage.setItem('useNav.urlStack', JSON.stringify(arUrl));
    sessionStorage.setItem('useNav.count', (Number(sessionStorage.getItem('useNav.count')||0) - 1).toString());
    if (url) {
      _nav(`/${isCP() ? 'main_cp' : 'main'}/${url}`, { replace: true, state: navParams /* ej: GxSlsAuxTableItem y GxSlsAuxTableItem */ }); //useLocation() captura los params
    }
  }

  const _navStack = () => {
    return JSON.parse(sessionStorage.getItem('useNav.urlStack'));
  }

  /** devuelve la posición en que se encuentra la url pasada, o -1 si no la encuentra. */
  const _navStackFind = (url) => {
    const stack = _navStack();
    if (!Array.isArray(stack)) {
      return false; // Manejo de error: _navStack() no devolvió un array
    }
    return stack.includes(url);
  };

  const _navClear = () => {
    sessionStorage.removeItem('useNav.urlStack');
    sessionStorage.setItem('useNav.count', '0');
    //_navTo('blank'); // 2025-03-17 - daq: quitado porque provoca que se pierda el hilo de cuándo poner las flechas de retroceso.
  }

  const _navCount = () => {
    let count = sessionStorage.getItem('useNav.count')||0;
    FxLog.infox('useNav._navCount', 'count: ' + count);
    return count;
  }

  return [
    {
      navTo: _navTo,
      navBack: _navBack,
      navClear: _navClear,
      navCount: _navCount,
      navExit: _navExit,
      navStack: _navStack,
      navStackFind: _navStackFind,
    },
  ];
};
