import { BsMagic } from "react-icons/bs"; 
import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import CxTable from '../../cx/CxTable';
import CxDialog from '../../cx/CxDialog';
import CxSnackbar from '../../cx/CxSnackbar';
import CxDate from '../../cx/CxDate';
import CxSearcher from '../../cx/CxSearcher';
import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';
import * as FxEdition from '../../fx/FxEdition';
import DocType from '@material-ui/icons/Receipt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StorePurLst, StorePurDoc, StoreIntegrationPurInvoices, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import * as MxPurDocFormH1 from './MxPurDocFormH1';
import CxToolbar from '../../cx/CxToolbar';
import { makeStyles } from '@material-ui/core/styles';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
import CxIconButtonMenu from '../../cx/CxIconButtonMenu';
import { CgImport } from "react-icons/cg"; 

const useStyles = makeStyles({
  toggleButtonGroup: {
    background: 'var(--ibs_color_gray_light)',
    '& .MuiToggleButton-root.Mui-selected': {
      background: 'var(--ibs_color_white)',
    },
    '& .MuiToggleButton-root': {
      padding: '0px 10px 0px 10px',
    },
  },
});

const MxPurDocList = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles();
  const [{ navTo }] = useNav();
  const size = useWindowSize();

  const rf_table = React.useRef(null);
  let r_dlg_upgrade = React.useRef(null);

  const r_snack_required = React.useRef(null);

  const rf_create_orders_dtFrom = React.useRef(null);
  const rf_create_orders_dtTo = React.useRef(null);
  const rf_create_orders_txSupplier = React.useRef(null);

  const [dlgCreateOrdersFromSales, setDlgCreateOrdersFromSales] = React.useState(false);
  const [dlgCreateOrdersFromStock, setDlgCreateOrdersFromStock] = React.useState(false);

  const [storePurLst, setStorePurLst] = useSessionStorage('StorePurLst', StorePurLst);
  const [storePurDoc, setStorePurDoc] = useSessionStorage('StorePurDoc', StorePurDoc);

  const [docTitle, setDocTitle] = React.useState(trans('field.invoices'));

  // eslint-disable-next-line
  const [storeIntegrationPurInvoices, setStoreIntegrationPurInvoices] = useSessionStorage('StoreIntegrationPurInvoices', StoreIntegrationPurInvoices);

  React.useEffect(() => {
    changeDocType(null, storePurLst.listType || 'i');

    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {

    storePurDoc.boFirstRender = true;
    storePurDoc.boDataChanged = '0';
    storePurDoc.docType = storePurLst.listType;
    if (row === null) {
      storePurDoc.inPurXXX = null;
      storePurDoc.nuStatus = '1';
      setStorePurDoc(storePurDoc);
      navTo("pur-doc-form");
    } else {
      storePurDoc.inPurXXX = row.original[storePurLst.table_idField];
      Promise.all([MxPurDocFormH1.load(storePurLst, storePurDoc)]).then((result) => {
        setStorePurDoc(storePurDoc);
        navTo("pur-doc-form");
      });
    }
  }

  const changeDocType = (event, newType) => {
    clearStore('StorePurDoc', storePurDoc);
    if (newType === null) {
      return;
    }
    let hasTypeChanged = false;
    if (newType !== storePurLst.listType) {
      hasTypeChanged = true;
    }
    switch (newType) {
      case 'o':
        storePurLst.listType = 'o';
        storePurLst.listTypeBackground = 'var(--ibs_color_yellow_light)';
        storePurLst.table = 'n_pur_order';
        storePurLst.table_idField = 'inPurOrder';
        storePurLst.table_txField = 'txPurOrder';
        storePurLst.table_dtField = 'dtPurOrder';
        storePurLst.table_item = 'n_pur_order_item';
        storePurLst.table_item_idField = 'inPurOrderItem';
        storePurLst.table_paym_idField = 'inPurPayment';
        Promise.all([setStorePurLst(storePurLst)]).then((result) => {
          setDocTitle(trans('field.orders'));
          rf_table.current.setTableID(`MxPurDocList-${storePurLst.listType}`);
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('pur/doclist/o');
        });
        break;
      case 'i':
        storePurLst.listType = 'i';
        storePurLst.listTypeBackground = 'var(--ibs_color_blue_light)';
        storePurLst.table = 'n_pur_invoice';
        storePurLst.table_idField = 'inPurInvoice';
        storePurLst.table_txField = 'txPurInvoice';
        storePurLst.table_dtField = 'dtPurInvoice';
        storePurLst.table_item = 'n_pur_invoice_item';
        storePurLst.table_item_idField = 'inPurInvoiceItem';
        storePurLst.table_paym_idField = 'inPurPayment';
        Promise.all([setStorePurLst(storePurLst)]).then((result) => {
          setDocTitle(trans('field.invoices'));
          rf_table.current.setTableID(`MxPurDocList-${storePurLst.listType}`);
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('pur/doclist/i');
        });
        break;
      case 'r':
        storePurLst.listType = 'r';
        storePurLst.listTypeBackground = 'var(--ibs_color_green_light)';
        storePurLst.table = 'n_receipt';
        storePurLst.table_idField = 'inPurReceipt';
        storePurLst.table_txField = 'txPurReceipt';
        storePurLst.table_dtField = 'dtPurReceipt';
        storePurLst.table_item = 'n_pur_invoice_item';
        storePurLst.table_item_idField = 'inPurInvoiceItem';
        storePurLst.table_paym_idField = 'inPurPayment';
        Promise.all([setStorePurLst(storePurLst)]).then((result) => {
          setDocTitle(trans('field.receipts'));
          rf_table.current.setTableID(`MxPurDocList-${storePurLst.listType}`);
          rf_table.current.setRefColors(getrefColors());
          if (hasTypeChanged) rf_table.current.redisplay('pur/doclist/r');
        });
        break;

      default:
        FxLog.errorx("MxPurDocList.openForm", "Cannot find doctype!!! -> " + newType);
        break;
    }
  };

  const autoCreateOrders = async (option) => {
    if (!FxEdition.isInEdition('PUR_ord')) {
      r_dlg_upgrade.current.setOpen(true);
    } else {
      if (option === 'sales') {
        setDlgCreateOrdersFromSales(true);
      };
      if (option === 'stock') {
        setDlgCreateOrdersFromStock(true);
      };
    }
  }

  const integrationImports = async (option) => {
    if (option === 'nuzoa') {
      autoImportInvoices('Nuzoa');
    };
  }

  const autoImportInvoices = async (strSupplierName) => {
    FxLog.infox('MxPurDocList.autoImportInvoices', '...strSupplierName:', strSupplierName);
    if (!FxEdition.isInEdition('PUR_inv')) {
      r_dlg_upgrade.current.setOpen(true);
    } else {
      storeIntegrationPurInvoices.strSupplierName = strSupplierName;
      Promise.all([setStoreIntegrationPurInvoices(storeIntegrationPurInvoices)]).then((result) => {
        navTo("integration-pur-invoices-option");
      });
    }
  }

  const doCreateOrdersFromSales = async (option) => {
    setDlgCreateOrdersFromSales(false);
    let record = {};
    record['dtFrom'] = rf_create_orders_dtFrom.current.getVal();
    record['dtTo'] = rf_create_orders_dtTo.current.getVal();
    record['inSupplier'] = rf_create_orders_txSupplier.current.getKey();

    if (rf_create_orders_dtFrom.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + trans('field.from'));
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_create_orders_dtTo.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + trans('field.to'));
      r_snack_required.current.setOpen(true);
      return;
    }
    if (rf_create_orders_txSupplier.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + trans('field.supplier'));
      r_snack_required.current.setOpen(true);
      return;
    }

    Promise.all([FxFetch.doJsonX(`/pur/doclist/o/create_orders_from_sales`, record)]).then((result) => {
      // refresca listado
      rf_table.current.redisplay();
    });
  }

  const doCreateOrdersFromStock = async (option) => {
    setDlgCreateOrdersFromStock(false);
    let record = {};
    record['inSupplier'] = rf_create_orders_txSupplier.current.getKey();

    if (rf_create_orders_txSupplier.current.isEmpty()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + trans('field.supplier'));
      r_snack_required.current.setOpen(true);
      return;
    }

    Promise.all([FxFetch.doJsonX(`/pur/doclist/o/create_orders_from_stock`, record)]).then((result) => {
      // refresca listado
      rf_table.current.redisplay();
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: storePurLst.table_idField, show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.doc'), accessor: row => rf_table.current.highlightText(row[storePurLst.table_txField]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => rf_table.current.highlightDate(row[storePurLst.table_dtField]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.supplier'), accessor: row => rf_table.current.highlightText(row["txSupplier"]), width: '20%' });
      cols.push({ id: id++, Header: trans('field.taxable'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTaxable"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.discount'), accessor: row => (FxTable.cellTo2r00(row["nuTotalDiscount"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.tax'), accessor: row => (FxTable.cellTo2r00(row["nuTotalTax"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalAmount"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.surcharge'), accessor: row => (FxTable.cellTo2r00(row["nuTotalSurcharge"])), width: '10%', align: 'right' });
      cols.push({ id: id++, Header: trans('field.paid'), accessor: row => (FxTable.cellTo2r00(row["nuTotalPayment"])), width: '10%', align: 'right', show: (storePurLst.listType !== 'o') });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => rf_table.current.highlightDate(row[storePurLst.table_dtField]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.supplier'), accessor: row => rf_table.current.highlightText(row["txSupplier"]), width: '50%' });
      cols.push({ id: id++, Header: trans('field.total'), accessor: row => (FxTable.cellTo2r00(row["nuTotalAmount"])), width: '20%', align: 'right' });
    }
    if (storePurLst.listType === 'i' || storePurLst.listType === 'r') {
      cols.push({ id: 'A', Header: "unconfirmed", accessor: row => Boolean(row["nuStatus"] === '1' ? true : false), show: false });
    }
    if (storePurLst.listType === 'i') {
      cols.push({ id: 'G', Header: "hasReceipt", accessor: row => Boolean(row["_inPurReceipt"] !== ''), show: false });
    }
    return cols;
  }

  const getrefColors = () => {
    let rv;
    try {
      switch (storePurLst.listType) {
        case 'o':
          rv = '';
          break;

        case 'i':
          rv = [            
            'table_cell_black|' + trans('field.included_in_x', 'field.stock'),
            'table_cell_color_gray|' + trans('field.excluded_from_x', 'field.stock'),
            'table_cell_color_green|' + trans('field.receipt')
            ];
          break;

        case 'r':
          rv = '';
          break;

        default: break;
      }

    } catch (error) {
      FxLog.errorx("MxPurDocList.getrefColors", error);
    }
    return rv;
  }

  const getTooltip = () => {
    let tip = '';
    tip = `${trans('field.doc')} / ${trans('field.date')} / ${trans('field.supplier')}`;
    return tip;
  }

  return (
    <div>
      <CxToolbar
        moduleName={`${trans('field.purchases')}: ${docTitle}`}
        background={storePurLst.listTypeBackground}
        addToolbarButtons={
          <>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5px' }}>
              {(storePurLst.listType === 'o')
                && <CxIconButtonMenu icon={<BsMagic fontSize="22px" />} tooltip={trans('field.auto_create_x', 'field.orders')} onSelection={autoCreateOrders} className="ibs_iconbutton_toolbar" width='250px'
                  options={[
                    {
                      id: 'sales',
                      text: trans('field.create_x_from_x', 'field.orders', 'field.sales'),
                      color: !FxEdition.isInEdition('PUR_ord') && 'var(--ibs_color_gray)',
                    },
                    {
                      id: 'stock',
                      text: trans('field.create_x_from_x', 'field.orders', 'field.stock'),
                      color: !FxEdition.isInEdition('PUR_ord') && 'var(--ibs_color_gray)',
                    },
                  ]}
                />}

              {(storePurLst.listType === 'i') && isApp('vett')
                && <CxIconButtonMenu icon={<CgImport fontSize="24px" />} tooltip={trans('field.import_x', 'field.invoices')} onSelection={integrationImports} className="ibs_iconbutton_toolbar" width='250px'
                  options={[
                    {
                      id: 'nuzoa',
                      text: trans('field.import_x_from_x', 'field.invoices', 'Nuzoa'),
                      //color: !FxEdition.isInEdition('PUR_ord') && 'var(--ibs_color_gray)',
                    },
                  ]}
                />}

            </div>

            <ToggleButtonGroup value={storePurLst.listType} exclusive onChange={changeDocType} className={classes.toggleButtonGroup} >
              <ToggleButton value="o">
                <DocType style={{ color: 'var(--ibs_color_toggle_yellow)' }} />
              </ToggleButton>
              <ToggleButton value="i">
                <DocType style={{ color: 'var(--ibs_color_toggle_blue)' }} />
              </ToggleButton>
              <ToggleButton value="r">
                <DocType style={{ color: 'var(--ibs_color_toggle_green)' }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        }
      />

      <div style={{ margin: 'var(--ibs_container_margin)' }}>
        <CxTable
          xref={rf_table}
          id={`MxPurDocList-${storePurLst.listType || 'i'}`}
          tooltip={getTooltip()}
          url={`pur/doclist/${storePurLst.listType || 'i'}`}
          fullscreen
          hasSearchBox
          hasButtonAdd={storePurLst.listType !== 'r'}
          refColors={getrefColors()}
          columns={getTableColumns()}
          onOpenForm={openForm}
        >
        </CxTable>
      </div>

      <CxSnackbar xref={r_snack_required} severity="error" />

      <CxDialog
        title={trans('field.create_x_from_x', 'field.orders', 'field.sales')}
        open={dlgCreateOrdersFromSales}
        onCancel={() => setDlgCreateOrdersFromSales(false)}
        onOk={() => doCreateOrdersFromSales()}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxDate xref={rf_create_orders_dtFrom} label={trans('field.from')} width='var(--ibs_control_width_md)' today required />
          <CxDate xref={rf_create_orders_dtTo} label={trans('field.to')} width='var(--ibs_control_width_md)' today required />

          <CxSearcher xref={rf_create_orders_txSupplier}
            label={trans('field.supplier')}
            url="suppliers"
            col_id="inSupplier"
            col_tx="txSupplier"
            refColors={['table_cell_color_red|' + trans('field.debtor')]}
            columns={[
              { id: "0", Header: "ID", accessor: "inSupplier", show: false },
              { id: "1", Header: trans('field.supplier'), accessor: row => rf_create_orders_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
              { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
            ]}
            width='var(--ibs_control_width_md)'
            required
          />
        </div>
      </CxDialog>

      <CxDialog
        title={trans('field.create_x_from_x', 'field.orders', 'field.stock')}
        open={dlgCreateOrdersFromStock}
        onCancel={() => setDlgCreateOrdersFromStock(false)}
        onOk={() => doCreateOrdersFromStock()}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxSearcher xref={rf_create_orders_txSupplier}
            label={trans('field.supplier')}
            url="suppliers"
            col_id="inSupplier"
            col_tx="txSupplier"
            refColors={['table_cell_color_red|' + trans('field.debtor')]}
            columns={[
              { id: "0", Header: "ID", accessor: "inSupplier", show: false },
              { id: "1", Header: trans('field.supplier'), accessor: row => rf_create_orders_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
              { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
            ]}
            width='var(--ibs_control_width_md)'
            required
          />
        </div>
      </CxDialog>

      <CxDlgUpgrade xref={r_dlg_upgrade} />

    </div>
  );

}

export default MxPurDocList;
