import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

const CxIcon = props => {
  const { iconBack, iconFront, size, colorBack, colorFront } = props;
  const [iconSize, ] = React.useState(size||'24px');

  const defaultColorBack = 'var(--ibs_color_cy_blue)';
  const defaultColorFront = 'var(--ibs_color_red)';
  const [iconColorBack, setIconColorBack] = React.useState(colorBack||defaultColorBack);
  const [iconColorFront, setIconColorFront] = React.useState(colorFront||defaultColorFront);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(props.xref, () => {
    return {
      getType,
    };
  });
  const getType = () => { return 'CxIcon' };

  return (
    <div style={{ position: "relative", width: iconSize, height: iconSize }}>
      {/* Ícono de Fondo */}
      {React.cloneElement(iconBack, {
        style: { fontSize: iconSize, color: iconColorBack },
        onMouseEnter: () => setIconColorBack('var(--ibs_color_red)'), 
        onMouseLeave: () => setIconColorBack(colorBack||defaultColorBack), 
      })}

      {/* Ícono de Salida (Más Pequeño y en la esquina) */}
      {iconFront && React.cloneElement(iconFront, { 
        style: { position: "absolute", bottom: "0", right: "0", fontSize: "16px", color: iconColorFront, },
        onMouseEnter: () => setIconColorFront('var(--ibs_color_red)'), 
        onMouseLeave: () => setIconColorFront(colorFront||defaultColorFront), 
      })}

    </div>
  );

};

export default CxIcon;
