// Input para formularios
import React from "react";
import TextField from '@material-ui/core/TextField';
import * as FxLog from '../fx/FxLog';
import * as FxNum from '../fx/FxNum';
import CxDialog from "./CxDialog";
import CxTable from "./CxTable";
import CxIconButton from "./CxIconButton";
import '../../styles/main.css';
import * as FxFetch from '../fx/FxFetch';
import { getStoreValue } from "../gx/GxStore";
import { useStyles } from "./StylesCx";


const CxChooser = props => {
  const classes = useStyles(props);
  const { onValueChange, onBlur, onClear, onCreate, onSelection, onButtonAdd, canOpenDialog,
    InputLabelProps, defaultKeyAndValue, bottomLegend, ...rest } = props;
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  const [isRequired, setIsRequired] = React.useState(false);
  const [dlg, setDlg] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [wasChanged, setChanged] = React.useState(false);

  const r_table = React.useRef(null);
  const r_dec_separator = React.useRef(getStoreValue('StoreSession', 'txDecimalSeparator'));

  React.useEffect(() => {
    if (onValueChange !== undefined) onValueChange();
    // eslint-disable-next-line
  }, [myValue]);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultKeyAndValue) {
      setKey(defaultKeyAndValue.split("|")[0]);
      setVal(defaultKeyAndValue.split("|")[1]);
    }
    if (props.value) {
      setMyValue(props.value);
    }
    if (rest.filter) {
      setFilter(rest.filter);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getFloatVal, setKey, getKey, getIsRequired, getLabel, getDbfield, setFilter, clear,
      isEmpty, setKeyAndVal, getTable, wasChanged, setChanged, setDlg, search
    };
  });
  const setVal = (newValue) => { setMyValue(newValue) };
  const getVal = () => { return myValue };
  const getFloatVal = () => { return parseFloat(getVal() || 0) };
  const setKey = (newId) => { setMyKey(newId) };
  const getKey = () => { return myKey };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const getType = () => { return 'CxChooser' };
  const getTable = () => { return r_table.current };

  const setKeyAndVal = async (table, txFieldToGet, idField, idValue) => {
    try {
      setMyKey(idValue);
      let where = idField + "='" + idValue + "'";
      let record = {};
      record['table'] = table;
      record['field'] = txFieldToGet;
      record['where'] = where;
      Promise.all([FxFetch.doJsonX("general/r", record)]).then((result) => {
        if (result[0]) {
          let newValue = result[0].theValue;
          setMyValue(newValue);
        }
      });
      // -------------------------------------------------------------

    } catch (error) {
      FxLog.errorx("CxChooser.setKeyAndVal", error);
    }
  }

  const clear = () => {
    FxLog.infox("CxChooser.clear", "...");
    if (!props.readOnly && !props.disabled) {
      setMyKey('');
      setMyValue('');
      setChanged(true);
      if (onClear !== undefined) onClear();
    }
  };

  const create = () => {
    FxLog.infox("CxChooser.create", "...");
    if (!props.readOnly && !props.disabled) {
      if (onCreate !== undefined) onCreate();
    }
  };

  const isEmpty = () => { return myValue.length === 0 };

  const search = async () => {
    if (!props.readOnly && !props.disabled) {
      let boOpen = (canOpenDialog === undefined || canOpenDialog());
      FxLog.infox("CxChooser.search", "opening dialog..." + boOpen);
      if (boOpen) {
        Promise.all([setDlg(true)]).then((result) => {
          r_table.current.redisplay(); // 2025-01-29 - daq: para que al volver a abrirlo no recuerde la página vieja
        });
      }
    }
  }

  const doClose = () => {
    setDlg(false);
  }

  const doOperation = async (op, row) => {
    if (op === 'c') {
      if (onButtonAdd !== undefined) {
        onButtonAdd();
      }
    } else {
      let id = row.original[props.col_id];
      let tx = row.original[props.col_tx];
      setMyKey(id);
      setMyValue(tx);
      setDlg(false);
      setChanged(true);
      if (props.onSelection !== undefined) {
        props.onSelection(row);
      }
    }
  }

  const getFormatted = (row) => {
    let rv = row[props.col_tx];
    if (rest.decimals) {
      if (rest.decimals === 2) {
        rv = FxNum.to2r00(rv);
      }
      if (rest.decimals === 4) {
        rv = FxNum.to4r00(rv);
      }
      rv = rv.replace('.', r_dec_separator.current);
    }
    return rv;
  }

  const getValue = () => {
    let rv = '';
    if (myValue && rest.decimals) {
      if (rest.decimals === 2) {
        rv = FxNum.to2r00(myValue);
      }
      if (rest.decimals === 4) {
        rv = FxNum.to4r00(myValue);
      }
      rv = rv.replace('.', r_dec_separator.current);
    } else {
      rv = myValue;
    }
    return rv || ''; // 2022-09-11 - daq: añadido ||'' para evitar el error de componente no controlado a controlado.
  }

  const getTableColumns = () => {
    let cols = [];
    try {
      let id = -1;
      cols.push({ id: id++, Header: "ID", accessor: props.col_id, show: false });
      cols.push({ id: id++, Header: props.label, accessor: row => getFormatted(row), width: '100%' });
      if (props.extra_column_field_1) {
        cols.push(props.extra_column_value_1);
      }

    } catch (error) {
      FxLog.errorx('CxChooser.getTableColumns', error)
    }
    return cols;
  }

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          minRows={props.minRows}
          multiline={props.minRows !== undefined ? true : false}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            disableUnderline: true,
            required: false,
            classes: { root: classes.root, },
            inputProps: { //refers to the input element
              style: { cursor: 'default' },
              maxLength: props.maxLength,
              readOnly: true,
            },
          }}
          value={getValue()}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => setMyValue(e.target.value)}
          onClick={() => search()} // 2025-02-13 - daq: mismo efecto que darle al botoncito, para que la gente no se frustre cuando tratan de darle foco para escribir.
          {...rest}
        />
        <div>
          <CxDialog title={rest.label} open={dlg} onClose={doClose} height='600px'>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
              <CxTable
                xref={r_table}
                id={props.id}
                origin='CxChooser'
                fullscreen
                windowHeight={600}
                hasButtonAdd={onButtonAdd} // NO tiene botón de manera predeterminada
                url="general/page"
                filter={filter || ''}
                addParams={`tableName=${props.table}&idField=${props.col_id}&txField=${props.col_tx}&txExtraColumn1=${props.extra_column_field_1 || ''}&txExtraFilter1=${props.extra_filter_1 || ''}`}
                onOpenForm={doOperation}
                columns={getTableColumns()}
              />
            </div>
            {bottomLegend && <span style={{
              margin: '5px 2px 2px 2px',
              paddingTop: '5px',
              paddingBottom: '5px',
              background: 'var(--ibs_color_red_dark)',
              color: 'var(--ibs_color_white)',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '12px',
            }}>{bottomLegend}</span>}
          </CxDialog>
        </div>
      </div>

      <div className={classes.frame_buttons}>
        {!props.readOnly && !onCreate &&
          <CxIconButton type="clear" classType={classes.frame_icons} onClick={clear} disabled={props.disabled} />}
        {!props.readOnly && onCreate &&
          <CxIconButton type="add" classType={classes.frame_icons} onClick={create} disabled={props.disabled} />}
        {!props.readOnly &&
          <CxIconButton type="search" classType={classes.frame_icons} onClick={() => search()} disabled={props.disabled} />}
      </div>
    </div>
  );
};

export default CxChooser; 
