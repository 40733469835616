import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
import CxNumber from '../../cx/CxNumber';
import CxRadio from '../../cx/CxRadio';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import { StoreSettRate } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxForm from '../../gx/GxForm';


const MxSettSlsRatePsForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  let rf_base = React.useRef(null);
  let rf_txTypePsSubgroup = React.useRef(null);
  let rf_txPs = React.useRef(null);
  let rf_nuDiscountPerc = React.useRef(null);

  const r_snack_save = React.useRef(null);
  const r_snack_required = React.useRef(null);

  const [base, setBase] = React.useState('SG');

  // eslint-disable-next-line no-unused-vars
  const [storeSettRate, setStoreSettRate] = useSessionStorage('StoreSettRate', StoreSettRate);


  const formSaveCustom = async (lastSaveRv) => {
    if (!isRequired()) {
      let filter = {};
      filter['base'] = base;
      let record = {};
      record['itRate'] = storeSettRate.itRate;
      if (base === 'SG') record['itPsSubgroup'] = rf_txTypePsSubgroup.current.getKey();
      if (base === 'PS') record['inPs'] = rf_txPs.current.getKey();
      record['nuDiscountPerc'] = rf_nuDiscountPerc.current.getVal();
      Promise.all([FxFetch.doJsonF("sett/rate/ps/cu", filter, record)]).then((result) => {
        if (result[0]) {
          r_snack_save.current.setOpen(true);
        }
      });
    }
  }

  const isRequired = () => {
    let rv = false;
    let cc = null;
    if (base === 'SG') cc = [rf_txTypePsSubgroup, rf_nuDiscountPerc];
    if (base === 'PS') cc = [rf_txPs, rf_nuDiscountPerc];
    cc.reverse().forEach((c, index) => {
      if (c.current.getIsRequired()) {
        let label = c.current.getLabel();
        r_snack_required.current.setVal(trans('field.required') + ": " + label);
        r_snack_required.current.setOpen(true);
        rv = true;
      }
    });
    return rv;
  };

  return (
    <div>
      <GxForm
        xref={r_form}
        moduleName={`${trans('field.rate')}: ${trans('field.pss')}`}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <br />
        <CxRadio xref={rf_base}
          list={[
            { id: 'SG', type: trans('field.subgroup') },
            { id: 'PS', type: trans('field.ps') },
          ]}
          onSelection={() => setBase(base === 'PS' ? 'SG' : 'PS')}
          autoSelectFirst
        />

        <br />
        {base === 'SG' &&
          <CxSearcher xref={rf_txTypePsSubgroup} 
            label={trans('field.subgroup')}
            tooltip={`${trans('field.subgroup')}`}
            url="sett/ps_subgroup"
            col_id="itPsSubgroup"
            col_tx="txTypePsSubgroup"
            columns={[
              { id: "0", Header: "ID", accessor: "itPsSubgroup", show: false },
              { id: "1", Header: trans('field.subgroup'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsSubgroup"]), width: '50%' },
              { id: "2", Header: trans('field.group'), accessor: row => rf_txTypePsSubgroup.current.getTable().highlightText(row["txTypePsGroup"]), width: '50%' },
            ]}
            required
          />
        }

        {base === 'PS' &&
          <CxSearcher xref={rf_txPs} 
            label={trans('field.ps')}
            tooltip={`${trans('field.ps')}`}
            url="pss"
            col_id="inPs"
            col_tx="txPs"
            columns={[
              { id: "0", Header: "ID", accessor: "inPs", show: false },
              { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '100%' },
            ]}
            required
          />
        }

        <CxNumber xref={rf_nuDiscountPerc} label={trans('field.discount_perc')} decimals={2} decimalsfixed="true" required />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSettSlsRatePsForm;
