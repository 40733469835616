import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxState from '../../cx/CxState';
import CxNumber from '../../cx/CxNumber';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';


const MxSettCalendarGeneral = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  const r_snack_save = React.useRef(null);
  const r_snack_required = React.useRef(null);

  let r_form = React.useRef(null);

  const rf_boSingleCalendar = React.useRef(null);
  const rf_nuIntervalMins = React.useRef(null);


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('cal/sett', record)]).then((result) => {
      if (result[0]) {
        rf_boSingleCalendar.current.setVal(result[0].boSingleCalendar);
        rf_nuIntervalMins.current.setVal(result[0].nuIntervalMins);
      }
    });

  }

  const formSaveCustom = async () => {

    if (rf_nuIntervalMins.current.isEmpty() || rf_nuIntervalMins.current.isZero()) {
      r_snack_required.current.setVal(trans('field.required') + ": " + rf_nuIntervalMins.current.getLabel());
      r_snack_required.current.setOpen(true);
      return;
    }

    let record = {};
    record['boSingleCalendar'] = rf_boSingleCalendar.current.getVal();
    record['nuIntervalMins'] = rf_nuIntervalMins.current.getVal();
    Promise.all([FxFetch.doJsonF("/sett/cal/general/u", null, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.calendars')} - ${trans('field.general')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <CxState mode='checkbox' xref={rf_boSingleCalendar} label={trans('field.sett_single_calendar')} required />
        <CxNumber xref={rf_nuIntervalMins} label={`${trans('field.interval')} - ${trans('field.minutes')}`} decimals={0} required />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSettCalendarGeneral;
