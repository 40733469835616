import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxSearcher from '../../cx/CxSearcher';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import { getStoreValue, setStoreValue } from "../../gx/GxStore";
import GxForm from '../../gx/GxForm';


const MxSettCommGeneral = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_save = React.useRef(null);
  let r_form = React.useRef(null);

  const rf_txUserToShowCommDlg = React.useRef(null);
  const rf_txEmailForComm = React.useRef(null);


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('sett/branch/r', record)]).then((result) => {
      if (result[0]) {
        rf_txUserToShowCommDlg.current.setKey(result[0][0].inUserToShowCommDlg);
        rf_txUserToShowCommDlg.current.setVal(result[0][0].txUserToShowCommDlg);
        rf_txEmailForComm.current.setKey(result[0][0].itEmailForComm);
        rf_txEmailForComm.current.setVal(result[0][0].txEmailForComm);
      }
    });
  }

  const formSaveCustom = async () => {

    let filter = { "inBranch": getStoreValue('StoreSession', 'inBranch'), };
    let record = {};
    record['s_branch.inUserToShowCommDlg'] = rf_txUserToShowCommDlg.current.getKey();
    record['s_branch.itEmailForComm'] = rf_txEmailForComm.current.getKey();
    setStoreValue('StoreSession', 'inUserToShowCommDlg', rf_txUserToShowCommDlg.current.getKey());
    setStoreValue('StoreSession', 'itEmailForComm', rf_txEmailForComm.current.getKey());
    Promise.all([FxFetch.doJsonF("/sett/branch/u", filter, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.comms')} - ${trans('field.general')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <CxSearcher xref={rf_txUserToShowCommDlg} label={trans('field.user') + '-' + trans('field.popup_window')}
          url="sett/sec/users"
          col_id="inUser"
          col_tx="txUser"
          columns={[
            { id: "0", Header: "ID", accessor: "inUser", show: false },
            { id: "1", Header: trans('field.user'), accessor: row => rf_txUserToShowCommDlg.current.getTable().highlightText(row["txUser"]), width: '100%' },
          ]}
          width="var(--ibs_control_width_lg)"
        />

        <CxSearcher xref={rf_txEmailForComm} label={trans('field.comm_email')}
          url="sett/email"
          col_id="itEmail"
          col_tx="txName"
          columns={[
            { id: "0", Header: "ID", accessor: "itEmail", show: false },
            { id: "1", Header: trans('field.name'), accessor: row => rf_txEmailForComm.current.getTable().highlightText(row["txName"]), width: '100%' },
          ]}
          width="var(--ibs_control_width_lg)"
        />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettCommGeneral;
