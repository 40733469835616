import React from 'react';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import { useNav } from '../../hx/useNav';
import { useWindowSize } from '../../hx/useWindowSize';
import { clearStore, StoreClient, StoreGrooming } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxDlgSelectClient from '../../gx/GxDlgSelectClient';
import GxIconButtonLastClient from '../../gx/GxIconButtonLastClient';


const MxActivityVettG = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const size = useWindowSize();
  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  const [storeGrooming, setStoreGrooming] = useSessionStorage('StoreGrooming', StoreGrooming);
  
  const r_list = React.useRef(null);
  const r_dlg_select_client = React.useRef(null);

  const hasColumnSpecies = () => { return Boolean(localStorage.getItem('MxActivityVettG.addcol_txTypeAnimalSpecies') === '1') };
  const hasColumnBreed = () => { return Boolean(localStorage.getItem('MxActivityVettG.addcol_txTypeAnimalBreed') === '1') };

  React.useEffect(() => {
    clearStore('StoreGrooming', storeGrooming);
    // eslint-disable-next-line
  }, []);

  const openForm = async (op, row) => {
    if (row === null) {
      r_dlg_select_client.current.setIntermediateValues({});
      r_dlg_select_client.current.setOpen(true);
    } else {
      storeClient.inClient = row.original.inClient;
      storeClient.txClient = row.original.txClient;
      storeClient.inAnimal = row.original.animal_inAnimal;
      storeClient.txAnimal = row.original.animal_txAnimal;
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        FxLog.infox('MxActivityVettG.openForm', '...storeClient:', storeClient);
        storeGrooming.inGrooming = row.original._id;
        setStoreGrooming(storeGrooming);
        navTo("animal-groo-form");
      });
    }
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    try{
    cols.push({ id: id++, Header: "ID", accessor: "_id", show: false });
    cols.push({ id: "inClient", Header: "inClient", accessor: "inClient", show: false });
    cols.push({ id: "inAnimal", Header: "inAnimal", accessor: "inAnimal", show: false });
    cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '20%' });
    cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["animal_txAnimal"]), width: '10%' });

    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.species'), accessor: row => r_list.current.getTable().highlightText(row["animal_txTypeAnimalSpecies"]), width: '10%', show: hasColumnSpecies() });
      cols.push({ id: id++, Header: trans('field.breed'), accessor: row => r_list.current.getTable().highlightText(row["animal_txTypeAnimalBreed"]), width: '10%', show: hasColumnBreed() });
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => r_list.current.getTable().highlightDate(row["dtFrom"]), width: '10%' });
      cols.push({ id: id++, Header: trans('field.groo_pickup'), accessor: row => (FxTable.cellToLocalTime(row["dtTo"])), width: '10%' });
      cols.push({ id: id++, Header: trans('field.remarks'), accessor: row => r_list.current.getTable().highlightText(row["txRemarks"]), width: '50%' });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.date'), accessor: row => (FxTable.cellToLocalDate(row["dtFrom"])), width: '10%' });
    }
    return cols;

    } catch(error) {
      FxLog.errorx('MxActivityVettG.getTableColumns', error)
    }
    return cols;
  }

  const getTooltip = () => {
    let tip = `${trans('field.client')} / ${trans('field.patient')} / ${trans('field.date')} / ${trans('field.remarks')}`;
    if (size.isLarge) {
      if (hasColumnSpecies()) tip = tip + `${' / ' + trans('field.species')}`;
      if (hasColumnBreed()) tip = tip + `${' / ' + trans('field.breed')}`;
    }

    return tip;
  }

  const getTableOptionalCols = () => {
    let cols = [];
    cols.push({ id: 'txTypeAnimalSpecies', name: trans('field.patient') + '-' + trans('field.species'), checked: hasColumnSpecies() ? '1' : '0' });
    cols.push({ id: 'txTypeAnimalBreed', name: trans('field.patient') + '-' + trans('field.breed'), checked: hasColumnBreed() ? '1' : '0' });
    return cols;
  }

  const optionsSelected = (options) => {
    localStorage.setItem('MxActivityVettG.addcol_txTypeAnimalSpecies', options.includes('txTypeAnimalSpecies') ? '1' : '0');
    localStorage.setItem('MxActivityVettG.addcol_txTypeAnimalBreed', options.includes('txTypeAnimalBreed') ? '1' : '0');
    r_list.current.getTable().setTooltipText(getTooltip());
    r_list.current.getTable().clearSearch();
    forceUIUpdate();
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.activity')}: ${trans('field.grooming')}`}
        xref={r_list}
        tooltip={getTooltip()}
        fullscreen
        url={`activity/vett/typelist/g`}
        onOpenForm={openForm}
        refColors={null}
        columns={getTableColumns()}
        onOptions={optionsSelected}
        optionalColumns={getTableOptionalCols()}
        optionalFromTo={true}
        optionalFromToButtonToday={true}
        optionalFromToButtonLast7Days={true}
        optionalFromToButtonLast30Days={true}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GxIconButtonLastClient theList={r_list} />
          </div>
        }
        speechExternal
      />

      <GxDlgSelectClient xref={r_dlg_select_client} 
        includeAnimal={true}
        onSelect={(row, intermediateValues) => {
          FxLog.infox('MxActivityVettG.return.GxDlgSelectClient', '...row:', row);
          Promise.all([setStoreClient(storeClient)]).then((result) => {
            openForm(null, row);
          });
        }} 
      />

    </div>
  );

}

export default MxActivityVettG;
