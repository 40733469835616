import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxNumber from '../../cx/CxNumber';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxSearcher from '../../cx/CxSearcher';
import CxChooser from '../../cx/CxChooser';
import * as FxLog from '../../fx/FxLog';
import CxSnackbar from './../../cx/CxSnackbar';
import CxIconButton from './../../cx/CxIconButton';
import { StoreCash, getStoreValue } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import { useAccess } from '../../hx/useAccess';


const MxCashForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.cash_reg'));
  const [{ navBack }] = useNav();

  const [storeCash, setStoreCash] = useSessionStorage('StoreCash', StoreCash);

  let r_form = React.useRef(null);
  const r_snack_req_positive = React.useRef(null);
  const r_snack_req_negative = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txItem = React.useRef(null);
  const rf_nuAmount = React.useRef(null);
  const rf_txTypeMovement = React.useRef(null);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      r_form.current.getRef("n_cash_item.dtCashItem").setValToday();
      r_form.current.getRef("t_movement.txTypeMovement").setKeyAndVal("t_movement", "txTypeMovement", "itMovement", "000004.SRV");
      r_form.current.getRef("t_payment.txTypePayment").setKeyAndVal("t_payment", "txTypePayment", "itPayment", "000001.SRV");
      r_form.current.getRef("t_cash.txTypeCash").setKey(getStoreValue('StoreSession', 'itCash'));
      r_form.current.getRef("t_cash.txTypeCash").setVal(getStoreValue('StoreSession', 'txTypeCash'));
    } else {
      r_form.current.getRef(`t_payment_det.txTypePaymentDet`).setFilter(`itPayment='${data[0].itPayment}'`);
    }
  }

  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theValue.startsWith("rv_")) {
      // errores al guardar
      if (lastSaveRv.theValue.startsWith("rv_req_positive")) {
        r_snack_req_positive.current.setOpen(true);
      }
      if (lastSaveRv.theValue.startsWith("rv_req_negative")) {
        r_snack_req_negative.current.setOpen(true);
      }
    } else {
      // se guardó exitosamente
      storeCash.inCashItem = lastSaveRv.theValue;
      Promise.all([setStoreCash(storeCash)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const onSelectionPayment = async (row) => {
    if (row !== null) {
      // inyecta filtros en el combo detalle
      let itPayment = row.original.itPayment;
      r_form.current.getRef(`t_payment_det.txTypePaymentDet`).setFilter(`itPayment='${itPayment}'`);
      r_form.current.getRef(`t_payment_det.txTypePaymentDet`).clear();
    }
  }

  const createCashOpening = async (createCashOpening) => {
    try {
      rf_txItem.current.setVal(trans("field.cash_opening"));
      rf_txTypeMovement.current.setKeyAndVal('t_movement', 'txTypeMovement', 'itMovement', '000001.SRV');
 
      rf_nuAmount.current.setFocusSel();

    } catch (error) {
      FxLog.errorx("MxCashForm.createCashOpening", error);
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.cash_reg')}: ${trans('field.item')}`}
        xref={r_form}
        url="cashitem"
        filterIdField='inCashItem'
        filterIdValue={storeCash.inCashItem}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de apertura de caja */}
            {!storeCash.inCashItem && <CxIconButton type="open" classType="ibs_iconbutton_toolbar" onClick={createCashOpening} tooltip={trans('field.cash_opening')} />}

          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="n_cash_item.inCashItem" value={storeCash.inCashItem || ''} />

        <CxInput xref={rf_txItem} label={trans('field.item')} dbfield="n_cash_item.txItem" required />
        <CxNumber xref={rf_nuAmount} label={trans('field.amount')} dbfield="n_cash_item.nuAmount" required />
        <CxDate xref={useRef(null)} label={trans('field.date')} dbfield="n_cash_item.dtCashItem" required />


        <CxChooser xref={rf_txTypeMovement} label={trans('field.movement')} dbfield="t_movement.txTypeMovement" dbfieldkey="n_cash_item.itMovement"
          id="t_movement"
          table="t_movement"
          col_id="itMovement"
          col_tx="txTypeMovement"
          readOnly={false}
          required
        />
        <CxChooser xref={useRef(null)} label={trans('field.cash_reg')} dbfield="t_cash.txTypeCash" dbfieldkey="n_cash_item.itCash"
          id="t_cash"
          table="t_cash"
          col_id="itCash"
          col_tx="txTypeCash"
          readOnly={false}
          required
        />
        <CxChooser xref={useRef(null)} label={trans('field.payment')} dbfield="t_payment.txTypePayment" dbfieldkey="n_cash_item.itPayment"
          id="t_payment"
          table="t_payment"
          col_id="itPayment"
          col_tx="txTypePayment"
          readOnly={false}
          onSelection={onSelectionPayment}
          required
        />
        <CxChooser xref={useRef(null)} label={trans('field.detail')} dbfield="t_payment_det.txTypePaymentDet" dbfieldkey="n_cash_item.itPaymentDet"
          id="t_payment_det"
          table="t_payment_det"
          col_id="itPaymentDet"
          col_tx="txTypePaymentDet"
          readOnly={false}
        />

        <CxChooser xref={useRef(null)} label={trans('field.employee')} dbfield="n_employee.txEmployee" dbfieldkey={`n_cash_item.inEmployee`}
          id="n_employee"
          table="n_employee"
          col_id="inEmployee"
          col_tx="txEmployee"
        />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} dbfield={`n_client.txClient`} dbfieldkey={`n_cash_item.inClient`}
          id="n_client"
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          refColors={['table_cell_color_red|' + trans('field.debtor')]}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          readOnly={false}
        />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_cash_item.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_req_positive} severity="error" value={trans('msg.value_req_positive')} />
      <CxSnackbar xref={r_snack_req_negative} severity="error" value={trans('msg.value_req_negative')} />

    </div>
  );

}

export default MxCashForm;
