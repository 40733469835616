// Input para formularios
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Draggable from "react-draggable";
import { useTranslation } from '../hx/useTranslation';
import Button from '@material-ui/core/Button';
import '../../styles/main.css';
import * as FxLog from '../fx/FxLog';
import '../../styles/Dialog/Dialog.css';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CxBackdrop from './CxBackdrop';


const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    display: 'flex',
    flexFlow: 'column',
    gap: '10px',
    "&.MuiDialogTitle-root": {
      padding: '12px',
      "& .MuiIconButton-root": {
        padding: '0px',
      },
    },
    "&.MuiDialogTitle-root .MuiTypography-h6": {
      fontSize: '16px',
    },
    "&.MuiDialogContent-root": {
      padding: '12px',
    },
  },
  dialogPaper: {
    margin: '10px',
    minHeight: props => props.height,
    maxHeight: props => props.height,
    width: props => (props.width||''),
    //position: props => props.isLargeWindow ? "absolute" : "", /* centra ventana en el parent e independientemente de formato móvil o desktop */
    //left: props => props.isLargeWindow ? "50%" : "", /* centra ventana en el parent e independientemente de formato móvil o desktop */
  },
});

const CxDialog = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const { onClose, onCancel, onOk, addDiv, buttonCancelText, buttonOkText, ...rest } = props;
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');

  let rnd = Math.floor(Math.random() * (10000 + 1));

  React.useEffect(() => {
    if (props.value) {
      setMyValue(props.value);
    }

    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, setKey, getKey, setHandle, 
    };
  });
  const setVal = (newValue) => { setMyValue(newValue) };
  const getVal = () => { return myValue };
  const setKey = (newId) => { setMyKey(newId) };
  const getKey = () => { return myKey };
  const setHandle = (newRnd) => { rnd = newRnd };
  const getType = () => { return 'CxDialog' };

  const doCancel = () => {
    FxLog.infox("CxDialog.doCancel", "Cancelling...");
    if (onCancel) onCancel(myKey, myValue);
  };

  const doClose = () => {
    FxLog.infox("CxDialog.doClose", "Closing...");
    if (onClose) onClose();
  };

  const doOk = () => {
    if (onOk) onOk(myKey, myValue);
  };

  return (
    <div>
      <Draggable handle={"#theTitle_" + rnd}>
        <Dialog 
          classes={{ paper: classes.dialogPaper }} 
          hideBackdrop={true} 
          disableScrollLock={true} // 2024-09-20 - daq: evita que desaparezca el scrollbar cuando se abre la ventana
          open={rest.open} 
          >
          <DialogTitle id={"theTitle_" + rnd} className={'dialog-title'} classes={{ root: classes.root }}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} >{props.title}</Box>
              <Box>
                {onClose && <IconButton onClick={doClose}><CloseIcon style={{ color: 'white' }} /></IconButton>}
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent className={'dialog-content'} classes={{ root: classes.root }}>
            {props.children}
          </DialogContent>

          {(onCancel || onOk || addDiv) &&
            <DialogActions className={'dialog-actions'}>
              <div className={'dialog-buttons'}>
                {onCancel && <Button onClick={doCancel} disabled={rest.disabled} autoFocus>
                  {buttonCancelText || trans('field.cancel')}
                </Button>}
                {onOk && <Button onClick={doOk} disabled={rest.disabled}>
                  {buttonOkText || trans('field.ok')}
                </Button>}
                {addDiv}
              </div>
            </DialogActions>
          }
        </Dialog>
      </Draggable>

      <CxBackdrop open={rest.open} hideProgress />
    </div>
  );
};

export default CxDialog;
