import { BsList } from "react-icons/bs"; 
import { MdClear } from "react-icons/md"; 
import React from "react";
import TextField from '@material-ui/core/TextField';
import * as FxLog from '../fx/FxLog';
import * as FxNum from '../fx/FxNum';
import CxIconButtonNew from "./CxIconButtonNew";
import '../../styles/main.css';
import { getStoreValue } from "../gx/GxStore";
import { useStyles } from "./StylesCx";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;

const useStylesLocal = makeStyles({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: props => { return props.width || 'var(--ibs_control_width_lg)' },
    borderRadius: '5px',
    marginTop: '5px',
    marginLeft: '-5px',
  },
  root: {
    fontSize: '15px',
    background: 'transparent',
    marginLeft: '-10px',
  }
});

const CxCombo = props => {
  const classes = useStyles(props);
  const classesL = useStylesLocal(props);
  const { onValueChange, onBlur, onClear, onSelection, onButtonAdd, InputLabelProps, autoSelectFirst, defaultValue, clearDisabled, ...rest } = props;
  const [myKey, setMyKey] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  const [isRequired, setIsRequired] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [wasChanged, setChanged] = React.useState(false);

  const rf_control = React.useRef(null);
  const r_dec_separator = React.useRef(getStoreValue('StoreSession', 'txDecimalSeparator'));

  React.useEffect(() => {
    if (onValueChange !== undefined) onValueChange();
    // eslint-disable-next-line
  }, [myValue]);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (props.value) {
      setMyValue(props.value);
    }
    if (defaultValue) {
      setVal(defaultValue);
    }
    if (autoSelectFirst) {
      setVal(rest.list[0].id);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  React.useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getValForKey, getFloatVal, setKey, getKey, setKeyAndVal, setKeyAndVal2, getIsRequired, getLabel, getDbfield,
      clear, isEmpty, wasChanged, setChanged
    };
  });
  const setVal = (newValue) => {
    if (newValue) {
      let item = rest.list.find((item) => item.id === newValue);
      if (item) {
        setMyKey(item.id);
        setMyValue(item.type);
      }
    }
  };
  const getVal = () => { return myKey; }; // no devuelve myValue xq trabaja con campos que no son identificadores sino valores fijos (ej: nuNature).
  const getValForKey = (key) => {
    let item = rest.list.find((item) => item.id === key);
    return item.type;
  }
  const getFloatVal = () => { return parseFloat(getVal() || 0) };
  const setKey = (newId) => { setMyKey(newId) };
  const getKey = () => { return myKey };
  const getIsRequired = () => { return isRequired && (getVal() === null || (getVal()||'').length === 0) };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const getType = () => { return 'CxCombo' };

  const setKeyAndVal = async (newKey, newValue) => {
    if (newKey) {
      setMyKey(newKey);
      setMyValue(newValue);
    }
  }

  const setKeyAndVal2 = async (newKey) => {
    setKeyAndVal(newKey, getValForKey(newKey));
  }

  const clear = () => {
    FxLog.infox("CxCombo.clear", "...");
    if (!props.readOnly && !props.disabled) {
      setMyKey('');
      setMyValue('');
      setChanged(true);
      if (onClear !== undefined) onClear();
    }
  };

  const isEmpty = () => { return myValue.length === 0 };

  const getValue = () => {
    let rv = '';
    if (rest.decimals) {
      if (rest.decimals === 2) {
        rv = FxNum.to2r00(myValue);
      }
      if (rest.decimals === 4) {
        rv = FxNum.to4r00(myValue);
      }
      rv = rv.replace('.', r_dec_separator.current);
    } else {
      rv = myValue;
    }
    return rv;
  }

  const dropDown = () => {
    if (!props.readOnly && !props.disabled) {
      setAnchorEl(anchorEl ? null : rf_control.current);
    }
  };

  const doSelection = (event) => {
    setAnchorEl(null); // handle close
    const { myValue } = event.currentTarget.dataset;
    if (myValue) {
      let id = myValue.split('|')[0];
      let tx = myValue.split('|')[1];
      setMyKey(id);
      setMyValue(tx);
      setAnchorEl(null);
      setChanged(true);
      if (props.onSelection !== undefined) {
        props.onSelection(myValue);
      }
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.frame}>
      <div className={classes.frame_content}>
        <TextField
          inputRef={rf_control}
          minRows={props.minRows}
          multiline={props.minRows !== undefined ? true : false}
          className={classes.control}
          InputLabelProps={{
            classes: { root: classes.root, },
            shrink: true,
            ...InputLabelProps
          }}
          InputProps={{ //refers to the Input component
            disableUnderline: true,
            required: false,
            classes: { root: classes.root, },
            inputProps: { //refers to the input element
              style: { cursor: 'default' },
              maxLength: props.maxLength,
              readOnly: true,
            },
          }}
          value={getValue()}
          onBlur={() => {
            if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
          }}
          onChange={(e) => setMyValue(e.target.value)}
          onClick={() => dropDown()} // 2025-02-13 - daq: mismo efecto que darle al botoncito, para que la gente no se frustre cuando tratan de darle foco para escribir.
          {...rest}
        />
        <div>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            keepMounted
            open={open}
            onClose={doSelection}
            classes={{ paper: classesL.paper }}
          >
            {rest.list.map((option) => (
              <MenuItem
                classes={{ root: classesL.root }}
                key={option.id}
                data-my-value={`${option.id}|${option.type}`}
                onClick={doSelection}>
                {option.type}
              </MenuItem>
            ))}
          </Menu>

        </div>
      </div>

      <div className={classes.frame_buttons}>
        {!props.readOnly &&
          <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={clear} disabled={props.disabled || clearDisabled} />}
        {!props.readOnly &&
          <CxIconButtonNew icon={<BsList fontSize={20} />} classType={classes.frame_icons_new} onClick={dropDown} disabled={props.disabled} />}
      </div>
    </div>
  );
};

export default CxCombo; 
