import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxChooser from '../../cx/CxChooser';
import CxNumber from '../../cx/CxNumber';
import CxDate from '../../cx/CxDate';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';
import * as FxStr from '../../fx/FxStr';
import GxForm from '../../gx/GxForm';
import { StorePurDoc, StorePurLst } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import { useAccess } from '../../hx/useAccess';


const MxPurDocFormPaym = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storePurLst, setStorePurLst] = useSessionStorage('StorePurLst', StorePurLst);
  const [storePurDoc, setStorePurDoc] = useSessionStorage('StorePurDoc', StorePurDoc);

  let r_form = React.useRef(null);
  let r_idx = React.useRef(storePurLst.table_paym_idx);

  const [docTypeName, ] = React.useState(FxStr.choose(storePurDoc.docType, 'o|i|r', trans('field.order'), trans('field.invoice'), trans('field.receipt')));

  const rf_dtPurPayment = React.useRef(null);
  const rf_txTypePayment = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  React.useEffect(() => {
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    if (storePurLst.table_paym_idx === null) { //nuevo registro
      rf_dtPurPayment.current.setValToday();
      //rf_txTypePayment.current.setKeyAndVal('t_payment', 'txTypePayment', 'itPayment', '000001.SRV');/* DAQ 2022.05.30 - innecesario porque abre ventana de selección */
      let balance = FxNum.to2r(storePurDoc.balance * -1);
      rf_nuAmount.current.setVal(balance);
      rf_txTypePayment.current.search(); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de tipo de cobro */

    } else {
      rf_dtPurPayment.current.setVal(storePurDoc.arPayms[r_idx.current].dtPurPayment);
      rf_txTypePayment.current.setKey(storePurDoc.arPayms[r_idx.current].itPayment);
      rf_txTypePayment.current.setVal(storePurDoc.arPayms[r_idx.current].txTypePayment);
      rf_nuAmount.current.setVal(storePurDoc.arPayms[r_idx.current].nuAmount);
    }
  }
  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }

    //---------------------------------------------
    // Loading
    var payms = storePurDoc.arPayms || [];
    /*console.log("# of payms: " + payms.length);
    payms.forEach(function(paym, index) {
      console.log("[" + index + "]: " + paym.txPs);
    });*/

    if (storePurLst.table_paym_idx === null) {
      // Adding
      var paym = {
        idx: 0,
        inPurPayment: '',
        dtPurPayment: rf_dtPurPayment.current.getVal(),
        itPayment: rf_txTypePayment.current.getKey(),
        txTypePayment: rf_txTypePayment.current.getVal(),
        nuAmount: rf_nuAmount.current.getVal(),
      };
      payms.unshift(paym);
      // reorder
      payms.forEach(function (paym, index) {
        paym.idx = index;
      })
    } else {
      // Modifying
      storePurDoc.arPayms[r_idx.current].idx = storePurLst.table_paym_idx;
      storePurDoc.arPayms[r_idx.current].dtPurPayment = rf_dtPurPayment.current.getVal();
      storePurDoc.arPayms[r_idx.current].itPayment = rf_txTypePayment.current.getKey();
      storePurDoc.arPayms[r_idx.current].txTypePayment = rf_txTypePayment.current.getVal();
      storePurDoc.arPayms[r_idx.current].nuAmount = rf_nuAmount.current.getVal();
    }

    //console.log("Added paym: " + paym.nuAmount);
    storePurDoc.arPayms = payms;

    // asienta que los datos fueron cambiados
    storePurDoc.boDataChanged = '1';

    // Saving
    //---------------------------------------------
    Promise.all([setStorePurDoc(storePurDoc)]).then((result) => {
      navBack();
    });

  }
  const formDeleteCustom = () => {
    if (storePurLst.table_paym_idx !== null) {
      if (storePurDoc.arPayms[r_idx.current].inPurPayment) {
        storePurDoc.txPaymsDeleted = storePurDoc.arPayms[r_idx.current].inPurPayment + ' ' + storePurDoc.txPaymsDeleted;
      }
      storePurDoc.arPayms.splice(r_idx.current, 1); // 2nd parameter means remove one paym only
      // reorder
      storePurDoc.arPayms.forEach(function (paym, index) {
        paym.idx = index;
      })

      // asienta que los datos fueron cambiados
      storePurDoc.boDataChanged = '1';

      Promise.all([setStorePurDoc(storePurDoc)]).then((result) => {
        navBack();
      });
    }
  }

  const onSelectionPayment = async (row) => {
    if (row !== null) {
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.purchases')}: ${docTypeName}: ${trans('field.payment')}`}
        xref={r_form}
        toolbarBackground={storePurLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            {isFullAccess() && <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}

            {/* botón guardar */}
            {isFullAccess() && <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}

          </div>
        }
      >
        <CxDate xref={rf_dtPurPayment} label={trans('field.date')} />

        <CxChooser xref={rf_txTypePayment} label={trans('field.payment')}
          table="t_payment"
          col_id="itPayment"
          col_tx="txTypePayment"
          extra_filter_1={"not txTypePayment like 'OLD-%'"}
          readOnly={false}
          onSelection={onSelectionPayment}
          required
        />


        <CxNumber xref={rf_nuAmount} label={trans('field.amount')}
          decimals={2}
          decimalsfixed="true"
          required
        />

      </GxForm>

    </div>
  );

}

export default MxPurDocFormPaym;
