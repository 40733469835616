import { BiPrinter } from "react-icons/bi";
import { FaPrescription } from "react-icons/fa"; 
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
//import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
import CxTableMem from '../../cx/CxTableMem';
import CxTime from '../../cx/CxTime';
import CxCombo from '../../cx/CxCombo';
import CxNumber from '../../cx/CxNumber';
import CxLink from '../../cx/CxLink';
import CxStatic from '../../cx/CxStatic';
import * as FxLog from '../../fx/FxLog';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import * as FxNum from '../../fx/FxNum';
import * as FxDat from '../../fx/FxDat';
import * as FxStr from '../../fx/FxStr';
import * as FxEdition from '../../fx/FxEdition';
import '../../../styles/main.css';
import CxBackdrop from '../../cx/CxBackdrop';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxChip from '../../cx/CxChip';
import CxState from '../../cx/CxState';
import CxDialog from '../../cx/CxDialog';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
import CxButtonMenu from '../../cx/CxButtonMenu';
import { StoreSlsLst, StoreSlsDoc, StoreRxAuto, isApp, getStoreValue, getStoreValueBo, clearStore } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import * as MxSlsDocFormH1 from './MxSlsDocFormH1';
import { FaCoins, FaRegTimesCircle } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { useAccess } from '../../hx/useAccess';
import CxDlgBarcode from '../../cx/CxDlgBarcode';
import MxSlsDocFormClientDataDlg from './MxSlsDocFormClientDataDlg';
import * as MxSlsDocFormItemH1 from './MxSlsDocFormItemH1';
import * as MxSlsDocFormPaymH1 from './MxSlsDocFormPaymH1';
import CxIconButton from '../../cx/CxIconButton';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import { BsCashStack } from "react-icons/bs";
import { RiBarcodeFill } from "react-icons/ri";
import * as MxRxAutoFormH1 from '../files/MxAnimalRxAutoFormH1';
import GxLongTaskWin from '../../gx/GxLongTaskWin';
import { useWindowSize } from '../../hx/useWindowSize';
import GxSearcherClient from '../../gx/GxSearcherClient';
import GxSearcherAnimal from '../../gx/GxSearcherAnimal';
import GxIconButtonLastClient2 from '../../gx/GxIconButtonLastClient2';


const MxSlsDocForm = (props) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ navTo, navBack, }] = useNav();

  //const [useInvoices, ] = React.useState(getStoreValueBo('StoreSession', 'boUseInvoices'));
  const [useReceipts,] = React.useState(getStoreValueBo('StoreSession', 'boUseReceipts'));
  const [autoCreateRxForce,] = React.useState(getStoreValueBo('StoreSession', 'boAutoCreateRxForce'));

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  // eslint-disable-next-line no-unused-vars
  const [storeSlsLst, setStoreSlsLst] = useSessionStorage('StoreSlsLst', StoreSlsLst);
  const [storeSlsDoc, setStoreSlsDoc] = useSessionStorage('StoreSlsDoc', StoreSlsDoc);

  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);

  const r_form = React.useRef(null);
  let r_print = React.useRef(null);
  let r_task = React.useRef(null);
  let r_sett_wtax_perc = React.useRef(null);

  const r_dlg_barcode = React.useRef(null);
  const r_dlg_alert = React.useRef(null);
  const r_dlg_clientdata = React.useRef(null);
  const r_dlg_upgrade = React.useRef(null);

  const r_snack_error = React.useRef(null);
  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_snack_missing_template = React.useRef(null);

  const [docTypeName, ] = React.useState(FxStr.choose(storeSlsDoc.docType, 'q|i|r', trans('field.quotation'), trans('field.invoice'), trans('field.receipt')));

  const [showRxIcon, setShowRxIcon] = React.useState(false);

  const [backdrop, setBackdrop] = React.useState(false);

  const [dlgPrint, setDlgPrint] = React.useState(false);
  const [dlgIssue, setDlgIssue] = React.useState(false);
  const [dlgDelete, setDlgDelete] = React.useState(false);
  const [dlgExit, setDlgExit] = React.useState(false);
  const [dlgSeriesForNegativeReceipt, setDlgSeriesForNegativeReceipt] = React.useState(false);

  const rf_print_format = React.useRef(null);
  const rf_print_preview = React.useRef(null);
  const rf_print_copies = React.useRef(null);

  const rf_chip_rec = React.useRef(null);
  const rf_chip_void = React.useRef(null);
  const rf_chip_balance = React.useRef(null);

  const rf_dtXXX_d = React.useRef(null);
  const rf_dtXXX_t = React.useRef(null);
  const rf_txXXX = React.useRef(null);
  const rf_txTypeSeries = React.useRef(null);
  const rf_hasWTax = React.useRef(false);

  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);
  const rf_txEmployee = React.useRef(null);

  const rf_table_mem_items = React.useRef(null);
  const rf_table_mem_payms = React.useRef(null);

  const rf_txNotes = React.useRef(null);

  const rf_negative_receipt_txTypeSeries = React.useRef(null);

  const rf_issue_date = React.useRef(null);
  
  const rf_delete_return_stock = React.useRef(null);


  React.useEffect(() => {
    try {
      FxLog.infox('MxSlsDocForm.useEffect', '...storeSlsDoc.arItems:', storeSlsDoc.arItems);
      FxLog.infox('MxSlsDocForm.useEffect', '...storeSlsDoc.arPayms:', storeSlsDoc.arPayms);
      
      r_sett_wtax_perc.current = getStoreValue('StoreSession', 'nuWTaxPerc');

      formLoadCustom();
      
      // 2025-01-29 - daq: solución a problema que sucedía en clientes, que al volver de la impresión, se veían los ps duplicados.
      setBalance();
      if (storeSlsDoc.arItems) {
        // refresca el listado de items.
        rf_table_mem_items.current.setTheList(storeSlsDoc.arItems);
        rf_table_mem_items.current.refresh();
      }
      if (storeSlsDoc.arPayms && rf_table_mem_payms.current) {
        // refresca el listado de pagos.
        rf_table_mem_payms.current.setTheList(storeSlsDoc.arPayms);
        rf_table_mem_payms.current.refresh();
      }

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.setup', error);
    }
    // eslint-disable-next-line
  }, []);

  //const isFree = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').includes('free')) }

  const sendStoreToForm = () => {
    try {
      if (rf_dtXXX_d.current) rf_dtXXX_d.current.setVal(storeSlsDoc.dtXXX);
      if (rf_dtXXX_t.current) rf_dtXXX_t.current.setVal(storeSlsDoc.dtXXX);
      if (rf_txXXX.current) rf_txXXX.current.setVal(storeSlsDoc.txXXX);
      if (rf_txTypeSeries.current) rf_txTypeSeries.current.setVal(storeSlsDoc.txTypeSeries);
      if (rf_txTypeSeries.current) rf_txTypeSeries.current.setKey(storeSlsDoc.itSeries);
      if (rf_hasWTax.current) {
        if (FxNum.isZero(storeSlsDoc.nuTotalWtaxPerc)) {
          rf_hasWTax.current.setVal('0');
          storeSlsDoc.nuTotalWtaxPerc = 0;
        } else {
          rf_hasWTax.current.setVal('1');
        }
      };

      if (rf_txClient.current) rf_txClient.current.setKey(storeSlsDoc.inClient);
      if (rf_txClient.current) rf_txClient.current.setVal(storeSlsDoc.txClient);
      if (rf_txAnimal.current) rf_txAnimal.current.setKey(storeSlsDoc.inAnimal);
      if (rf_txAnimal.current) rf_txAnimal.current.setVal(storeSlsDoc.txAnimal);
      if (rf_txEmployee.current) rf_txEmployee.current.setKey(storeSlsDoc.inEmployee);
      if (rf_txEmployee.current) rf_txEmployee.current.setVal(storeSlsDoc.txEmployee);

      if (rf_txNotes.current) rf_txNotes.current.setVal(storeSlsDoc.txNotes);

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.sendStoreToForm', error)
    }
  }

  const sendFormToStore = () => {
    try {
      if (rf_dtXXX_d.current) storeSlsDoc.dtXXX = (rf_dtXXX_d.current.getVal() + ' ' + rf_dtXXX_t.current.getVal()).trim();
      if (rf_txXXX.current) storeSlsDoc.txXXX = rf_txXXX.current.getVal();
      if (rf_txTypeSeries.current) storeSlsDoc.txTypeSeries = rf_txTypeSeries.current.getVal();
      if (rf_txTypeSeries.current) storeSlsDoc.itSeries = rf_txTypeSeries.current.getKey();
      if (rf_hasWTax.current) {
        if (rf_hasWTax.current.isChecked()) {
          storeSlsDoc.nuTotalWtaxPerc = r_sett_wtax_perc.current;
        } else {
          storeSlsDoc.nuTotalWtaxPerc = 0;
        }
      }

      if (rf_txClient.current) storeSlsDoc.inClient = rf_txClient.current.getKey();
      if (rf_txClient.current) storeSlsDoc.txClient = rf_txClient.current.getVal();
      if (rf_txAnimal.current) storeSlsDoc.inAnimal = rf_txAnimal.current.getKey();
      if (rf_txAnimal.current) storeSlsDoc.txAnimal = rf_txAnimal.current.getVal();
      if (rf_txEmployee.current) storeSlsDoc.inEmployee = rf_txEmployee.current.getKey();
      if (rf_txEmployee.current) storeSlsDoc.txEmployee = rf_txEmployee.current.getVal();

      if (rf_txNotes.current) storeSlsDoc.txNotes = rf_txNotes.current.getVal();

      if (storeSlsDoc.boDataChanged === '0') {
        storeSlsDoc.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
      }

      setStoreSlsDoc(storeSlsDoc);

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.sendFormToStore', error)
    }
  }

  const formLoadCustom = () => {
    try {
      sendStoreToForm();

      if (!storeSlsDoc.inXXX) { //nuevo registro
        if (storeSlsDoc.boFirstRender) {
          if (rf_dtXXX_d.current) rf_dtXXX_d.current.setValToday();
          if (rf_dtXXX_t.current) rf_dtXXX_t.current.setValNow();
          if (getStoreValueBo('StoreSession', 'boAutoSelEmployee') && !storeSlsDoc.inEmployee) {
            rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
            rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
          }
          if (rf_hasWTax.current) rf_hasWTax.current.setVal(getStoreValue('StoreSession', 'boWTaxByDefault')); // 2023-10-06 - daq: añadido
          storeSlsDoc.boFirstRender = false;
          setStoreSlsDoc(storeSlsDoc);
        }
      } else { // registro existente
        if (isApp('vett')) {
          Promise.all([checkPendingRxPs()]).then((result) => {
            setShowRxIcon(result[0]);
          });
        }
        loadRates(storeSlsDoc.inClient, storeSlsDoc.txClient, storeSlsDoc.inAnimal, storeSlsDoc.txAnimal);
      }

      setBalance();

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.formLoadCustom', error)
    }
  }

  const formSaveCustom = () => {
    try {
      if (r_form.current.hasIncompleteRequired()) {
        return;
      }

      if (doLots() === 'OK' && doMultiplePatientsPerInvoice() === 'OK') {
        sendFormToStore();

        FxLog.infox('MxSlsDocForm.formSaveCustom', '...');
        r_task.current.doProcessStart();
        Promise.all([FxFetch.doJsonF(`sls/doc/cu/long_task`, null, storeSlsDoc)]).then((resultSave) => {});
      }

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.formSaveCustom', error)
    }
  }

  const onTaskSaveProgress = (txProgressText, txTaskMessage, nuTaskPercentage) => {
    r_task.current.getDlgAlert().setText(trans('msg.saving'));
    r_task.current.getDlgAlert().setProgressBarPerc(nuTaskPercentage);
    r_task.current.getDlgAlert().setTextColor('var(--ibs_color_blue)');
  }
  const onTaskSaveEnd = (txResultJson, txResultError) => {
    FxLog.infox('MxSlsDocForm.onTaskSaveEnd', '...txResultJson:' + txResultJson);
    FxLog.infox('MxSlsDocForm.onTaskSaveEnd', '...txResultError:' + txResultError);
    // limpieza y recarga
    if (txResultError) {
      r_task.current.getDlgAlert().setText(trans('msg.error') + ": " + txResultError);
      r_task.current.getDlgAlert().setTextColor('var(--ibs_color_red)');
      r_task.current.getDlgAlert().setAutoClose(true);
    return;
    }
    if (txResultJson) {
      let isNew = Boolean(!storeSlsDoc.inXXX);
      let theJson = JSON.parse(txResultJson);
      FxLog.infox('MxSlsDocForm.onTaskSaveEnd', '...inXXX:' + theJson['inXXX']);
      FxLog.infox('MxSlsDocForm.onTaskSaveEnd', '...txXXX:' + theJson['txXXX']);
      r_form.current.resetChanged();
      storeSlsDoc.boDataChanged = '0';
      storeSlsDoc.inXXX = theJson['inXXX'];
      storeSlsDoc.arItems = [];
      storeSlsDoc.arPayms = [];
      Promise.all([MxSlsDocFormH1.load(storeSlsLst, storeSlsDoc)]).then((result) => {
        Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
          rf_txXXX.current.setVal(theJson['txXXX']);
          r_task.current.getDlgAlert().setText(trans('msg.saved'));
          r_task.current.getDlgAlert().setTextColor('var(--ibs_color_green)');
          r_task.current.getDlgAlert().setAutoClose(true);
          if (isApp('vett')) {
            Promise.all([checkPendingRxPs()]).then((result) => {
              setShowRxIcon(result[0]);
              if (result[0] && isNew) {
                if (autoCreateRxForce) {
                  forceRxsNewAuto();
                }
              }
            });
          }
          forceUIUpdate();
        });
      });
    }
  }

  const forceRxsNewAuto = async () => {
    let inEmployee = getStoreValue('StoreSession', 'inEmployee');
    if (!storeSlsDoc.inClient) {
      return;
    }
    if (!storeSlsDoc.inAnimal) {
      return;
    }
    if (!storeSlsDoc.inEmployee) {
      r_dlg_alert.current.setValAndOpen(trans('field.rx_auto'), trans('msg.missing_x', 'field.session_employee'), 'var(--ibs_color_red)');
      return;
    }
    clearStore('StoreRxAuto', storeRxAuto);
    storeRxAuto.boFirstRender = true;
    storeRxAuto.boDataChanged = '0';
    storeRxAuto.boAuto = true;
    storeRxAuto.inRxAuto = null;
    storeRxAuto.txRxAuto = null;
    storeRxAuto.itSeriesRx = null;
    storeRxAuto.inInvoice = storeSlsDoc.inXXX;
    storeRxAuto.inEmployee = inEmployee;
    Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
      Promise.all([MxRxAutoFormH1.loadFromInvoice(storeRxAuto)]).then((result) => {
        Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
          let filter = {};
          filter['txRxAuto'] = null; // es nueva
           Promise.all([FxFetch.doJsonF(`rx_auto/cu`, filter, storeRxAuto)]).then((resultSave) => {
           if (resultSave[0]) {
            r_snack_op_successful.current.setValAndOpen(trans('msg.x_generated', 'field.rx'));
          }
         });
        });
      });
    });
  }

  /** averigua si hay productos recetables pendientes */
  const checkPendingRxPs = async () => {
    return Promise.all([FxFetch.doJsonF(`sls/doc/has_pending_rx_ps`, null, storeSlsDoc)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'Y') {
          return true;
        } else {
          return false;
        }
      }
    });
  }

  /** pregunta lotes */
  const doLots = () => {
    let rv = 'OK';
    try {
      if (storeSlsDoc.docType === 'i') {
        for (let index = 0; index < storeSlsDoc.arItems.length; index++) {
          const item = storeSlsDoc.arItems[index];
          if (item.txLotCode === '**pending**') {
            rv = 'NOK';

            // 2024-07-03 - daq: antes de ir, preserva cambios en cabecera
            sendFormToStore();

            navTo("sls-doc-form-lots");
            break;
          }
        }
      }
    } catch (error) {
      FxLog.errorx('MxSlsDocForm.doLots', error)
    }
    return rv;
  }

  /** pregunta qué paciente corresponde a cada ps que tenga frecuencia */
  const doMultiplePatientsPerInvoice = () => {
    let rv = 'OK';
    if (storeSlsDoc.inXXX) {
      //FxLog.errorx('MxSlsDocForm.doMultiplePatientsPerInvoice', 'el documento ya existe')
      return rv;
    }
    if (!isApp('vett') || !getStoreValueBo('StoreSession', 'boAutoCreateMp')) {
      //FxLog.errorx('MxSlsDocForm.doMultiplePatientsPerInvoice', 'no es vett o boAutoCreateMp=0')
      return rv;
    }
    if (rf_txClient.current.isEmpty() || !rf_txAnimal.current.isEmpty()) { 
      //FxLog.errorx('MxSlsDocForm.doMultiplePatientsPerInvoice', 'no seleccionó cliente o seleccionó paciente')
      return rv;
    }

    try {
      if (storeSlsDoc.docType === 'i') {
        for (let index = 0; index < storeSlsDoc.arItems.length; index++) {
          const item = storeSlsDoc.arItems[index];
          if (item.nuFrequency>0 && !storeSlsDoc.arItemsFq) {
            rv = 'NOK';

            // 2024-07-03 - daq: antes de ir, preserva cambios en cabecera
            sendFormToStore();

            navTo("sls-doc-form-patients-fq-ps");
            break;
          }
        }
      }
    } catch (error) {
      FxLog.errorx('MxSlsDocForm.doMultiplePatientsPerInvoice', error)
    }
    return rv;
  }

  const formDeleteCustom = () => {
    FxLog.infox("MxSlsDocForm.formDeleteCustom", "...");
    setDlgDelete(true);
  }

  const doDelete = () => {
    FxLog.infox("MxSlsDocForm.doDelete", "...");

    //-----------------------------------------------------------------------
    setBackdrop(true);

    let filter = {};
    if (storeSlsDoc.docType === 'i') {
      filter['boReturnStock'] = rf_delete_return_stock.current.getVal();
    }
    Promise.all([FxFetch.doJsonF(`sls/doc/${storeSlsDoc.docType}/d`, filter, storeSlsDoc)]).then((result) => {
      if (result[0]) {
      } else {
      }
      r_snack_delete.current.setOpen(true);
    });
    //-----------------------------------------------------------------------
  }

  const setBalance = () => {
    try {
      let nuTotalDiscount = 0;
      let nuTotalTaxable = 0;
      let nuTotalTax = 0;
      let nuTotalAmount = 0;
      let nuTotalWtax = 0;
      let nuTotalFinal = 0;
      let nuTotalPayment = 0;

      if (storeSlsDoc.arItems) {
        storeSlsDoc.arItems.forEach(function (item, index) {
          nuTotalDiscount = nuTotalDiscount + parseFloat(item.nuDiscount);
          nuTotalTaxable = nuTotalTaxable + parseFloat(item.nuTaxable);
          nuTotalTax = nuTotalTax + parseFloat(item.nuTax);
          nuTotalAmount = nuTotalAmount + parseFloat(item.nuAmount);
          nuTotalFinal = nuTotalFinal + parseFloat(item.nuAmount);
        })
        if (storeSlsDoc.nuTotalWtaxPerc !== 0) {
          nuTotalWtax = nuTotalTaxable * r_sett_wtax_perc.current / 100;
          nuTotalFinal = nuTotalAmount - nuTotalWtax;
        }
      }
      if (storeSlsDoc.arPayms) {
        storeSlsDoc.arPayms.forEach(function (paym, index) {
          nuTotalPayment = nuTotalPayment + parseFloat(paym.nuAmount);
        })
      }

      storeSlsDoc.nuTotalDiscount = nuTotalDiscount.toString();
      storeSlsDoc.nuTotalTaxable = nuTotalTaxable.toString();
      storeSlsDoc.nuTotalTax = nuTotalTax.toString();
      storeSlsDoc.nuTotalAmount = nuTotalAmount.toString();
      storeSlsDoc.nuTotalWtax = nuTotalWtax.toString();

      // 2023-01-12 - daq: tratamos los valores que se utilizan para el balance
      if (storeSlsDoc.docType === 'r') {
        nuTotalFinal = storeSlsDoc.nuTotalFinal;
        nuTotalPayment = storeSlsDoc.nuTotalPayment;
      } else {
        storeSlsDoc.nuTotalFinal = FxNum.to2r(nuTotalFinal).toString();
        storeSlsDoc.nuTotalPayment = FxNum.to2r(nuTotalPayment).toString();
      }

      let balance = FxNum.to2r(nuTotalPayment) - FxNum.to2r(nuTotalFinal);
      storeSlsDoc.balance = balance;


      setStoreSlsDoc(storeSlsDoc);

      let total_label;
      if (storeSlsDoc.docType === 'q') {
        total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalFinal)}`;
        rf_chip_balance.current.setLabel(`${trans('field.total')}: ${FxTable.cellTo2r00(Math.abs(balance))}`);
      } else {
        total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalFinal)} - ${trans('field.payment')}: ${FxTable.cellTo2r00(nuTotalPayment)} = ${FxTable.cellTo2r00(Math.abs(balance))}`;
        rf_chip_balance.current.setWarn(balance < 0);
        rf_chip_balance.current.setLabel(`${trans('field.balance')}: ${FxTable.cellTo2r00(balance)}`);
      }
      rf_chip_balance.current.setTooltip(total_label);

    } catch (error) {
      FxLog.errorx('MxSlsDocForm.setBalance', error)
    }
  }

  const openFormItem = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storeSlsLst.table_item_idx = null;
    } else {
      storeSlsLst.table_item_idx = row.original["idx"];
    }
    setStoreSlsLst(storeSlsLst);
    setStoreSlsDoc(storeSlsDoc);

    navTo("sls-doc-form-item");
  }

  const openFormPaym = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storeSlsLst.table_paym_idx = null;
    } else {
      storeSlsLst.table_paym_idx = row.original["idx"];
    }
    setStoreSlsLst(storeSlsLst);
    setStoreSlsDoc(storeSlsDoc);

    navTo("sls-doc-form-paym");
  }

  const loadRates = (inClient, txClient, inAnimal, txAnimal) => {
    // selecciona las tasas. Primero del animal, si hay, y sino del cliente.

    // limpia
    FxLog.infox('MxSlsDocForm.loadRates', 'limpiando tasas...');
    storeSlsDoc.arRates = '';
    Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
      // carga tasas del cliente
      if (inClient) {
        Promise.all([loadRatesHelper('cli', inClient, txClient)]).then((result) => {
          let rv = result[0]
          storeSlsDoc.arRates = rv;
          setStoreSlsDoc(storeSlsDoc);
          FxLog.infox('MxSlsDocForm.loadRates', '...tasas cliente:', Object.keys(rv[0]).length === 0 ? 'NO' : 'SÍ');
        });
      }
      // carga tasa del animal
      if ((isApp('vett') || isApp('spaw')) && inAnimal) {
        Promise.all([loadRatesHelper('ani', inAnimal, txAnimal)]).then((result) => {
          let rv = result[0]
          if (rv) { // sólo machacamos las posibles tasas del cliente si existen tasas del animal
            storeSlsDoc.arRates = rv;
            setStoreSlsDoc(storeSlsDoc);
            FxLog.infox('MxSlsDocForm.loadRates', '...tasas animal:', Object.keys(rv[0]).length === 0 ? 'NO' : 'SÍ');
          }
        });
      }
    });
  }

  const loadRatesHelper = async (who, inXXX, txXXX) => {
    try {
      let record = {};
      record['who'] = who;
      record['inXXX'] = inXXX;
      return Promise.all([FxFetch.doJsonX(`sls/doc/rates`, record)]).then((result) => {
        if (result[0]) {
          return [result[0]];
        } else {
          return '';
        }
      });

    } catch (error) {
      FxLog.errorx("MxSlsDocForm.loadRatesHelper", error);
    }
  }

  const doPrintX = async (option) => {
    let inClient = storeSlsDoc.inClient;
    let boPreview = true;
    if (rf_print_preview.current) {
      boPreview = rf_print_preview.current.getValBoolean();
    }
    FxLog.infox('MxSlsDocForm.doPrintX', '...boPreview:', boPreview);
    let oo = {};
    if (option) {
      oo = {...option}
    } else {
      oo.inClient = `${storeSlsDoc.inClient}`;
      oo.inXXX = `${storeSlsDoc.inXXX}`;
      oo.docType = `${storeSlsDoc.docType}`;
      oo.docSize = (rf_print_format.current ? rf_print_format.current.getVal() : 'A4');
      oo.nuCopies = (rf_print_copies.current ? rf_print_copies.current.getVal() : '0');
      oo.salesSlipWidth = localStorage.getItem('MxSettSlsPrinting.sales_slip_width') || '60';
      oo.salesSlipMarginLeft = localStorage.getItem('MxSettSlsPrinting.sales_slip_margin_left') || '0';
    }
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inClient'] = oo.inClient;
    filter['inXXX'] = oo.inXXX;
    filter['docType'] = oo.docType;
    filter['docSize'] = oo.docSize;
    filter['nuCopies'] = oo.nuCopies;
    filter['salesSlipWidth'] = oo.salesSlipWidth;
    filter['salesSlipMarginLeft'] = oo.salesSlipMarginLeft;
    let record = {};
    if (filter['docSize'] === 'A8') {
      r_print.current.setScale(2.0);
    }
    if (filter['docSize'] === 'A4') {
      r_print.current.setScale(1.5);
    }
    r_print.current.setUrl('slsDoc/report');
    r_print.current.setPreview(boPreview);
    localStorage.setItem(`MxSlsDocForm.${storeSlsDoc.docType}.docSize`, filter['docSize']);
    localStorage.setItem(`MxSlsDocForm.${storeSlsDoc.docType}.boPreview`, boPreview ? '1' : '0');
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInClient(inClient),
    ]).then((result) => {
      r_print.current.doPrint();
      setDlgPrint(false);
    });
  }

  const doIssueDoc = () => {
    try {
      // chequea requeridos
      if (rf_issue_date.current.isEmpty()) {
        r_snack_error.current.setVal(trans('field.required') + ": " + rf_issue_date.current.getLabel());
        r_snack_error.current.setOpen(true);
        return;
      }
      setDlgIssue(false);

      let itSeries = '';
      if (rf_txTypeSeries.current) {
        itSeries = rf_txTypeSeries.current.getKey();
      }

      if (storeSlsDoc.docType === 'i' && !storeSlsDoc.inReceipt) {
        FxLog.infox('MxSlsDocForm.doIssueDoc', '...issue_receipt_from_invoice', );
        let record = {};
        record['inInvoice'] = storeSlsDoc.inXXX;
        record['itSeries'] = itSeries;
        record['dtReceipt'] = rf_issue_date.current.getVal();
        Promise.all([FxFetch.doJsonX(`/sls/doc/issue_receipt_from_invoice`, record)]).then((result) => {
          storeSlsDoc.inReceipt = result[0].inReceipt;
          storeSlsDoc.boUpdateDelete = false;
          Promise.all([setStoreSlsDoc(storeSlsDoc)]).then((result) => {
            let boShowReceipt = true;
            forceUIUpdate();
            if (boShowReceipt) {
              // mostramos factura creada
              let option = {};
              option.inClient = `${storeSlsDoc.inClient}`;
              option.inXXX = `${storeSlsDoc.inReceipt}`;
              option.docType = `r`;
              option.docSize = 'A4';
              option.nuCopies = '0';
              doPrintX(option);
            } else {
              r_snack_op_successful.current.setOpen(true);
            }
          });
        });
      }
      if (storeSlsDoc.docType === 'q') {
        let record = {};
        record['inQuotation'] = storeSlsDoc.inXXX;
        record['itSeries'] = itSeries;
        record['dtInvoice'] = rf_issue_date.current.getVal();
        Promise.all([FxFetch.doJsonX(`/sls/doc/issue_invoice_from_quotation`, record)]).then((result) => {
          r_snack_op_successful.current.setOpen(true);
        });
      }

    } catch (error) {
      FxLog.errorx("MxSlsDocForm.doIssueDoc", error);
    }
  }

  const logStatus = () => {
    // eslint-disable-next-line no-unused-vars
    let status = {
      "storeSlsDoc.isVoid": storeSlsDoc.isVoid,
      "storeSlsDoc.inXXX": storeSlsDoc.inXXX,
      "storeSlsDoc.inReceipt": storeSlsDoc.inReceipt,
    }
  }

  const doNegativeDoc = () => {
    setDlgSeriesForNegativeReceipt(false);

    if (storeSlsDoc.docType === 'i') {
      let record = {};
      record['inInvoice'] = storeSlsDoc.inXXX;
      record['itSeries'] = rf_negative_receipt_txTypeSeries.current.getKey();
      Promise.all([FxFetch.doJsonX(`/sls/doc/issue_negative_invoice`, record)]).then((result) => {
        navBack();
      });
    }
    if (storeSlsDoc.docType === 'r') {
      let record = {};
      record['inReceipt'] = storeSlsDoc.inXXX;
      record['itSeries'] = rf_negative_receipt_txTypeSeries.current.getKey();
      Promise.all([FxFetch.doJsonX(`/sls/doc/issue_negative_receipt`, record)]).then((result) => {
        navBack();
      });
    }
    r_snack_op_successful.current.setOpen(true);
  }

  const changedWTax = (id, newValue) => {
    if (newValue === '1') {
      storeSlsDoc.nuTotalWtaxPerc = r_sett_wtax_perc.current;
    } else {
      storeSlsDoc.nuTotalWtaxPerc = 0;
    }
    setStoreSlsDoc(storeSlsDoc);
    setBalance();
  }

  const canGoBack = () => {
    if (storeSlsDoc.boDataChanged === '0') {
      storeSlsDoc.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
    }
    if (storeSlsDoc.boDataChanged === '0') {
      return true;
    } else {
      setDlgExit(true);
      return false;
    }
  }

  const isDisabled = () => {
    /* no deja modificar o eliminar si es factura, o un albarán con factura */
    // eslint-disable-next-line
    switch (storeSlsDoc.docType) {
      case 'q': // siempre puede modificar o eliminar presupuestos
      return false;
      
      case 'i': // sólo puede modificar o eliminar albaranes si está activado boUpdateDelete o si no tienen factura
        if (storeSlsDoc.boUpdateDelete) {
          return false;
        } else {
          return Boolean(storeSlsDoc.inReceipt);
        }

      case 'r': // sólo puede modificar o eliminar facturas si está activado el boUpdateDelete
        return !storeSlsDoc.boUpdateDelete;
    }
  };

  const doBarcodeAddPs = (inPs, hasLots, qy) => {
    try {
      let record = {};
      record['idValue'] = inPs;
      Promise.all([FxFetch.doJsonX('pss/r', record)]).then((result) => {
        if (result[0]) {
          let ps = result[0][0];

          storeSlsLst.table_item_idx = null;
          Promise.all([setStoreSlsLst(storeSlsLst)]).then((result) => {
            let inPs = ps.inPs;

            let _nuDiscount = (qy * ps.nuPrice * ps.nuDiscountPercS / 100);
            let _nuTaxable = ((qy * ps.nuPrice) - _nuDiscount);
            let _nuTax = (_nuTaxable * ps.nuTaxPercS / 100);
            let _nuAmount = FxNum.to2r(_nuTaxable + _nuTax);

            let itemData = {
              inPs: inPs,
              txPs: ps.txPs,
              txLotCode: (hasLots==='1' ? '**pending**' : ''),
              dtLotExpr: '',
              txPsAlias: ps.txPsAlias,
              txAlert: ps.txAlert,
              txRemarks: '',

              nuQuantity: FxNum.to2r(qy),
              nuPrice: FxNum.to2r(ps.nuPrice),
              nuDiscountPerc: FxNum.to2r(ps.nuDiscountPercS),
              nuTaxPerc: FxNum.to2r(ps.nuTaxPercS),
              nuAmount: _nuAmount,
            };

            let idxForEdit = null; // xq siempre es para añadir (y no modificar).


            if (ps.boComposite === "1") {
              let nuQuantity = itemData.nuQuantity;
              Promise.all([MxSlsDocFormItemH1.saveItemAttachedToStore(storeSlsDoc, setStoreSlsDoc, inPs, nuQuantity)]).then((result) => {
                Promise.all([MxSlsDocFormItemH1.saveItemToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst, idxForEdit, inPs, itemData)]).then((result) => {
                  // Aquí refresca el listado de items.
                  setBalance();
                  rf_table_mem_items.current.setTheList(storeSlsDoc.arItems);
                  rf_table_mem_items.current.refresh();
                });
              });
            } else {
              Promise.all([MxSlsDocFormItemH1.saveItemToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst, idxForEdit, inPs, itemData)]).then((result) => {
                // Aquí refresca el listado de items.
                setBalance();
                rf_table_mem_items.current.setTheList(storeSlsDoc.arItems);
                rf_table_mem_items.current.refresh();
              });
            }
          });

        }
      });

    } catch (error) {
      FxLog.errorx("MxSlsDocForm.doBarcodeAddPs", error);
    }
  }

  const getQuickPaymentOptions = () => {
    return [
      { id: 'Cash', text: trans('field.cash') },
      { id: 'Card', text: trans('field.card') },
    ];
  }

  const doQuickPayment = async (option) => {
    try {
      let itPayment = null;
      let txTypePayment = null;
      //let itMovement = null;

      if (option === 'Cash') { // cash
        itPayment = '000001.SRV';
        //itMovement = '000001.SRV';
        txTypePayment = trans('field.cash');
      }
      if (option === 'Card') { // card
        itPayment = '000003.SRV';
        //itMovement = '000003.SRV';
        txTypePayment = trans('field.card');
      }

      if (!itPayment) {
        // el usuario clicó fuera del menú, así que salimos.
        return;
      }

      let balance = FxNum.to2r(storeSlsDoc.balance * -1);

      storeSlsLst.table_paym_idx = null;
      Promise.all([setStoreSlsLst(storeSlsLst)]).then((result) => {
        let paymData = {
          dtPayment: FxDat.today(),
          itCash: getStoreValue('StoreSession', 'itCash'),
          txTypeCash: getStoreValue('StoreSession', 'txTypeCash'),
          itPayment: itPayment,
          txTypePayment: txTypePayment,
          itPaymentDet: '',
          txTypePaymentDet: '',
          nuAmount: balance,
        };

        let idxForEdit = null; // xq siempre es para añadir (y no modificar).


        // Saving
        //---------------------------------------------
        Promise.all([MxSlsDocFormPaymH1.savePaymToStore(storeSlsDoc, setStoreSlsDoc, storeSlsLst.table_paym_idx, idxForEdit, paymData)]).then((result) => {
          // Aquí refresca el listado de pagos.
          setBalance();
          rf_table_mem_payms.current.setTheList(storeSlsDoc.arPayms);
          rf_table_mem_payms.current.refresh();
        });
      });

    } catch (error) {
      FxLog.errorx("MxSlsDocForm.doQuickPayment", error);
    }
  }

  const openFormRxsNewAuto = async () => {
    if (!FxEdition.isInEdition('CLI_rxs')) {
      r_dlg_upgrade.current.setOpen(true);
      return;
    }
    if (!storeSlsDoc.inClient) {
      r_snack_error.current.setVal(trans('msg.missing_x', 'field.client'));
      r_snack_error.current.setOpen(true);
      return;
    }
    if (!storeSlsDoc.inAnimal) {
      r_snack_error.current.setVal(trans('msg.missing_x', 'field.patient'));
      r_snack_error.current.setOpen(true);
      return;
    }
    clearStore('StoreRxAuto', storeRxAuto);
    storeRxAuto.boFirstRender = true;
    storeRxAuto.boDataChanged = '0';
    storeRxAuto.boAuto = true;
    storeRxAuto.inRxAuto = null;
    storeRxAuto.txRxAuto = null;
    storeRxAuto.itSeriesRx = null;
    storeRxAuto.inInvoice = storeSlsDoc.inXXX;
    Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
      Promise.all([MxRxAutoFormH1.loadFromInvoice(storeRxAuto)]).then((result) => {
        Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
          navTo("animal-rx-auto-form");
        });
      });
    });
  }

  const getTableItemColumns = () => {
    let cols = [];
    cols.push({ id: "id1", Header: "ID", accessor: "idx", show: false });
    cols.push({ id: "id2", Header: "ID", accessor: `${storeSlsLst.table_item_idField}`, show: false });
    cols.push({ id: "fq", Header: trans('field.frequency'), accessor: row => FxTable.cellToInt(row["nuFrequency"]), align: 'right', show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: "ps", Header: trans('field.ps'), accessor: "txPs", width: '40%' });
      cols.push({ id: "qy", Header: trans('field.quantity'), accessor: row => FxTable.cellTo2r00(row["nuQuantity"]), width: '12%', align: 'right' });
      cols.push({ id: "pr", Header: trans('field.price'), accessor: row => FxTable.cellTo2r00(row["nuPrice"]), width: '12%', align: 'right' });
      cols.push({ id: "dp", Header: trans('field.discount_perc'), accessor: row => FxTable.cellTo2r00(row["nuDiscountPerc"]), width: '12%', align: 'right' });
      cols.push({ id: "tp", Header: trans('field.tax_perc'), accessor: row => FxTable.cellTo2r00(row["nuTaxPerc"]), width: '12%', align: 'right' });
      cols.push({ id: "tot", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '12%', align: 'right' });

    } else { // PANTALLA PEQUEÑA
      cols.push({ id: "ps", Header: trans('field.ps'), accessor: "txPs", width: '60%' });
      cols.push({ id: "qy", Header: trans('field.quantity'), accessor: row => FxTable.cellTo2r00(row["nuQuantity"]), width: '20%', align: 'right' });
      cols.push({ id: "tot", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '20%', align: 'right' });
    }

    return cols;
  }

  const getTablePaymColumns = () => {
    let cols = [];
    cols.push({ id: "id1", Header: "ID", accessor: "idx", show: false });
    cols.push({ id: "id2", Header: "ID", accessor: "inPayment", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: "dat", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtPayment"]), width: '18%' });
      cols.push({ id: "csh", Header: trans('field.cash_reg'), accessor: "txTypeCash", width: '35%' });
      cols.push({ id: "typ", Header: trans('field.type'), accessor: "txTypePayment", width: '35%' });
      cols.push({ id: "tot", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '12%', align: 'right' });
      
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: "dat", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtPayment"]), width: '30%' });
      cols.push({ id: "typ", Header: trans('field.type'), accessor: "txTypePayment", width: '50%' });
      cols.push({ id: "tot", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '20%', align: 'right' });
    }

    return cols;
  }

  return (
    <div>
      {logStatus()}
      <GxForm
        xref={r_form}
        moduleName={`${trans('field.sales')}: ${docTypeName}`}
        canGoBack={canGoBack}
        toolbarBackground={storeSlsLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de cargar último cliente utilizado */}
            {(storeSlsDoc.docType === 'q' || storeSlsDoc.docType === 'i' || storeSlsDoc.docType === 'r')
              && !storeSlsDoc.inXXX
              && <GxIconButtonLastClient2 theInput={rf_txClient} />}

            {/* botón de receta */}
            {(storeSlsDoc.docType === 'i')
              && isApp('vett') && storeSlsDoc.inXXX && showRxIcon && !autoCreateRxForce
              && <CxIconButtonNew icon={<FaPrescription fontSize="20px" style={{paddingTop:'2px'}} />} classType="ibs_iconbutton_toolbar" onClick={() => openFormRxsNewAuto()} tooltip={trans('field.rx')} />}

            {/* botón de impresión */}
            {(storeSlsDoc.docType === 'q' || storeSlsDoc.docType === 'i' || storeSlsDoc.docType === 'r')
              && storeSlsDoc.inXXX
              && <CxIconButtonNew icon={<BiPrinter fontSize="24px" />} classType="ibs_iconbutton_toolbar" onClick={() => setDlgPrint(true)} tooltip={trans('field.print')} />}

            {/* botón de generación de albarán a partir de presupuesto */}
            {storeSlsDoc.docType === 'q' && storeSlsDoc.inXXX && isFullAccess()
              && <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={() => setDlgIssue(true)} tooltip={trans('field.issue_x', 'field.invoice')} />}

            {/* botón de generación de factura a partir de albarán */}
            {storeSlsDoc.docType === 'i' && !storeSlsDoc.inReceipt && storeSlsDoc.inXXX && isFullAccess() && useReceipts
              && <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={() => setDlgIssue(true)} tooltip={trans('field.issue_x', 'field.receipt')} />}

            {/* botón de cambio de datos del cliente en factura */}
            {storeSlsDoc.docType === 'r'
              && !storeSlsDoc.isVoid && storeSlsDoc.inXXX && isFullAccess() && !storeSlsDoc.inReceiptOri
              && <CxIconButton type="change_client_info" classType="ibs_iconbutton_toolbar" onClick={() => r_dlg_clientdata.current.openDlg(storeSlsDoc.inXXX)} tooltip={trans('msg.change_client_data_on_receipt')} />}

            {/* botón de creación de documento negativo */}
            {storeSlsDoc.docType === 'r'
              && !storeSlsDoc.isVoid && storeSlsDoc.inXXX && isFullAccess() && !storeSlsDoc.inReceiptOri
              && <CxIconButton type="minus" classType="ibs_iconbutton_toolbar" onClick={() => setDlgSeriesForNegativeReceipt(true)} tooltip={trans('field.negative_receipt')} />}

            {/* botón eliminar. Sólo eliminar lo que no es factura ni relacionado a factura. */}
            {storeSlsDoc.inXXX && !isDisabled() && isFullAccess()
              && <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar. Incluso la factura puede guardar cambios para poder cambiar las notas. */}
            {!storeSlsDoc.isVoid && isFullAccess()
              && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save')} />}

          </div>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '5px' }}>
            {storeSlsDoc.docType === 'i' && storeSlsDoc.inReceipt && <CxChip xref={rf_chip_rec} icon={<MdReceipt size={20} color={"var(--ibs_color_toggle_green)"} />} label={trans('field.receipted')} />}
            {storeSlsDoc.isVoid && <CxChip xref={rf_chip_void} icon={<FaRegTimesCircle size={20} color={"var(--ibs_color_blue)"} />} label={trans('field.voided')} />}
            <CxChip xref={rf_chip_balance} icon={<FaCoins size={18} color={"var(--ibs_color_cy_yellow)"} />} />
          </div>
        </div>

        {/* DATOS DE DOCUMENTO */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}

        <CxDate xref={rf_dtXXX_d} label={trans('field.date')} required disabled={isDisabled()} />
        <CxTime xref={rf_dtXXX_t} label={trans('field.time')} required disabled={isDisabled()} />
        <CxInput xref={rf_txXXX} label={trans('field.doc')}
          maxLength="16"
          required={storeSlsDoc.inXXX ? true : false}
          placeholder={storeSlsDoc.inXXX ? '' : 'AUTO'}
          disabled={isDisabled()}
        />
        <CxChooser xref={rf_txTypeSeries} label={trans('field.series')}
          table="t_series"
          col_id="itSeries"
          col_tx="txTypeSeries"
          extra_filter_1={"boActive='1'"}
          disabled={isDisabled()}
        />

        <CxState mode='checkbox' xref={rf_hasWTax} label={trans('field.wtax')}
          onChange={changedWTax}
          disabled={(isDisabled() || storeSlsDoc.arPayms.length > 0) ? true : false}
        />


        {/* DATOS DE PERSONAS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <GxSearcherClient xref={rf_txClient} animalRef={rf_txAnimal} 
          onClear={() => { 
            loadRates(); //limpia tasas
          }} 
          onSelection={(row) => {
            FxLog.infox('MxSlsDocForm.return.GxSearcherClient', '...row.original:', row.original);
            loadRates(row.original.inClient, row.original.txClient, row.original.animal_inAnimal, row.original.animal_txAnimal);
          }} 
          disabled={isDisabled()}
        />
        {(isApp('vett') || isApp('spaw')) && (storeSlsDoc.docType === 'i' || storeSlsDoc.docType === 'q') && 
          <GxSearcherAnimal xref={rf_txAnimal} clientRef={rf_txClient}
            onClear={(inClient, txClient) => { 
              loadRates(inClient, txClient); //vuelve a tasas del cliente
            }} 
            onSelection={(inClient, txClient, inAnimal, txAnimal) => {
              loadRates(inClient, txClient, inAnimal, txAnimal);
            }}
            disabled={isDisabled()} 
          />}

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
          disabled={isDisabled()}
        />

        {/* TABLA PRODUCTOS Y SERVICIOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {(storeSlsDoc.docType === 'i' || storeSlsDoc.docType === 'q') &&
          <CxTableMem
            id='doc_items'
            title={trans('field.pss')}
            xref={rf_table_mem_items}
            onOpenForm={openFormItem}
            hasButtonAdd={isFullAccess()}
            addTableButtonsLeft={
              isFullAccess() &&
              <CxIconButtonNew icon={<RiBarcodeFill fontSize="25px" />} classType="table_button"
                onClick={() => r_dlg_barcode.current.openWindow(true)}
                disabled={Boolean(isDisabled() || storeSlsDoc.isVoid)}
                tooltip={trans('field.add_by_x', 'field.barcode')}
              />
            }
            disabled={Boolean(isDisabled() || storeSlsDoc.isVoid)}
            pageSize={5}
            list={storeSlsDoc.arItems || []}
            columns={getTableItemColumns()}
            label={trans('field.ps')}
            required
          />
        }

        {/* TABLA PAGOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {(storeSlsDoc.docType === 'i') &&
          <CxTableMem
            id='doc_payms'
            title={trans('field.payments')}
            xref={rf_table_mem_payms}
            onOpenForm={openFormPaym}
            hasButtonAdd={isFullAccess()}
            addTableButtonsLeft={
              isFullAccess() &&
              <CxButtonMenu onSelection={doQuickPayment} className='table_button'
                icon={<BsCashStack fontSize="25px" />}
                options={getQuickPaymentOptions()}
                disabled={Boolean(storeSlsDoc.isVoid)}
                posV="top" posH="right"
                tooltip={trans('field.automatic_x_1', 'field.payment')}
              />
            }
            disabled={Boolean(storeSlsDoc.isVoid)}
            pageSize={5}
            list={storeSlsDoc.arPayms || []}
            columns={getTablePaymColumns()}
          />
        }

        {/* NOTAS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <CxInput xref={rf_txNotes} label={trans('field.notes')} minRows={2} disabled={Boolean(storeSlsDoc.isVoid)} />

        {/* MOSTRAR TABLA ALBARANES DE FACTURAS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {(storeSlsDoc.docType === 'r') &&
          <div>
            <CxLink label={`[${trans('field.invoices').toUpperCase()}]`} 
              style={{ margin: '0 0 0 1px', fontSize: '12px' }} 
              onClick={() => navTo('sls-receipt-invoices-list')} 
            />
          </div>
        }

      </GxForm>

      <CxDialog
        title={trans('field.issue_x', storeSlsDoc.docType === 'q' ? trans('field.invoice') : trans('field.receipt'))}
        open={dlgIssue}
        onOk={() => doIssueDoc()}
        onCancel={() => setDlgIssue(false)}
        height='250px'>

        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }} >
          <CxDate xref={rf_issue_date} label={trans('field.date')} width='var(--ibs_control_width_md)' today required />
        </div>
      </CxDialog>

      <CxDialog title={trans('msg.ask_print_x').replace('%s', docTypeName)} open={dlgPrint} onCancel={() => setDlgPrint(false)} onOk={() => doPrintX()} height='280px'>
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxCombo xref={rf_print_format}
            label={`${trans('field.type')}`}
            width='var(--ibs_control_width_md)'
            list={storeSlsDoc.docType === 'q' ?
              [
                { id: 'A4', type: trans('field.paper_a4') },
              ] : [
                { id: 'A8', type: trans('field.paper_a8') },
                { id: 'A4', type: trans('field.paper_a4') },
              ]}
            defaultValue={localStorage.getItem(`MxSlsDocForm.${storeSlsDoc.docType}.docSize`) || 'A4'}
          />
          <CxNumber xref={rf_print_copies} label={trans('field.copies')}
            decimals={0}
            width='var(--ibs_control_width_md)'
            sumButton
          />
          <CxState mode='checkbox' xref={rf_print_preview}
            label={trans('field.preview')}
            width='var(--ibs_control_width_md)'
            value={localStorage.getItem(`MxSlsDocForm.${storeSlsDoc.docType}.boPreview`) || '0'}
          />
        </div>
      </CxDialog>

      <CxDialog title={trans('msg.confirm_operation')} open={dlgExit} onCancel={() => setDlgExit(false)} onOk={() => navBack()} height='200px'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: 'var(--ibs_control_width_lg)' }} >
          {trans('msg.ask_exit_without_saving')}
        </div>
      </CxDialog>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='220px'>
        <CxStatic label={trans('msg.ask_delete').toUpperCase()} border='none' fontcolor='var(--ibs_color_red)' fontsize='14px' width='var(--ibs_control_width_lg)' />
        {storeSlsDoc.docType === 'i' && <CxState mode='checkbox' xref={rf_delete_return_stock} label={trans('msg.return_x', 'field.stock')} value='1' />}
      </CxDialog>

      <CxDialog
        title={trans('field.negative_receipt')}
        open={dlgSeriesForNegativeReceipt}
        onCancel={() => setDlgSeriesForNegativeReceipt(false)}
        onOk={() => doNegativeDoc()}
        height='200px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxChooser xref={rf_negative_receipt_txTypeSeries} label={trans('field.series')}
            table="t_series"
            col_id="itSeries"
            col_tx="txTypeSeries"
            extra_filter_1={"boActive='1'"}
          />
        </div>
      </CxDialog>

      <MxSlsDocFormClientDataDlg xref={r_dlg_clientdata} onOk={(txClientNew) => {
        storeSlsDoc.txClient = txClientNew;
        setStoreSlsDoc(storeSlsDoc);
        rf_txClient.current.setVal(txClientNew);
      }} />

      <CxDlgBarcode xref={r_dlg_barcode} onAddPs={doBarcodeAddPs} />

      <GxPrint xref={r_print} />
      <CxBackdrop open={backdrop} />

      <CxDlgAlert xref={r_dlg_alert} />
      <CxDlgUpgrade xref={r_dlg_upgrade} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => { setBackdrop(false) /* navBack(); */ }} />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => { navBack(); }} />
      <CxSnackbar xref={r_snack_missing_template} severity="error" value={trans('msg.missing_x', 'field.template')} />
      <CxSnackbar xref={r_snack_error} severity="error" />

      <GxLongTaskWin xref={r_task} txTaskName="tsk_sls_doc_save" onProgress={onTaskSaveProgress} onEnd={onTaskSaveEnd} />

    </div>
  );

}

export default MxSlsDocForm;
