import { BsCalendarPlus } from "react-icons/bs"; 
import { BsCalendarDate } from "react-icons/bs"; 
import React from 'react';
import i18n from '../../../i18n';
import GxList from '../../gx/GxList';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { useNav } from '../../hx/useNav';
import * as FxDat from '../../fx/FxDat';
import { useWindowSize } from '../../hx/useWindowSize';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import * as FxFetch from '../../fx/FxFetch';
import { StoreWaiting } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgCalendar from '../../cx/CxDlgCalendar';
import CxDlgCheckList from '../../cx/CxDlgCheckList';
import CxSnackbar from '../../cx/CxSnackbar';


const MxWaitingList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();
  const r_list = React.useRef(null);
  const timer = React.useRef(null);
  const size = useWindowSize();
  const rf_cxDlgCalendar = React.useRef(null);
  
  const [storeWaiting, setStoreWaiting] = useSessionStorage('StoreWaiting', StoreWaiting);
  const r_date = React.useRef(FxDat.today());
  
  const [dlgNewDate, setDlgNewDate] = React.useState(false);

  const r_dlg_import_calendar = React.useRef(null);

  const r_snack_error = React.useRef(null);
  const r_snack_save = React.useRef(null);
  

  React.useEffect(() => {
    if (storeWaiting._date) {
      doDateSelectedFromCalendar(storeWaiting._date.substring(0,10));
    }

    timer.current = setInterval(() => {
      if (FxInet.isOnline()) {
        Promise.all([r_list.current.refresh()]).then((result) => {
          FxLog.infox('MxWaitingList.useEffect', '...result[0]:', result[0]);
          if (result[0]==='FETCH-ERROR: NULL') { // el servidor devolvió null, significando que no tiene esa db activa.
            clearInterval(timer.current);
          }
        });
      }
    }, 10000); //milisegundos

    return () => {
      // refresca listado para mostrar los estados.
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    r_list.current.refresh();
    // eslint-disable-next-line
  }, [size]);

  const openForm = async (op, row) => {
    storeWaiting._date = r_date.current;
    if (row === null) {
      storeWaiting.inWaiting = null;
    } else {
      storeWaiting.inWaiting = row.original.inWaiting;
    }
    setStoreWaiting(storeWaiting);
    navTo("waiting-form");
  }

  const doDateSelectedFromCalendar = (d) => {
    FxLog.infox('MxWaitingList.doDateSelectedFromCalendar', '...d:', d);
    setDlgNewDate(false);
    if (d) {
      r_date.current = d || '';
      Promise.all([r_list.current.setdeepFilter(`date(dtIssue)='${r_date.current}'`)]).then((result) => {
        r_list.current.setModuleName(`${trans('field.waiting_room')}: ${FxDat.toLocalDate(r_date.current)}`);
        r_list.current.refresh();
      });
    }
  }

  const doImportFromCalendarOpen = () => {
    let record = {};
    record['txExtraFilter1'] = `date(n_calendar.dtFrom)='${r_date.current}' and date(n_calendar.dtTo)='${r_date.current}'`;
    Promise.all([FxFetch.doJsonX('waiting/import/events_get', record)]).then((result) => {
      if (result[0]) {
        let ls = result[0].reverse(); //invertimos array
        let calColsFinal = ls.map((item) => (
          item = { ...item, checked: '1' }
        ));
        Promise.all([r_dlg_import_calendar.current.setList(calColsFinal)]).then((result) => {
        });
      }
    });
    
    r_dlg_import_calendar.current.setOpen(true);
  }

  const doImportFromCalendarCopy = (colsSelected) => {
    FxLog.infox('MxWaitingList.doImportFromCalendarCopy', '...colsSelected:', colsSelected);
    let record = {};
    record['colsSelected'] = colsSelected;
    Promise.all([FxFetch.doJsonX('waiting/import/events_save_to_waiting', record)]).then((result) => {
      if (result[0].theValue === 'rv_no_events_selected') {
        r_snack_error.current.setVal(trans('msg.x_not_selected','field.events'));
        r_snack_error.current.setOpen(true);
      } else {
        r_snack_save.current.setOpen(true);
        r_list.current.refresh();
      }
    });
  }

  const getTableColumns = () => {
    let cols = [];
    let id = -1;
    cols.push({ id: id++, Header: "ID", accessor: "inWaiting", show: false });
    if (size.isLarge) { // PANTALLA GRANDE
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.patient'), accessor: row => r_list.current.getTable().highlightText(row["txAnimal"]), width: '15%'});
      cols.push({ id: id++, Header: trans('field.time'), accessor: row => (FxTable.cellToLocalTime(row["dtIssue"])), width: '5%' });
      cols.push({ id: id++, Header: trans('field.issue'), accessor: row => r_list.current.getTable().highlightText(row["txIssue"]), width: '30%' });
      cols.push({ id: id++, Header: trans('field.status'), accessor: row => (Boolean(row["boPresent"] === '1') ? row["txTypeStatusWaiting"] : trans('field.absent')), width: '10%' });
      cols.push({ id: id++, Header: trans('field.balance'), accessor: row => (FxTable.cellTo2r00(row["nuBalance"])), width: '10%', align: 'right' });
    } else { // PANTALLA PEQUEÑA
      cols.push({ id: id++, Header: trans('field.client'), accessor: row => r_list.current.getTable().highlightText(row["txClient"]), width: '55%' });
      cols.push({ id: id++, Header: trans('field.time'), accessor: row => (FxTable.cellToLocalTime(row["dtIssue"])), width: '15%' });
      cols.push({ id: id++, Header: trans('field.status'), accessor: row => (Boolean(row["boPresent"] === '1') ? row["txTypeStatusWaiting"] : trans('field.absent')), width: '30%' });
    }
    cols.push({ id: 'A', Header: '_present', accessor: row => Boolean(row["boPresent"] === '0'), show: false });
    cols.push({ id: 'R', Header: '_waiting', accessor: row => Boolean(row["itStatusWaiting"] === '000001.SRV'), show: false });
    cols.push({ id: 'B', Header: '_attended', accessor: row => Boolean(row["itStatusWaiting"] === '000002.SRV'), show: false });
    cols.push({ id: 'G', Header: '_finished', accessor: row => Boolean(row["itStatusWaiting"] === '000003.SRV'), show: false });
    return cols;
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.waiting_room')}: ${FxDat.toLocalDate(r_date.current)}`}
        xref={r_list}
        tooltip={`${trans('field.client')} / ${trans('field.patient')}`}
        fullscreen
        //help="waiting"
        url="waiting"
        onOpenForm={openForm}
        refColors={[ 
          'table_cell_color_gray|' + trans('field.absent'),
          'table_cell_color_red|' + trans('field.waiting'), 
          'table_cell_color_blue|' + trans('field.attended_1'), 
          'table_cell_color_green|' + trans('field.finished_1'), 
        ]}
        columns={getTableColumns()}
        filter={`date(dtIssue)='${r_date.current}'`}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew 
              icon={<BsCalendarPlus fontSize={20} style={{ margin: '0 0 0 4px'}} />} 
              //icon2={<FaArrowCircleDown fontSize={12} />} icon2_top={13} icon2_left={15}
              classType="ibs_iconbutton_toolbar" 
              onClick={() => doImportFromCalendarOpen()} 
              tooltip={trans('field.import_x_from_x', 'field.appointments', 'field.calendar')} 
            />
            <CxIconButtonNew 
              icon={<BsCalendarDate fontSize={20} style={{ margin: '0 0 0 4px'}} />} 
              classType="ibs_iconbutton_toolbar" 
              onClick={() => setDlgNewDate(true)} 
              tooltip={trans('field.change_x', 'field.date')} 
            />
          </div>
        }
      />

      <CxDlgCalendar
        xref={rf_cxDlgCalendar}
        open={dlgNewDate}
        onClose={(d) => doDateSelectedFromCalendar(d)}
        title={i18n.t('msg.select_x').replace('%s', i18n.t('field.date'))}
      />

      <CxDlgCheckList xref={r_dlg_import_calendar} 
        title={trans('field.import_x_from_x', 'field.appointments', 'field.calendar')} 
        flexFlow='column'
        width='var(--ibs_control_width_md)'
        height='500px'
        itemWidth='calc(var(--ibs_control_width_md)-5px)'
        onOk={doImportFromCalendarCopy} 
      />

      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} />

    </div>
  );

}

export default MxWaitingList;
