import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxIcon from '../../cx/CxIcon';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxCombo from '../../cx/CxCombo';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';


const MxSettDeviceForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const rf_startupMod = React.useRef(null);

  const r_snack_save = React.useRef(null);

  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    rf_startupMod.current.setVal(localStorage.getItem('MxLogin.startup_mod'));
  }

  const formSaveCustom = async () => {
    localStorage.setItem('MxLogin.startup_mod', rf_startupMod.current.getVal());
    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={trans('field.files')}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <CxCombo xref={rf_startupMod}
          label={`${trans('field.startup')}`}
          list={[
            { id: 'client-list', type: trans('field.clients') },
            { id: 'sls-doc-list', type: trans('field.sales') },
            { id: 'calendar-view', type: trans('field.calendar') },
          ]}
        />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
    </div>
  );

}

export default MxSettDeviceForm;
