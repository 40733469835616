import { GrMoreVertical } from "react-icons/gr"; 
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import CxTooltip from './CxTooltip';


const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: props => { return props.width },
    borderRadius: '5px',
    fontFamily: 'var(--ibs_control_label_font_family)',
    fontSize: '14px',
    color: 'var(--ibs_color_black)',
  },
  root: {
    fontSize: '14px',
    background: 'transparent',
  }
}));


const ITEM_HEIGHT = 48;

export default function CxIconButtonMenu(props) {
  const classes = useStyles(props);
  const { xref, onSelection, data, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [options, setOptions] = React.useState(rest.options);
  const open = Boolean(anchorEl);

  const [disabled, setDisabled] = React.useState(rest.disabled);
  React.useEffect(() => setDisabled(rest.disabled), [rest.disabled]); // mantiene actualizado la habilitación, si se cambia el valor isDisabled en la llamada.

  const [icon, ] = React.useState(rest.icon);

  const rf_tooltip = React.useRef(null);
  
  React.useImperativeHandle(xref, () => {
    return {
      setOptions, setDisabled, getTooltip
    };
  });

  const getTooltip = () => {
    return rf_tooltip.current;
  }

  const doClick = (event) => {
    if (options) {
      setAnchorEl(event.currentTarget);
    } else {
      onSelection();
    }
  };

  const doSelection = (event) => {
    setAnchorEl(null); // handle close
    const { myValue } = event.currentTarget.dataset;
    if (onSelection && myValue) {
      onSelection(myValue, data);
    }
  };

  return (
    <div>
      <CxTooltip xref={rf_tooltip} text={rest.tooltip ? rest.tooltip : null}>
        <span>
          <IconButton className={rest.className} onClick={doClick} disabled={disabled}>
            {!icon && <GrMoreVertical fontSize="24px" color={props.defaultIconColor} />}
            {icon}
          </IconButton>
        </span>
      </CxTooltip>
      {options && <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ // es el punto a partir del cual se despliega
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{ // es la dirección hacia la que se despliega
          vertical: rest.posV || "top",
          horizontal: rest.posH || "left",
        }}
        keepMounted
        open={open}
        onClose={doSelection}
        classes={{ paper: classes.paper }}
        disableScrollLock={true}
      >
        {options.map((option) => (
          <MenuItem
            classes={{ root: classes.root }}
            style={{ color: option.color || 'var(--ibs_color_black)' }}
            key={option.id}
            data-my-value={option.id}
            onClick={doSelection}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>}
    </div>
  );
}
