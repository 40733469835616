import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { clearStore, StoreSettLab } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxList from '../../gx/GxList';


const MxSettLabList = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navTo }] = useNav();

  const [storeSettLab, setStoreSettLab] = useSessionStorage('StoreSettLab', StoreSettLab);

  React.useEffect(() => {
    clearStore('StoreSettLab', storeSettLab);
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
  }

  const openForm = async (op, row) => {
    if (row === null) {
      storeSettLab.itLab = null;
    } else {
      storeSettLab.itLab = row.original.itLab;
    }
    setStoreSettLab(storeSettLab);
    navTo("sett-lab-form");
  }

  return (
    <div>
      <GxList
        moduleName={`${trans('field.types')} - ${trans('field.labs')}`}
        fullscreen
        hasSearchBox={false}
        url="sett/lab"
        onOpenForm={openForm}
        columns={[
          { id: "0", Header: "ID", accessor: "itLab", show: false },
          { id: "1", Header: `${trans('field.type')} - ${trans('field.lab')}`, accessor: "txTypeLab", width: '100%' },
        ]}
      />
    </div>
  );
}

export default MxSettLabList;
