import { AiFillCaretLeft } from "react-icons/ai"; 
import { BiSave } from "react-icons/bi"; 
import CxIcon from '../../cx/CxIcon';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
import CxState from '../../cx/CxState';
import CxSnackbar from '../../cx/CxSnackbar';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import GxForm from '../../gx/GxForm';


const MxSettCalendarVisibleDays = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  const r_snack_save = React.useRef(null);
  const r_snack_required = React.useRef(null);

  let r_form = React.useRef(null);

  const rf_day_mo = React.useRef(null);
  const rf_day_tu = React.useRef(null);
  const rf_day_we = React.useRef(null);
  const rf_day_th = React.useRef(null);
  const rf_day_fr = React.useRef(null);
  const rf_day_sa = React.useRef(null);
  const rf_day_su = React.useRef(null);


  React.useEffect(() => {
    formLoad();
    // eslint-disable-next-line
  }, []);

  const formLoad = async () => {
    let record = {};
    Promise.all([FxFetch.doJsonX('cal/sett', record)]).then((result) => {
      if (result[0]) {
        let txWeekDays = result[0].txWeekDays;
        rf_day_mo.current.setValBoolean(txWeekDays.charAt(0) === '1');
        rf_day_tu.current.setValBoolean(txWeekDays.charAt(1) === '1');
        rf_day_we.current.setValBoolean(txWeekDays.charAt(2) === '1');
        rf_day_th.current.setValBoolean(txWeekDays.charAt(3) === '1');
        rf_day_fr.current.setValBoolean(txWeekDays.charAt(4) === '1');
        rf_day_sa.current.setValBoolean(txWeekDays.charAt(5) === '1');
        rf_day_su.current.setValBoolean(txWeekDays.charAt(6) === '1');
      }
    });
  }

  const formSaveCustom = async () => {

    if ( // si ninguno está chequeado, solicitamos que marque alguno
      !rf_day_mo.current.isChecked() && !rf_day_tu.current.isChecked() && !rf_day_we.current.isChecked() &&
      !rf_day_th.current.isChecked() && !rf_day_fr.current.isChecked() && !rf_day_sa.current.isChecked() &&
      !rf_day_su.current.isChecked()
    ) {
      r_snack_required.current.setVal(trans('msg.check_at_least_one'));
      r_snack_required.current.setOpen(true);
      return;
    }

    let record = {};
    record['mo'] = rf_day_mo.current.getVal();
    record['tu'] = rf_day_tu.current.getVal();
    record['we'] = rf_day_we.current.getVal();
    record['th'] = rf_day_th.current.getVal();
    record['fr'] = rf_day_fr.current.getVal();
    record['sa'] = rf_day_sa.current.getVal();
    record['su'] = rf_day_su.current.getVal();
    Promise.all([FxFetch.doJsonF("/sett/cal/visible_days/u", null, record)]).then((result) => {
      if (result[0]) {
        if (result[0].theValue === 'OK') {
          r_snack_save.current.setOpen(true);
        }
      }
    });

    r_snack_save.current.setOpen(true);
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.calendars')} - ${trans('field.visible_days')}`}
        xref={r_form}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CxIconButtonNew icon={<CxIcon iconBack={<BiSave/>} iconFront={<AiFillCaretLeft/>} />} classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save_and_exit')} />
          </div>
        }
      >
        <CxState mode='checkbox' xref={rf_day_mo} label={trans('field.day_mo')} />
        <CxState mode='checkbox' xref={rf_day_tu} label={trans('field.day_tu')} />
        <CxState mode='checkbox' xref={rf_day_we} label={trans('field.day_we')} />
        <CxState mode='checkbox' xref={rf_day_th} label={trans('field.day_th')} />
        <CxState mode='checkbox' xref={rf_day_fr} label={trans('field.day_fr')} />
        <CxState mode='checkbox' xref={rf_day_sa} label={trans('field.day_sa')} />
        <CxState mode='checkbox' xref={rf_day_su} label={trans('field.day_su')} />

      </GxForm>

      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => navBack()} />
      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxSettCalendarVisibleDays;
