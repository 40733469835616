import { BiMicrophone } from "react-icons/bi"; 
import { BiInfoCircle } from "react-icons/bi"; 
import { BiSearch } from "react-icons/bi"; 
import { HiDownload } from "react-icons/hi"; 
import { MdClear } from "react-icons/md"; 
import { RiAsterisk } from "react-icons/ri"; 
import { BiLockAlt } from "react-icons/bi"; 
import React, { useState, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import '../../styles/main.css';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import CxIconButtonNew from "./CxIconButtonNew";
import CxTooltip from './CxTooltip';
import CxDlgRadioList from './CxDlgRadioList';
import CxDlgPin from './CxDlgPin';
import CxSpeech from './CxSpeech';
import { useStyles } from "./StylesCx";
import { useTranslation } from '../hx/useTranslation';
import { getStoreValueBo } from "../gx/GxStore";


const CxInput = props => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);
  const { onGetValue, onBlur, onClear, onChange, onEnter, onSearch, onInfo, InputLabelProps, defaultValue, redText, 
    upperCase, lowerCase, normalizeSpaces, capitalize, speech, speechExternal, onSpeechFinish, customButton, ...rest } = props;
  const [myValue, setMyValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [wasChanged, setChanged] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [visible, setVisible] = useState(rest.visible==='false' ? false : true);

  const r_dlg_placeholders = React.useRef(null);
  const r_clearing = React.useRef(false);
  const r_dlg_pin = React.useRef(null);
  const r_speech = React.useRef(null);

  const rf_control = React.useRef(null);
  const rf_tooltip = React.useRef(null);

  React.useEffect(() => {
    setIsRequired(props.required);
    if (defaultValue) {
      setVal(defaultValue);
    }
    if (props.value) {
      setMyValue(props.value);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (r_clearing.current) {
      r_clearing.current = false;
      if (onClear !== undefined) onClear();
    }
    // eslint-disable-next-line
  }, [myValue]);

  const doClear = () => {
    if (!props.readOnly && !props.disabled) {
      r_clearing.current = true;
      setMyValue('');
      setChanged(true);
      setFocus();
    }
  }

  const doGetValue = () => {
    if (!props.readOnly && !props.disabled) {
      props.onGetValue();
    }
  }

  const doSearch = () => {
    if (!props.readOnly && !props.disabled) {
      props.onSearch();
    }
  }

  const doInfo = () => {
    if (!props.readOnly && !props.disabled) {
      props.onInfo();
    }
  }

  const setTooltipText = (text) => {
    rf_tooltip.current.setTooltipText(text);
  }

  const getTooltipText = () => {
    return rf_tooltip.current.getTooltipText();
  }

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getIsRequired, getLabel, getDbfield, setFocus, doClear, isEmpty, clear, wasChanged, setChanged,
      setTooltipText, getTooltipText, setLocked, doSpeech, setVisible,
    };
  });
  const setVal = (newValue) => {
    if (newValue) {
      newValue = newValue.toString().replace(/['"]+/g, "`"); // 2023-10-02 - daq: quita comillas simples y dobles para evitar errores en queries

      if (upperCase) newValue = newValue.toUpperCase();
      if (lowerCase) newValue = newValue.toLowerCase();
      if (normalizeSpaces) newValue = newValue.replace(/\s+/g, ' ');
      if (capitalize) {
        if (getStoreValueBo('StoreSession', 'boUseUcaseForNames')) {
          newValue = newValue.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        }
      }
    }
    setMyValue(newValue)
  };
  const getVal = () => {
    return myValue;
  };
  const getIsRequired = () => { return isRequired && (myValue===null || myValue==='') };
  const getLabel = () => { return props.label };
  const getDbfield = () => { return props.dbfield };
  const setFocus = () => rf_control.current.focus();
  const isEmpty = () => { return myValue.length === 0 };
  const clear = () => { setMyValue(''); setChanged(true); };
  const getType = () => { return 'CxInput' };

  const doPlaceholderInsert = (option) => {
    FxLog.infox("CxInput.doPlaceholderInsert", "...option:", option);

    Promise.all([r_dlg_placeholders.current.setOpenAndFill(false)]).then(() => {
      if (option) {
        rf_control.current.focus();
        let textToInsert = option;
        let cursorPosition = rf_control.current.selectionStart;
        let textBeforeCursorPosition = myValue.substring(0, cursorPosition);
        let textAfterCursorPosition = myValue.substring(cursorPosition, myValue.length);
        let newText = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        setMyValue(newText);
        let posTo = textBeforeCursorPosition.length + textToInsert.length;
        rf_control.current.selectionStart = posTo;
        rf_control.current.selectionEnd = posTo;
      }
    });
  }

  const doSpeech = (startStop) => {
    r_speech.current.doStartStop(props.label, startStop);
  }

  return (
    <CxTooltip xref={rf_tooltip} text={props.tooltip}>
      <div id='div_Cxinput' className={rest.framesimple ? classes.frame_simple : classes.frame} style={{ display: visible ? '' : 'none' }}>
        <div className={rest.framesimple ? classes.frame_simple_content : classes.frame_content}>
          <TextField
            inputRef={rf_control}
            disabled={locked}
            minRows={props.minRows}
            multiline={props.minRows !== undefined ? true : false}
            className={classes.control}
            autoComplete='new-password'
            InputLabelProps={{
              classes: { root: classes.root, },
              shrink: true,
              ...InputLabelProps
            }}
            InputProps={{ //refers to the Input component
              disableUnderline: true,
              required: false,
              classes: { root: classes.root, },
              inputProps: { //refers to the input element
                maxLength: props.maxLength,
                readOnly: props.readOnly,
                style: {
                  color: redText ? 'var(--ibs_color_red)' : ((props.disabled || locked) ? 'var(--ibs_color_disabled)' : 'var(--ibs_color_black)'),
                  textTransform: upperCase ? 'uppercase' : lowerCase ? 'lowercase' : (capitalize && getStoreValueBo('StoreSession', 'boUseUcaseForNames')) ? 'capitalize' : '',
                },
              },
            }}
            value={myValue || ''}
            onBlur={() => {
              if (normalizeSpaces) setMyValue(myValue.trim());
              if (props.onBlur !== undefined) props.onBlur(props.dbfield, myValue);
            }}
            onChange={(e) => {
              Promise.all([setVal(e.target.value)]).then((result) => {
                if (onChange) onChange(e.target.value);
              });
            }}
            onFocus={event => {
              if (rest.autoselect) event.target.select();
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                if (onEnter) onEnter();
              } else {
                setChanged(true);
              }
            }}
            {...rest}
          />
        </div>

        {!props.hidebuttons && <div className={classes.frame_buttons}>
          {!props.readOnly && !locked && <CxIconButtonNew icon={<MdClear fontSize={20} />} classType={classes.frame_icons_new} onClick={doClear} disabled={props.disabled} />}
          {props.onGetValue && <CxIconButtonNew icon={<HiDownload fontSize={20} />} classType={classes.frame_icons_new} onClick={doGetValue} disabled={props.disabled} />}
          {props.onSearch && <CxIconButtonNew icon={<BiSearch fontSize={20} />} classType={classes.frame_icons_new} onClick={doSearch} disabled={props.disabled} />}
          {props.onInfo && <CxIconButtonNew icon={<BiInfoCircle fontSize={18} />} classType={classes.frame_icons_new} onClick={doInfo} disabled={props.disabled} />} {/* OJO ESTE BOTÓN NO ES EL MISMO QUE HAY EN CXTABLE */}
          {props.placeholders && <CxIconButtonNew icon={<RiAsterisk fontSize={20} />} classType={classes.frame_icons_new} onClick={() => r_dlg_placeholders.current.setOpenAndFill(true)} disabled={props.disabled} />}
          {locked && <CxIconButtonNew icon={<BiLockAlt fontSize={20} />} classType={classes.frame_icons_new} onClick={() => r_dlg_pin.current.setOpen(true)} />}
          {customButton && <CxIconButtonNew icon={customButton.icon} classType={classes.frame_icons_new} onClick={customButton.fx} tooltip={customButton.tooltip} />}
          {speech && 
            <CxIconButtonNew icon={<BiMicrophone fontSize={20} />} 
              classType={classes.frame_icons_new} 
              disabled={props.disabled} 
              onMouseDown={() => doSpeech(1)} 
              onMouseUp={() => doSpeech(0)} 
            />
          } {/* OJO ESTE BOTÓN NO ES EL MISMO QUE HAY EN CXTABLE */}
        </div>}

        {props.placeholders &&
          <CxDlgRadioList
            xref={r_dlg_placeholders}
            onOpen={() => { return props.placeholders || [] }}
            onOk={doPlaceholderInsert}
            title={trans('field.placeholders')}
          />
        }

        {locked && <CxDlgPin xref={r_dlg_pin} onOk={() => setLocked(false)} />}

        {(speech||speechExternal) && 
          <CxSpeech xref={r_speech} id={props.label} 
            setVal={(newValue) => {
              if (newValue) {
                setMyValue(newValue);
              }
            }}
            onSpeechFinish={onSpeechFinish} 
            />
        }

      </div>
    </CxTooltip>
  );
};

export default CxInput;
