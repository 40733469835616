import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxCombo from '../../cx/CxCombo';
import CxChooser from '../../cx/CxChooser';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { getStoreValue, StoreSettAnimalLocation } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';


const MxSettAnimalLocationForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();
  const r_snack_record_used = React.useRef(null);

  let r_form = React.useRef(null);
  let rf_txType = React.useRef(null);
  let rf_txBranch = React.useRef(null);

  const [storeSettAnimalLocation, setStoreSettAnimalLocation] = useSessionStorage('StoreSettAnimalLocation', StoreSettAnimalLocation);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
      rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
      rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus==='error') {
    } else {
      storeSettAnimalLocation.itAnimalLocation = lastSaveRv.theValue;
      Promise.all([setStoreSettAnimalLocation(storeSettAnimalLocation)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
    if (rv.theValue==='rv_error') {
      r_snack_record_used.current.setOpen(true);
    }
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.patients')}: ${trans('field.location')}`}
        xref={r_form}
        url="sett/animal_location" 
        filterIdField='itAnimalLocation'
        filterIdValue={storeSettAnimalLocation.itAnimalLocation} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        saveAndExit
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_animal_location.itAnimalLocation" value={storeSettAnimalLocation.itAnimalLocation || ''} />

        <CxInput xref={useRef(null)} label={trans('field.location')} dbfield="t_animal_location.txTypeAnimalLocation" maxLength={50} required />

        <CxCombo xref={rf_txType}
          label={`${trans('field.type')}`}
          dbfield='t_animal_location.txType'
          list={[
            { id: 'X', type: trans('field.all_1') },
            { id: 'H', type: trans('field.cns_hos') },
            { id: 'B', type: trans('field.boarding') },
          ]}
          defaultValue='X'
          clearDisabled
          required
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          dbfield="n_branch.txBranch" dbfieldkey="t_animal_location.inBranch"
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.remarks')} dbfield="t_animal_location.txRemarks" minRows={4} maxLength={255} />
        
      </GxForm>

      <CxSnackbar xref={r_snack_record_used} severity="error" value={trans('msg.record_used')} />

    </div>
  );

}

export default MxSettAnimalLocationForm;
